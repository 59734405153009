import http from './httpServices';

export async function createIdeaFunction(data, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/idea/create-idea`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeaList(sessionToken, userId, currentPage, size) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideas?idUser=${userId}&page=${currentPage}&size=${size}`;
  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getAdminIdeaList(
  sessionToken,
  search,
  currentPage,
  size,
  adminIdeaList
) {
  let status = '&status=';
  // Map status selected

  adminIdeaList.advanceStatusSearch.forEach((item) => {
    if (item.selected) {
      status = status + item.search + `,`;
    }
  });

  status === '&status=' ? (status = '') : null;

  let type = '&type=';
  // Map sector selected

  adminIdeaList.advanceTypeSearch.forEach((item) => {
    if (item.selected) {
      type = type + item.name + `,`;
    }
  });

  type === '&type=' ? (type = '') : null;

  let documentType = '&documentType=';
  // Map sector selected

  adminIdeaList.advanceDocumentSearch.forEach((item) => {
    if (item.selected) {
      documentType = documentType + item.search + `,`;
    }
  });

  documentType === '&documentType=' ? (documentType = '') : null;

  let date = '';

  if (adminIdeaList.startDate.data && adminIdeaList.endDate.data) {
    date = `&startDate=${adminIdeaList.startDate.data}&endDate=${adminIdeaList.endDate.data}`;
  } else {
    date = '';
  }

  let sessionContest = sessionStorage.getItem('contestId');
  let contestId = '';

  if (sessionContest) {
    contestId = '&contestId=' + sessionContest;
  }

  let url = process.env.REACT_APP_API_URL;
  search ? (search = `search=${search.trim()}&`) : (search = '');
  let apiUrl = `/ideasByAdmin?${search}page=${currentPage}&size=${size}${status}${type}${date}${documentType}${contestId}`;
  url = url + apiUrl;
  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getCurrentYearIdeas(sessionToken, userId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideasCurrentYear/${userId}`;
  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteIdea(ideaId, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/remove-idea`;

  var data = {
    id: ideaId,
  };

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function changeStatusIdea(idea, idStatus, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideaChangeStatus/${idea.id}/${idStatus}`;

  var data = {
    idea: idea,
  };

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function updateIdeasAward(ideaId, awardId, status) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url = url + `/updateIdeasAward/${ideaId}/${awardId}/${status}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, {}, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeaById(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideaById/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
