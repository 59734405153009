import axios from 'axios';

import { toast } from 'react-toastify';

function setJWT(jwt) {
  axios.defaults.headers.common['x-auth-token'] = jwt;
}

function setupInterceptors() {
  axios.interceptors.request.use(
    (config) => {
      //Do someyhins before request is sent
      return config;
    },
    (error) => {
      // Do something with error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.message === 'Network Error' && !error.response) {
        toast.error('Network error - Contact Administrator');
      }
      const { status } = error.response;
      if (status === 400) {
        // sessionStorage.clear();
        toast.info(error.response.data.errorMessage || 'Campo inválido');
        return Promise.reject(error);
      }
      if (status === 401) {
        sessionStorage.clear();
        toast.info('Acceso no autorizado');
      }
      if (status === 403) {
        toast.info('Su sesión  ha expirado');
        setTimeout(function () {
          sessionStorage.clear();
          window.location.reload();
        }, 3000);
        return Promise.reject(error);
      }
      if (status === 409) {
        // sessionStorage.clear();
        // toast.error("El usuario ya se encuentra registrado");
        return Promise.reject(error);
      }
      if (status === 500) {
        toast.error('Server error - Contact  Administrator');
      }

      return Promise.reject(error);
    }
  );
}

export default {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
  setJWT,
  setupInterceptors,
};
