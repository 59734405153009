import React from "react";
import OldStageDownClosed from "../../../images/OldStageDownClosed.svg";
import CurrentStageDownClosed from "../../../images/CurrentStageDownClosed.svg";
import "./StageDropdown.scss";

export const StageDropdown = (props) => {
  const {
    showStage,
    label,
    handleStageDropDown,
    name,
    currentState,
    stagedPassed,
    currentStatus,
  } = props;

  let opened = "opened";
  let isCurrent = "new";
  currentState ? null : (isCurrent = "old");

  if (showStage === true || currentState === true) {
    opened = "opened";
  }
  if (showStage === false) {
    opened = "closed";
  }

  if (!currentStatus) {
    return null;
  }
  if (stagedPassed || currentStatus) {
    return (
      <div className={`stage-dropdown ${isCurrent}-stage`}>
        <img
          onClick={() => handleStageDropDown(name)}
          className={`stage-${opened}-dropdown`}
          src={
            isCurrent === "new" ? CurrentStageDownClosed : OldStageDownClosed
          }
          alt="arrow"
        />
        <h3>{label}</h3>
      </div>
    );
  } else {
    return null;
  }
};
