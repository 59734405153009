/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import registered from '../../../images/registered.svg';
import participating from '../../../images/participating.svg';
import { Dropdown } from 'react-bootstrap';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {
  getContests,
  getStageByContest,
} from '../../../services/contestService';
import Loader from 'react-loader-spinner';
import { updateIdeasAward } from '../../../services/ideaService.js';
import { toast } from 'react-toastify';
import { ActionButton, EvaluationStatusButton } from '../../common/Buttons/Buttons';
import { translateUserType , translateEvalType} from '../../../helpers/translate';
import {SpecialMentionModal, DeleteSpecialMentionModal} from "../../views/DashBoard/SpecialMentionModal/SpecialMentionModal"
import {ReportModal} from "../../views/DashBoard/ReportModal/ReportModal"
import switchOn from "../../../images/switchOn.svg"
import switchOff from "../../../images/switchOff.svg"
import { getIdeaById } from '../../../services/ideaService';
import { CSVLink } from 'react-csv';
import './Tables.scss';

// Users list Table component
export const UsersTable = (props) => {
  const { users, loadUserDataForEdition } = props;
  if (!users) {
    return null;
  } else {
    return (
      <table id="users-table" className="users-table">
        <thead>
          <tr>
            <th>Tipo de usuario</th>
            <th>Nombre y apellido</th>
            <th>Email</th>
            <th>Organización</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => (
            <tr key={i}>
              <td>
                <h6>{user.userTypeName}</h6>
              </td>
              <td>
                <h6>
                  {user.name} {user.lastName}
                </h6>
              </td>
              <td>
                <h6>{user.email}</h6>
              </td>
              <td>
                <h6>
                  {user.userType === 'Gobierno'
                    ? user.govType
                    : user.userType === 'Aseguradora'
                    ? user.insuranceId
                    : user.insuranceName}
                </h6>
              </td>
              <td>
                <NavLink to="/gestion/edicion-usuario">
                  <button
                    onClick={() => loadUserDataForEdition(user)}
                    className="font-button"
                  >
                    <FontAwesomeIcon icon="pencil-alt" />
                  </button>
                </NavLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

export const StatusComponent = (props) => {
  const { stage } = props;

  if (!stage) {
    return null;
  }
  if (stage.id === 2) {
    return (
      <div className="status-component">
        <img src={registered} />
        <h6>{stage.status}</h6>
      </div>
    );
  } else {
    return (
      <div className="status-component">
        <img src={participating} />
        <h6>{stage.status}</h6>
      </div>
    );
  }
};

// Users list Table component
export const IdeaListTable = (props) => {
  const {
    ideas,
    pages,
    currentPage,
    handleIdeaListPageChange,
    selectIdea,
    deleteIdea,
    blockedLogin,
    stagesDropdowns,
  } = props;

  const [show, setShow] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleDelete = async (id) => {
    deleteIdea(id);
    setShow(false);
  };

  if (ideas.length <= 0) {
    return <h1>No posee ideas registradas</h1>;
  } else if (ideas.length > 0) {
    return (
      <React.Fragment>
        <LoadSpinner
          blocked={blockedLogin}
          text="Espere mientras procesamos su solicitud"
          color="#000000"
        />

        <div className="tables-container">
          <table id="ideas-table" className="ideas-table">
            <thead>
              <tr>
                <th>Título</th>
                <th>Sector</th>
                <th>Status</th>
                <th>Categoría</th>
                <th>Fecha de Creación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {ideas.map((idea, i) => (
                <tr key={i}>
                  <td>
                    <h6>{idea.title}</h6>
                  </td>
                  <td>
                    <h6>{idea.sector.economicSector}</h6>
                  </td>
                  <td>
                    <StatusComponent stage={idea.stage} />
                  </td>
                  <td>
                    <h6>{idea.proposalType.proposalType}</h6>
                  </td>
                  <td>
                    <h6>
                      {moment(idea.creationDate)
                        .locale('es')
                        .format('DD/MM/YYYY')}
                    </h6>
                  </td>
                  <td onClick={() => selectIdea(idea)}>
                    <NavLink to={`/home/participant/ideas/${idea.id}`}>
                      <FontAwesomeIcon icon="pencil-alt" />
                    </NavLink>

                    {stagesDropdowns.stage1.current ? (
                      <FontAwesomeIcon
                        icon="trash-alt"
                        variant="primary"
                        className="btn-delete"
                        onClick={() => {
                          handleShow();
                          setSelectedRow(idea);
                        }}
                      />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <ModalComponent
          title={'Eliminar idea'}
          show={show}
          accept={'Si, Eliminar'}
          cancel={'Cancelar'}
          text={
            <p>
              ¿Está usted seguro que desea eliminar la idea{' '}
              <b>
                " {selectedRow.codeDana} - {selectedRow.title} "
              </b>{' '}
              ? Si la elimina, ya no estará participando con esta idea y todos
              los documentos cargados previamente serán eliminados.{' '}
            </p>
          }
          onCancel={handleClose}
          onAccept={() => handleDelete(selectedRow.id)}
        />

        <Pagination
          count={pages}
          variant="outlined"
          shape="rounded"
          page={currentPage + 1} // +1 So the page counter starts in 1
          onChange={handleIdeaListPageChange}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export const PagesPerPage = (props) => {
  const { adminIdeaList, handleAdminRowsPerPage } = props;
  return (
    <div className="pages-per-page-container">
      <h6>Filas por página:</h6>
      <select
        onChange={handleAdminRowsPerPage}
        // name={name}
        value={adminIdeaList.ideasPerPage}
      >
        {adminIdeaList.ideaPerPageOptions.map((option, i) => (
          <option key={i} className="page-option" value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export const ModalComponent = (props) => {
  const { title, show, accept, cancel, onCancel, onAccept, text } = props;

  if (show) {
    return (
      <div className="modal-component-container">
        <div className="modal-component">
          <div className="modal-component-container-header">
            <h6>{title}</h6>
          </div>
          <div className="modal-component-body">{text}</div>
          <div className="modal-component-footer">
            <div></div>
            <div className="modal-button-container">
              <div
                onClick={onCancel}
                className="modal-button modal-close-button"
              >
                <h6>{cancel}</h6>
              </div>
              <div
                onClick={onAccept}
                className="modal-button modal-open-button"
              >
                <h6>{accept}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

// Users list Table component
export const AdminIdeaListTable = (props) => {
  const {
    adminIdeaList,
    ideas,
    pages,
    currentPage,
    selectIdea,
    changeIdeaStatus,
    handleAdminIdeaListPageChange,
    handleAdminRowsPerPage,
  } = props;

  const [show, setShow] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [registerId, setSegisterId] = useState();
  const [participantId, setParticipantId] = useState();
  const [semifinalistId, setSemifinalistId] = useState();
  const [finalistId, setFinalistId] = useState();
  const [winnerID, setWinnerId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState("");


  const history = useHistory(); 
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };



  const handleChangeStatus = async (idea, status) => {
    changeIdeaStatus(idea, status);
    setShow(false);
  };

  const getContestsStages = async (contest, modality) => {
    if (contest && modality) {
      await getStageByContest(contest, modality)
        .then((response) => {
          if (response.status === 200) {
            let [register] = response.data.filter(
              (s) => s.status === 'Registrado'
            );
            setSegisterId(register?.id);

            let [participant] = response.data.filter(
              (s) => s.status === 'Participante'
            );
            setParticipantId(participant?.id);

            let [semifinalist] = response.data.filter(
              (s) => s.status === 'Semifinalista'
            );

            setSemifinalistId(semifinalist?.id);
            let [finalist] = response.data.filter(
              (s) => s.status === 'Finalista'
            );
            setFinalistId(finalist?.id);

            let [winner] = response.data.filter((s) => s.status === 'Ganador');
            setWinnerId(winner?.id);
          } else if (response.status === 404) {
            console.log('response.errorMessage:', response.errorMessage);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error(`Error al obtener etapas del concurso`);
        });
    }
  };

  const getContestFunction = async () => {
    await getContests()
      .then((response) => {
        if (response.status == 200) {
          let [selectedContest] = response.data.filter((c) => c.isOpen);
          if (selectedContest) {
            getContestsStages(selectedContest?.id, '0');
          }
        } else if (response.status == 404) {
          console.log('No hay concurso abierto');
        } else {
          toast.error(`${response.message}` || 'Error al obtener concursos');
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Error al obtener etapas del concursos');
      });
  };


  const openMentionModal = (idea) => {
    setSelectedIdea(idea)

    setIsModalOpen(!isModalOpen)
  }
  
  const closeModal = () => {
    setSelectedIdea("")
    setIsModalOpen(false)

  }


  useEffect(() => {
    getContestFunction();
  }, []);
  
  if (adminIdeaList.loading) {
    return (
      <div className="loading-ideas-container">
        <Loader
          type="Oval"
          color={'gray'}
          height={120}
          width={120}
          timeout={0} //3 secs
        />
        <h4 style={{ color: 'gray' }}>Cargando ideas</h4>
      </div>
    );
  } else if (ideas.length > 0) {
    return (
      <React.Fragment>
        <SpecialMentionModal  isOpen={isModalOpen} selectedIdea={selectedIdea} closeModal={closeModal}/>
        <div className="tables-container">
          <table id="admin-ideas-table" className="ideas-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Título</th>
                <th>Tipo</th>
                <th>Sector</th>
                <th>Estado</th>
                <th>Estatus</th>
                <th>Líder</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {ideas.map((idea, i) => (
                <tr key={i}>
                  <td>
                    <h6>{idea.codeDana}</h6>
                  </td>
                  <td>
                    <h6>{idea.title}</h6>
                  </td>
                  <td>
                    <h6>{idea.proposalType.proposalType}</h6>
                  </td>
                  <td>
                    <h6>{idea.sector.economicSector}</h6>
                  </td>
                  <td>
                    <h6>{idea.state.state}</h6>
                  </td>
                  <td>
                    <StatusComponent stage={idea.stage} />
                  </td>
                  <td>
                    <h6>
                      {idea.user.name} {idea.user.lastName}
                    </h6>
                  </td>
                  <td
                    onClick={() => {
                      selectIdea(idea);
                    }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle id="header-dropdown">
                        Seleccionar
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => history.push(`/home/admin/ideas/${idea.id}`)}>
                            Detalles de la idea
                        </Dropdown.Item>

                        {
                          (idea.stage.status !== 'Registrado')&&(idea.contest.isOpen) ? (
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedRow(idea);
                              handleShow();
                            }}
                          >
                            Mover a Registrado
                          </Dropdown.Item>
                        ) : null}
                        {
                          (idea.stage.status !== 'Participante') &&(idea.contest.isOpen) ? (
                          <Dropdown.Item
                            onClick={() =>
                              handleChangeStatus(idea, participantId)
                            }
                          >
                            Mover a Participante
                          </Dropdown.Item>
                        ) : null}
                        {
                          (idea.stage.status !== 'Semifinalista') &&(idea.contest.isOpen) ? (
                          <Dropdown.Item
                            onClick={() =>
                              handleChangeStatus(idea, semifinalistId)
                            }
                          >
                            Mover a Semifinalista
                          </Dropdown.Item>
                        ) : null}
                        {
                          idea.stage.status !== 'Finalista' &&(idea.contest.isOpen) ? (
                          <Dropdown.Item
                            onClick={() => handleChangeStatus(idea, finalistId)}
                          >
                            Mover a Finalista
                          </Dropdown.Item>
                        ) : null}
                        {
                        (idea.stage.status !== 'Ganador') &&(idea.contest.isOpen)? (
                          <Dropdown.Item
                            onClick={() => handleChangeStatus(idea, winnerID)}
                          >
                            Mover a Ganador
                          </Dropdown.Item>
                        ) : null}
                          {/* <Dropdown.Item
                          onClick={() => openMentionModal(idea)}
                        >
                          Mención Especial
                        </Dropdown.Item> */}
                        {
                          (idea.contest.isOpen)?(
                            <Dropdown.Item
                            onClick={() => handleChangeStatus(idea, registerId)}
                          >
                            Descalificar
                          </Dropdown.Item>

                          ): null }

                      </Dropdown.Menu>
                    </Dropdown>
                    {/* {show ? : null} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <ModalComponent
          title={'Cambiar estatus de idea a registrado'}
          show={show}
          accept={'Aceptar'}
          cancel={'Cancelar'}
          text={
            <p>
              La idea{' '}
              <b>
                {selectedRow.codeDana} - {selectedRow.title}
              </b>{' '}
              tiene un resumen cargado y debe estar al menos en estatus
              Participante. ¿Está seguro que quiere cambiar a registrado?{' '}
            </p>
          }
          onCancel={handleClose}
          onAccept={() => handleChangeStatus(selectedRow, registerId)}
        />

        <PagesPerPage
          adminIdeaList={adminIdeaList}
          handleAdminRowsPerPage={handleAdminRowsPerPage}
        />

        <Pagination
          count={pages}
          variant="outlined"
          shape="rounded"
          page={currentPage + 1} // +1 So the page counter starts in 1
          onChange={handleAdminIdeaListPageChange}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

// Users list Table component
export const ProcessStagesTable = (props) => {
  const {
    // handlePageChange,
    data,
    isLoading,
    validateDistribution,
    evaluationList,
    selectReport,
    modalOpen,
    closeReportModal,
    selectedStage
  } = props;

  const header = ['Tipo', '# Ideas', '# Jurado', '','Resultado'];

  if (isLoading) {
    return (
      <div style={{ marginTop: '120px', width: '100%' }}>
        <Loader
          type="Oval"
          color={'gray'}
          height={120}
          width={120}
          timeout={0} //3 secs
        />
        <h4 style={{ color: 'gray' }}>Cargando Premios</h4>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <ReportModal selectedStage={selectedStage} isOpen={modalOpen} selectedIdea={""} closeModal={()=>closeReportModal()} csvData={evaluationList}/>
        {data.length === 0 ? null : (
          <div className="tables-container">
            <table
              id="criteria-table"
              className="ideas-table processtable"
            >
              <thead>
                <tr>
                  {header.map((h) => (
                    <th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((c,i) => (
                  <tr key={c.type + i}>
                    <td>
                      <h6>{c?.[2]}</h6>
                    </td>
                    <td>
                      <h6>{c?.[3]}</h6>
                    </td>
                    <td>
                      <h6>{c?.[4]}</h6>
                    </td>
                    <td>
                      <ActionButton
                        text="Distribuir"
                        action={() => validateDistribution(c)}
                      />
                    </td>
                    <td>
                    {c?.[5] && <h6>Resultados Distribución {c?.[6]} Evaluaciones 
                    <br />
                    <b style={{color:"#30377b", cursor:"pointer"}} onClick={()=>selectReport(c)}>
                      
                      Descargar Reporte 

                    </b> 
                    </h6>}
                      
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td>
                    <b>
                      <h6>
                        {data
                          .map((d) => d[3])
                          .reduce((prev, next) => prev + next)}
                      </h6>
                    </b>
                  </td>
                  <td>
                    <b>
                      <h6>
                        {data
                          .map((d) => d[4])
                          .reduce((prev, next) => prev + next)}
                      </h6>
                    </b>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {/* <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div> */}
          </div>
        )}
      </React.Fragment>
    );
  }
};

// criteria table to copy
export const CriteriaTableCopied = (props) => {
  const {
    data,
    isLoading,
    selectedCriteria,
    setSelectedCriteria,
    handleSelectAllAcrossPages
  } = props;

  const header = ['Fase', 'Tipo de Idea', 'Criterio', 'Peso %'];
  const [selectedAll, setSelectedAll] = useState(false);

  const typesComponent = (type) => {
    switch (type) {
      case 'BUSINESS':
        return 'Negocio';
      case 'SOCIAL':
        return 'Social';
      case 'DIGITAL':
        return 'Digital';
      case 'ONG':
        return 'ONG';
      default:
        return 'Desconocido';
    }
  };


  const handleSelectAll = () => {
    const newSelectAll = !selectedAll;
    setSelectedAll(newSelectAll);
    handleSelectAllAcrossPages(newSelectAll);
  };

  const handleSelectOne = (index) => {
    const newSelectedCriteria = [...selectedCriteria];
    newSelectedCriteria[index].selected = !newSelectedCriteria[index].selected;
    setSelectedCriteria(newSelectedCriteria);
  };

  return (
    <React.Fragment>
      {isLoading ? (

        <div className="loader-container"> 
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} // 3 secs
          />
           <h4 style={{ color: 'gray', marginTop: '20px' }}>Cargando Criterios</h4>
        </div>
      ) : data?.length > 0 ? (
        <div className="criteria-table-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                <th>
                  <Form.Check
                    type="checkbox"
                    checked={selectedAll}
                    onChange={handleSelectAll}
                  />
                </th>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {selectedCriteria.map((c, index) => (
                <tr key={c.criteria}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={c.selected}
                      onChange={() => handleSelectOne(index)}
                    />
                  </td>
                  <td>
                    <h6>{c?.stage?.stage}</h6>
                  </td>
                  <td>
                    <h6>{typesComponent(c?.types)}</h6>
                  </td>
                  <td>
                    <h6>{c?.criteriaName}</h6>
                  </td>
                  <td>
                    <h6>{c.percentage}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </React.Fragment>
  );
};

// Users list Table component
export const CriteriaTable = (props) => {
  const {
    handlePageChange,
    handleOpenDeleteModal,
    data,
    isLoading,
    totalPercentage,
    openEditModal,
  } = props;

  const header = ['Fase', 'Tipo de Idea', 'Criterio', 'Peso %', 'Acciones'];

  const typesComponent = (type) => {
    if (type === 'BUSINESS') {
      return 'Negocio';
    }
    if (type === 'SOCIAL') {
      return 'Social';
    }
    if (type === 'DIGITAL') {
      return 'Digital';
    }
    if (type === 'ONG') {
      return 'ONG';
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando Premios</h4>
        </div>
      ) : data?.numberOfElements > 0 ? (
        <div className="tables-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.criteria}>
                  <td>
                    <h6>{c?.stage?.stage}</h6>
                  </td>
                  <td>
                    <h6>{typesComponent(c?.types)}</h6>
                  </td>
                  <td>
                    <h6>{c?.criteriaName}</h6>
                  </td>
                  <td>
                    <h6>{c.percentage}</h6>
                  </td>
                  <td>
                    {c?.contest?.isOpen && (
                      <div className="ideas-tables-action-container">
                        <ion-icon
                          onClick={() => openEditModal(c)}
                          name="create-outline"
                        ></ion-icon>
                        <ion-icon
                          onClick={() => handleOpenDeleteModal(c)}
                          name="trash-outline"
                        ></ion-icon>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>{totalPercentage ? `${totalPercentage} %` : null}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

// Users list Table component
export const UsersListTable = (props) => {
  const {
    handlePageChange,
    handleOpenDeleteModal,
    data,
    isLoading,
    disbableUser,
  } = props;

  const header = ['Nombre', 'Email', 'Rol', 'Acciones', 'Activo'];


  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando</h4>
        </div>
      ) : data?.content?.length > 0 ? (
        <div className="tables-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.id}>
                  <td>
                    <h6>
                      {c?.name} {c?.lastName}
                    </h6>
                  </td>
                  <td>
                    <h6>{c?.email}</h6>
                  </td>
                  <td>
                    <h6>{translateUserType(c?.userType)}</h6>
                  </td>
                  <td>
                    <div className="ideas-tables-action-container">
                      <NavLink
                        to={`/home/admin/user-management/${c.id}/general-info`}
                      >
                        <ion-icon name="create-outline"></ion-icon>
                      </NavLink>
                      <ion-icon
                        onClick={() => handleOpenDeleteModal(c)}
                        name="trash-outline"
                      ></ion-icon>
                    </div>
                  </td>
                  <td>
                    {c.active ? (
                      <ion-icon
                        onClick={() => disbableUser(c)}
                        style={{
                          color: '#32CD32',
                          transform: 'rotate(180deg)',
                          fontSize: '24px',
                          cursor: 'pointer',
                        }}
                        name="toggle"
                      ></ion-icon>
                    ) : (
                      <ion-icon
                        onClick={() => disbableUser(c)}
                        style={{
                          color: 'gray',
                          fontSize: '24px',
                          cursor: 'pointer',
                        }}
                        name="toggle"
                      ></ion-icon>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : <h4 style={{marginTop:"120px"}}>No se encontraron resultados</h4>}
    </React.Fragment>
  );
};

export const CompanyListTable = (props) => {
  const {
    handlePageChange,
    handleOpenDeleteModal,
    data,
    isLoading,
    disbableUser,
  } = props;
  
  const header = ['Nombre', 'Sector', 'Acciones'];
  

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando</h4>
        </div>
      ) : data?.content?.length > 0 ? (
        <div className="tables-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.id}>
                  <td>
                    <h6>
                      {c?.businessName} 
                    </h6>
                  </td>
                  <td>
                  <h6>
                      {c?.economicSector?.economicSector} 
                    </h6>      
                  </td>
                  <td>
                    <div className="ideas-tables-action-container">
                      <NavLink
                        to={`/home/admin/company-management/edit/${c.id}/general-info`}
                      >
                        <ion-icon name="create-outline"></ion-icon>
                      </NavLink>
                      <ion-icon
                        onClick={() => handleOpenDeleteModal(c)}
                        name="trash-outline"
                      ></ion-icon>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : <h4 style={{marginTop:"120px"}}>No se encontraron resultados</h4>}
    </React.Fragment>
  );
};



export const SpecialMentionTable = (props) => {
  const {
    handlePageChange,
    openDeleteModal,
    handleOpenDeleteModal,
    data,
    isLoading,
    disbableUser,
    selectIdea,loadCount
  } = props;
  
  const header = ['Titulo del Proyecto', 'ID de la Idea', 'Tipo', 'Mención Especial', 'Estatus', "Acciones"];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


  const history = useHistory();
  const getIdeaDetail = async (idea) => {

    let userSession = sessionStorage.getItem("userSession")
    userSession = JSON.parse(userSession)

    getIdeaById(idea[7]).then((response) => {
      if (response.status === 200) {
        selectIdea(response?.data)
        history.push(`/home/${userSession.userType === "admin" ? "admin":"judge"}/ideas/${idea[7]}`)
      }
    })
    .catch(() => {
      toast.error('Error al conseguir premios');
    });
    // selectIdea(idea.judgeIdea?.idea)
  }

  const makeIdeaWinner = (idea, status) => {
    updateIdeasAward(idea[7], idea[8], status)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Idea Actualizada');
          closeModal()
          closeDeleteModal()
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      })
      .catch(() => {
        toast.error('Error al conseguir premios');
      });
  };

  let userSession = sessionStorage.getItem("userSession")
  userSession = JSON.parse(userSession)

  const openMentionModal = (idea) => {
    setSelectedIdea(idea)
    setIsModalOpen(!isModalOpen)
  }
  
  const openDeleteMentionModal = (idea) => {
    setSelectedIdea(idea)
    setIsDeleteModalOpen(!isDeleteModalOpen)
  }


  const closeModal = () => {
    setSelectedIdea("")
    setIsModalOpen(false)
  }
  const closeDeleteModal = () => {
    setSelectedIdea("")
    setIsDeleteModalOpen(false)
  }

  


  let csvData = []
  
  
 for (let i = 0; i < data?.content?.length; i++) {
  csvData.push({
    "ID DANA":data?.content[i][1],
    "Nombre de la Idea":data?.content[i][0],
    "Tipo":data?.content[i][2],
    "Mención Especial":data?.content[i][3],
    "Estatus":data?.content[i][4],
    "Ganadora de la mención especial":data?.content[i][5] ? "Ganadora":"",
  }) 
 }

 
  return (
    <React.Fragment>
              <SpecialMentionModal  isOpen={isModalOpen} selectedIdea={selectedIdea} closeModal={closeModal} action={makeIdeaWinner}/>
              <DeleteSpecialMentionModal  isOpen={isDeleteModalOpen} selectedIdea={selectedIdea} closeModal={closeDeleteModal} action={makeIdeaWinner}/>

      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando</h4>
        </div>
      ) : data?.content?.length > 0 ? (
        <div className="tables-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.id}>
                  <td>
                    <div className="name-and-trophy-container">
                      {c[5] ?                        <ion-icon  style={{color:"gold", marginRight:"12px"}} name="trophy"></ion-icon> : <span style={{ marginRight:"28px"}}></span>
}
                    <h6>
                      {c[0]} 
                    </h6>
                    </div>
                  </td>
                  <td>
                    <h6>                      {c[1]} 
</h6>
                  </td>
                  <td>
                    <h6>{c[2]} </h6>
                  </td>
                  <td>
                    <h6>{c[3]} </h6>
                  </td>
                  <td>
                    <h6>{c[4]} </h6>
                  </td>
                  <td>

                  <Dropdown>
                  <Dropdown.Toggle id="header-dropdown">
                    <span style={{color:"black"}}>Seleccionar</span>
                        <Dropdown.Menu>

                          {userSession?.userType === "admin" && !c[5] &&                  
                          <Dropdown.Item onClick={()=>openMentionModal (c)}>
                          <span style={{color:"#007bff"}} >
                            Mención Especial
                          </span>
                          </Dropdown.Item>
                          }
                          {userSession?.userType === "admin" &&    c[5] &&              
                          <Dropdown.Item onClick={()=>openDeleteMentionModal (c)}>
                          <span style={{color:"#007bff"}} >
                            Quitar Mención Especial
                          </span>
                          </Dropdown.Item>
                          }
                          <Dropdown.Item onClick={()=>getIdeaDetail(c)}>
                          <span style={{color:"#007bff"}} >
                          Ver Información del Proyecto                           
                          </span>
                          </Dropdown.Item>
{/* {
  userSession?.userType === "admin" &&    
                          <span style={{color:"#007bff", padding:"24px"}} >
                          <CSVLink data={csvData} filename="reporte-de-mencion-especial.csv">
                          Exportar Reporte                           
                          </CSVLink>
                          </span>                      
} */}
                            
                          </Dropdown.Menu>
                      </Dropdown.Toggle>
                  </Dropdown>



                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : <h4 style={{marginTop:"120px"}}>{loadCount !== 0 ? "No se encontraron resultados": "No existen ideas postuladas a algún premio especial para el concurso actual" }</h4>}
    </React.Fragment>
  );
};



export const EvalStatusComponent = (props) => {
  
  const {status} = props;

  if (status === "IN_PROGRESS") {
    return (
      <div style={{backgroundColor:"#777777"}} className='evaluation-status-component'>
      <h6>EN PROGRESO</h6>
    </div>
    )
  }
  if (status === "NOT_EVALUATED") {
    return (
      <div style={{backgroundColor:"#ff5756"}} className='evaluation-status-component'>
      <h6>NO EVALUADO</h6>
    </div>
    )
  }

  if (status === "EVALUATED") {
    return (
      <div style={{backgroundColor:"#4aaf05"}} className='evaluation-status-component'>
      <h6>EVALUADO</h6>
    </div>
    )
  }

  
  else return null
}

export const EvaluationsListTable = (props) => {
  const { handlePageChange,  data, isLoading, selectIdea, activeStage,userSession  } = props;
  const history = useHistory();
  

  // Function that loads in app.js setState{} the selected idea and redirects to the idea info page
  const redirectAndSelectIdea = (idea, userSession) => {
    idea.judgeIdea
    selectIdea(idea.judgeIdea?.idea)
    history.push(`/home/${userSession.userType === "admin" ? "admin":"judge"}/evaluations/${idea?.id}/project-info`)
  }
  
  const header = [
    'Título del Proyecto',
    'ID de la Idea',
    'Tipo',
    'Estatus Evaluación',
    'Nota Fase Actual',
    'Acciones',
  ];
  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando</h4>
        </div>
      ) : data?.content?.length > 0 ? (
        <div className="tables-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.id}>
                  <td>
                    <span style={{color:"#007bff", cursor:"pointer"}} onClick={()=>redirectAndSelectIdea(c, userSession)}>
                    {c?.judgeIdea?.idea?.title}
                          </span>
                  </td>
                  <td>
                    <h6>{c?.judgeIdea?.idea?.codeDana}</h6>
                  </td>
                  <td>
                  <h6>{c?.judgeIdea?.idea?.proposalType?.proposalType}</h6>
                  
                                    </td>
                  <td>
                   <EvalStatusComponent status={c?.evaluationStatus}/>
                  </td>
                  <td>
                  <h6>{c?.qualification}</h6>
                  </td>
                  <td>
                  <Dropdown>
                  <Dropdown.Toggle id="header-dropdown">
                    <span style={{color:"black"}}>Seleccionar</span>
                        <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>redirectAndSelectIdea (c, userSession)}>
                          <span style={{color:"#007bff"}} >
                            Información del Proyecto
                          </span>
                          </Dropdown.Item>
                          {c?.evaluationStatus !== "EVALUATED" && activeStage?.stage === c?.judgeIdea?.stage?.stage && 
                            <Dropdown.Item onClick={()=>history.push(`/home/judge/evaluations/${c?.id}/evaluation`)}>
                          <span style={{color:"#007bff"}} >
                          Evaluar
                          </span>                            
                          </Dropdown.Item>
                            }
                          {c?.evaluationStatus === "EVALUATED" && activeStage?.stage === c?.judgeIdea?.stage?.stage && 
                                                    <Dropdown.Item onClick={()=>history.push(`/home/judge/evaluations/${c?.id}/evaluation`)}>
                                                    <span style={{color:"#007bff"}} >
                                                    Reevaluar
                                                    </span>                            
                                                    </Dropdown.Item>
                          }
                          </Dropdown.Menu>
                      </Dropdown.Toggle>
                  </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};


export const IdeaManagementTable = (props) => {
  const { handlePageChange,  data, isLoading, selectIdea, userSession , openAssginModal, openRemoveModal} = props;
  const history = useHistory();


  // Function that loads in app.js setState{} the selected idea and redirects to the idea info page
  const redirectAndSelectIdea = (idea, userSession) => {
    idea.judgeIdea
    selectIdea(idea.judgeIdea?.idea)
    history.push(`/home/${userSession.userType === "admin" ? "admin":"judge"}/evaluations/${idea?.id}/project-info`)
  }

  const header = [
    'Título del Proyecto',
    'ID de la Idea',
    'Tipo',
    'Estatus Evaluación',
    'Nombre del Evaluador',
    'Nota Fase Actual',
    'Acciones',
  ];
  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando</h4>
        </div>
      ) : data?.content?.length > 0 ? (
        <div className="tables-container">
          <table id="criteria-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.id}>
                  <td>
                    <span style={{color:"#007bff", cursor:"pointer"}} onClick={()=>redirectAndSelectIdea(c, userSession)}>
                    {c?.judgeIdea?.idea?.title}
                          </span>
                  </td>
                  <td>
                    <h6>{c?.judgeIdea?.idea?.codeDana}</h6>
                  </td>
                  <td>
                  <h6>{c?.judgeIdea?.idea?.proposalType?.proposalType}</h6>
                  </td>
                  <td>
                   <EvalStatusComponent status={c?.evaluationStatus}/>
                  </td>
                  <td>
                  {c?.judgeIdea?.user?.name + "   "} 
                  {"  " +  c?.judgeIdea?.user?.lastName}
                  </td>
                  <td>
                  <h6>{c?.qualification}</h6>
                  </td>
                  <td>
                  <Dropdown>
                  <Dropdown.Toggle id="header-dropdown">
                    <span style={{color:"black"}}>Seleccionar</span>
                        <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>redirectAndSelectIdea (c, userSession)}>
                          <span style={{color:"#007bff"}} >
                            Información del Proyecto
                          </span>
                          </Dropdown.Item>
                            {/* <Dropdown.Item onClick={()=>history.push(`/home/judge/evaluations/${c?.id}/evaluation`)}>
                          <span style={{color:"#007bff"}} >
                          Evaluar
                          </span>                            
                          </Dropdown.Item> */}
                          {["judge_leader","admin"].includes(userSession?.userType) &&
                           <>
                           <Dropdown.Item  onClick={()=>openAssginModal(c)}>
                          <span  style={{color:"#007bff"}}>
                            Asignar Evaluador
                          </span>
                          </Dropdown.Item>
                          <Dropdown.Item  onClick={()=>openRemoveModal(c)}>
                          <span  style={{color:"#007bff"}}>
                            Quitar Jurado
                          </span>
                          </Dropdown.Item>
                           </>}
                          </Dropdown.Menu>
                      </Dropdown.Toggle>
                  </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
  
};


export const EvaluatorManagementTable = (props) => {
  const { handlePageChange,  data, isLoading, selectIdea, userSession , openAssginModal, openRemoveModal} = props;
  const history = useHistory();
  

    // Function that loads in app.js setState{} the selected idea and redirects to the idea info page
    const redirectAndSelectIdea = (idea, userSession) => {
      idea.judgeIdea
      selectIdea(idea.judgeIdea?.idea)
      history.push(`/home/${userSession.userType === "admin" ? "admin":"judge"}/evaluations/${idea?.id}/project-info`)
    }
  
  const header = [
    'Nombre',
    'Email',
    'Tipo',
    'Ideas',
    'Evaluación',
    'Activo',
    // 'Acciones',
  ];
  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando</h4>
        </div>
      ) : data?.content?.length > 0 ? (
        <div className="tables-container">
          <table id="evaluators-table" className="ideas-table">
            <thead>
              <tr>
                {header.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.content?.map((c) => (
                <tr key={c.id}>
                  <td>
                  {c[0]} 
                  </td>
                  <td>
                  {c[1]} 
                  </td>
                  <td>
                  <h6>               
                       {translateEvalType(c[2])} 
                  </h6>
                  </td>
                  <td>
                  {c[3]} 
                  </td>
                  <td>
                    <div className='evaluation-status-bar-percentage-container'>

                  <EvaluationStatusButton label={c[4]} />
                  <h6>{c[5] + "%"}</h6>
                  
                    </div>
                  </td>
                  <td>
                  <h6>{c[6] ? <img src={switchOn}/>: <img src={switchOff}/>}</h6>
                  </td>
                  {/* <td>
                  <Dropdown>
                  <Dropdown.Toggle id="header-dropdown">
                    <span style={{color:"black"}}>Seleccionar</span>
                        <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>redirectAndSelectIdea (c, userSession)}>
                          <span style={{color:"#007bff"}} >
                            Información del Proyecto
                          </span>
                          </Dropdown.Item>
                          {["judge_leader","admin"].includes(userSession?.userType) &&
                           <>
                           <Dropdown.Item  onClick={()=>openAssginModal(c)}>
                          <span  style={{color:"#007bff"}}>
                            Asignar Evaluador
                          </span>
                          </Dropdown.Item>
                          <Dropdown.Item  onClick={()=>openRemoveModal(c)}>
                          <span  style={{color:"#007bff"}}>
                            Quitar Jurado
                          </span>
                          </Dropdown.Item>
                           </>}
                          </Dropdown.Menu>
                      </Dropdown.Toggle>
                  </Dropdown>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              count={data?.totalPages}
              variant="outlined"
              shape="rounded"
              page={data?.number + 1} // +1 So the page counter starts in 1
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );  
};
