/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  JudgDashboardCard,
  ContestStage,
  validateIfContestIsOpen,
} from '../../common/IdeaCard/IdeaCard.jsx';
import { getJudgeDashboard } from '../../../services/judgeService.js';
import './JudgeDashboard.scss';

export const JudgeDashboard = (props) => {
  const { currentContest, stagesDropdowns } = props;
  const [dashboardData, setDashboardData] = useState({
    asigned: 0,
    evaluated: 0,
    toEval: 0,
    pending: 0,
    daysLeft: 0,
  });

  let contestIsOpen = true;
  if (currentContest.stages) {
    contestIsOpen = validateIfContestIsOpen(currentContest);
  }

  const getDashboardInfo = () => {
    let userSession = sessionStorage.getItem(`userSession`);
    userSession = JSON.parse(userSession);

    getJudgeDashboard(userSession?.userId)
      .then((response) => {
        if (response.status === 200) {
          response.data.filter((d) => d[0] === 'Dias Restantes');

          setDashboardData({
            asigned: response.data.filter((d) => d[0] === 'Asignadas')[0][1],
            evaluated: response.data.filter((d) => d[0] === 'Evaluadas')[0][1],
            toEval: response.data.filter((d) => d[0] === 'Por Evaluar')[0][1],
            daysLeft: response.data.filter(
              (d) => d[0] === 'Dias Restantes'
            )[0][1],
          });
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  useEffect(() => {
    getDashboardInfo();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard-title-container">
        <h1>DASHBOARD</h1>
      </div>

      <ContestStage
        currentContest={currentContest}
        contestIsOpen={contestIsOpen}
      />

      <div className="row">
        <JudgDashboardCard
          name={'Asignadas'}
          ideaCount={dashboardData.asigned}
          color={'#66c780'}
        />
        <JudgDashboardCard
          name={'Evaluadas'}
          ideaCount={dashboardData.evaluated}
          color={'#b9bcc8'}
        />
        <JudgDashboardCard
          name={'Por Evaluar'}
          ideaCount={dashboardData.toEval}
          color={'#f4441c'}
        />
        <JudgDashboardCard
          name={'Días Restantes'}
          ideaCount={dashboardData.daysLeft}
          color={'#aabffc'}
        />
        {/* <IdeaListCard />
        <NotificationCard /> */}
      </div>
    </React.Fragment>
  );
};
