// Variable that handles the create account form
export const createAccountForm = {
  data: {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAndConditions: false,
    captcha: false,
  },
  errors: {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAndConditions: '',
    captcha: '',
  },
};

// Handles the show password option in the input
export const passwordShown = false;

// Handles the forgot password input
export const forgotPwdForm = {
  data: { email: '' },
  errors: { email: '' },
  blocked: false,
};

export const basicData = {
  data: {
    name: '',
    lastName: '',
    idType: '',
    id: '',
    birthDate: '',
    gender: '',
    phoneNumber: '',
    mobileNumber: '',
    otherNumber: '',
    state: '',
    address: '',

    academicInstitution: '',
    educationLevel: '',

    employmentState: '',

    economicSector: '',
    company: '',
    charge: '',
    startUpDesctiption: '',
  },
  errors: {
    name: '',
    lastName: '',
    idType: '',
    id: '',
    birthDate: '',
    gender: '',
    phoneNumber: '',
    mobileNumber: '',
    otherNumber: '',
    state: '',
    address: '',

    academicInstitution: '',
    educationLevel: '',

    employmentState: '',

    economicSector: '',
    company: '',
    charge: '',
    startUpDesctiption: '',
  },
};

// Variable that handles the Basic step Wizard step change
export const basicDataStep = {
  counter: 0,
  steps: [{ name: 'Datos Personales' }, { name: 'Confirmación de Datos' }],
};

// Variable that handles the states options from the DB
export const states = [];

// Variable that handles the states options from the DB
export const economicSectors = [];
// Variable that handles the edicational institutions options from the DB
export const educationalInstitution = [];

// Variable that handles the edicational levels options from the DB
export const educationalLevels = [];

export const currentContest = {};

export const createIdea = {
  data: {
    proposalType: '',
    socialType: '',
    ocupation: '',
    title: '',
    firstTime: '1',
    lastThreeYears: '0',
    newIdea: '1',
    state: '',
    sector: '',
    ongName: '',
    ongAddress: '',
    ongState: '',
    ongEmail: '',
    ongPhone: '',
  },
  errors: {
    proposalType: '',
    socialType: '',
    ocupation: '',
    title: '',
    firstTime: '',
    lastThreeYears: '',
    newIdea: '',
    state: '',
    sector: '',
    ongName: '',
    ongAddress: '',
    ongState: '',
    ongEmail: '',
    ongPhone: '',
  },
};

export const createIdeaStep = {
  counter: 0,
  steps: [
    { name: 'Tipo de Propuesta' },
    { name: 'Datos de la Idea' },
    { name: 'Confirmación de Participación' },
  ],
};

// All ideas list table
export const ideaList = {
  ideas: [],
  pages: 0,
  currentPage: 0,
  ideasPerPage: 3,
};

// All ideas list table
export const adminIdeaList = {
  ideas: [],
  ideasToPrint: [],
  search: '',
  loading: true,
  pages: 0,
  currentPage: 0,
  ideasPerPage: 10,
  ideaPerPageOptions: [10, 15, 20, 50, 100],
  totalElements: 0,
  showAdvanceFilter: false,
  advanceStatusSearch: [
    {
      name: 'register',
      selected: false,
      search: 'Registrado',
    },
    {
      name: 'participant',
      selected: false,
      search: 'Participante',
    },

    {
      name: 'semiFinalist',
      selected: false,
      search: 'Semifinalista',
    },

    {
      name: 'finalist',
      selected: false,
      search: 'Finalista',
    },

    {
      name: 'ganador',
      selected: false,
      search: 'Ganador',
    },
  ],
  advanceTypeSearch: [
    {
      name: 'N',
      selected: false,
      search: 'Negocio',
    },
    {
      name: 'S',
      selected: false,
      search: 'Social',
    },
    {
      name: 'D',
      selected: false,
      search: 'Digital',
    },
  ],
  advanceDocumentSearch: [
    {
      name: 'Plan_negocios',
      selected: false,
      search: 'Plan_negocios',
      label: 'Plan de negocio',
    },
    {
      name: 'Final_presentation',
      selected: false,
      search: 'Final_presentation',
      label: 'Presentación final',
    },
  ],
  startDate: {
    data: '',
    errors: '',
  },
  endDate: {
    data: '',
    errors: '',
  },
};

// All ideas list table
export const currentYearIdeas = [];
export const currentYearDocuments = [];

// Variable that handles files upload
export const files = [];
// Variable that handles idea for edition page
export const ideaForEdition = {
  idea: '',
  files: [],
  leaderDocloading: false,
  ongDocloading: false,
  ideaSummaryFiles: [],
  ideaSummaryFilesloading: false,
  finalPresentationloading: false,
  members: [],
  awards: [],
};

export const evaluationcriteria = {
  files: [],
  contestId:'',
  faseId:'',
  typeIdea:'',
  evaluationFiles:[],
  evaluationFilesloading:false,
}

export const sidebarShow = false;

export const ideasRegistered = '';
export const usersParticipant = '';
export const ideasParticipant = '';
export const ideasFinalist = '';
export const ideasSemifinalist = '';
export const ideasWinner = '';
export const documentActives = '';
export const documentActivesPresentation = '';

// Variable that handles the stages dropdowns
export const stagesDropdowns = {
  stage1: { selected: false, current: false, stagedPassed: false },
  stage2: { selected: false, current: false, stagedPassed: false },
  stage3: { selected: false, current: false, stagedPassed: false },
};

export const isFase1Open = true;

export const currentContestStage = [];
