/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import './DistributionModal.scss';

export const DistributionModal = (props) => {
  const { isOpen, closeModal, distributeIdea } = props;

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textTransform: 'capitalize' }}>
          Distribución
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <h6 style={{ padding: '12px' }}>
            Para Volver a distribuir debe estar seguro que no existan ideas
            evaluadas. Si continua esta acción no podrá recuperar ninguna de las
            asignaciones de la distribución anterior
            <br />
            <br />
            ¿Está seguro desea continuar?
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ width: '60px' }}
          variant="secondary"
          onClick={() => closeModal()}
        >
          No
        </Button>
        <Button
          variant="primary"
          style={{ width: '60px' }}
          onClick={() => distributeIdea()}
        >
          <span style={{ textTransform: 'capitalize' }}>Si</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
