import React from 'react';
import instagram from '../../../images/instagram.svg';
import basketball from '../../../images/basketball.svg';
import twitter from '../../../images/twitter.svg';
import youtube from '../../../images/youtube.svg';
import './Footer.scss';

export const Footer = () => {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  if (userSession?.userType !== 'admin') {
    return (
      <div className="footer">
        <h6 className="footer-copy">© 2022 Fundación Ideas</h6>
        <div className="social-media-container">
          <a target="_blank" href="https://www.instagram.com/concursoideas/">
            <img src={instagram} alt="" />
          </a>
          <a target="_blank" href="http://concursoideas.com/">
            <img src={basketball} alt="" />
          </a>
          <a target="_blank" href="https://twitter.com/ConcursoIdeas">
            {' '}
            <img src={twitter} alt="" />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCy34_pXKuDwtRxiB1sU01mg"
          >
            <img src={youtube} alt="" />
          </a>
        </div>
        <div>
          <h5>¿Necesitas Ayuda?</h5>
          <h6>
            Contáctanos para soporte{' '}
            <a href="mailto:comunicaciones@ideas.com.ve">
              <span>comunicaciones@ideas.com.ve</span>
            </a>
          </h6>
        </div>
      </div>
    );
  } else return null;
};
