/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getEconomicSectorList } from '../../../services/economicSectorService.js';
import { getCompanies, getBusiness } from '../../../services/companyService';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  ContestSelectInput,
  ContestInput,
} from '../../common/ContestForms/ContestForms';
import { UsersListTable, CompanyListTable } from '../../common/Tables/Tables';
import { CreateCompanyModal } from './CreateCompanyModal/CreateCompanyModal';
import { regularExpression } from '../../../helpers/regex.js';
import { Switch, Route, useHistory } from 'react-router-dom';
import { editUser, deleteUser } from '../../../services/userListService.js';
import {
  createBusiness,
  deleteCompany,
} from '../../../services/companyService';
import { getIdeaStates } from '../../../services/economicSectorService.js';
import { DeleteCompanyModal } from './DeleteCompanyModal/DeleteCompanyModal';
import { DeactivateUserModal } from './DeactivateCompanyModal/DeactivateCompanyModal';
import { CompanyDetail } from './CompanyDetail/CompanyDetail';
import { toast } from 'react-toastify';
import './CompanyManagement.scss';

export const CompanyManagement = () => {
  const [userList, setUserList] = useState({ number: 0, content: [] });
  const [isLoading, setIsloading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [user, setUser] = useState('');
  const [userToDelete, setUserToDelete] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [canPost, setCanPost] = useState(true);
  const [filter, setFilter] = useState({ rol: '', estatus: '', empresa: '' });
  const [companyList, setCompanyList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [canDelete, setCanDelete] = useState(true);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const history = useHistory();

  const rolesOptions = [
    { id: '0', name: 'Administrador' },
    { id: '1', name: 'Participante' },
    { id: '2', name: 'Jurado' },
    { id: '3', name: 'Jurado Líder' },
    { id: '4', name: 'Jurado Especial' },
  ];

  const statusOption = [
    { id: true, name: 'Activo' },
    { id: false, name: 'Inactivo' },
  ];

  const handleOpenCreateCriteriaModal = () => {
    setOpenCreateUserModal(true);
    setUser('');
    clearValidationErrors();
    setModalType('create');
  };

  const handleCloseCreateUserModal = () => {
    setOpenCreateUserModal(false);
    clearValidationErrors();
    setUser('');
    setModalType('');
  };

  const clearValidationErrors = () => {
    setUser({
      ...user,
      errors: {
        businessName: '',
        email: '',
        phone: '',
        state: '',
        address: '',
        postalCode: '',
        economicSector: '',
      },
    });
  };

  const openEditModal = (selectedUser) => {
    setUser({
      id: selectedUser?.id,
      name: selectedUser?.name,
      lastName: selectedUser?.lastName,
      email: selectedUser?.email,
      userType: selectedUser?.userType,
      errors: {
        name: '',
        lastName: '',
        email: '',
      },
    });

    setOpenCreateUserModal(true);
    setModalType('edit');
  };

  const validateUser = () => {
    let businessNameError = '';
    let emailError = '';
    let addressError = '';
    let postalCodeError = '';
    let phoneError = '';
    let canPost = true;

    if (regularExpression.noSpecialCharacters.test(String(user.businessName))) {
      businessNameError = 'El nombre no puede contener caracteres especiales';
      canPost = false;
    }
    if (user.businessName.length > 30) {
      businessNameError = 'El nombre debe tener un máximo de 30 caracteres';
      canPost = false;
    }

    if (user.email && !regularExpression.emailFormat.test(String(user.email))) {
      emailError = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    if (user?.phone && (user?.phone.length < 10 || user?.phone.length > 12)) {
      phoneError = 'Ingrese un Teléfono valido';
      canPost = false;
    }

    if (user.address && user.address.length > 100) {
      addressError = 'La dirección debe tener un máximo de 100 caracteres';
      canPost = false;
    }
    if (
      user.postalCode &&
      (user.postalCode.length > 6 || user.postalCode.length < 4)
    ) {
      postalCodeError = 'El código postal  debe tener entre 4 y 6 caracteres';
      canPost = false;
    }

    setUser({
      ...user,
      errors: {
        businessName: businessNameError,
        email: emailError,
        phone: phoneError,
        state: '',
        address: addressError,
        postalCode: postalCodeError,
        economicSector: '',
      },
    });

    return canPost;
  };

  const createEditUser = () => {
    // Validation
    let canPost = validateUser();
    if (canPost) {
      let data = {
        businessName: user.businessName ? user.businessName : null,
        economicSector: user.economicSector
          ? parseInt(user.economicSector)
          : null,
        email: user.email ? user.email : null,
        phone: user.phone ? user.phone : null,
        state: user.state ? parseInt(user.state) : null,
        address: user.address ? user.address : null,
        postalCode: user.postalCode ? user.postalCode : null,
      };

      if (modalType === 'create') {
        createUserFunction(data);
      }
      if (modalType === 'edit') {
        editUserFunction(data);
      }
    }
  };

  const createUserFunction = (data) => {
    if (canPost) {
      setCanPost(false);
      createBusiness(data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Empresa Creada');
            getAllUsersFunction();
            handleCloseCreateUserModal();
            setCanPost(true);
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          setCanPost(true);
          console.log('error:', error);
          toast.error('Error creando usuario');
        });
    }
  };

  const editUserFunction = (data) => {
    if (canPost) {
      setCanPost(false);
      editUser(data, data.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Usuario Editado');
            getAllUsersFunction();
            handleCloseCreateUserModal();
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error creando usuario');
          setCanPost(true);
        });
    }
  };

  const handleUserChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUser({ ...user, [name]: value });
  };

  const options = [
    { id: 1, name: 'Grupo 1' },
    { id: 2, name: 'Grupo 2' },
    { id: 3, name: 'Grupo 3 ' },
  ];

  const deleteUserFunction = () => {
    if (canDelete) {
      setCanDelete(false);
      deleteCompany(userToDelete?.id)
        .then((response) => {
          if (response?.status === 200) {
            toast.success('Empresa eliminada');
            getAllUsersFunction();
            handleCloseDeleteModal();
            setCanDelete(true);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setCanDelete(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error al eliminar usuario');
          setCanDelete(true);
        });
    }
  };

  const getAllUsersFunction = () => {
    let params = {
      page: userList.number,
      size: 10,
      rol: filter.rol,
      search: filter.filtro,
      estatus: filter.estatus,
      group: filter.group,
      empresa: filter.empresa ? filter.empresa.toString() : filter.empresa,
    };

    setIsloading(true);
    getBusiness(params)
      .then((response) => {
        if (response.status === 200) {
          setUserList(response.data);
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete('');
    setIsDeleteModalOpen(false);
  };

  const handlePageChange = (event, value) => {
    setUserList({ ...userList, number: value - 1 }); // -1 To remove page counter offset
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;
    setFilter({ ...filter, [name]: value });
    setUserList({ ...userList, number: 0 });
  };

  const getCompanyList = () => {
    getCompanies()
      .then((response) => {
        if (response.status === 200) {
          let companies = [];
          for (let i = 0; i < response.data.length; i++) {
            companies.push({
              id: response.data[i]?.id,
              name: response.data[i]?.businessName,
            });
          }
          setCompanyList(companies);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const disbableUser = (user) => {
    setIsDeactivateModalOpen(true);
    setUser(user);
  };

  const disableUser = () => {
    let usuarioEdit = user;
    usuarioEdit.active = !usuarioEdit.active;
    editUserFunction(usuarioEdit);
    handleCloseActivateModal();
  };

  const handleCloseActivateModal = () => {
    setUser('');
    setIsDeactivateModalOpen(false);
  };

  const getEconomicSectors = () => {
    getEconomicSectorList()
      .then((response) => {
        if (response.status === 200) {
          let data = [];

          for (let i = 0; i < response.data.length; i++) {
            data.push({
              id: response.data[i]?.id,
              name: response.data[i]?.name,
            });
          }
          setSectorList(data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getIdeaStatesFunction = () => {
    getIdeaStates()
      .then((response) => {
        if (response.status === 200) {
          let data = [];

          for (let i = 0; i < response.data.length; i++) {
            data.push({
              id: response.data[i]?.id,
              name: response.data[i]?.name,
            });
          }
          setStatesList(data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  useEffect(() => {
    getAllUsersFunction();
    getCompanyList();
    getEconomicSectors();
    getIdeaStatesFunction();
  }, [
    userList.number,
    filter.rol,
    filter.estatus,
    filter.empresa,
    filter.group,
  ]);

  return (
    <>
      <Switch>
        <Route
          path="/home/admin/company-management/edit/:id"
          render={(props) => {
            return <CompanyDetail {...props} />;
          }}
        />

        <Route
          path="/home/admin/company-management"
          render={(props) => {
            return (
              <div {...props}>
                <DeactivateUserModal
                  isOpen={isDeactivateModalOpen}
                  data={user}
                  closeDeleteModal={handleCloseActivateModal}
                  action={disableUser}
                />
                <DeleteCompanyModal
                  isOpen={isDeleteModalOpen}
                  data={userToDelete}
                  closeDeleteModal={handleCloseDeleteModal}
                  action={deleteUserFunction}
                />
                <CreateCompanyModal
                  sectorList={sectorList}
                  statesList={statesList}
                  isOpen={openCreateUserModal}
                  // contestList={contestList}
                  closeDeleteModal={handleCloseCreateUserModal}
                  // stagesOptions={stagesOptions}
                  action={createEditUser}
                  user={user}
                  handleChange={handleUserChange}
                  modalType={modalType}
                />

                <div className="dashboard-title-container">
                  <h1>GESTIÓN DE EMPRESAS</h1>
                  <div style={{ width: '300px' }}>
                    <ActionButton
                      action={() => handleOpenCreateCriteriaModal()}
                      text="Agregar Empresa"
                    />
                  </div>
                </div>
                <div className="contest-input-flex col-12">
                  <div className="company-filter-container">
                    <ContestInput
                      handleChange={onChangeFilter}
                      name="filtro"
                      label="Nombre o Sector"
                      value={filter?.filtro}
                      errors={''}
                      type={'text'}
                      placeholder={''}
                    />

                    <ActionButton
                      text={'Buscar'}
                      action={() => getAllUsersFunction()}
                    />
                  </div>
                </div>

                <CompanyListTable
                  data={userList}
                  isLoading={isLoading}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                  disbableUser={disbableUser}
                  openEditModal={openEditModal}
                  handlePageChange={handlePageChange}
                />
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
};
