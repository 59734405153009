import http from "./httpServices";

export async function updatePassword(datos, id, sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/password/${id}`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  

 try {
    const result = await http.put(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


export async function forgotPassword(datos) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/password`;

  const config = {
    headers: {     
      'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.post(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}