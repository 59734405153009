import React, { useEffect, useState } from 'react';
import { BusinessPlanDropzone } from '../IdeaDetail/MembersDropzone';
import { ConfirmedElement } from '../../common/ConfirmedElement/ConfirmedElement';
import { AwardCard } from '../../common/AwardCard/AwardCard.jsx';
import prize from '../../../images/prize.png';
import { getSpecialAwards } from '../../../services/awardService.js';
import { toast } from 'react-toastify';
// import { InfoCenter } from '../../common/InfoCenter/InfoCenter';
import './Stage2.scss';

export const SpecialAwardComponent = (props) => {
  const { award } = props;
  return (
    <div className="special-award-component">
      <img src={prize} alt="img" className="display-inline icon-prize" />
      <h5 className="display-inline">
        {award.title}, otorgado por {award?.business?.businessName}
      </h5>
    </div>
  );
};

// let infoCenterURL = process.env.REACT_APP_API_STAGE_2_INFO_CENTER;
export const Stage2 = (props) => {
  const {
    ideaForEdition,
    handleBusinessDragAndDropFiles,
    removeDocumentsfromList,
    openBase64File,
    showStage,
    currentState,
    currentStatus,
    userSession,
    handleAwardOnChange,
  } = props;

  const [awardList, setAwardList] = useState([]);

  const getAwards = () => {
    getSpecialAwards(ideaForEdition?.idea?.contest?.id)
      .then((response) => {
        if (response.status === 200) {
          let specialAwards = response.data.filter(
            (r) => r.postulable === false
          );
          setAwardList(specialAwards);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Error al obtener premios especiales');
      });
  };

  useEffect(() => {
    getAwards();
  }, []);

  if (showStage && currentStatus) {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  {/* <InfoCenter infoCenterURL={infoCenterURL} /> */}
                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      <ConfirmedElement
                        checked={
                          ideaForEdition.files.filter(
                            (i) => i.type === 'Plan_negocios'
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                      <BusinessPlanDropzone
                        ideaSummaryFiles={ideaForEdition.ideaSummaryFiles}
                        ideaForEdition={ideaForEdition}
                        handleDragAndDropFiles={handleBusinessDragAndDropFiles}
                        removeDocumentsfromList={removeDocumentsfromList}
                        openBase64File={openBase64File}
                        currentState={currentState}
                        ideaStatus={ideaForEdition.idea.stage.status}
                        isParticipant={
                          userSession.userType === 'participant' ? true : null
                        }
                      />
                    </div>
                  </div>

                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      <ConfirmedElement
                        checked={
                          ideaForEdition.awards.filter(
                            (i) => i.isCompeting === true
                          ).length > 0
                            ? true
                            : false
                        }
                      />

                      <div className="vertical-timeline-element-content bounce-in align-left">
                        <h4 className="timeline-title">
                          PREMIOS ESPECIALES (OPCIONAL)
                        </h4>
                        <p>
                          Su Idea se encuentra automáticamente Nominada a los
                          siguientes reconocimientos:
                        </p>
                        {awardList.map((a) => (
                          <SpecialAwardComponent
                            key={a?.id + a?.title}
                            award={a}
                          />
                        ))}

                        <p style={{ marginTop: '24px' }}>
                          Los siguientes premios son opcionales y para poder
                          participar debe postularse. Seleccione los premios
                          especiales a los cuales desea postularse.
                        </p>

                        {ideaForEdition.awards.length > 0
                          ? ideaForEdition.awards.map((c, i) => (
                              <AwardCard
                                key={i}
                                numberArray={i}
                                name={c.awards.title}
                                description={c.awards.description}
                                id={c.awards.id}
                                competing={c.competing}
                                currentState={currentState}
                                ideaStatus={ideaForEdition.idea.stage.status}
                                isParticipant={
                                  userSession.userType === 'participant'
                                    ? true
                                    : null
                                }
                                index={i}
                                handleAwardOnChange={handleAwardOnChange}
                              />
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};
