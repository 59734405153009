/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { ActionButton } from '../../../common/Buttons/Buttons';
import {
  NavLink,
  useParams,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import { Evaluations } from './Evaluations/Evaluations';
import { Preferences } from './Preferences/Preferences';
import {
  getUserById,
  editUserFromAdmin,
} from '../../../../services/userListService.js';
import { getStateListFunction } from '../../../../services/stateService.js';
import { sendJudgeInvitation } from '../../../../services/userListService';

import { getCompanies } from '../../../../services/companyService';
import { regularExpression } from '../../../../helpers/regex.js';
import { translateUserType } from '../../../../helpers/translate.js';
import {
  updatedUserLeader,
  getJudgePreferences,
} from '../../../../services/judgeService.js';

import './UserDetail.scss';
import { toast } from 'react-toastify';

const JudgeTypeComponent = (props) => {
  const { user, judgeLeadership, changeJudgeType, preferences } = props;

  if (['judge', 'judge_leader'].includes(user.userType) && preferences) {
    return (
      <div className="judge-change-tipe-container">
        <h6>{translateUserType(user.userType)}</h6>
        {/* {judgeLeadership === 'judge_leader' ? (
          <ion-icon
            onClick={() => changeJudgeType('judge')}
            name="checkbox-outline"
          ></ion-icon>
        ) : (
          <ion-icon
            onClick={() => changeJudgeType('judge_leader')}
            name="square-outline"
          ></ion-icon>
        )} */}
      </div>
    );
  } else {
    return <h6>{translateUserType(user?.userType)}</h6>;
  }
};

const UserDetailsTab = (props) => {
  const params = useParams();
  const { tab, user } = props;

  let pathArray = window.location.pathname.split('/');

  let selected = 'gray';
  let barColor = '#f2f2f2';
  if (pathArray[5] === tab.url) {
    selected = '#30377b';
    barColor = '#30377b';
  }

  if (['judge', 'judge_special', 'judge_leader'].includes(user.userType)) {
    if (['evaluations', 'general-info', 'preferences'].includes(tab.url)) {
      // Special admin does not access Prefeerences
      if (user.userType === 'judge_special' && tab.url === 'preferences') {
        return null;
      }

      return (
        <div className="users-detail-tab-component">
          <NavLink to={`/home/admin/user-management/${params?.id}/${tab.url}`}>
            <h6 style={{ color: selected }}>{tab.label}</h6>
          </NavLink>
          <div style={{ backgroundColor: barColor }}></div>
        </div>
      );
    } else {
      return null;
    }
  }
  if (user.userType === 'admin') {
    if (['general-info'].includes(tab.url)) {
      return (
        <div className="users-detail-tab-component">
          <NavLink to={`/home/admin/user-management/${params?.id}/${tab.url}`}>
            <h6 style={{ color: selected }}>{tab.label}</h6>
          </NavLink>
          <div style={{ backgroundColor: barColor }}></div>
        </div>
      );
    } else {
      return null;
    }
  }
  if (user.userType === 'participant') {
    if (['evaluations', 'general-info'].includes(tab.url)) {
      return (
        <div className="users-detail-tab-component">
          <NavLink to={`/home/admin/user-management/${params?.id}/${tab.url}`}>
            <h6 style={{ color: selected }}>{tab.label}</h6>
          </NavLink>
          <div style={{ backgroundColor: barColor }}></div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const UserDetail = () => {
  const [user, setUser] = useState({
    errors: {
      name: '',
      lastName: '',
      email: '',
      birthDate: '',
      gender: '',
      phoneHome: '',
      phoneMobile: '',
      phoneOther: '',
      state: '',
      address: '',
      business: '',
      companyPosition: '',
      idNumber:''
    },
  });

  const [stateList, setStateList] = useState([]);
  const [preferences, setPreferences] = useState('');
  const [canPost, setCanPost] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [judgeLeadership, setJudgeLeadership] = useState();

  const history = useHistory();
  const params = useParams();

  const tabs = [
    { label: 'Información General', url: 'general-info' },
    // { label: 'Evaluaciones', url: 'evaluations' },
    { label: 'Preferencias', url: 'preferences' },
  ];

  const changeJudgeType = (judgeType) => {
    updatedUserLeader(user?.id, judgeType)
      .then((response) => {
        if (response.status === 200) {
          setJudgeLeadership(judgeType);
          toast.success('Jurado actualizado');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error(error.message);
      });
  };

  const getUserData = (userId) => {
    getUserById(userId)
      .then((response) => {
        if (response.status === 200) {
          let userData = response.data;

          userData.gender = response.data?.gender?.id?.toString();
          userData.business = response.data?.business?.id;

          if (response.data?.birthDate) {
            let [newBirthdateFormat, rest] =
              response.data?.birthDate?.split('T');
            userData.birthDate = newBirthdateFormat;
          }

          setUser({ ...user, ...userData });
          setJudgeLeadership(userData?.userType);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const onChangeUser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const getStates = () => {
    getStateListFunction()
      .then((response) => {
        if (response.status === 200) {
          setStateList(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getCompaniesFunction = () => {
    getCompanies()
      .then((response) => {
        if (response.status === 200) {
          let companies = [];
          for (let i = 0; i < response.data.length; i++) {
            companies.push({
              id: response.data[i]?.id,
              name: response.data[i]?.businessName,
            });
          }
          setCompanyList(companies);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const validateUser = () => {
    // let createAccountForm = { ...this.state.createAccountForm };
    let canPost = true;
    //Delete error messages

    let errorString = {
      name: '',
      lastName: '',
      email: '',
      birthDate: '',
      gender: '',
      phoneHome: '',
      phoneMobile: '',
      phoneOther: '',
      state: '',
      address: '',
      business: '',
      companyPosition: '',
      idNumber: ''
    };

    // Evaluate required inputs
    if (user?.name == '') {
      errorString.name = 'Requerido';
      canPost = false;
    }
    // Evaluate required inputs
    if (user?.lastName == '') {
      errorString.lastName = 'Requerido';
      canPost = false;
    }
    // Evaluate required inputs
    if (user?.email == '') {
      errorString.email = 'email';
      canPost = false;
    }
    if(user?.idNumber==''){
      errorString.idNumber = 'Requerido';
      canPost = false;
    }

    if (user?.idNumber?.length < 6 || user?.idNumber?.length > 9) {
      errorString.idNumber = 'Ingrese un número de cédula válido';
      canPost = false;
    }


    if (!regularExpression.emailFormat.test(String(user.email))) {
      errorString.email = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    if (user?.phoneHome && user?.phoneHome.length < 10) {
      errorString.phoneHome = 'Ingrese un telefono valido';
      canPost = false;
    }

    if (user?.phoneMobile && user?.phoneMobile.length < 10) {
      errorString.phoneMobile = 'Ingrese un telefono valido';
      canPost = false;
    }

    if (user?.phoneOther && user?.phoneOther.length < 10) {
      errorString.phoneOther = 'Ingrese un telefono valido';
      canPost = false;
    }

    setUser({
      ...user,
      errors: {
        name: errorString.name,
        lastName: errorString.lastName,
        email: errorString.email,
        birthDate: errorString.birthDate,
        gender: errorString.gender,
        phoneHome: errorString.phoneHome,
        phoneMobile: errorString.phoneMobile,
        phoneOther: errorString.phoneOther,
        state: errorString.state,
        address: errorString.address,
        business: errorString.business,
        companyPosition: errorString.companyPosition,
        idNumber: errorString.idNumber
      },
    });

    return canPost;
  };

  const clearErrors = () => {
    setUser({
      ...user,
      errors: {
        name: '',
        lastName: '',
        email: '',
        birthDate: '',
        gender: '',
        phoneHome: '',
        phoneMobile: '',
        phoneOther: '',
        state: '',
        address: '',
        business: '',
        companyPosition: '',
        idNumber:''
      },
    });
  };

  const editUserFunction = () => {
    let data = {
      userType: user.userType,
      email: user.email,
      name: user.name,
      lastName: user.lastName,
      birthDate: user.birthDate,
      gender: parseInt(user.gender),
      idNumber:user.idNumber,
      phoneHome: user.phoneHome,
      phoneMobile: user.phoneMobile,
      phoneOther: user.phoneOther,
      state: parseInt(user.state),
      address: user.address,
      business: user.business ? { id: parseInt(user.business) } : user.business,
      companyPosition: user.companyPosition,
      active: user.active,
    };

    let isValid = validateUser();

    if (canPost && isValid) {
      setCanPost(false);
      editUserFromAdmin(data, user.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Usuario Editado');
            setCanPost(true);
            setTimeout(() => {
              setCanPost(true);
            }, 1500);
            // history.push('/home/admin/preferences');
              history.push('/home/admin/user-management');
              window.location.reload();
          } else {
          }
        })
        .catch((error) => {
          console.log('error:', error);
          setCanPost(true);
        });
    }
  };

  const sendNotification = (id) => {
    if (canPost) {
      setCanPost(false);
      sendJudgeInvitation(id)
        .then((response) => {
          if (response.status === 200) {
            setCanPost(true);
            toast.success('Invitacion enviada correctamente');
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          setCanPost(true);
        });
    }
  };

  const cancelUserEdition = () => {
    clearErrors();
    history.push('/home/admin/user-management/');
  };

  const changeUserStatus = () => {
    let editUser = user;
    editUser.active = !user.active;

    setUser(editUser);
    editUserFunction();
  };

  const getJudgePreferencesFunction = () => {
    getJudgePreferences(params.id)
      .then((response) => {
        if (response?.status === 200) {
          setPreferences(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  useEffect(() => {
    getUserData(params.id);
    getStates();
    getCompaniesFunction();
    getJudgePreferencesFunction();
  }, []);

  console.log('user:', user);
  return (
    <div>
      <div className="dashboard-title-container">
        <h1 style={{ textTransform: 'uppercase' }}>
          <NavLink to="/home/admin/user-management">
            <span style={{ color: 'gray' }}>USUARIOS</span>
          </NavLink>
          {'   '}> DETALLES DEL USUARIO {user?.name} {user?.lastName}
        </h1>
      </div>
      <div className="user-detail-round-container-component">
        <div>
          <div className="round-name">
            <h1>
              {user?.name?.substring(0, 1)}
              {user?.lastName?.substring(0, 1)}
            </h1>
          </div>
          {user.active ? (
            <ion-icon
              onClick={() => changeUserStatus()}
              style={{
                color: '#32CD32',
                transform: 'rotate(180deg)',
                fontSize: '50px',
                cursor: 'pointer',
              }}
              name="toggle"
            ></ion-icon>
          ) : (
            <ion-icon
              onClick={() => changeUserStatus()}
              style={{
                color: 'gray',
                fontSize: '50px',
                cursor: 'pointer',
              }}
              name="toggle"
            ></ion-icon>
          )}
        </div>
        <div className="user-detail-info-container">
          <div className="user-detail-info">
            <h2>
              {user?.name} {user?.lastName}
            </h2>
            <JudgeTypeComponent
              preferences={preferences}
              changeJudgeType={changeJudgeType}
              judgeLeadership={judgeLeadership}
              user={user}
            />{' '}
            {user.userName ? (
              <h6>
                <b>Nombre de Usuario:</b> {user?.userName}
              </h6>
            ) : (
              <h6>
                {' '}
                <b>Email:</b> {user?.email}
              </h6>
            )}
          </div>

          {['judge', 'judge_leader', 'judge_special'].includes(
            user?.userType
          ) && (
            <span style={{ marginTop: '12px' }}>
              <ActionButton
                action={() => sendNotification(params.id)}
                text={'Enviar Notificación'}
              />
            </span>
          )}
        </div>
      </div>
      <div className="user-details-tabs-container">
        {tabs.map((t) => (
          <UserDetailsTab tab={t} user={user} />
        ))}
      </div>
      <Switch>
        <Route
          path="/home/admin/user-management/:id/general-info"
          render={(props) => {
            return (
              <GeneralInfo
                {...props}
                user={user}
                onChange={onChangeUser}
                cancelEdition={cancelUserEdition}
                save={editUserFunction}
                stateList={stateList}
                companyList={companyList}
              />
            );
          }}
        />
        <Route
          path="/home/admin/user-management/:id/evaluations"
          render={(props) => {
            return <Evaluations {...props} />;
          }}
        />
        <Route
          path="/home/admin/user-management/:id/preferences"
          render={(props) => {
            return <Preferences {...props} />;
          }}
        />
      </Switch>
    </div>
  );
};
