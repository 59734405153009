/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  getContests,
  getStageByContest,
} from '../../../services/contestService.js';
import { evaluationAssignedByGroup } from '../../../services/ideaHelperService.js';
import {
  getJudgeAvailableByIdea,
  assignJudgeToIdea,
  getJudgePreferences,
  deleteJudgeIdea,
  userEvaluationInformation,
  getJudgeCanEvaluate,
  judgeIdeaStatus,
} from '../../../services/judgeService.js';
import { AssignJudgeModal } from '../../common/Modals/AssignJudgeModal/AssignJudgeModal';
import { RemoveJudgeModal } from '../../common/Modals/RemoveJudgeModal/RemoveJudgeModal';
import { EvaluatorManagementTable } from '../../common/Tables/Tables';
import {
  ContestSelectInput,
  ContestInput,
} from '../../common/ContestForms/ContestForms';
import { showDate } from '../../../helpers/dateFormat.js';
import { getAllUsers } from '../../../services/userListService.js';
import './EvaluatorsManagement.scss';

export const EvaluationStageElement = (props) => {
  const { stage } = props;

  let isOpen = '';

  if (stage.isOpen) {
    isOpen = 'evaluation-stage-is-open';
  }

  return (
    <div className={`evaluation-stage-element ${isOpen} `}>
      <h6>{stage.stage === 'Fase 3' ? 'Final' : stage.stage}</h6>
    </div>
  );
};

export const EvaluatorsManagement = (props) => {
  const { selectIdea } = props;
  const [selectedContest, setSelectedContest] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    ideaType: '',
    filter: '',
    evalStatus: '',
  });
  const [stages, setStages] = useState([]);
  const [activeStage, setActiveStage] = useState('');
  const [userSession, setUserSession] = useState('');

  const [evaluatorsList, setevaluatorsList] = useState({
    number: 0,
    content: [],
  });
  const [userPreferences, setUserPreferences] = useState('');

  const [showAsignModal, setShowAsignModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedEval, setSelectedEval] = useState('');
  const [currentStage, setCurrentStage] = useState('');
  const [groupId, setGroupId] = useState('');
  const [judgesForIdea, setJudgesForIdea] = useState('');
  const [selectedJudge, setSelectedJudge] = useState('');
  const [searchJudge, setSearchJudge] = useState('');
  const [asignedEvals, setAsignedEvals] = useState('');
  const [typesCanEval, setTypesCanEval] = useState('');

  const options = [
    { id: '', name: 'Todos' },
    { id: '0', name: 'No Iniciado' },
    { id: '1', name: 'En proceso' },
    { id: '2', name: 'Finalizado' },
  ];

  const optionTypes = [
    { id: 'D', name: 'Digital' },
    { id: 'S', name: 'Social' },
    { id: 'N', name: 'Negocio' },
  ];

  const fasesOption = [
    { id: 'Fase 1', name: 'Fase 1' },
    { id: 'Fase 2', name: 'Fase 2' },
    { id: 'Fase 3', name: 'Fase 3' },
  ];

  const getContestsStages = async (contest, modality) => {
    if (contest && modality) {
      await getStageByContest(contest?.id, modality)
        .then((response) => {
          if (response.status == 200) {
            let stages = response.data;

            if (modality == '0') {
              // Remove Registrado and Ganador stages if modality == Postulacion
              stages = stages.filter(
                (s) => s.status !== 'Registrado' && s.status !== 'Ganador'
              );
            }
            // Set error messages and changed state in blank
            stages.forEach((item) => {
              item.hasChanged = false;
              item.startDateError = '';
              item.endDateError = '';
              item.isOpenError = '';
            });

            setStages(stages);

            let [isOpen] = stages.filter((s) => s.isOpen === true);

            if (isOpen) {
              setActiveStage(isOpen);
            }

            // getjudgeIdeas(contest, isOpen);
            let [seachStage] = contest.stages.filter(
              (s) => s.stage === isOpen?.stage && s.type === 'JURADO'
            );
            setCurrentStage(seachStage);

            getEvaluationAssignedByGroup(isOpen);
          } else {
            toast.error(`Error al obtener concursos`);
          }
        })
        .catch(() => {});
    } else {
    }
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;

    // if (['D', 'S', 'N'].includes(value)) {
    //   setFilter({ ...filter, ideaType: value, evalStatus: value });
    // } else if (['Fase 1', 'Fase 2', 'Fase 3'].includes(value)) {
    //   setFilter({ ...filter, stageName: value });
    // } else {
    //   setFilter({ ...filter, [name]: value, ideaType: '' });
    // }

    // if (!value) {
    //   setFilter({ ...filter, ideaType: '', evalStatus: '', [name]: value });
    // }

    setFilter({ ...filter, [name]: value });

    setevaluatorsList({ ...evaluatorsList, number: 0 });
  };

  const handlePageChange = (event, value) => {
    setTimeout(() => {
      setevaluatorsList({ ...evaluatorsList, number: value - 1 }); // -1 To remove page counter offset
    }, 500);
  };

  const getUserData = () => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    setUserSession(userSession);
  };

  const getEvaluatorsFunction = (params) => {
    getAllUsers(params)
      .then((response) => {
        if (response.status === 200) {
          setevaluatorsList(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getDataOnInit = () => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);

    getJudgePreferences(userSession?.userId)
      .then((response) => {
        if (response.status === 200) {
          let params = {
            page: 0,
            size: 10,
            rol: 2,
            // idStage: seachStage?.id,
            // idContest: contest?.id,
            groupId: groupId ? groupId : response.data.groupNumber,
            // idUser: userSession.userId,
            // status: ['D', 'S', 'N'].includes(filter.evalStatus)
            //   ? ''
            //   : filter.evalStatus,
            // ideatype: filter.ideaType,
            // search: filter.search,
          };

          getEvaluatorsFunction(params);
        } else {
          toast.error('Error al obtener preferencias');
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getEvaluatorsData = () => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);

    let params = {
      page: evaluatorsList.number,
      // page: 0,
      size: 10,
      // rol: 2,
      // idStage: seachStage?.id,
      // idContest: contest?.id,
      // groupId: groupId ? groupId : response.data.groupNumber,
      // idUser: userSession.userId,
      status: ['D', 'S', 'N'].includes(filter.evalStatus)
        ? ''
        : filter.evalStatus,
      ideaType: filter.ideaType,
      stageName: filter.stageName,
      search: filter.search,
    };
    judgeIdeaStatus(userSession?.userId, params)
      .then((response) => {
        if (response.status === 200) {
          setevaluatorsList(response.data);
          setIsLoading(false);
          // setGroupId(response.data.groupNumber);
        } else {
          toast.error('Error al obtener evaluadores');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserData();
    getEvaluatorsData();
    // getContestFunction();
    // getDataOnInit();
    // getEvaluationAssignedByGroup();
  }, [
    evaluatorsList.number,
    filter.ideaType,
    filter.evalStatus,
    filter.stageName,
  ]);

  return (
    <>
      {/* <AssignJudgeModal
        selectedEval={selectedEval}
        show={showAsignModal}
        judgesForIdea={judgesForIdea}
        selectJudge={selectJudge}
        selectedJudge={selectedJudge}
        closeModal={closeAssginModal}
        assignJudge={assignJudge}
        handleUserSearchChange={handleUserSearchChange}
        searchJudge={searchJudge}
        searchJudgesFunction={searchJudgesFunction}
        asignedEvals={asignedEvals}
        typesCanEval={typesCanEval}
      /> */}
      {/* <RemoveJudgeModal
        selectedEval={selectedEval}
        show={showRemoveModal}
        // judgesForIdea={judgesForIdea}
        // selectJudge={selectJudge}
        selectedJudge={selectedJudge}
        closeModal={closeRemoveModal}
        removeJudgeFromIdea={removeJudgeFromIdea}
        handleUserSearchChange={handleUserSearchChange}
        searchJudge={searchJudge}
        searchJudgesFunction={searchJudgesFunction}
      /> */}

      <div className="dashboard-title-container">
        <h1>GESTIÓN DE EVALUADORES (COLABORADORES)</h1>
      </div>

      {stages.length > 0 && (
        <div className="evaliation-state-container">
          {stages.map((s) => (
            <EvaluationStageElement key={s.id} stage={s} />
          ))}
        </div>
      )}
      <br />

      {activeStage && (
        <p className="judge-evaluation-end-date-message">
          Fecha límite para evaluar la fase actual termina{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            el {showDate(activeStage?.endDate)}
          </span>
          <br />
          {/* Le quedan cinco (5) días para completar las evaluaciones que le falten */}
        </p>
      )}

      <div className="judge-evaluation-filters-container">
        <div className="row">
          <div
            style={{ padding: '0px', marginTop: '4px' }}
            className="contest-input-flex evaluations-search-pt1-element col-12 col-lg-6"
          >
            <div className="evaluation-filter-container">
              <ContestInput
                handleChange={onChangeFilter}
                name="search"
                label="Buscar usuarios"
                value={filter?.search}
                errors={''}
                type={'text'}
                placeholder={''}
              />

              <ActionButton
                text={'Buscar'}
                action={() => getEvaluatorsData()}
              />
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="users-dropdown-container">
              <ContestSelectInput
                options={fasesOption}
                handleChange={onChangeFilter}
                name="stageName"
                label="Fase"
                value={filter.stageName}
                errors={''}
                type={'text'}
                placeholder={''}
              />
            </div>
          </div>
          <div className="col-12 col-lg-2">
            <div className="users-dropdown-container">
              <ContestSelectInput
                options={optionTypes}
                handleChange={onChangeFilter}
                name="ideaType"
                label="Tipo:"
                value={filter.ideaType}
                errors={''}
                type={'text'}
                placeholder={''}
              />
            </div>
          </div>
          <div className="col-12 col-lg-2">
            <div className="users-dropdown-container">
              <ContestSelectInput
                options={options}
                handleChange={onChangeFilter}
                name="evalStatus"
                label="Evaluación:"
                value={filter.evalStatus}
                errors={''}
                type={'text'}
                placeholder={''}
              />
            </div>
          </div>
        </div>
      </div>

      {!isLoading && evaluatorsList.content.length === 0 ? (
        <h4 style={{ marginTop: '200px' }}> No se encontraron resultados</h4>
      ) : null}
      <EvaluatorManagementTable
        data={evaluatorsList}
        isLoading={isLoading}
        selectIdea={selectIdea}
        userSession={userSession}
        // openAssginModal={openAssginModal}
        // openRemoveModal={openRemoveModal}
        // handleOpenDeleteModal={handleOpenDeleteModal}
        // openEditModal={openEditModal}
        handlePageChange={handlePageChange}
      />
    </>
  );
};
