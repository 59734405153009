/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  ContestInput,
  ContestSelectInput,
} from '../../../common/ContestForms/ContestForms';
import { getContests } from '../../../../services/contestService';
import { getStageByContest } from '../../../../services/contestService';
import { evaluationAssignedByGroup } from '../../../../services/ideaHelperService.js';
import { translateStatus } from '../../../../helpers/translate.js';
import {
  getJudgeDistribution,
  distributeIdeas,
} from '../../../../services/judgeService';
import { ProcessStagesTable } from '../../../common/Tables/Tables';
import { toast } from 'react-toastify';
import { DistributionModal } from './DistributionModal/DistributionModal';
import './GeneralInfo.scss';
import Loader from 'react-loader-spinner';
export const GeneralInfo = () => {
  const [contestList, setContestList] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [selectedContest, setSelectedContest] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [judges, setJudges] = useState({ value: 3, error: '' });
  const [distributeModal, setSistributeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDistribution, setSelectedDistribution] = useState('');
  const [stagesOptions, setStagesOptions] = useState([
    { id: 'Fase 1', name: 'Fase 1' },
    { id: 'Fase 2', name: 'Fase 2' },
    { id: 'Fase 3', name: 'Fase 3' },
  ]);
  const [evaluationsReportFilter, setEvaluationsReportFilter] = useState({
    stage: 'Fase 1',
  });

  const [evaluationList, setEvaluationList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const selectReport = (c) => {
    getAllEvaluationsFunction(selectedStage, c[1]);
    setModalOpen(true);
  };

  const closeReportModal = () => {
    setEvaluationList([]);
    setModalOpen(false);
  };

  const getAllEvaluationsFunction = (stage, ideaType) => {
    let params = {
      page: 0,
      size: 100000,
      // groupId: groupId ? groupId : '',
      // idUser: userSession.userId,
      status: evaluationsReportFilter.status,
      ideaType: ideaType,
    };

    evaluationAssignedByGroup(stage, params)
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response.data.content.length; i++) {
            data.push({
              // Fase: response.data.content[i]?.judgeIdea?.stage?.stage,
              'ID de la Idea': response.data.content[i]?.judgeIdea?.idea?.id,
              Nombre: response.data.content[i]?.judgeIdea?.idea?.title,
              'Tipo de Idea':
                response.data.content[i]?.judgeIdea?.idea?.proposalType
                  ?.proposalType,
              'Estatus de la idea':
                response.data.content[i]?.judgeIdea?.idea?.stage?.status,
              'ID DANA': response.data.content[i]?.judgeIdea?.idea?.codeDana,
              'Estatus de Evaluación': translateStatus(
                response.data.content[i]?.evaluationStatus
              ),
              'Email Lider de Idea':
                response.data.content[i]?.judgeIdea?.idea?.user?.email,

              'Lider de Idea':
                response.data.content[i]?.judgeIdea?.idea?.user?.name +
                '  ' +
                response.data.content[i]?.judgeIdea?.idea?.user?.lastName,

              'Email del jurado evaluador':
                response.data.content[i]?.judgeIdea?.user?.email,
              'Jurado Evaluador':
                response.data.content[i]?.judgeIdea?.user?.name +
                '  ' +
                response.data.content[i]?.judgeIdea?.user?.lastName,
              'Telefono Jurado':
                response.data.content[i]?.judgeIdea?.user?.phoneMobile,

              // 'Criterio 1':
              //   response.data.content[i]?.singleQualifications[0]
              //     ?.qualification,
              // 'Criterio 2':
              //   response.data.content[i]?.singleQualifications[1]
              //     ?.qualification,
              // 'Criterio 3':
              //   response.data.content[i]?.singleQualifications[2]
              //     ?.qualification,
              // 'Criterio 4':
              //   response.data.content[i]?.singleQualifications[3]
              //     ?.qualification,
              // 'Criterio 5':
              //   response.data.content[i]?.singleQualifications[4]
              //     ?.qualification,
              // 'Criterio 6':
              //   response.data.content[i]?.singleQualifications[5]
              //     ?.qualification,

              'Nota total': response.data.content[i]?.qualification,
            });
          }

          setEvaluationList(data);
        } else {
          toast.error('Error obtener evaluaciones');
          // setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        // setIsLoading(false);
      });
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response?.status == 200) {
        let openContest = response.data.filter((d) => d.isOpen);

        setContestList(openContest);
        let [selected] = response.data.filter((d) => d.isOpen);
        if (selected) {
          let [stageOpen] = selected.stages.filter(
            (d) => d.isOpen && d.type === 'JURADO'
          );
          if (stageOpen) {
            setSelectedStage(stageOpen?.stage);
            getDistributionIdeas(selected.id, stageOpen?.stage);
          }
        }
        setSelectedContest(selected.id);
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  const onChangeJudgeCount = (event) => {
    const { value } = event.target;
    let error = '';

    if (value < 1 && value !== '') {
      error = 'El proceso debe un mínimo de 1 jurado';
    }
    if (value > 10) {
      error = 'El proceso debe un máximo de 10 jurados';
    }

    setJudges({ ...judges, value: value, error: error });
  };

  const onChangeSelectedStage = (event) => {
    const { value } = event.target;
    setSelectedStage(value);
    getDistributionIdeas(selectedContest, value);
  };

  const onChangeSelectedContest = (event) => {
    const { value } = event.target;
    setSelectedContest(value);
    setSelectedStage('');
    getStages(value);
  };

  const getStages = (contestId) => {
    if (contestId) {
      getStageByContest(contestId, 1)
        .then((response) => {
          if (response?.status == 200) {
            let options = [];

            for (let i = 0; i < response.data.length; i++) {
              options.push({
                name: response.data[i].stage,
                id: response.data[i].id,
              });
            }
            response.data.forEach((element) => {
              element.name = response.data.stage;
            });

            setStagesOptions(options);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error al obtener etapas');
        });
    }
  };

  const getDistributionIdeas = (contestId, stage) => {
    setIsLoading(true);
    getJudgeDistribution(contestId, stage)
      .then((response) => {
        if (response?.status == 200) {
          setDistribution(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        // toast.error('Error al obtener etapas');
      });
  };

  const distributeIdeasFunction = (process) => {
    let ideaType = '';

    if (process[1] === 'S') {
      ideaType = 'SOCIAL';
    }
    if (process[1] === 'N') {
      ideaType = 'BUSINESS';
    }

    if (process[1] === 'D') {
      ideaType = 'DIGITAL';
    }

    let data = {
      contestId: selectedContest,
      stageName: selectedStage,
      ideaType: ideaType,
      ideaTypeCode: process[1],
      evaluators: judges.value,
    };

    if (!judges.error) {
      distributeIdeas(data)
        .then((response) => {
          if (response?.status == 200) {
            toast.success('Distribución exitosa');
            getDistributionIdeas(selectedContest, selectedStage);
          }
          if (response?.status != 200 && response?.status != 400) {
            toast.error(response?.data?.errorMessage);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          // toast.error('Error al obtener etapas');
        });
    }
  };

  const validateDistribution = (c) => {
    if (c?.[5]) {
      setSistributeModal(true);
      setSelectedDistribution(c);
    } else {
      distributeIdeasFunction(c);
    }
  };

  const distributeIdea = () => {
    setSistributeModal(false);
    distributeIdeasFunction(selectedDistribution);
  };

  useEffect(() => {
    getContestFunction();
  }, []);
  return (
    <>
      <DistributionModal
        isOpen={distributeModal}
        closeModal={() => setSistributeModal(false)}
        distributeIdea={distributeIdea}
      />
      <div className="contest-list-container">
        <h5 className="preferences-subtitle">Información Básica del Proceso</h5>
      </div>
      <div className="row">
        <div className="contest-input-flex col-12 col-md-4">
          <ContestSelectInput
            options={contestList}
            handleChange={onChangeSelectedContest}
            name="selectedContest"
            label="Concurso"
            type={'text'}
            placeholder={''}
            errors={''}
            value={selectedContest}
          />
        </div>
        <div className="constes-small-input contest-input-flex col-12 col-md-4">
          <ContestSelectInput
            options={stagesOptions}
            handleChange={onChangeSelectedStage}
            name="selectedStage"
            label="Fase"
            type={'text'}
            placeholder={''}
            errors={''}
            value={selectedStage}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="constes-small-input contest-input-flex col-12 col-md-4">
          <ContestInput
            handleChange={onChangeJudgeCount}
            name="judges"
            label="Jurados por Idea"
            value={judges.value}
            errors={judges.error}
            type={'number'}
            placeholder={''}
          />
        </div>
      </div>
      {isLoading && selectedStage !== 'Fase 3' && (
        <div style={{ marginTop: '120px', width: '100%' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando </h4>
        </div>
      )}
      {selectedStage !== 'Fase 3' && !isLoading && (
        <ProcessStagesTable
          data={distribution}
          distributeIdeasFunction={distributeIdeasFunction}
          validateDistribution={validateDistribution}
          evaluationList={evaluationList}
          selectReport={selectReport}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          closeReportModal={closeReportModal}
          selectedStage={selectedStage}
        />
      )}
      {selectedStage === 'Fase 3' && (
        <h2 style={{ marginTop: '48px' }}>
          La fase ingresada no permite distribución
        </h2>
      )}
    </>
  );
};
