/* eslint-disable */
import React, { useEffect, useState } from 'react';
import logo from '../../../images/logo-transparent.png';
import background from '../../../images/background3.jpg';
import ErrorAlert from '../../common/ErrorAlert/ErrorAlert';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getContestLogo } from '../../../services/contestService.js';
import {
  getContestStagesBeforelogin,
  isContestOpen,
} from '../../../services/contestService.js';
import './Login.scss';

let meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const LoginButton = (user, loginService, props) => {
  if (user.email === '' || user.password === '') {
    return (
      <button id="login-button" className="button login-button blocked-button">
        Ingresar
      </button>
    );
  } else {
    return (
      <button
        id="login-button"
        onClick={() => loginService(props)}
        className="button login-button"
      >
        Ingresar
      </button>
    );
  }
};

const Login = (props) => {
  const {
    passwordShown,
    setPasswordShownValue,
    inputChange,
    loginService,
    user,
    errors,
    blockedLogin,
    isFase1Open,
  } = props;

  const [stageDates, setStageDates] = useState();
  const [contestOpen, setContestOpen] = useState(true);
  const [contestLogo, setContestLogo] = useState('');
  const [contestBackground, setContestBackground] = useState('');

  const getCurrentContestStage = () => {
    getContestStagesBeforelogin().then((response) => {
      if (response?.status === 200) {
        let [data] = response.data.filter((d) => d.isOpen);
        setStageDates(data);
      } else if (response?.status === 404) {
        console.log('No hay concursos abiertos');
      } else {
        toast.error('Error al obtener etapas');
      }
    });
  };

  const isContestOpenFunction = () => {
    isContestOpen().then((response) => {
      if (response?.status === 200) {
        setContestOpen(true);
      } else if (response?.status === 404) {
        setContestOpen(false);
      } else {
        toast.error('Error al obtener concurso');
      }
    });
  };

  const getContestLogoFunction = () => {
    getContestLogo('Logo')
      .then((response) => {
        if (response.status === 200) {
          setContestLogo(response.data.fileUrl);
        }
      })
      .catch(() => {
        toast.error('Error al obtener logo del concurso');
      });
  };

  const getContestBackgroundFunction = () => {
    getContestLogo('Background')
      .then((response) => {
        if (response.status === 200) {
          setContestBackground(response.data.fileUrl);
        }
      })
      .catch(() => {
        toast.error('Error al obtener fondo del concurso');
      });
  };

  useEffect(() => {
    getCurrentContestStage();
    isContestOpenFunction();
    getContestLogoFunction();
    getContestBackgroundFunction();
  }, []);

  const showDate = (date) => {
    let [year, month, day] = date.split('-');
    let stringDate = `el ${parseInt(day)} de ${
      meses[parseInt(month) - 1]
    } del ${year}`;
    return stringDate;
  };

  return (
    <div className="login-container row">
      <LoadSpinner
        blocked={blockedLogin}
        text="Iniciando sesión"
        color="#000000"
      />

      <div className="login-dashboard login-container col-12 col-md-7 col-lg-6 ">
        {contestLogo && (
          <img className="login-dashboard-logo" src={contestLogo} alt="logo" />
        )}{' '}
        <div className="login-ident-content">
          <h1 className="title">
            Bienvenido al Centro de Atención al Participante (CAP)
          </h1>

          {isFase1Open && contestOpen ? (
            <h6>
              <NavLink to="/register">Crea una cuenta para participar</NavLink>
            </h6>
          ) : null}

          <h6>
            Sólo el líder del equipo necesita una cuenta para poder aplicar al
            Concurso Ideas. Desde aquí podrás consignar todos los documentos
            requeridos en cada una de las etapas, así como también el acceso a
            todo el material de apoyo y consultas con los organizadores.
          </h6>

          {!contestOpen ? (
            <h6>
              <b>
                El concurso no se encuentra activo, aun no inicia el proceso de
                postulación.
              </b>
            </h6>
          ) : null}

          {contestOpen && stageDates && (
            <h6>
              <b>
                Las aplicaciones para competir estarán activas desde el{' '}
                {showDate(stageDates?.startDate)}
                {'  '} hasta el {showDate(stageDates?.endDate)}.
              </b>
            </h6>
          )}

          <h6>
            Al registrarte, recibirás un correo de bienvenida con toda tu
            información de registro, si ya tienes tu cuenta con tu contraseña,
            puedes iniciar sesión abajo, o usar la funcionalidad de ¿Olvidaste
            tu contraseña?
          </h6>

          <h6>Ingresa los datos de tu cuenta para continuar</h6>
          <div className="login-form-container">
            <input
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  loginService(props);
                }
              }}
              onChange={inputChange}
              autoComplete="off"
              className="input"
              type="text"
              name="email"
              placeholder="Nombre de Usuario o Correo Electrónico"
              value={user.email}
            />
            <ErrorAlert error={errors.email} />
            <div className="input-eye-container">
              <input
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    loginService(props);
                  }
                }}
                onChange={inputChange}
                className="input input-icon"
                type={passwordShown === true ? 'text' : 'password'}
                name="password"
                placeholder="Contraseña"
                value={user.password}
              />
              <i
                id="eyePassword"
                className="font-eye"
                onClick={() => setPasswordShownValue()}
              >
                {passwordShown === false ? (
                  <FontAwesomeIcon icon="eye" />
                ) : (
                  <FontAwesomeIcon icon="eye-slash" />
                )}
              </i>
            </div>

            <ErrorAlert error={errors.password} />

            {LoginButton(user, loginService, props)}
            <a className="forgot-password" href="/recover-password">
              ¿Olvidaste tu contraseña?
            </a>
          </div>
        </div>
      </div>

      <div className="login-background-container login-container  col-md-5 col-lg-6 ">
        {contestBackground && (
          <img
            className="login-dashboard-background"
            src={contestBackground}
            alt="logo"
          />
        )}{' '}
      </div>
    </div>
  );
};

export default Login;
