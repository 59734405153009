/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  ContestInput,
  ContestSelectInput,
} from '../../../common/ContestForms/ContestForms';
import { rolesOptions } from '../../../../helpers/types.js';

import './CreateUserModal.scss';

export const CreateUserModal = (props) => {
  const {
    isOpen,
    closeDeleteModal,
    action,
    user,
    handleChange,
    contestList,
    modalType,
  } = props;

  const nameComponent = (type, rolesOptions) => {
    let [selectedType] = rolesOptions.filter((r) => r.id == type);
    return selectedType?.name || null;
  };

  let callToAction = '';
  if (modalType == 'create') {
    callToAction = 'Crear';
  } else {
    callToAction = 'Editar';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textTransform: 'capitalize' }}>
          {callToAction} Usuario {nameComponent(user?.userType, rolesOptions)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="name"
              label="Nombre"
              value={user?.name}
              errors={user?.errors?.name}
              type={'text'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="lastName"
              label="Apellido"
              value={user?.lastName}
              errors={user?.errors?.lastName}
              type={'text'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="email"
              label="Email"
              value={user?.email}
              errors={user?.errors?.email}
              type={'text'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={rolesOptions}
              handleChange={handleChange}
              name="userType"
              label="Rol del Usuario"
              type={'text'}
              placeholder={''}
              errors={''}
              value={user?.userType}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => action()}
          disabled={
            !user?.name || !user?.lastName || !user?.email || !user?.userType
          }
        >
          <span style={{ textTransform: 'capitalize' }}>
            {callToAction} Usuario {nameComponent(user?.userType, rolesOptions)}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
