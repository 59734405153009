import http from "./httpServices";

export async function createMember(data, ideaId, sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${ideaId}/create-member`;

const config = {
    headers: {
      Authorization: `${sessionToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteMember(ideaId, memberId, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/${ideaId}/remove-member`;

  var data = {
    id: memberId
  };

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      "Content-Type": "application/json",
    },
  };


  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }

}

export async function memberList(ideaId, sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${ideaId}/members-idea`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}