import React from 'react';
import empty from 'is-empty';
import arrowRight from '../../../images/arrowRight.svg';
import arrowLeft from '../../../images/arrowLeft.svg';
import './Buttons.scss';

export const EvaluationStatusButton = (props) => {
  const { label } = props;

  if (label === 'NO INICIADO') {
    return (
      <div
        style={{ backgroundColor: '#ff5756' }}
        className="evaluation-status-button"
      >
        {label}
      </div>
    );
  }

  if (label === 'EN PROGRESO' || label === 'EN PROCESO') {
    return (
      <div
        style={{ backgroundColor: '#fb8832' }}
        className="evaluation-status-button"
      >
        {label}
      </div>
    );
  }
  if (label === 'NO ASIGNADO') {
    return (
      <div
        style={{ backgroundColor: '#777777' }}
        className="evaluation-status-button"
      >
        {label}
      </div>
    );
  }
  if (label === 'FINALIZADO') {
    return (
      <div
        style={{ backgroundColor: '#4aaf05' }}
        className="evaluation-status-button"
      >
        {label}
      </div>
    );
  } else return label;
};

export const ActionButton = (props) => {
  const { action, text, disabled } = props;
  if (disabled) {
    return (
      <button
        style={{ opacity: '0.2', cursor: 'auto' }}
        className="action-button button login-button action-button"
      >
        {text}
      </button>
    );
  } else {
    return (
      <button
        onClick={() => action()}
        className="action-button button login-button action-button"
      >
        {text}
      </button>
    );
  }
};

export const ForgotPasswordB = (props) => {
  const { action, text, disabled } = props;
  if (disabled) {
    return (
      <button
        style={{ opacity: '0.2', cursor: 'auto', width: '100%' }}
        className="action-button button login-button action-button"
      >
        {text}
      </button>
    );
  } else {
    return (
      <button
        style={{ width: '100%' }}
        onClick={() => action()}
        className="action-button button login-button action-button"
      >
        {text}
      </button>
    );
  }
};

// Button that handles the wizard next step
export const CancelActionButton = (props) => {
  const { action, text } = props;
  return (
    <div
      onClick={() => action()}
      className="return-wizard-button wizard-button action-button"
    >
      <h6>{text}</h6>
    </div>
  );
};

export const Button = (props) => {
  const { action, text } = props;
  return (
    <button
      id="login-button"
      onClick={() => action()}
      className="button login-button action-button"
    >
      {text}
    </button>
  );
};

// Button that handles the wizard next step
export const ConfirmWizardButton = (props) => {
  const { handleConfirm, text } = props;
  return (
    <div
      onClick={handleConfirm}
      className="confirm-wizard-button wizard-button"
    >
      <h6>{text}</h6>
    </div>
  );
};

// Button that handles the wizard next step
export const ReturnWizardButton = (props) => {
  const { handlePrevStep, text } = props;
  return (
    <div
      onClick={handlePrevStep}
      className="return-wizard-button wizard-button"
    >
      <img src={arrowLeft} alt="" />
      <h6>{text}</h6>
    </div>
  );
};

// Button that handles the wizard cancel option
export const CancelWizardButton = (props) => {
  const { cancelIdeaCreation, text, history, userSession } = props;
  return (
    <div
      onClick={() => cancelIdeaCreation(history, userSession)}
      className="return-wizard-button wizard-button"
    >
      <h6>{text}</h6>
    </div>
  );
};

// Button that handles the wizard next step
export const NextWizardButton = (props) => {
  const { handleNextStep, text } = props;
  return (
    <div onClick={handleNextStep} className="next-wizard-button wizard-button">
      <h6>{text}</h6>
      <img src={arrowRight} alt="" />
    </div>
  );
};

// Buton that cancels the file removal from DB
export const CancelFileRemovalFomDBButton = (props) => {
  const { closeDeleteDocummentModal } = props;
  return (
    <div onClick={closeDeleteDocummentModal} className="button cancel-button">
      <h6>Cancelar</h6>
    </div>
  );
};

// Buton that deletes the file from the DB
export const DeleteFileFomDBButton = (props) => {
  const { deleteDocummentFromDBFunction } = props;
  return (
    <div
      onClick={deleteDocummentFromDBFunction}
      className="button delete-button"
    >
      <h6>Eliminar documento</h6>
    </div>
  );
};

// Buton that sends the forgot password email to the API
export const ForgotPasswordButton = (props) => {
  const { forgotPasswordFunction, forgotPwdForm } = props;

  if (empty(forgotPwdForm.data.email)) {
    return (
      <button className="button login-button blocked-button">ENVIAR</button>
    );
  } else {
    return (
      <button
        onClick={() => forgotPasswordFunction()}
        className="button login-button"
      >
        ENVIAR
      </button>
    );
  }
};

export const SavePasswordButton = (props) => {
  const { updatePasswordFunction, history, passwordUpdateForm } = props;
  let { oldPassword, newPassword, confirmNewPassword } =
    passwordUpdateForm.data;

  if (empty(oldPassword) || empty(newPassword) || empty(confirmNewPassword)) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => updatePasswordFunction(history)}
        className="button login-button"
      >
        <h6>Guardar</h6>
      </div>
    );
  }
};

// Button that Creates a new accout
export const CreateAccountButton = (props) => {
  const { createAccountForm, createAccountFunction, history } = props;
  const {
    name,
    lastName,
    email,
    password,
    confirmPassword,
    captcha,
    termsAndConditions,
  } = createAccountForm.data;

  // IF inputs are empty block button
  if (
    !name ||
    !lastName ||
    !email ||
    !password ||
    !confirmPassword ||
    captcha === false ||
    termsAndConditions === false
  ) {
    return (
      <button
        id="create-account-button"
        className="button login-button blocked-button"
      >
        REGISTRARME Y CONTINUAR
      </button>
    );
  } else {
    return (
      <button
        id="create-account-button"
        onClick={() => createAccountFunction(history)}
        className="button login-button"
      >
        REGISTRARME Y CONTINUAR
      </button>
    );
  }
};

// Button that edits the user in the edit user page
export const EditUserButton = (props) => {
  const { userForm, editUserFunction, history } = props;
  const { name, lastName, email, phone } = userForm.data;

  // IF inputs are empty block button
  if (!name || !lastName || !email || !phone) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => editUserFunction(history)}
        className="button login-button"
      >
        <h6>Guardar</h6>
      </div>
    );
  }
};

// Button that saves the user in the create user page
export const SaveUserButton = (props) => {
  const { userForm, createUserFunction, history } = props;
  const { name, lastName, email, phone } = userForm.data;

  // IF inputs are empty block button
  if (!name || !lastName || !email || !phone) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => createUserFunction(history)}
        className="button login-button"
      >
        <h6>Guardar</h6>
      </div>
    );
  }
};

// Button that saves the audit
// Button that consults the bond by its numner
export const ConsultBondByIdNumberButton = (props) => {
  const { bondNumber, getBondByNumberFunction } = props;
  // IF inputs are empty block button
  if (bondNumber === '') {
    return (
      <div
        id="consult-bond-by-number-button"
        className="button login-button blocked-button"
      >
        <h6>BUSCAR</h6>
      </div>
    );
  } else {
    return (
      <div
        id="consult-bond-by-number-button"
        onClick={getBondByNumberFunction}
        className="button login-button"
      >
        <h6>BUSCAR</h6>
      </div>
    );
  }
};
// Button that saves the bond audit
export const SaveAuditButton = (props) => {
  const { comments, createAuditFunction } = props;
  // IF inputs are empty block button
  if (comments === '') {
    return (
      <div
        id="save-audit-button"
        className="button-to-left-container save-button-contanier"
      >
        <div className="button login-button blocked-button">
          <h6>Guardar</h6>
        </div>
      </div>
    );
  } else {
    return (
      <div id="save-audit-button" className="save-button-contanier">
        <div onClick={createAuditFunction} className="button login-button">
          <h6>Guardar</h6>
        </div>
      </div>
    );
  }
};

// Create Bond and Upload fules button
export const CreateBondButton = (props) => {
  const { bondForm, createBondFunction, files } = props;
  const { client, number, actDate } = bondForm.data;

  // if (client === "" || number === "" || bondAmount === "" || projectAmount === "" || startDate === "" || endDate === "" || files.length === 0 ) {
  if (client === '' || number === '' || actDate === '' || files.length === 0) {
    return (
      <div id="registro-login-button" className="col-12 save-button-contanier">
        <div className="button login-button blocked-button">
          <h6>Guardar</h6>
        </div>
      </div>
    );
  } else {
    return (
      <div id="registro-login-button" className="col-12 save-button-contanier">
        <div onClick={createBondFunction} className="button login-button">
          <h6>Guardar</h6>
        </div>
      </div>
    );
  }
};

// Button that edits the user info and uploads more files
export const EditBondButton = (props) => {
  const { bondForm, uptadeBondFunction, files, history } = props;

  const { client, number, actDate } = bondForm.data;

  // if (!client  || !number  || !bondAmount  || !projectAmount  || !startDate  || !endDate  || files.length === 0 ) {
  if (!client || !number || !actDate || files.length === 0) {
    return (
      <div id="registro-login-button" className="col-12 save-button-contanier">
        <div className="button login-button blocked-button">
          <h6>Guardar</h6>
        </div>
      </div>
    );
  } else {
    return (
      <div id="registro-login-button" className="col-12 save-button-contanier">
        <div
          onClick={() => uptadeBondFunction(history)}
          className="button login-button"
        >
          <h6>Guardar</h6>
        </div>
      </div>
    );
  }
};
