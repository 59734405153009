import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ProtectedRoute } from './components/common/ProtectedRoute/ProtectedRoute';
import { signIn } from './services/loginService';
import {
  createUser,
  getUserData,
  updateUser,
  getRegisteredUser,
} from './services/userListService';
import { getStateList } from './services/stateService';
import {
  getCurrentContest,
  getStagesByCurrentContest,
  updateStage,
  getContestStagesBeforelogin,
  closeContest,
  getContests,
} from './services/contestService';
import {
  createMember,
  deleteMember,
  memberList,
} from './services/memberService';
import { getAwardsForIdea, saveAwardIdea } from './services/awardService';
import { getEconomicSectorsList } from './services/economicSectorService';
import {
  createDocumentService,
  getDocumentsByIdeaId,
  createDocumentByMember,
  createDocumentByCriteria,
  getDocumentsByCriteria
} from './services/documentService';
import {
  getEducationalInstitution,
  getEducationalLevel,
} from './services/educationalInstitutionService';
import { updatePassword, forgotPassword } from './services/passwordService';
import Login from './components/views/Login/Login';
import { BasicData } from './components/views/BasicData/BasicData';
import { CreateAccount } from './components/views/CreateAccount/CreateAccount';
import { Footer } from './components/common/Footer/Footer';
import ForgottPassword from './components/views/ForgotPassword/ForgotPassword';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joi from 'joi-browser';
import './App.css';
import empty from 'is-empty';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import * as initial from './variables/initialState';
import {
  faPencilAlt,
  faEye,
  faEyeSlash,
  faComments,
  faCheckSquare,
  faHome,
  faTrash,
  faTrashAlt,
  faFilePdf,
  faLightbulb,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';
import { CreateIdea } from './components/views/CreateIdea/CreateIdea';
import {
  getProposalTypes,
  getSocialTypes,
  getOcupations,
  getEconomicSectors,
  getIdeaStates,
  createOngFunction,
  addFinalPresentationUrl,
} from './services/ideaHelperService';

import { Home } from './components/views/Home/Home';
import {
  createIdeaFunction,
  getIdeaList,
  getCurrentYearIdeas,
  getAdminIdeaList,
  deleteIdea,
  changeStatusIdea,
} from './services/ideaService';
library.add(
  faPencilAlt,
  fab,
  faEye,
  faEyeSlash,
  faComments,
  faCheckSquare,
  faHome,
  faTrash,
  faFilePdf,
  faTrashAlt,
  faLightbulb,
  faChalkboardTeacher
);
import moment from 'moment';

class App extends Component {
  state = {
    comments: { show: false, data: '' },
    userSession: {}, //Data returned by the API after the login
    user: { email: '', password: '' }, // User data
    errors: { email: '', password: '' }, //Errors in login inputs
    blockedLogin: false, //Boolean thta show the load spinner
    gestion: { userType: '', govType: '' }, // User and gov type and insurances
    userList: {}, //User list dynamic table component
    userData: {},
    createAccountForm: initial.createAccountForm,
    forgotPwdForm: initial.forgotPwdForm,
    passwordShown: initial.passwordShown,
    basicData: initial.basicData,
    basicDataStep: initial.basicDataStep,
    states: initial.states,
    educationalInstitution: initial.educationalInstitution,
    educationalLevels: initial.educationalLevels,
    createIdea: initial.createIdea,
    createIdeaStep: initial.createIdeaStep,
    economicSectors: initial.economicSectors,
    currentContest: initial.currentContest,
    ideaList: initial.ideaList,
    adminIdeaList: initial.adminIdeaList,
    ideasParticipant: initial.ideasParticipant,
    ideasRegistered: initial.ideasRegistered,
    ideasFinalist: initial.ideasFinalist,
    ideasWinner: initial.ideasWinner,
    documentActives: initial.documentActives,
    documentActivesPresentation: initial.documentActivesPresentation,
    ideasSemifinalist: initial.ideasSemifinalist,
    usersParticipant: initial.usersParticipant,
    currentYearIdeas: initial.currentYearIdeas,
    currentYearDocuments: initial.currentYearDocuments,
    ideaForEdition: initial.ideaForEdition,
    sidebarShow: initial.sidebarShow,
    stagesDropdowns: initial.stagesDropdowns,
    isFase1Open: initial.isFase1Open,
    evaluationcriteria: initial.evaluationcriteria,
    proposalType: [],
    socialType: [],
    ocupation: [],
    statesList: [],
    sectors: [],
    userForm: {
      data: {
        idUserType: '',
        name: '',
        lastName: '',
        email: '',
      },
      errors: {
        idUserType: '',
        name: '',
        lastName: '',
        email: '',
      },
    }, // Create user form data
    query: '', // For search box
    files: initial.files, //Files for uploading
    passwordUpdateForm: {
      data: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
      errors: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
    },
    passwordUpdate: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    audit: {
      show: false,
      comments: '',
      error: '',
      documents: '',
      blocked: false,
    },
    forgotPwd: { email: '' },
    currentContestStage: initial.currentContestStage,
  };

  componentDidMount() {
    // Get variables from session storage
    let userSession = this.getFromSessionStorage('userSession');
    let gestion = this.getFromSessionStorage('gestion');
    let url = window.location.pathname;
    let basicData = this.state.basicData;
    let ideaList = this.state.ideaList;
    this.validateIfFase1Ended();

    if (userSession) {
      this.getCurrentState(userSession);
      this.getCurrentContestStage(userSession);

      // Get List if needed
      if (url === '/basic-data') {
        this.getStateListFunction(userSession);
        this.educationalInstitutionFunction(userSession);
        this.educationalLevelFunction(userSession);
        this.getEconomicSectorsFunction(userSession);
        basicData.data.name = userSession.name;
        basicData.data.lastName = userSession.lastName;
        this.setState({ basicData });
      }

      if (userSession.userType === 'participant') {
        this.getIdeaListFunction(
          userSession,
          ideaList.currentPage,
          this.state.ideaList.ideasPerPage
        );
      }
      if (userSession.userType === 'admin') {
        this.getRegisteredUserFunction(userSession);

        this.getAdminIdeaListFunction(
          userSession,
          this.state.adminIdeaList.search,
          this.state.adminIdeaList.currentPage,
          this.state.adminIdeaList.ideasPerPage
        );
      }

      this.getProposalTypesFunction(userSession);
      this.getOcupationFunction(userSession);
      this.getSocialTypesFunction(userSession);
      this.getSectorsFunction(userSession);
      this.getStatesListFunction(userSession);
      this.getCurrentState(userSession);
      this.getCurrentContestStage(userSession);
    }

    toast.configure();

    // Search in session storage
    // Update variables in state
    if (userSession !== null) {
      this.setState({ userSession });
    }

    if (gestion !== null) {
      this.setState({ gestion });
    }
  }

  // Function that loads mode data in the users tables
  loadMoreUserData() {
    let userList = { ...this.state.userList };

    const data = userList.isFiltered
      ? userList.orgFilteredData
      : userList.orgtableData;

    // Get ammount of pages and slice data
    userList.pageCount = Math.ceil(data.length / userList.perPage);
    userList.data = data.slice(
      userList.offset,
      userList.offset + userList.perPage
    );

    this.setState({ userList });
  }

  validateIfFase1Ended = async () => {
    let isFase1Open = this.state.isFase1Open;
    let response = await getContestStagesBeforelogin();
    if (response?.status === 200) {
      let [stage1] = response.data.filter(
        (s) => s.status === 'Registrado' && s.type === 'PARTICIPANTE'
      );
      if (response?.status === 200) {
        let today = new Date();
        today = new Date(today.setHours(0, 0, 0, 0));
        let newEndDate = new Date(moment(stage1?.endDate).format('llll'));
        today > newEndDate ? (isFase1Open = false) : (isFase1Open = true);

        if (stage1?.isOpen) {
          isFase1Open = true;
        }
      }
      this.setState({ isFase1Open });
    } else if (response?.status === 404) {
      toast.info(`${response?.data?.errorMessage}`);
    }
  };

  // Get Ideas Dropdown information
  getProposalTypesFunction = async (userSession) => {
    let response = await getProposalTypes(userSession.sessionToken);
    let proposalType = this.state.proposalType;
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      proposalType = response.data;
      this.setState({ proposalType });
    }
    // Show DB message
  };

  // Get Ideas Dropdown information
  getIdeaListFunction = async (userSession, currentPage, size) => {
    let ideaList = this.state.ideaList;
    let response = await getIdeaList(
      userSession.sessionToken,
      userSession.userId,
      currentPage,
      size
    );
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      ideaList.ideas = response.data.content;
      ideaList.pages = response.data.totalPages;
      this.setState({ ideaList });
    }
    this.getCurrentYearIdeasFunction(userSession);
    this.getCurrentYearDocumentsFunction(userSession);
  };

  validateAdvanceSearchDates = () => {
    let adminIdeaList = this.state.adminIdeaList;
    adminIdeaList.startDate.errors = '';
    adminIdeaList.endDate.errors = '';

    // If any date is selected then validate cases
    if (adminIdeaList.startDate.data || adminIdeaList.endDate.data) {
      let from = '';
      adminIdeaList.startDate.data
        ? (from = new Date(moment(adminIdeaList.startDate.data).format('llll')))
        : null;
      let to = '';
      adminIdeaList.endDate.data
        ? (to = new Date(moment(adminIdeaList.endDate.data).format('llll')))
        : null;

      if (!from) {
        adminIdeaList.startDate.errors =
          'Indique fecha de inicio de su búsqueda';
        adminIdeaList.showAdvanceFilter = true;
        this.setState({ adminIdeaList });
        return false;
      }
      if (!to) {
        adminIdeaList.endDate.errors = 'Indique fecha final de su búsqueda';
        adminIdeaList.showAdvanceFilter = true;
        this.setState({ adminIdeaList });
        return false;
      }

      if (from && to && from > to) {
        adminIdeaList.startDate.errors =
          'La fecha de inicio no puede ser mayor a la final';
        adminIdeaList.showAdvanceFilter = true;
        this.setState({ adminIdeaList });
        return false;
      }
    }
    this.setState({ adminIdeaList });
    return true;
  };
  // Get Ideas Dropdown information
  getAdminIdeaListFunction = async (userSession, search, currentPage, size) => {
    let canSearch = true;

    canSearch = this.validateAdvanceSearchDates();

    if (canSearch) {
      let adminIdeaList = this.state.adminIdeaList;
      adminIdeaList.loading = true;
      this.setState({ adminIdeaList });
      let response = await getAdminIdeaList(
        userSession.sessionToken,
        search,
        currentPage,
        size,
        adminIdeaList
      );
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        adminIdeaList.loading = false;
        this.setState({ adminIdeaList });
        return null;
      }
      if (response.status == 200) {
        adminIdeaList.ideas = response.data.content;
        adminIdeaList.pages = response.data.totalPages;
        adminIdeaList.totalElements = response.data.totalElements;
        adminIdeaList.loading = false;
        this.setState({ adminIdeaList });

        //Add info for Csv
        let responseCsv = await getAdminIdeaList(
          userSession.sessionToken,
          search,
          0,
          adminIdeaList.totalElements,
          adminIdeaList
        );
        // No response from API
        if (!responseCsv) {
          this.notify('Connection Error', 'error');
          return null;
        }
        if (responseCsv.status == 200) {
          adminIdeaList.ideasToPrint = responseCsv.data.content;
          this.setState({ adminIdeaList });
          return true;
        }

        return true;
      } else {
        adminIdeaList.loading = false;
        this.setState({ adminIdeaList });
      }
    }
  };

  // Get current year ideas for the dashboard
  getCurrentYearIdeasFunction = async (userSession) => {
    let currentYearIdeas = this.state.currentYearIdeas;

    let response = await getCurrentYearIdeas(
      userSession.sessionToken,
      userSession.userId
    );

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      currentYearIdeas = response.data;
      this.setState({ currentYearIdeas });
      return true;
    }
  };

  // Get current year documents for the dashboard
  getCurrentYearDocumentsFunction = async (userSession) => {
    let currentYearDocuments = this.state.currentYearDocuments;
    currentYearDocuments = [];

    let response = await getCurrentYearIdeas(
      userSession.sessionToken,
      userSession.userId
    );

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      for (let countIdea = 0; countIdea < response.data.length; countIdea++) {
        var id = response.data[countIdea].id;
        let documentos = await getDocumentsByIdeaId(
          userSession.sessionToken,
          id
        );
        if (!documentos) {
          this.notify('Connection Error', 'error');
          return null;
        }
        if (documentos.status == 200) {
          for (
            let countDoc = 0;
            countDoc < documentos.data.length;
            countDoc++
          ) {
            var documento = documentos.data[countDoc];
            currentYearDocuments.push(documento);
          }
          this.setState({ currentYearDocuments });
        }
      }
    }
  };

  //change idea status
  changeIdeaStatus = async (idea, status) => {
    let userSession = { ...this.state.userSession };
    let ideaForEdition = this.state.ideaForEdition;
    let response = await changeStatusIdea(
      idea,
      status,
      userSession.sessionToken
    );
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      ideaForEdition.idea = response.data;
      this.setState({ ideaForEdition });
      this.getAdminIdeaListFunction(
        userSession,
        this.state.adminIdeaList.search,
        this.state.adminIdeaList.currentPage,
        this.state.adminIdeaList.ideasPerPage
      );
      this.notify(
        'El estatus de idea fue actualizado exitosamente.',
        'success'
      );
      return true;
    }
  };

  // Function that handles idea list page change
  handleIdeaListPageChange = async (event, value) => {
    let ideaList = this.state.ideaList;
    let userSession = this.state.userSession;
    ideaList.currentPage = value - 1; // -1 Tp remove page counter offset
    this.setState({ ideaList });
    this.getIdeaListFunction(
      userSession,
      ideaList.currentPage,
      this.state.ideaList.ideasPerPage
    );
  };

  // Function that handles idea list page change
  handleAdminIdeaListPageChange = async (event, value) => {
    let adminIdeaList = this.state.adminIdeaList;
    let userSession = this.state.userSession;
    adminIdeaList.currentPage = value - 1; // -1 Tp remove page counter offset
    this.setState({ adminIdeaList });
    this.getAdminIdeaListFunction(
      userSession,
      adminIdeaList.search,
      adminIdeaList.currentPage,
      adminIdeaList.ideasPerPage
    );
  };

  handleAdminRowsPerPage = ({ currentTarget: input }) => {
    let adminIdeaList = this.state.adminIdeaList;
    let userSession = this.state.userSession;
    adminIdeaList.ideasPerPage = input.value;
    adminIdeaList.currentPage = 0;
    this.setState({ adminIdeaList });
    this.getAdminIdeaListFunction(
      userSession,
      adminIdeaList.search,
      0,
      adminIdeaList.ideasPerPage
    );
  };

  getSocialTypesFunction = async (userSession) => {
    let response = await getSocialTypes(userSession.sessionToken);
    let socialType = this.state.socialType;
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      socialType = response.data;
      this.setState({ socialType });
    }
  };

  getUserDataFunction = async (userSession) => {
    let response = await getUserData(
      userSession.sessionToken,
      userSession.userId
    );
    let userData = this.state.userData;
    if (response.status == 200) {
      userData = response.data;
      this.setState({ userData });
    }
    return userData;
  };

  getSocialTypesFunction = async (userSession) => {
    let response = await getSocialTypes(userSession.sessionToken);
    let socialType = this.state.socialType;

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }

    if (response.status == 200) {
      socialType = response.data;
      this.setState({ socialType });
    }
  };

  getOcupationFunction = async (userSession) => {
    let response = await getOcupations(userSession.sessionToken);
    let ocupation = this.state.ocupation;

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }

    if (response.status == 200) {
      ocupation = response.data;
      this.setState({ ocupation });
    }
  };

  getSectorsFunction = async (userSession) => {
    let response = await getEconomicSectors(userSession.sessionToken);
    let sectors = this.state.sectors;
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      sectors = response.data;
      this.setState({ sectors });
    }
  };

  getStatesListFunction = async (userSession) => {
    let response = await getIdeaStates(userSession.sessionToken);
    let statesList = this.state.statesList;
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      statesList = response.data;
      this.setState({ statesList });
    }
  };

  getRegisteredUserFunction = async (userSession) => {
    let response = await getRegisteredUser(userSession.sessionToken);
    let usersParticipant = this.state.usersParticipant;

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }

    if (response.status == 200) {
      usersParticipant = response.data;
      this.setState({ usersParticipant });
    }
  };

  // Function that Handles pagination events on the users table
  handleUserPageClick = (e) => {
    let userList = { ...this.state.userList };
    const selectedPage = e.selected;

    userList.offset = selectedPage * userList.perPage;
    userList.currentPage = selectedPage;

    this.setState({ userList }, () => {
      this.loadMoreUserData();
    });
  };

  // Function that ends the contest
  closeContestFunction = async () => {
    let userSession = this.state.userSession;
    let response = await closeContest(userSession.sessionToken);
    // No response from API
    if (!response) {
      return null;
    }
    // Set states
    if (response.status == 200) {
      this.notify('Concurso finalizado satisfactoriamente', 'success');
      this.getCurrentContestStage(userSession);
      window.location.reload();
    }
  };

  // Function that handles event changes on the user input
  inputChange = async ({ currentTarget: input }) => {
    let user = { ...this.state.user };
    let errors = { email: '', password: '' };
    // Trim input to avoid erros on the login
    user[input.name] = input.value.trim();
    // Convert email to lowercase
    // if (input.name == 'email') {
    //   user[input.name] = user[input.name].toLowerCase(user[input.name]);
    // }
    this.setState({ user, errors });
  };

  // Function that validates the user login inputs
  validateLogin = () => {
    let user = { ...this.state.user };
    let errors = { ...this.state.errors };
    let canPost = true;
    // Validate email format
    // if (!this.validateEmailFormat(user.email)) {
    //   errors.email = 'Ingrese un correo electrónico válido';
    //   canPost = false;
    // }

    if (user.email == '') {
      errors.email = 'Correo o usuario requerido';
      canPost = false;
    }
    if (user.password == '') {
      errors.password = 'Contraseña requerida';
      canPost = false;
    }
    this.setState({ errors });
    return canPost;
  };

  // Function that validates the user and password inputs
  validate = () => {
    const options = { abortEarly: false };
    const user = {
      email: this.state.user.email,
      password: this.state.user.password,
    };

    //Validation with Joi
    var schema = {
      email: Joi.string().required().label('User'),
      password: Joi.string().required().label('Password'),
    };

    const { error } = Joi.validate(user, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  // Function that handles the login and expects a Bearer token in response
  loginService = async (props) => {
    let user = { ...this.state.user };
    let errors = { ...this.state.errors };
    let canPost = this.validateLogin();
    // Show load spinner
    let blockedLogin = true;
    this.setState({ blockedLogin });

    if (canPost == false) {
      // Unlock Button
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }

    let userSession = await signIn(user);

    if (!userSession) {
      this.notify('Connection Error', 'error');
      // Unlock Button
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }
    // If the login was successfull saves the data in session storage
    if (userSession.status == 200) {
      userSession = userSession.data;
      errors = { email: '', password: '' };
      this.setState({ userSession, errors });
      this.saveOnSessionStorage('userSession', userSession);
      let userInfo = await this.getUserDataFunction(userSession);
      blockedLogin = false;
      this.setState({ blockedLogin });
      if (userInfo.userType === 'participant') {
        let isContestOpen = await this.getContestListFunction();
        if (isContestOpen) {
          this.protectLoginRoutes(userSession, userInfo, props);
        } else {
          this.notify(
            'En este momento no hay concursos activos, esperar al proceso de postulación',
            'info'
          );
        }
      } else {
        this.protectLoginRoutes(userSession, userInfo, props);
      }
    }
    if (userSession.status == 401) {
      errors.password = 'Usuario o contraseña inválida';
      this.setState({ errors });
      blockedLogin = false;
      this.setState({ blockedLogin });
    }
    if (userSession.status == 400) {
      blockedLogin = false;
      this.setState({ blockedLogin });
    } else {
      blockedLogin = false;
      this.setState({ blockedLogin });
    }
  };

  // Function that determines if there is a contest open or not
  getContestListFunction = async () => {
    return await getContests().then((response) => {
      if (response.status == 200) {
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        if (selectedContest) {
          return true;
        } else return false;
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  // Get data an redirect to respective route depending on user type
  protectLoginRoutes = async (userSession, userInfo, props) => {
    let basicData = this.state.basicData;
    // Login process for participants
    if (userSession.userType == 'participant') {
      if (this.validateUserInformation(userInfo)) {
        props.history.push(`/home/participant`);
      } else {
        basicData.data.name = userSession.name;
        basicData.data.lastName = userSession.lastName;
        this.setState({ basicData });
        props.history.push(`/basic-data`);
      }
      // Dropdowns options
      this.getStateListFunction(userSession);
      this.getEconomicSectorsFunction(userSession);
      this.getProposalTypesFunction(userSession);
      this.getOcupationFunction(userSession);
      this.getSocialTypesFunction(userSession);
      this.getSectorsFunction(userSession);
      this.getStatesListFunction(userSession);
      this.educationalInstitutionFunction(userSession);
      this.educationalLevelFunction(userSession);
      this.getCurrentState(userSession);

      // Idea cards and lists
      this.getIdeaListFunction(
        userSession,
        this.state.ideaList.currentPage,
        this.state.ideaList.ideasPerPage
      );
    }
    if (['judge', 'judge_leader'].some((l) => l === userSession.userType)) {
      props.history.push(`/home/judge/evaluations`);
    }
    if (['judge_special'].some((l) => l === userSession.userType)) {
      props.history.push(`/home/judge/special-mention`);
    } else if (userSession.userType == 'admin') {
      this.getRegisteredUserFunction(userSession);
      this.getCurrentState(userSession);
      this.getCurrentContestStage(userSession);

      this.getAdminIdeaListFunction(
        userSession,
        this.state.adminIdeaList.search,
        this.state.adminIdeaList.currentPage,
        this.state.adminIdeaList.ideasPerPage
      );
      props.history.push(`/home/admin`);
    }
  };

  validateUserInformation = (userObject) => {
    let fields = Object.values(userObject);
    let count = 0;
    fields.forEach((y) => {
      if (!this.isEmpty(y)) {
        count += 1;
      }
    });
    return count > 10;
  };

  isEmpty = (value) => {
    return !value;
  };

  // Function that gets the states list
  getStateListFunction = async (userSession) => {
    let states = this.state.states;
    let response = await getStateList(userSession.sessionToken);

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    // Set states
    if (response.status == 200) {
      states = response.data;
      this.setState({ states });
    }
  };

  // Function that validates if in current state
  validateIfCurrentStage = (startDate, endDate, stage) => {
    let today = new Date();
    // Change dates formants
    let newStartDate = new Date(moment(startDate).format('llll'));
    let newEndDate = new Date(moment(endDate).format('llll'));
    // Validate if stage is in current time period and the stage is open manualy
    if (stage?.isOpen) {
      return true;
    } else {
      return today < newEndDate && today >= newStartDate && stage.isOpen;
    }
  };

  // Function that validates if in current state or passed
  validateIfStagePased = (startDate) => {
    let today = new Date();
    // Change dates formants
    let newStartDate = new Date(moment(startDate).format('llll'));
    // Validate if stage is in current time period
    return today >= newStartDate;
  };

  // Function that gets the stages of the current contest
  getCurrentContestStage = async (userSession) => {
    let currentContestStage = this.state.currentContestStage;
    let response = await getStagesByCurrentContest(userSession.sessionToken);

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    // Set states
    if (response.status == 200) {
      currentContestStage = response.data;

      currentContestStage.forEach((item) => {
        item.hasChanged = false;
        item.startDateError = '';
        item.endDateError = '';
        item.isOpenError = '';
      });

      this.setState({ currentContestStage });
    }
  };

  // Function that changes the date format for posting and validation
  changeDateFormat = (date) => {
    let dateTimezone = moment(date).format('llll');
    let newDateFormat = new Date(dateTimezone);
    return newDateFormat;
  };

  // Validate selected stage end date with next date start date
  validateEndDateWithNextStartDate = (index) => {
    let currentContestStage = this.state.currentContestStage;
    let date = this.changeDateFormat(currentContestStage[index].endDate);
    let nextDate = this.changeDateFormat(
      currentContestStage[index + 1]['startDate']
    );

    if (nextDate <= date) {
      currentContestStage[index][
        'endDateError'
      ] = `La fecha límite debe ser menor a la fecha de lanzamiento de la ${currentContestStage[
        index + 1
      ].stage.toLowerCase()} `;
      this.setState({ currentContestStage });
      return false;
    } else return true;
  };

  // Validate selected stage start date with previous stage end date
  validateStartDateWithPrevEndDate = (index) => {
    let currentContestStage = this.state.currentContestStage;
    let date = this.changeDateFormat(currentContestStage[index].startDate);
    let prevDate = this.changeDateFormat(
      currentContestStage[index - 1]['endDate']
    );

    if (prevDate >= date) {
      currentContestStage[index][
        'startDateError'
      ] = `La fecha de lanzamiendo debe ser mayor a la fecha límite de la ${currentContestStage[
        index - 1
      ].stage.toLowerCase()} `;
      this.setState({ currentContestStage });
      return false;
    } else return true;
  };

  constValidateIfCanOpen = (index) => {
    let currentContestStage = this.state.currentContestStage;
    // Counter of how many stages are open
    let isAnyOpen = 0;
    // Remove Registrado and Ganador from the array
    let filteredStages = currentContestStage.filter(
      (c) => c.id != 2 && c.id != 6
    );

    for (let i = 0; i < filteredStages.length; i++) {
      if (filteredStages[i].isOpen === true) {
        isAnyOpen++;
      }
    }

    if (isAnyOpen > 1) {
      currentContestStage[index].isOpenError =
        'No puede haber 2 rondas abiertas al mismo tiempo';
    }

    this.setState({ currentContestStage });
    return isAnyOpen < 2;
  };

  validateStageDates = (data) => {
    let currentContestStage = this.state.currentContestStage;
    let index = currentContestStage.findIndex((c) => c.id === data.id);
    let canPost = true;
    // Validates if a stage is open
    !this.constValidateIfCanOpen(index) ? (canPost = false) : null;

    // Validate stages 1 & 2 end dates with their next stage
    if (data.id === 3 || data.id === 4) {
      !this.validateEndDateWithNextStartDate(index) ? (canPost = false) : null;
    }

    // Validate stages 2 & 3 start dates with their previous stage
    if (data.id === 4 || data.id === 5) {
      !this.validateStartDateWithPrevEndDate(index) ? (canPost = false) : null;
    }

    //  Validate start and end date of the selected stage
    if (data.startDate >= data.endDate) {
      currentContestStage[index].startDateError =
        'La fecha de lanzamiento debe ser menor que la fecha límite';
      this.setState({ currentContestStage });
      canPost = false;
    }
    return canPost;
  };
  // Function that updates the selected stage
  updateStageFunction = async (stage, update) => {
    let userSession = this.state.userSession;
    let currentContestStage = this.state.currentContestStage;
    // Remove errors messages from date pickers before validating
    let i = currentContestStage.findIndex((c) => c.id === stage.id);
    currentContestStage[i].startDateError = '';
    currentContestStage[i].endDateError = '';
    this.setState({ currentContestStage });

    if (!update) {
      this.getCurrentContestStage(userSession);
    } else {
      let data = {
        endDate: this.changeDateFormat(stage.endDate),
        id: stage.id,
        isAutoEnd: stage.isAutoEnd,
        isAutoStart: stage.isAutoStart,
        isOpen: stage.isOpen,
        stage: stage.stage,
        startDate: this.changeDateFormat(stage.startDate),
        status: stage.status,
      };

      let canPost = this.validateStageDates(data);

      if (canPost) {
        let response = await updateStage(
          data,
          userSession.sessionToken,
          stage.id
        );

        // No response from API
        if (!response) {
          this.notify('Connection Error', 'error');
          return null;
        }
        // Set states
        if (response.status == 200) {
          let currentContestStage = this.state.currentContestStage;
          // Find the changed stage and update it
          let index = currentContestStage.findIndex(
            (c) => c.id === response.data.id
          );
          response.data.hasChanged = false;
          currentContestStage[index] = response.data;
          this.setState({ currentContestStage });
          this.notify('La fase ha sido actualizada', 'success');
        }
      } else {
        return null;
      }
    }
  };

  // Function that handles the stage change stage change
  handleStageChange = (stage, { currentTarget: input }) => {
    let currentContestStage = this.state.currentContestStage;
    let index = currentContestStage.findIndex((c) => c.id === stage.id);

    if (input.name === 'startDate' || input.name === 'endDate') {
      currentContestStage[index][input.name] = input.value;
    } else if (input.id === 'isOpen') {
      currentContestStage[index][input.id] =
        !currentContestStage[index][input.id];
    } else {
      currentContestStage[index][input.name] =
        !currentContestStage[index][input.name];
    }
    currentContestStage[index].hasChanged = true;

    this.setState({ currentContestStage });
  };

  // // Function that gets the economic sectores list
  getCurrentState = async (userSession) => {
    let currentContest = this.state.currentContest;
    let stagesDropdowns = this.state.stagesDropdowns;
    let response = await getCurrentContest(userSession.sessionToken);

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    // Set states
    if (response.status == 200) {
      currentContest = response.data;
      this.setState({ currentContest });

      // Preset stages selections and validate if they are in teh current state
      for (let i = 1; i <= 3; i++) {
        stagesDropdowns[`stage${i}`].selected = this.validateIfCurrentStage(
          currentContest.stages[i]?.startDate,
          currentContest.stages[i]?.endDate,
          currentContest.stages[i]
        );
        stagesDropdowns[`stage${i}`].stagedPassed = this.validateIfStagePased(
          currentContest.stages[i]?.startDate
        );
        stagesDropdowns[`stage${i}`].current =
          stagesDropdowns[`stage${i}`].selected;
      }
      this.setState({ stagesDropdowns });
    }
  };

  // // Function that gets the economic sectores list
  getEconomicSectorsFunction = async (userSession) => {
    let economicSectors = this.state.economicSectors;
    let response = await getEconomicSectorsList(userSession.sessionToken);

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    // Set states
    if (response.status == 200) {
      economicSectors = response.data;
      this.setState({ economicSectors });
    }
  };

  // Function that gets the educational institutions list
  educationalInstitutionFunction = async (userSession) => {
    let educationalInstitution = this.state.educationalInstitution;
    let response = await getEducationalInstitution(userSession.sessionToken);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    // Set states
    if (response.status == 200) {
      educationalInstitution = response.data;
      this.setState({ educationalInstitution });
    }
  };

  // Function that gets the educational institutions list
  educationalLevelFunction = async (userSession) => {
    let educationalLevels = this.state.educationalLevels;
    let response = await getEducationalLevel(userSession.sessionToken);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    // Set states
    if (response.status == 200) {
      educationalLevels = response.data;
      this.setState({ educationalLevels });
    }
  };

  //Function that handles the show/hide of the password
  setPasswordShownValue = () => {
    let passwordShown = !this.state.passwordShown;
    this.setState({ passwordShown });
  };

  // Function that validates the update password inputs
  passwordFormValidate = () => {
    const options = { abortEarly: false };
    const update = {
      oldPassword: this.state.passwordUpdate.oldPassword,
      newPassword: this.state.passwordUpdate.newPassword,
      confirmNewPassword: this.state.passwordUpdate.confirmNewPassword,
    };

    //Validation with Joi
    var schema = {
      oldPassword: Joi.string().required().label('oldPassword'),
      newPassword: Joi.string().required().label('newPassword'),
      confirmNewPassword: Joi.string().required().label('confirmNewPassword'),
    };

    const { error } = Joi.validate(update, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  //Function that posts a password update to the API
  updatePasswordFunction = async (history) => {
    let userSession = { ...this.state.userSession };
    let passwordUpdateForm = { ...this.state.passwordUpdateForm };

    // Validate the user form inputs
    let canPost = this.validateUpdatePasswordForm();

    if (canPost == true) {
      let body = {
        oldPassword: passwordUpdateForm.data.oldPassword,
        newPassword: passwordUpdateForm.data.newPassword,
      };

      let response = await updatePassword(
        body,
        userSession.userId,
        userSession.sessionToken
      );

      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        return null;
      }

      // password updated
      if (response.status == 200) {
        this.notify('Contraseña actualizada satisfactoriamente', 'success');
        // Delete data from form inputs
        passwordUpdateForm.data = {
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        };
        passwordUpdateForm.errors = {
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        };

        history.push(`/home/participant`);

        this.setState({ passwordUpdateForm });
        // Borar datos de los inputs
      }
    }
  };

  // Function that validates the update password inputs
  validateUpdatePasswordForm = () => {
    let passwordUpdateForm = { ...this.state.passwordUpdateForm };
    let canPost = true;
    //Delete error messages
    passwordUpdateForm.errors = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };

    // Evaluate required inputs
    if (passwordUpdateForm.data.oldPassword == '') {
      passwordUpdateForm.errors.oldPassword = 'Contraseña actual requerida';
      canPost = false;
    }
    if (passwordUpdateForm.data.newPassword == '') {
      passwordUpdateForm.errors.newPassword = 'Nueva contraseña requerida';
      canPost = false;
    }
    if (passwordUpdateForm.data.confirmNewPassword == '') {
      passwordUpdateForm.errors.confirmNewPassword =
        'Confirmación de nueva contraseña requerida';
      canPost = false;
    }

    // Evaluate inputs length
    if (passwordUpdateForm.data.newPassword.length < 4) {
      passwordUpdateForm.errors.newPassword =
        'La contraseña debe tener al menos 4 caracteres';
      canPost = false;
    }
    if (passwordUpdateForm.data.confirmNewPassword.length < 4) {
      passwordUpdateForm.errors.confirmNewPassword =
        'La contraseña debe tener al menos 4 caracteres';
      canPost = false;
    }

    // Evaluate oldPassword and newPassword are different
    if (
      passwordUpdateForm.data.oldPassword == passwordUpdateForm.data.newPassword
    ) {
      passwordUpdateForm.errors.newPassword =
        'La contraseña nueva no puede ser igual a la anterior';
      canPost = false;
    }

    // Evaluate confirmNewPassword and newPassword are equals
    if (
      passwordUpdateForm.data.newPassword !=
      passwordUpdateForm.data.confirmNewPassword
    ) {
      passwordUpdateForm.errors.confirmNewPassword =
        'Las contraseñas no coinciden';
      canPost = false;
    }

    this.setState({ passwordUpdateForm });
    return canPost;
  };

  // Function that handles event changes on the create user inputs
  userForminputChange = async ({ currentTarget: input }) => {
    let userForm = { ...this.state.userForm };
    // Trim input to avoid erros on the login
    userForm.data[input.name] = input.value.trimStart();
    // Convert email to lowercase
    if (input.name == 'email') {
      userForm.data[input.name] = userForm.data[input.name].toLowerCase(
        userForm.data[input.name]
      );
    }
    this.setState({ userForm });
  };

  // Function that handles event changes on the update password inputs
  createAccountinputChange = async ({ currentTarget: input }) => {
    let createAccountForm = { ...this.state.createAccountForm };

    createAccountForm.errors[input.name] = '';

    createAccountForm.data[input.name] = input.value.trimStart();

    if (input.name == 'email') {
      createAccountForm.data[input.name] = createAccountForm.data[
        input.name
      ].toLowerCase(createAccountForm.data[input.name]);
    }
    // Trim input to avoid erros on the login
    this.setState({ createAccountForm });
  };

  // Function that handles event changes on the create user inputs
  AdvaceFilterinputChange = ({ currentTarget: input }) => {
    let adminIdeaList = { ...this.state.adminIdeaList };
    // Trim input to avoid erros on the login
    adminIdeaList[input.name] = input.value.trimStart();
    this.setState({ adminIdeaList });
  };

  cancelAdvanceSearch = () => {
    this.handleAdvanceFilter();
  };

  // Function that opens and closes the advance filter
  handleAdvanceFilter = () => {
    let adminIdeaList = { ...this.state.adminIdeaList };
    adminIdeaList.showAdvanceFilter = !adminIdeaList.showAdvanceFilter;
    this.setState({ adminIdeaList });
  };

  // Function that handles event changes on the advance filter filters
  advanceFilerInputChange = async ({ currentTarget: input }) => {
    let adminIdeaList = this.state.adminIdeaList;

    adminIdeaList.advanceStatusSearch.forEach((item) => {
      if (item.name === input.name) {
        item.selected = !item.selected;
      }
    });

    adminIdeaList.advanceTypeSearch.forEach((item) => {
      if (item.name === input.name) {
        item.selected = !item.selected;
      }
    });

    adminIdeaList.advanceDocumentSearch.forEach((item) => {
      if (item.name === input.name) {
        item.selected = !item.selected;
      }
    });

    if (input.name === 'startDate') {
      adminIdeaList.startDate.data = input.value;
    }

    if (input.name === 'endDate') {
      adminIdeaList.endDate.data = input.value;
    }

    adminIdeaList.currentPage=0
    adminIdeaList.ideasPerPage=10
    this.setState({ adminIdeaList });
  };

  // Function that makes the advance search
  makeAdvanceSearch = () => {
    let userSession = this.state.userSession;
    let adminIdeaList = this.state.adminIdeaList;
    this.handleAdvanceFilter();
    setTimeout(() => {
      this.getAdminIdeaListFunction(
        userSession,
        adminIdeaList.search,
        adminIdeaList.currentPage,
        adminIdeaList.ideasPerPage
      );
    }, 500);
  };

  // Function that clears the advance filter and refresh the table
  clearAdvanceFilter = async (refresh) => {
    let adminIdeaList = { ...this.state.adminIdeaList };
    let userSession = { ...this.state.userSession };

    // Trim input to avoid erros on the login
    adminIdeaList.search = '';
    adminIdeaList.currentPage = 0;
    adminIdeaList.showAdvanceFilter = false;
    adminIdeaList.startDate.data = '';
    adminIdeaList.endDate.data = '';

    adminIdeaList.advanceStatusSearch.forEach((item) => {
      item.selected = false;
    });

    adminIdeaList.advanceTypeSearch.forEach((item) => {
      item.selected = false;
    });

    adminIdeaList.advanceDocumentSearch.forEach((item) => {
      item.selected = false;
    });

    this.setState({ adminIdeaList });

    if (refresh) {
      setTimeout(() => {
        this.getAdminIdeaListFunction(
          userSession,
          '',
          adminIdeaList.currentPage,
          adminIdeaList.ideasPerPage
        );
      }, 500);
    }
  };

  // Function that handles the read confirmation of the terms and policies
  confirmTemrsAndCondition = () => {
    let createAccountForm = { ...this.state.createAccountForm };

    createAccountForm.data.termsAndConditions =
      !createAccountForm.data.termsAndConditions;
    // Trim input to avoid erros on the login
    this.setState({ createAccountForm });
  };

  // Function that validates the userform inputs
  validateAccountinputForm = () => {
    let createAccountForm = { ...this.state.createAccountForm };
    let canPost = true;
    //Delete error messages

    createAccountForm.errors = { ...initial.createAccountForm.errors };

    // Evaluate required inputs
    if (createAccountForm.data.name == '') {
      createAccountForm.errors.name = 'Nombre requerido';
      canPost = false;
    }

    if (createAccountForm.data.name.length > 80) {
      createAccountForm.errors.name =
        'El nombre debe tener menos de 80 caracteres';
      canPost = false;
    }

    if (createAccountForm.data.lastName == '') {
      createAccountForm.errors.lastName = 'Apellido requerido';
      canPost = false;
    }

    if (createAccountForm.data.lastName.length > 80) {
      createAccountForm.errors.lastName =
        'El apellido debe tener menos de 80 caracteres';
      canPost = false;
    }

    if (!this.validateEmailFormat(createAccountForm.data.email)) {
      createAccountForm.errors.email = 'Ingrese un email válido';
      canPost = false;
    }

    if (!this.validateCharacters(createAccountForm.data.name)) {
      createAccountForm.errors.name = 'Ingrese un nombre válido';
      canPost = false;
    }

    if (!this.validateCharacters(createAccountForm.data.lastName)) {
      createAccountForm.errors.lastName = 'Ingrese un nombre válido';
      canPost = false;
    }

    if (createAccountForm.data.email == '') {
      createAccountForm.errors.email = 'Correo electrónico requerido';
      canPost = false;
    }

    if (
      createAccountForm.data.password.length < 4 ||
      createAccountForm.data.confirmPassword.length < 4
    ) {
      createAccountForm.errors.password =
        'La contraseña debe tener más de 4 caracteres';
      createAccountForm.errors.confirmPassword =
        'La contraseña debe tener más de 4 caracteres';
      canPost = false;
    }

    if (
      createAccountForm.data.password.length > 80 ||
      createAccountForm.data.confirmPassword.length > 80
    ) {
      createAccountForm.errors.password =
        'La contraseña debe tener menos de 80 caracteres';
      createAccountForm.errors.confirmPassword =
        'La contraseña debe tener más de 80 caracteres';
      canPost = false;
    }

    if (createAccountForm.data.password == '') {
      createAccountForm.errors.password = 'Ingrese una contraseña';
      canPost = false;
    }

    if (createAccountForm.data.confirmPassword == '') {
      createAccountForm.errors.confirmPassword = 'Confirme su contraseña';
      canPost = false;
    }
    if (createAccountForm.data.captcha === false) {
      createAccountForm.errors.captcha = 'Confirme el captcha';
      canPost = false;
    }

    if (
      createAccountForm.data.password !== createAccountForm.data.confirmPassword
    ) {
      createAccountForm.errors.password =
        'Contraseña y confirmación deben coincidir';
      createAccountForm.errors.confirmPassword =
        'Contraseña y confirmación deben coincidir';
      canPost = false;
    }

    this.setState({ createAccountForm });

    return canPost;
  };

  createAccountFunction = async (history) => {
    let createAccountForm = { ...this.state.createAccountForm };
    let data = {
      name: createAccountForm.data.name,
      lastName: createAccountForm.data.lastName,
      email: createAccountForm.data.email,
      encryptedPassword: createAccountForm.data.password,
      userType: 'participant',
    };

    // Validate the create account inputs
    let canPost = this.validateAccountinputForm();

    if (canPost === true) {
      let blockedLogin = true;
      this.setState({ blockedLogin });

      let response = await createUser(data);

      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        let blockedLogin = false;
        this.setState({ blockedLogin });
        return null;
      }

      // User created
      if (response.status == 200) {
        let blockedLogin = false;
        this.setState({ blockedLogin });

        createAccountForm = {
          data: {
            name: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            termsAndConditions: false,
            captcha: false,
          },
          errors: {
            name: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            termsAndConditions: '',
            captcha: '',
          },
        };
        this.state.user = { email: '', password: '' };

        history.push(`/login`);
        this.notify('Su cuenta ha sido creado de forma exitosa.', 'success');
        this.setState({ createAccountForm });
      }

      // Show DB message
      else if (response.status == 400) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      } else if (response.status == 401) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      }
      //  Show expired session messagen and redirect to login
      else if (response.status == 403) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      } else {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      }
    }
  };

  // Function that check if the captcha is expired
  recaptchaExpired = () => {
    this.state.createAccountForm.data.captcha = false;
  };

  // Function that change the state of the captcha in the create userform
  recaptchaLoaded = () => {};

  // Function that change the state of the captcha in the create userform
  verifyUser = (response) => {
    let createAccountForm = this.state.createAccountForm;
    createAccountForm.errors.captcha = '';
    if (response) {
      createAccountForm.data.captcha = true;
      this.setState(createAccountForm);
    }
  };

  // Function that handles event changes on the basic data inputs
  basicDataInputChange = async ({ currentTarget: input }) => {
    let basicData = { ...this.state.basicData };

    // Delete errors on typing
    basicData.errors[input.name] = '';

    // Trim input to avoid erros on the login
    basicData.data[input.name] = input.value.trimStart();

    if (input.name === 'employmentState') {
      basicData.data.company = '';
      basicData.data.charge = '';
      basicData.data.startUpDesctiption = '';
    }
    // Limit ID to 8 characters
    if (input.name === 'id') {
      if (parseInt(basicData.data[input.name]) === 0) {
        basicData.data[input.name] = '';
      }
      basicData.data[input.name] = basicData.data[input.name].substring(0, 8);
    }

    // Limit phone numbers to 13 characters
    if (
      input.name === 'phoneNumber' ||
      input.name === 'mobileNumber' ||
      input.name === 'otherNumber'
    ) {
      if (parseInt(basicData.data[input.name]) === 0) {
        basicData.data[input.name] = '';
      }
      basicData.data[input.name] = basicData.data[input.name].substring(0, 13);
    }

    this.setState({ basicData });
  };

  // Function that handles the brth date selector change
  handleActDateChange = (e) => {
    let basicData = { ...this.state.basicData };
    let getTimezone = moment(e.target.value).format('llll');
    let changeFormat = new Date(getTimezone);
    basicData.data.birthDate = changeFormat;
    // Delete errors on input change
    basicData.errors.birthDate = '';

    this.setState({ basicData });
  };

  // Function that handles the increase of the basic data step wizard
  increaseBasicDataStep = () => {
    let basicDataStep = { ...this.state.basicDataStep };
    basicDataStep.counter < basicDataStep.steps.length &&
    this.validateBasicData() === true
      ? basicDataStep.counter++
      : basicDataStep.counter;
    this.setState({ basicDataStep });
  };

  validateIfInStage2 = () => {
    // Function that validates if in current state
    const validateIfInStage = (startDate, endDate, isOpen) => {
      let today = new Date();
      // Change dates formants
      let newStartDate = new Date(moment(startDate).format('llll'));
      let newEndDate = new Date(moment(endDate).format('llll'));
      // Validate if stage is in current time period and the stage is open manualy
      if (isOpen) {
        return true;
      } else {
        return today < newEndDate && today >= newStartDate && isOpen;
      }
    };
    let currentContest = this.state.currentContest;
    let inStage1 = validateIfInStage(
      currentContest.stages[1].startDate,
      currentContest.stages[1].endDate,
      currentContest.stages[1].isOpen
    );
    return inStage1;
  };

  // Function that handles the udpate data function
  updateBasicDataFunction = async (history) => {
    let userSession = this.state.userSession;
    let basicData = this.state.basicData;
    let basicDataStep = this.state.basicDataStep;

    // Function that validates of the number exist and parses its itn value
    const parseIntFunction = (id) => {
      if (id) {
        return parseInt(id);
      } else return null;
    };

    // Function that validates if the user is an entrepreneur or not
    const validateEnterpreneurship = (e) => {
      return e === '2' || e === '3';
    };

    let data = {
      id: userSession.userId,
      userType: 1,
      token: userSession.sessionToken,
      name: basicData.data.name,
      lastName: basicData.data.lastName,
      nationality: parseIntFunction(basicData.data.idType),
      idNumber: basicData.data.idNumber,
      birthDate: moment(basicData.data.birthDate).format('YYYY-MM-DD'),
      gender: parseIntFunction(basicData.data.gender),
      phoneHome: basicData.data.phoneNumber,
      phoneMobile: basicData.data.mobileNumber,
      phoneOther: basicData.data.otherNumber,
      state: parseIntFunction(basicData.data.state),
      address: basicData.data.address,
      educationalInstitution: parseIntFunction(
        basicData.data.academicInstitution
      ),
      educationalLevel: parseIntFunction(basicData.data.educationLevel),
      economicSector: parseIntFunction(basicData.data.economicSector),
      companyName: basicData.data.company,
      companyPosition: basicData.data.charge,
      enterpreneurship: validateEnterpreneurship(
        basicData.data.employmentState
      ),
      entrepreneurshipDsc: basicData.data.startUpDesctiption,
    };

    let blockedLogin = true;
    this.setState({ blockedLogin });

    let response = await updateUser(
      data,
      userSession.sessionToken,
      userSession.userId
    );

    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      let blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }
    if (response.status == 200) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      let aver = this.validateIfInStage2();
      aver ? history.push(`/create-idea`) : history.push(`/home/participant`);
      this.notify('Datos actualizados satisfactoriamente', 'success');
      // If succeed reset values and step counter
      basicData.data = {
        name: '',
        lastName: '',
        idType: '',
        id: '',
        birthDate: '',
        gender: '',
        phoneNumber: '',
        mobileNumber: '',
        otherNumber: '',
        state: '',
        address: '',

        academicInstitution: '',
        educationLevel: '',

        employmentState: '',

        economicSector: '',
        company: '',
        charge: '',
        startUpDesctiption: '',
      };
      basicDataStep.counter = 0;

      this.setState({ basicDataStep, basicData });
    }
    // Show DB message
    else if (response.status == 400) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
    } else if (response.status == 401) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
    }

    //  Show expired session messagen and redirect to login
    else {
      let blockedLogin = false;
      this.setState({ blockedLogin });
    }
  };

  // Function that validates if the person is over a required age
  validateIfLegal = (birthDate, requiredAge) => {
    if (birthDate) {
      let today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (!m && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= requiredAge;
    } else {
      return false;
    }
  };

  // Function that validates the user basic data
  validateBasicData = () => {
    let basicData = { ...this.state.basicData };
    let canPost = true;

    if (!basicData.data.name) {
      basicData.errors.name = 'Nombre es requerido';
      canPost = false;
    }

    if (basicData.data.name.length > 25) {
      basicData.errors.name = 'El máximo permitido son 25 caracteres';
      canPost = false;
    }

    if (!basicData.data.lastName) {
      basicData.errors.lastName = 'Apellido es requerido';
      canPost = false;
    }

    if (basicData.data.lastName.length > 25) {
      basicData.errors.lastName = 'El máximo permitido son 25 caracteres';
      canPost = false;
    }

    if (!basicData.data.idType) {
      basicData.errors.idType = 'Indique el tipo de cédula';
      canPost = false;
    }
    if (basicData.data.id.length < 6) {
      basicData.errors.id = 'Ingrese un número de cédula válido';
      canPost = false;
    }
    if (!basicData.data.id) {
      basicData.errors.id = 'Número de cédula es requerido';
      canPost = false;
    }

    if (!basicData.data.birthDate) {
      basicData.errors.birthDate = 'Fecha de nacimiento requerida';
      canPost = false;
    }

    if (!this.validateIfLegal(basicData.data.birthDate, 12)) {
      basicData.errors.birthDate = 'El participante debe ser mayor de 12 años';
      canPost = false;
    }

    if (!basicData.data.gender) {
      basicData.errors.gender = 'Género  requerido';
      canPost = false;
    }

    if (
      basicData.data.phoneNumber &&
      !this.validatePhoneNumberFormat(basicData.data.phoneNumber)
    ) {
      basicData.errors.phoneNumber = 'Ingrese un número válido';
      canPost = false;
    }

    // Validate Repeated number
    if (
      basicData.data.phoneNumber &&
      basicData.data.phoneNumber === basicData.data.mobileNumber
    ) {
      basicData.errors.phoneNumber = 'Número duplicado';
      basicData.errors.mobileNumber = 'Número duplicado';
      canPost = false;
    }

    if (
      basicData.data.phoneNumber &&
      basicData.data.phoneNumber === basicData.data.otherNumber
    ) {
      basicData.errors.phoneNumber = 'Número duplicado';
      basicData.errors.otherNumber = 'Número duplicado';
      canPost = false;
    }

    if (
      basicData.data.mobileNumber &&
      basicData.data.mobileNumber === basicData.data.otherNumber
    ) {
      basicData.errors.mobileNumber = 'Número duplicado';
      basicData.errors.otherNumber = 'Número duplicado';
      canPost = false;
    }

    // Validate phone format
    if (
      basicData.data.mobileNumber &&
      !this.validatePhoneNumberFormat(basicData.data.mobileNumber)
    ) {
      basicData.errors.mobileNumber = 'Ingrese un número válido';
      canPost = false;
    }

    if (
      basicData.data.otherNumber &&
      !this.validatePhoneNumberFormat(basicData.data.otherNumber)
    ) {
      basicData.errors.otherNumber = 'Ingrese un número válido';
      canPost = false;
    }

    if (
      !basicData.data.phoneNumber &&
      !basicData.data.mobileNumber &&
      !basicData.data.otherNumber
    ) {
      basicData.errors.phoneNumber =
        'Indique por lo menos un número de teléfono';
      canPost = false;
    }

    if (!basicData.data.state) {
      basicData.errors.state = 'Estado requerido';
      canPost = false;
    }

    if (!basicData.data.address) {
      basicData.errors.address = 'Dirección requerida';
      canPost = false;
    }

    if (basicData.data.address.length > 100) {
      basicData.errors.address = 'El máximo permitido son 100 caracteres';
      canPost = false;
    }

    // Validations if the user inserts Employment data
    if (basicData.data.employmentState) {
      if (!basicData.data.economicSector) {
        basicData.errors.economicSector = 'Sector económico requerido';
        canPost = false;
      }
      // Entepreneur validations
      if (
        basicData.data.employmentState === '2' ||
        basicData.data.employmentState === '3'
      ) {
        let regularExpression = /^[0-9a-zñáéíóúü ]+$/i;
        if (
          !regularExpression.test(String(basicData.data.startUpDesctiption))
        ) {
          basicData.errors.startUpDesctiption =
            'No puede contener caracteres especiales';
          canPost = false;
        }

        if (basicData.data.startUpDesctiption.length > 100) {
          basicData.errors.startUpDesctiption =
            'El máximo permitido son 100 caracteres';
          canPost = false;
        }
        if (!basicData.data.startUpDesctiption) {
          basicData.errors.startUpDesctiption =
            'Una descripción de su emprendimiento es requerida';
          canPost = false;
        }
      }

      // Employee validations
      if (
        basicData.data.employmentState === '1' ||
        basicData.data.employmentState === '3'
      ) {
        if (!basicData.data.company) {
          basicData.errors.company = 'El nombre de su empresa es requerido';
          canPost = false;
        }
        if (basicData.data.company.length > 100) {
          basicData.errors.company = 'El máximo permitido son 100 caracteres';
          canPost = false;
        }

        if (!basicData.data.charge) {
          basicData.errors.charge = 'El nombre de su cargo es requerido';
          canPost = false;
        }
        if (basicData.data.charge.length > 100) {
          basicData.errors.charge = 'El máximo permitido son 100 caracteres';
          canPost = false;
        }
      }
    }

    // If The user incerts academic data then he should complete the form

    this.setState({ basicData });
    return canPost;
  };

  // Function that handles the decrease of the basic data step wizard
  decreaseBasicDataStep = () => {
    let basicDataStep = { ...this.state.basicDataStep };
    basicDataStep.counter > 0 ? basicDataStep.counter-- : basicDataStep.counter;
    this.setState({ basicDataStep });
  };

  // Function that handles event changes on the basic data inputs
  createIdeaInputChange = async ({ currentTarget: input }) => {
    let createIdea = { ...this.state.createIdea };
    createIdea.errors[input.name] = '';
    createIdea.data[input.name] = input.value.trimStart();
    if (input.name === 'proposalType') {
      createIdea.data.ocupation = '';
      createIdea.data.socialType = '';
    }
    // Limit phone numbers to 13 characters
    if (input.name === 'ongPhone') {
      if (parseInt(input.value) === 0) {
        input.value = '';
      }
      createIdea.data.ongPhone = input.value.substring(0, 13);
    }

    if (input.name === 'socialType') {
      createIdea.data.ocupation = '';
    }

    // Limit phone numbers to 13 characters
    if (input.name === 'ongPhone') {
      createIdea.data[input.name] = createIdea.data[input.name].substring(
        0,
        13
      );
    }

    this.setState({ createIdea });
  };

  // Function that handles the confirmation for the Create idea wizard
  confirmCreateIdeaStep = async (history) => {
    let blockedLogin = true;
    this.setState({ blockedLogin });

    let sessionToken = this.state.userSession.sessionToken;
    let ideaList = this.state.ideaList;
    let createIdea = this.state.createIdea;
    let createIdeaStep = this.state.createIdeaStep;
    let firstTime = this.state.createIdea.data.firstTime;
    let lastThreeYears = this.state.createIdea.data.lastThreeYears;
    let newIdea = this.state.createIdea.data.newIdea;
    let data = {};
    firstTime = firstTime === '0' ? false : true;
    lastThreeYears = lastThreeYears === '0' ? false : true;
    newIdea = newIdea === '0' ? false : true;

    if (
      this.state.createIdea.data.proposalType === '3' &&
      this.state.createIdea.data.socialType === '3'
    ) {
      let newOng = {
        name: this.state.createIdea.data.ongName,
        address: this.state.createIdea.data.ongAddress,
        email: this.state.createIdea.data.ongEmail,
        phone: this.state.createIdea.data.ongPhone,
        state: parseInt(this.state.createIdea.data.state),
      };

      let responseOng = await createOngFunction(newOng, sessionToken);

      // No response from API
      if (!responseOng) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
        this.notify('Connection Error', 'error');
      }

      // Ong created
      if (responseOng.status == 200) {
        let ongId = responseOng.data.id;
        data = {
          ocupation: parseInt(this.state.createIdea.data.ocupation),
          proposalType: parseInt(this.state.createIdea.data.proposalType),
          socialType: parseInt(this.state.createIdea.data.socialType),
          title: this.state.createIdea.data.title,
          firstTime: firstTime,
          lastThreeYears: lastThreeYears,
          newIdea: newIdea,
          sector: parseInt(this.state.createIdea.data.sector),
          state: parseInt(this.state.createIdea.data.state),
          user: this.state.userSession.userId,
          contest: parseInt(sessionStorage.getItem('contestId')),
          ong: parseInt(ongId),
          stage: parseInt(sessionStorage.getItem('stageId')),
          deleted: false,
        };

        let response = await createIdeaFunction(data, sessionToken);

        // No response from API
        if (!response) {
          let blockedLogin = false;
          this.setState({ blockedLogin });
          this.notify('Connection Error', 'error');
        }

        // Idea created
        if (response.status == 200) {
          let blockedLogin = false;
          this.setState({ blockedLogin });
          history.push(`/home/participant`);
          this.notify('Su Idea ha sido creada de forma exitosa.', 'success');
          // Delete form data and reset create idea counter
          createIdea.data = {
            proposalType: '',
            socialType: '',
            ocupation: '',
            title: '',
            firstTime: '1',
            lastThreeYears: '0',
            newIdea: '1',
            state: '',
            sector: '',
            ongName: '',
            ongAddress: '',
            ongState: '',
            ongEmail: '',
            ongPhone: '',
          };
          createIdeaStep.counter = 0;
          this.setState({ createIdea, createIdeaStep });

          this.getIdeaListFunction(
            this.state.userSession,
            ideaList.currentPage,
            this.state.ideaList.ideasPerPage
          );
        }

        // Show DB message
        else if (response.status == 400) {
          let blockedLogin = false;
          this.setState({ blockedLogin });
        } else if (response.status == 401) {
          let blockedLogin = false;
          this.setState({ blockedLogin });
        } else {
          let blockedLogin = false;
          this.setState({ blockedLogin });
        }
      }

      // Show DB message
      else if (responseOng.status == 400) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      } else if (responseOng.status == 401) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      } else {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      }
    } else {
      data = {
        ocupation: parseInt(this.state.createIdea.data.ocupation),
        proposalType: parseInt(this.state.createIdea.data.proposalType),
        socialType: parseInt(this.state.createIdea.data.socialType),
        title: this.state.createIdea.data.title,
        firstTime: firstTime,
        lastThreeYears: lastThreeYears,
        newIdea: newIdea,
        sector: parseInt(this.state.createIdea.data.sector),
        state: parseInt(this.state.createIdea.data.state),
        user: this.state.userSession.userId,
        contest: parseInt(sessionStorage.getItem('contestId')),
        stage: parseInt(sessionStorage.getItem('stageId')),
        deleted: false,
      };

      let response = await createIdeaFunction(data, sessionToken);

      // No response from API
      if (!response) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
        this.notify('Connection Error', 'error');
      }

      // User created
      if (response.status == 200) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
        history.push(`/home/participant`);
        this.notify('Su Idea ha sido creada de forma exitosa.', 'success');
        // Delete form data and reset create idea counter
        createIdea.data = {
          proposalType: '',
          socialType: '',
          ocupation: '',
          title: '',
          firstTime: '1',
          lastThreeYears: '0',
          newIdea: '1',
          state: '',
          sector: '',
          ongName: '',
          ongAddress: '',
          ongState: '',
          ongEmail: '',
          ongPhone: '',
        };
        createIdeaStep.counter = 0;
        this.setState({ createIdea, createIdeaStep });

        this.getIdeaListFunction(
          this.state.userSession,
          ideaList.currentPage,
          this.state.ideaList.ideasPerPage
        );
      }

      // Show DB message
      else if (response.status == 400) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      } else if (response.status == 401) {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      } else {
        let blockedLogin = false;
        this.setState({ blockedLogin });
      }
    }
  };

  // Function that handles the increase of the basic data step wizard
  increaseCreateIdeaStep = () => {
    let createIdeaStep = { ...this.state.createIdeaStep };
    createIdeaStep.counter < createIdeaStep.steps.length;
    this.validateCreateIdea() === true
      ? createIdeaStep.counter++
      : createIdeaStep.counter;
    this.setState({ createIdeaStep });
  };

  // Function that handles the decrease of the basic data step wizard
  decreaseCreateIdeaStep = () => {
    let createIdeaStep = { ...this.state.createIdeaStep };
    createIdeaStep.counter > 0
      ? createIdeaStep.counter--
      : createIdeaStep.counter;
    this.setState({ createIdeaStep });
  };

  validateCreateIdea = () => {
    let createIdea = { ...this.state.createIdea };
    let canPost = true;
    let step = this.state.createIdeaStep.counter;
    //Step 1 Validations
    if (step === 0) {
      /*
    /* Proposal 2-> Business / 3-> Social
    /* Social Type 2-> Individual / 3-> Organization
    */
      if (createIdea.data.proposalType === '') {
        createIdea.errors.proposalType = 'Este campo es requerido';
        canPost = false;
      } else {
        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === ''
        ) {
          createIdea.errors.socialType = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '2' &&
          createIdea.data.ocupation === ''
        ) {
          createIdea.errors.ocupation = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '2' &&
          createIdea.data.ocupation === ''
        ) {
          createIdea.errors.ocupation = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !createIdea.data.ongName
        ) {
          createIdea.errors.ongName = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !createIdea.data.ongAddress
        ) {
          createIdea.errors.ongAddress = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          createIdea.data.ongName.length > 150
        ) {
          createIdea.errors.ongName =
            'El nombre de la ONG debe tener menos de 150 caracteres';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          createIdea.data.ongAddress.length > 250
        ) {
          createIdea.errors.ongAddress =
            'La dirección debe tener menos de 250 caracteres';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !createIdea.data.ongState
        ) {
          createIdea.errors.ongState = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !createIdea.data.ongEmail
        ) {
          createIdea.errors.ongEmail = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !createIdea.data.ongPhone
        ) {
          createIdea.errors.ongPhone = 'Este campo es requerido';
          canPost = false;
        }

        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !this.validateEmailFormat(createIdea.data.ongEmail)
        ) {
          createIdea.errors.ongEmail = 'Ingrese un email válido';
          canPost = false;
        }
        if (
          createIdea.data.proposalType === '3' &&
          createIdea.data.socialType === '3' &&
          !this.validatePhoneNumberFormat(createIdea.data.ongPhone)
        ) {
          createIdea.errors.ongPhone = 'Ingrese un teléfono válido';
          canPost = false;
        }
      }
    }

    //Step 2 Validations
    if (step === 1) {
      // Validate special characters
      let regularExpression = /[^a-zA-Z0-9 ]/;
      if (regularExpression.test(String(createIdea.data.title))) {
        createIdea.errors.title =
          'La idea no puede contener caracteres especiales';
        canPost = false;
      }

      if (createIdea.data.title === '') {
        createIdea.errors.title = 'Este campo es requerido';
        canPost = false;
      }

      if (createIdea.data.title.length > 100) {
        createIdea.errors.title =
          'El titulo debe tener menos de 100 caracteres';
        canPost = false;
      }

      if (createIdea.data.sector === '') {
        createIdea.errors.sector = 'Este campo es requerido';
        canPost = false;
      }

      if (createIdea.data.state === '') {
        createIdea.errors.state = 'Este campo es requerido';
        canPost = false;
      }

      if (createIdea.data.firstTime === '') {
        createIdea.errors.firstTime = 'Este campo es requerido';
        canPost = false;
      }

      if (createIdea.data.lastThreeYears === '') {
        createIdea.errors.lastThreeYears = 'Este campo es requerido';
        canPost = false;
      }

      if (createIdea.data.newIdea === '') {
        createIdea.errors.newIdea = 'Este campo es requerido';
        canPost = false;
      }
    }
    this.setState({ createIdea });
    return canPost;
  };

  // Function that handles the idea selection for edition
  // Very Important function
  selectIdea = async (idea) => {
    let ideaForEdition = this.state.ideaForEdition;
    let files = []; //Remove preloaded files when selectign idea
    let members = []; //Remove preloaded members
    let awards = []; //Remove preloaded awards
    ideaForEdition.idea = idea;
    this.getDocumentsByIdeaIdFunction(idea.id);
    this.getMembersByIdeaFunction(idea.id);
    this.getAwardsByIdeaFunction(idea.id);
    this.setState({ ideaForEdition, files, members, awards });
  };

  getAwardsByIdeaFunction = async (ideaId) => {
    let userSession = this.state.userSession;
    let ideaForEdition = this.state.ideaForEdition;
    let response = await getAwardsForIdea(ideaId, userSession.sessionToken);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      ideaForEdition.awards = response.data;
      this.setState({ ideaForEdition });
    }
  };

  handleAwardOnChange = (index) => {
    let ideaForEdition = this.state.ideaForEdition;
    ideaForEdition.awards[index].competing =
      !ideaForEdition.awards[index].competing;

    this.setState({ ideaForEdition });
    this.saveAwards(
      ideaForEdition.awards[index].awards.id,
      ideaForEdition.awards[index].competing
    );
  };

  saveAwards = async (awardId, competing) => {
    let award = {
      id: parseInt(awardId),
    };
    let data = {
      isCompeting: competing,
      awards: award,
    };
    await this.selectAwardService(data, this.state.ideaForEdition.idea.id);
  };

  selectAwardService = async (data, ideaForEdition) => {
    let userSession = this.state.userSession;
    let blockedLogin = true;
    this.setState({ blockedLogin });

    let response = await saveAwardIdea(
      data,
      ideaForEdition,
      userSession.sessionToken
    );
    // No response from API
    if (!response) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 201 || response.status == 200) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify(
        'Se han guardado sus cambios en postulaciones en premios especiales de forma exitosa.',
        'success'
      );
      this.getAwardsByIdeaFunction(ideaForEdition);
    }
  };

  getMembersByIdeaFunction = async (ideaId) => {
    let userSession = this.state.userSession;
    let ideaForEdition = this.state.ideaForEdition;
    let response = await memberList(ideaId, userSession.sessionToken);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      ideaForEdition.members = response.data;
      ideaForEdition.members.map(
        (i) => ((i.memberDocLoading = false), (i.fileToUpload = []))
      );
      this.setState({ ideaForEdition });
    }
  };

  getDocumentsByIdeaIdFunction = async (ideaId) => {
    let userSession = this.state.userSession;
    let ideaForEdition = this.state.ideaForEdition;
    let response = await getDocumentsByIdeaId(userSession.sessionToken, ideaId);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      ideaForEdition.files = response.data;
      this.setState({ ideaForEdition });
      // Get the idea documents to display
    }
  };

  // Function that handles event changes on the update password inputs
  passwordForminputChange = async ({ currentTarget: input }) => {
    let passwordUpdateForm = { ...this.state.passwordUpdateForm };

    // Trim input to avoid erros on the login
    passwordUpdateForm.data[input.name] = input.value.trimStart();

    this.setState({ passwordUpdateForm });
  };

  // Returns all forms to their default values
  restoreAllForms = () => {
    this.restoreUserState();
  };

  //Restores bond State defeult values
  restoreUserState = () => {
    let userForm = { ...this.state.userForm };
    userForm.data = {
      idUserType: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
    };
    userForm.errors = {
      idUserType: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
    };

    this.setState({ userForm });
  };

  // Handle expired session and redirects to login
  handleExpiredSession = (history) => {
    this.notify('Su sesión ha expirado', 'info');
    sessionStorage.clear();
    history.push(`/login`);
  };

  // Function that handles the logout request
  logoutService = (props) => {
    let user = { email: '', password: '' };
    this.setState({ user });
    sessionStorage.clear();
    props.history.push(`/login`);
  };

  // Cancel idea ceration and return home
  cancelIdeaCreation = (history, userSession) => {
    history.push(`/home/${userSession.userType}`);
    location.reload();
  };

  addFinalPresentationUrlService = async (data, sessionToken) => {
    let ideaForEdition = this.state.ideaForEdition;
    let blockedLogin = true;
    this.setState({ blockedLogin });

    let response = await addFinalPresentationUrl(
      data,
      ideaForEdition.idea.id,
      sessionToken
    );
    // No response from API
    if (!response) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      if (data.finalPresentationUrl === '') {
        this.notify(
          'La URL de su presentación final fue eliminada de forma exitosa.',
          'success'
        );
      } else {
        this.notify(
          'La URL de su presentación final fue guardada de forma exitosa.',
          'success'
        );
      }

      ideaForEdition.idea.finalPresentationUrl = data.finalPresentationUrl;
      this.setState({ ideaForEdition });
    }
  };

  addMemberService = async (data, sessionToken) => {
    let ideaForEdition = this.state.ideaForEdition;
    let blockedLogin = true;
    this.setState({ blockedLogin });

    let response = await createMember(
      data,
      ideaForEdition.idea.id,
      sessionToken
    );
    // No response from API
    if (!response) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 400) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
    }
    if (response.status == 200) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify(
        'Su nuevo integrante ha sido registrado de forma exitosa.',
        'success'
      );
      this.getMembersByIdeaFunction(ideaForEdition.idea.id);
      return true;
    }
  };

  deleteMemberService = async (idIdea, idMember, sessionToken) => {
    let response = await deleteMember(idIdea, idMember, sessionToken);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      this.notify('Su integrante ha sido eliminado.', 'success');
      this.getMembersByIdeaFunction(idIdea);
    }
  };

  // Function that stores the variable in sesion storage
  saveOnSessionStorage = (name, input) => {
    const data = JSON.stringify(input);
    sessionStorage.setItem(`${name}`, data);
  };

  // Function that get the variable from the session storage
  getFromSessionStorage = (name) => {
    let data = sessionStorage.getItem(`${name}`);
    data = JSON.parse(data);
    return data;
  };

  // Function that capitalize the first letter of each word in a string
  capitalizeFirstLetter = (string) => {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // Function that validates email format return booleans
  validateEmailFormat(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Function that validates if inputs is a phone number
  validatePhoneNumberFormat(phone) {
    const re =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return re.test(phone);
  }

  // Function that validates email format return booleans
  validateCharacters(word) {
    const re = /^[ A-Za-zÀ-ÿ'´]*$/;
    return re.test(String(word).toLowerCase());
  }

  // Function that validates if inputs is number
  validateNumericFormat(phone) {
    const re = /^[0-9]*$/;
    return re.test(phone);
  }

  // Function that validates if the end date is greater than the start date
  validateIfEndDateIsGreaterEqual(startDate, endDate) {
    return startDate <= endDate;
  }

  // Toast notification custom  Message
  notify = (message, type) => {
    if (type == 'success') {
      toast.success(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoCLose: 3000,
        className: 'Ts-op',
      });
    }
    if (type == 'info') {
      toast.info(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'Ts-op',
      });
    }
    if (type == 'error') {
      toast.error(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'Ts-op',
      });
    }
  };

  // Function that handles event on the search box in user list
  searchUserBox = (query) => {
    let userFiltered;
    let userList = { ...this.state.userList };

    if (!empty(query)) {
      userFiltered = this.state.userList.orgtableData.filter(
        (list) =>
          list.name.toLowerCase().includes(query.toLowerCase()) ||
          list.email.toLowerCase().includes(query.toLowerCase()) ||
          list.lastName.toLowerCase().includes(query.toLowerCase()) ||
          list.userTypeName.toLowerCase().includes(query.toLowerCase()) ||
          list.insuranceName.toLowerCase().includes(query.toLowerCase()) ||
          list.govTypeName.toLowerCase().includes(query.toLowerCase())
      );

      userList.data = userFiltered;
      userList.orgFilteredData = userFiltered;
      userList.pageCount = Math.ceil(userFiltered.length / userList.perPage);
      // Slice users to max # users per page
      userList.data = userList.data.slice(
        userList.offset,
        userList.offset + userList.perPage
      );
      userList.isFiltered = true;
      this.setState({ userList });
    } else if (empty(query)) {
      userList.isFiltered = false;
      // Gets back the original number of pages
      userList.pageCount = Math.ceil(
        userList.orgtableData.length / userList.perPage
      );
      // Slice data to # users per page
      userList.data = userList.orgtableData.slice(
        userList.offset,
        userList.offset + userList.perPage
      );

      // The data to display per page
      this.setState({ userList });
    }
  };
  //handle files for evaluation criterias
  handleDragAndDropFilesEvaluationCriterias=(accepted,rejected,maxFilesPermitted) => {
    let files = this.state.files;   
    let evaluation= this.state.evaluationcriteria;
    if (
      files.length < maxFilesPermitted &&
      accepted.length <= maxFilesPermitted
    ) {
      let canPost = true;
      files = files.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      canPost = this.validateIfExceedsWeight(files, 25);
      this.setState({ files });

      if (canPost === true) {
        this.uploadDocumentEvaluationFunction(evaluation.contestId,evaluation.faseId,evaluation.typeIdea);
      } else {
        files = [];
        this.setState({ files });
      }
    }

  }

  // Handle files drag and drop
  handleDragAndDropFiles = (accepted, rejected, maxFilesPermited) => {
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    if (
      files.length < maxFilesPermited &&
      accepted.length <= maxFilesPermited
    ) {
      let canPost = true;
      files = files.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      canPost = this.validateIfExceedsWeight(files, 25);
      this.setState({ files });

      if (canPost === true) {
        this.uploadDocumentFunction(ideaForEdition.idea.id, 'CV_lider');
      } else {
        files = [];
        this.setState({ files });
      }
    }
  };

  // Handle files drag and drop
  handleBusinessDragAndDropFiles = (accepted, rejected, maxFilesPermited) => {
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    if (
      files.length < maxFilesPermited &&
      accepted.length <= maxFilesPermited
    ) {
      let canPost = true;
      files = files.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      this.setState({ files });
      canPost = this.validateIfExceedsWeight(files, 25);

      if (canPost === true) {
        this.uploadDocumentFunction(ideaForEdition.idea.id, 'Plan_negocios');
      }
    }
  };

  // Handle files drag and drop files for Resumen_ong
  handleONGDragAndDropFiles = (accepted, rejected, maxFilesPermited) => {
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    if (
      files.length < maxFilesPermited &&
      accepted.length <= maxFilesPermited
    ) {
      let canPost = true;
      files = files.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      this.setState({ files });
      canPost = this.validateIfExceedsWeight(files, 25);

      if (canPost === true) {
        this.ulploadONGDocumentFunction(ideaForEdition.idea.id, 'Resumen_ong');
      }
    }
  };

  // Handle files drag and drop for the Idea Summary
  handleDragAndDropSummaryFiles = (accepted, rejected, maxFilesPermited) => {
    let ideaForEdition = this.state.ideaForEdition;
    if (
      ideaForEdition.ideaSummaryFiles.length < maxFilesPermited &&
      accepted.length <= maxFilesPermited
    ) {
      let canPost = true;
      ideaForEdition.ideaSummaryFiles =
        ideaForEdition.ideaSummaryFiles.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      canPost = this.validateIfExceedsWeight(ideaForEdition, 25);
      if (canPost === true) {
        this.ulploadSummaryDocumentFunction(ideaForEdition.idea.id, 'Resumen');
      }
      ideaForEdition.ideaSummaryFiles = [];
      this.setState({ ideaForEdition });
    }
  };

  // Handle files drag and drop for the Idea Summary
  handleDragAndDropFinalPresentationFiles = (
    accepted,
    rejected,
    maxFilesPermited
  ) => {
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    if (
      files.length < maxFilesPermited &&
      accepted.length <= maxFilesPermited
    ) {
      let canPost = true;
      files = files.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      this.setState({ files });
      canPost = this.validateIfExceedsWeight(files, 25);

      if (canPost === true) {
        this.uploadFinalPresentationDocumentFunction(
          ideaForEdition.idea.id,
          'Final_presentation'
        );
      }
    }
  };

  // Handle files drag and drop
  handleDragAndDropMembersFiles = (
    accepted,
    rejected,
    maxFilesPermited,
    memberId
  ) => {
    let ideaForEdition = this.state.ideaForEdition;
    if (
      ideaForEdition.members[memberId].fileToUpload.length < maxFilesPermited &&
      accepted.length <= maxFilesPermited
    ) {
      let canPost = true;
      ideaForEdition.members[memberId].fileToUpload =
        ideaForEdition.members[memberId].fileToUpload.concat(accepted);
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .pdf`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }

      canPost = this.validateIfExceedsWeight(
        ideaForEdition.members[memberId].fileToUpload,
        25
      );

      if (canPost === true) {
        this.uploadMemberDocumentFunction(memberId, 'CV_participante');
      }
      ideaForEdition.members[memberId].fileToUpload = [];
      this.setState({ ideaForEdition });
    }
  };

  // Function that validates if the files exceed the max weight in MB
  validateIfExceedsWeight = (files, maxWeight) => {
    let totalWeight = 0;
    // Sum the weight of all the files
    for (let i = 0; i < files.length; i++) {
      totalWeight = totalWeight + files[i].size;
    }
    // Validate if the files weight more than 25Mb
    if (totalWeight / 1048576 > maxWeight) {
      toast.error(
        `La carga de documentos no puede superar los ${maxWeight}MB`,
        { position: toast.POSITION.TOP_RIGHT, className: 'Ts-op' }
      );
      return false;
    } else {
      return true;
    }
  };

  // Function that removes an specific documment from the list
  removeDocumentsfromList = (i) => {
    let files = this.state.files;
    files.splice(i, 1);
    this.setState({ files });
  };



  getDocumentsByCriteriaPrincipal=async (contestId,stageId,type) =>{

    let userSession = this.state.userSession;
    let evaluationcriteria= this.state.evaluationcriteria;
    let response = await getDocumentsByCriteria( userSession,
      contestId, 
      stageId,
      type);
    // No response from API
    if (!response) {
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      console.log(response.data)
      evaluationcriteria.files = response.data;
      this.setState({ evaluationcriteria });
      // Get the idea documents to display
    }

  }

  selectCriteriaDocument= async (contestId,stageId,type) => {

    this.getDocumentsByCriteriaPrincipal(contestId,stageId,type)
  }

  uploadDocumentEvaluationFunction= async (contestId,stageId,type,docType)=>{
    let userSession = { ...this.state.userSession };
    let files = this.state.files;
    let evaluationcriteria = this.state.evaluationcriteria;
    let canPost = this.validateIfExceedsWeight(files, 25);

    if (canPost == true) {
      evaluationcriteria.evaluationFilesloading = true;
      this.setState({ evaluationcriteria });
      let datos = new FormData();
      for (let i = 0; i < files.length; i++) {
        datos.append('files', files[i], files[i].path);
      }
      datos.append('type', docType);
      let response = await createDocumentByCriteria(datos, 
        contestId, 
        stageId,
        type,
        userSession);
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        evaluationcriteria.evaluationFilesloading = false;
        this.setState({ evaluationcriteria });
        return null;
      }
      if (response.status == 200) {
        console.log(response.data)
        files = [];
        this.setState({ files });
        // Get the idea documents to display
        this.getDocumentsByCriteriaPrincipal(
          evaluationcriteria.contestId, 
          evaluationcriteria.faseId,
          evaluationcriteria.typeIdea);
        evaluationcriteria.evaluationFilesloading = false;




      }
      // Show DB message
      else if (response.status == 400) {
        evaluationcriteria.evaluationFilesloading = false;
      } else if (response.status == 401) {
        evaluationcriteria.evaluationFilesloading = false;
      }
      //  Show expired session messagen and redirect to login
      else {
        evaluationcriteria.evaluationFilesloading = false;
      }
      this.setState({ evaluationcriteria });
      this.getCurrentYearDocumentsFunction(userSession);
    }

  };

  // Function that uploads CV LEADER a document
  uploadDocumentFunction = async (ideaId, docType) => {
    let userSession = { ...this.state.userSession };
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    let canPost = this.validateIfExceedsWeight(files, 25);

    if (canPost == true) {
      ideaForEdition.leaderDocloading = true;
      this.setState({ ideaForEdition });
      let datos = new FormData();
      for (let i = 0; i < files.length; i++) {
        datos.append('files', files[i], files[i].path);
      }
      datos.append('type', docType);
      let response = await createDocumentService(datos, ideaId, userSession);
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        ideaForEdition.leaderDocloading = false;
        this.setState({ ideaForEdition });
        return null;
      }
      if (response.status == 200) {
        files = [];
        this.setState({ files });
        // Get the idea documents to display
        this.getDocumentsByIdeaIdFunction(ideaId);
        ideaForEdition.leaderDocloading = false;
      }
      // Show DB message
      else if (response.status == 400) {
        ideaForEdition.leaderDocloading = false;
      } else if (response.status == 401) {
        ideaForEdition.leaderDocloading = false;
      }
      //  Show expired session messagen and redirect to login
      else {
        ideaForEdition.leaderDocloading = false;
      }
      this.setState({ ideaForEdition });
      this.getCurrentYearDocumentsFunction(userSession);
    }
  };

  // Function that uploads a document
  ulploadSummaryDocumentFunction = async (ideaId, docType) => {
    let userSession = { ...this.state.userSession };
    let ideaForEdition = this.state.ideaForEdition;
    let canPost = this.validateIfExceedsWeight(
      ideaForEdition.ideaSummaryFiles,
      25
    );

    if (canPost == true) {
      ideaForEdition.ideaSummaryFilesloading = true;
      this.setState({ ideaForEdition });
      let datos = new FormData();
      for (let i = 0; i < ideaForEdition.ideaSummaryFiles.length; i++) {
        datos.append(
          'files',
          ideaForEdition.ideaSummaryFiles[i],
          ideaForEdition.ideaSummaryFiles[i].path
        );
      }
      datos.append('type', docType);
      let response = await createDocumentService(datos, ideaId, userSession);
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        ideaForEdition.ideaSummaryFilesloading = false;
        this.setState({ ideaForEdition });
        return null;
      }
      if (response.status == 200) {
        ideaForEdition.ideaSummaryFiles = [];
        this.setState({ ideaForEdition });
        // Get the idea documents to display
        this.getDocumentsByIdeaIdFunction(ideaId);
        // Refresh the list of ideas and filter the selected idea
        if (await this.getCurrentYearIdeasFunction(userSession)) {
          let selectedIdea = this.state.currentYearIdeas.filter(
            (c) => c.id == ideaForEdition.idea.id
          );
          this.selectIdea(selectedIdea[0]);
        }
        ideaForEdition.ideaSummaryFilesloading = false;
        this.getIdeaListFunction(userSession, 0, 3);
      }
      // Show DB message
      else if (response.status == 400) {
        ideaForEdition.ideaSummaryFilesloading = false;
      } else if (response.status == 401) {
        ideaForEdition.ideaSummaryFilesloading = false;
      }
      //  Show expired session messagen and redirect to login
      else {
        ideaForEdition.leaderDocloading = false;
      }
      this.setState({ ideaForEdition });
    }
  };

  // Function that uploads CV LEADER a document
  uploadMemberDocumentFunction = async (counter, docType) => {
    let userSession = this.state.userSession;
    let ideaForEdition = this.state.ideaForEdition;
    let { fileToUpload } = ideaForEdition.members[counter];

    let canPost = this.validateIfExceedsWeight(fileToUpload, 25);
    if (canPost == true) {
      // Start load spinnet
      ideaForEdition.members[counter].memberDocLoading = true;
      this.setState({ ideaForEdition });
      let datos = new FormData();
      for (let i = 0; i < fileToUpload.length; i++) {
        datos.append('files', fileToUpload[i], fileToUpload[i].path);
      }
      datos.append('type', docType);
      let response = await createDocumentByMember(
        datos,
        ideaForEdition.members[counter].id,
        userSession
      );
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        ideaForEdition.leaderDocloading = false;
        this.setState({ ideaForEdition });
        return null;
      }
      if (response.status == 200) {
        this.setState({ ideaForEdition });
        this.getMembersByIdeaFunction(ideaForEdition.idea.id);
        // Get the idea documents to display
        ideaForEdition.members[counter].memberDocLoading = false;
      }
      // Show DB message
      else if (response.status == 400) {
        ideaForEdition.members[counter].memberDocLoading = false;
      } else if (response.status == 401) {
        ideaForEdition.members[counter].memberDocLoading = false;
      }
      //  Show expired session messagen and redirect to login
      else {
        ideaForEdition.members[counter].memberDocLoading = false;
      }
      this.setState({ ideaForEdition });
    }
  };

  // Function that uploads CV ONG a document
  ulploadONGDocumentFunction = async (ideaId, docType) => {
    let userSession = { ...this.state.userSession };
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    let canPost = this.validateIfExceedsWeight(files, 25);

    if (canPost == true) {
      ideaForEdition.ongDocloading = true;
      this.setState({ ideaForEdition });
      let datos = new FormData();
      for (let i = 0; i < files.length; i++) {
        datos.append('files', files[i], files[i].path);
      }
      datos.append('type', docType);
      let response = await createDocumentService(datos, ideaId, userSession);
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        ideaForEdition.ongDocloading = false;
        this.setState({ ideaForEdition });
        return null;
      }
      if (response.status == 200) {
        files = [];
        this.setState({ files });
        // Get the idea documents to display
        this.getDocumentsByIdeaIdFunction(ideaId);
        ideaForEdition.ongDocloading = false;
      }
      // Show DB message
      else if (response.status == 400) {
        ideaForEdition.ongDocloading = false;
      } else if (response.status == 401) {
        ideaForEdition.ongDocloading = false;
      }
      //  Show expired session messagen and redirect to login
      else {
        ideaForEdition.ongDocloading = false;
      }
      this.setState({ ideaForEdition });
    }
  };

  // Function that uploads CV ONG a document
  uploadFinalPresentationDocumentFunction = async (ideaId, docType) => {
    let userSession = { ...this.state.userSession };
    let files = this.state.files;
    let ideaForEdition = this.state.ideaForEdition;
    let canPost = this.validateIfExceedsWeight(files, 25);

    if (canPost == true) {
      ideaForEdition.finalPresentationloading = true;
      this.setState({ ideaForEdition });
      let datos = new FormData();
      for (let i = 0; i < files.length; i++) {
        datos.append('files', files[i], files[i].path);
      }
      datos.append('type', docType);
      let response = await createDocumentService(datos, ideaId, userSession);
      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        ideaForEdition.finalPresentationloading = false;
        this.setState({ ideaForEdition });
        return null;
      }
      if (response.status == 200) {
        files = [];
        this.setState({ files });
        // Get the idea documents to display
        this.getDocumentsByIdeaIdFunction(ideaId);
        ideaForEdition.finalPresentationloading = false;
      }
      // Show DB message
      else if (response.status == 400) {
        ideaForEdition.finalPresentationloading = false;
      } else if (response.status == 401) {
        ideaForEdition.finalPresentationloading = false;
      }
      //  Show expired session messagen and redirect to login
      else {
        ideaForEdition.finalPresentationloading = false;
      }
      this.setState({ ideaForEdition });
      this.getCurrentYearDocumentsFunction(userSession);
    }
  };

  // Function that handles event on the autit text area
  auditTextAreaChange = async ({ currentTarget: input }) => {
    let audit = { ...this.state.audit };
    audit.comments = input.value.trimStart();

    if (audit.comments.length > 500) {
      audit.error = `Supera por ${
        audit.comments.length - 500
      } el máximo de 500 caracteres permitidos.`;
    } else {
      audit.error = '';
    }
    this.setState({ audit });
  };

  // Function that closes commment list modal
  closeCommentListModal = async () => {
    let comments = { ...this.state.comments };
    comments.show = false;
    comments.data = '';
    this.setState({ comments });
  };

  // Function that opens the base64document in another window
  openBase64File = (doc) => {
    // Open a new window with the PDF inside an iframe
    let pdfWindow = window.open();
    pdfWindow.document.write(
      '<html><head><title>' +
        `${doc.fileName}` +
        "</title></head><body height='100%' width='100%'><iframe width='100%' height='100%' src='" +
        `${doc.fileUrl}` +
        "'></iframe></body>"
    );
  };

  deleteIdeaFunction = async (idIdea) => {
    let userSession = { ...this.state.userSession };
    let blockedLogin = true;
    this.setState({ blockedLogin });
    let response = await deleteIdea(idIdea, userSession.sessionToken);

    // No response from API
    if (!response) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify('Connection Error', 'error');
      return null;
    }
    if (response.status == 200) {
      let blockedLogin = false;
      this.setState({ blockedLogin });
      this.notify('Su idea ha sido eliminada.', 'success');
      this.getIdeaListFunction(userSession, 0, 3);
    }
  };

  // Function that loads the User data into the state for edition
  loadUserDataForEdition = async (user) => {
    if (!user.govTypeId) user.govTypeId = null;

    if (!user.insuranceId) user.insuranceId = null;

    let userForm = { ...this.state.userForm };
    // Load User data in state for edition
    userForm.data.email = user.email;
    userForm.data.idGovType = user.govTypeId;
    userForm.data.id = user.id;
    userForm.data.idInsurance = user.insuranceId;
    userForm.data.name = user.name;
    userForm.data.phone = user.phoneNumber;
    userForm.data.lastName = user.lastName;
    userForm.data.idUserType = user.userTypeId;
    this.setState({ userForm });
  };

  // Function that validates the forgot password input
  validateForgotPwd = () => {
    let forgotPwd = { ...this.state.forgotPwdForm.data };
    let errors = { ...this.state.forgotPwdForm.errors };
    let canPost = true;

    if (empty(forgotPwd)) {
      errors.email = 'Email requerido';
      canPost = false;
    }

    this.setState({ errors });
    return canPost;
  };

  // Function that validates the forgot password inputs
  passwordFormValidate = () => {
    const options = { abortEarly: false };

    const reset = {
      email: this.state.forgotPwdForm.data.email,
    };

    //Validation with Joi
    var schema = {
      email: Joi.string().required().label('email'),
    };

    const { error } = Joi.validate(reset, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  //Function that posts a password reset to the API
  forgotPasswordFunction = async () => {
    let forgotPwdForm = { ...this.state.forgotPwdForm };
    // Block button
    let blockedLogin = true;
    this.setState({ blockedLogin });

    // Validate the user form inputs
    let canPost = this.validateEmailFormat(forgotPwdForm.data.email);
    // Error message  in case the email format is wrong
    if (!canPost) {
      forgotPwdForm.errors.email = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    if (canPost === false) {
      // Unlock send button
      forgotPwdForm.blocked = false;
      this.setState({ forgotPwdForm });
      // Remove load spinner
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }

    if (canPost == true && forgotPwdForm.blocked === false) {
      // Block send button
      forgotPwdForm.blocked = true;
      this.setState({ forgotPwdForm });

      let body = {
        email: forgotPwdForm.data.email,
      };

      let response = await forgotPassword(body);

      // No response from API
      if (!response) {
        this.notify('Connection Error', 'error');
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
        return null;
      }

      // password updated
      if (response.status == 200) {
        this.notify(
          'Se ha enviado un correo para la recuperación de su contraseña. Sino lo recibe, verifique que está registrado con el correo electrónico ingresado.',
          'success'
        );
        // Delete data from form inputs
        forgotPwdForm.data = { email: '' };
        forgotPwdForm.errors = { email: '' };
        this.setState({ forgotPwdForm });
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      }

      // Show DB message
      else if (response.status == 400) {
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      } else if (response.status == 401) {
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      } else {
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      }
    }
  };

  handleSideBarShow = () => {
    let sidebarShow = !this.state.sidebarShow;
    this.setState({ sidebarShow });
  };

  // Function that handles event changes on the update password inputs
  passwordForgotinputChange = async ({ currentTarget: input }) => {
    let forgotPwdForm = { ...this.state.forgotPwdForm };

    // Trim input to avoid erros on the login
    forgotPwdForm.data[input.name] = input.value.trimStart();
    this.setState({ forgotPwdForm });
  };

  // Function that opens or closes the stage dropdown
  handleStageDropDown = (name) => {
    let stagesDropdowns = this.state.stagesDropdowns;
    stagesDropdowns[name].selected = !stagesDropdowns[name].selected;
    this.setState(stagesDropdowns);
  };

  // Function that refresh the admin dashboard stats
  updateAdminDashboard = () => {
    let userSession = { ...this.state.userSession };
    this.getRegisteredUserFunction(userSession);
  };

  render() {
    // New State variables
    const {
      userSession,
      user,
      errors,
      blockedLogin,
      createAccountForm,
      passwordShown,
      forgotPwdForm,
      basicData,
      basicDataStep,
      states,
      educationalInstitution,
      educationalLevels,
      createIdea,
      createIdeaStep,
      proposalType,
      ocupation,
      socialType,
      sectors,
      statesList,
      economicSectors,
      ideaList,
      adminIdeaList,
      currentYearIdeas,
      currentYearDocuments,
      files,
      ideaForEdition,
      currentContest,
      passwordUpdateForm,
      sidebarShow,
      ideasParticipant,
      ideasRegistered,
      ideasFinalist,
      documentActives,
      documentActivesPresentation,
      ideasWinner,
      ideasSemifinalist,
      usersParticipant,
      stagesDropdowns,
      currentContestStage,
      isFase1Open,
      evaluationcriteria
      
    } = this.state;

    return (
      <div className="App">
        <ToastContainer limit={1}></ToastContainer>
        <div className="content-page">
          <Switch>
            <Route
              path="/login"
              render={(props) => {
                return (
                  <Login
                    {...props}
                    props={this.state}
                    passwordShown={passwordShown}
                    setPasswordShownValue={this.setPasswordShownValue}
                    currentContestStage={currentContestStage}
                    inputChange={this.inputChange}
                    loginService={this.loginService}
                    user={user}
                    errors={errors}
                    blockedLogin={blockedLogin}
                    isFase1Open={isFase1Open}
                  />
                );
              }}
            />
            <Route
              path="/recover-password"
              render={(props) => {
                return (
                  <ForgottPassword
                    {...props}
                    passwordForgotinputChange={this.passwordForgotsetIdeaList}
                    forgotPasswordFunction={this.forgotPasswordFunction}
                    forgotPwdForm={forgotPwdForm}
                    blockedLogin={blockedLogin}
                  />
                );
              }}
            />
            <Route
              path="/register"
              render={(props) => {
                return (
                  <CreateAccount
                    {...props}
                    recaptchaLoaded={this.recaptchaLoaded}
                    recaptchaExpired={this.recaptchaExpired}
                    confirmTemrsAndCondition={this.confirmTemrsAndCondition}
                    verifyUser={this.verifyUser}
                    createAccountFunction={this.createAccountFunction}
                    createAccountinputChange={this.createAccountinputChange}
                    createAccountForm={createAccountForm}
                    blockedLogin={blockedLogin}
                    setPasswordShownValue={this.setPasswordShownValue}
                    passwordShown={passwordShown}
                  />
                );
              }}
            />
            <ProtectedRoute
              path="/basic-data"
              level={['participant']} // Authorized user
              component={BasicData}
              blockedLogin={blockedLogin}
              basicData={basicData}
              increaseBasicDataStep={this.increaseBasicDataStep}
              decreaseBasicDataStep={this.decreaseBasicDataStep}
              basicDataStep={basicDataStep}
              basicDataInputChange={this.basicDataInputChange}
              handleActDateChange={this.handleActDateChange}
              states={states} //States options for select dropdown
              economicSectors={economicSectors}
              educationalInstitution={educationalInstitution} // Educational Institutions options for select dropdown
              educationalLevels={educationalLevels} // Educational Levels options for select dropdown
              updateBasicDataFunction={this.updateBasicDataFunction}
              userSession={userSession}
              logoutService={this.logoutService}
            />
            <ProtectedRoute
              path="/home"
              level={[
                'participant',
                'admin',
                'judge',
                'judge_leader',
                'judge_special',
              ]} // Authorized user
              component={Home}
              sidebarShow={sidebarShow}
              handleSideBarShow={this.handleSideBarShow}
              blockedLogin={blockedLogin}
              userSession={userSession}
              basicData={basicData}
              ideaList={ideaList}
              currentContest={currentContest}
              handleIdeaListPageChange={this.handleIdeaListPageChange}
              deleteIdea={this.deleteIdeaFunction}
              changeIdeaStatus={this.changeIdeaStatus}
              currentYearIdeas={currentYearIdeas}
              currentYearDocuments={currentYearDocuments}
              handleDragAndDropFiles={this.handleDragAndDropFiles}
              handleBusinessDragAndDropFiles={this.handleBusinessDragAndDropFiles}
              handleONGDragAndDropFiles={this.handleONGDragAndDropFiles}
              handleDragAndDropSummaryFiles={this.handleDragAndDropSummaryFiles}
              handleDragAndDropMembersFiles={this.handleDragAndDropMembersFiles}
              handleDragAndDropFinalPresentationFiles={this.handleDragAndDropFinalPresentationFiles}
              handleDragAndDropFilesEvaluationCriterias={this.handleDragAndDropFilesEvaluationCriterias}
              evaluationcriteria={evaluationcriteria}
              files={files}
              selectCriteriaDocument={this.selectCriteriaDocument}
              handleAwardOnChange={this.handleAwardOnChange}
              logoutService={this.logoutService}
              ideaForEdition={ideaForEdition}
              selectIdea={this.selectIdea}
              removeDocumentsfromList={this.removeDocumentsfromList}
              openBase64File={this.openBase64File}
              addMemberService={this.addMemberService}
              deleteMemberService={this.deleteMemberService}
              validateEmailFormat={this.validateEmailFormat}
              validatePhoneNumberFormat={this.validatePhoneNumberFormat}
              validateNumericFormat={this.validateNumericFormat}
              validateIfLegal={this.validateIfLegal}
              blockedLogin={blockedLogin}
              stagesDropdowns={stagesDropdowns}
              handleStageDropDown={this.handleStageDropDown}
              addFinalPresentationUrlService={this.addFinalPresentationUrlService}
              selectAwardService={this.selectAwardService}
              getDocumentsByIdeaIdHelper={this.getDocumentsByIdeaIdHelper}
              // Change Password methods
              passwordForminputChange={this.passwordForminputChange}
              setPasswordShownValue={this.setPasswordShownValue}
              updatePasswordFunction={this.updatePasswordFunction}
              passwordUpdateForm={passwordUpdateForm}
              passwordShown={passwordShown}
              // Admin Methods
              currentContestStage={currentContestStage}
              adminIdeaList={adminIdeaList}
              ideasParticipant={ideasParticipant}
              ideasRegistered={ideasRegistered}
              ideasFinalist={ideasFinalist}
              ideasSemifinalist={ideasSemifinalist}
              ideasWinner={ideasWinner}
              usersParticipant={usersParticipant}
              documentActives={documentActives}
              documentActivesPresentation={documentActivesPresentation}
              handleAdminIdeaListPageChange={this.handleAdminIdeaListPageChange}
              handleAdminRowsPerPage={this.handleAdminRowsPerPage}
              AdvaceFilterinputChange={this.AdvaceFilterinputChange}
              getAdminIdeaListFunction={this.getAdminIdeaListFunction}
              clearAdvanceFilter={this.clearAdvanceFilter}
              handleAdvanceFilter={this.handleAdvanceFilter}
              cancelAdvanceSearch={this.cancelAdvanceSearch}
              makeAdvanceSearch={this.makeAdvanceSearch}
              advanceFilerInputChange={this.advanceFilerInputChange}
              updateStageFunction={this.updateStageFunction}
              handleStageChange={this.handleStageChange}
              closeContestFunction={this.closeContestFunction}
              updateAdminDashboard={this.updateAdminDashboard}
              getCurrentContestStage={this.getCurrentContestStage}
              getDocumentsByCriteriaPrincipal={this.getDocumentsByCriteriaPrincipal}

            />
            <ProtectedRoute
              path="/create-idea"
              level={['participant']} // Tipos de usuario autorizados a esta ruta
              component={CreateIdea}
              blockedLogin={blockedLogin}
              createIdea={createIdea}
              userSession={userSession}
              increaseCreateIdeaStep={this.increaseCreateIdeaStep}
              decreaseCreateIdeaStep={this.decreaseCreateIdeaStep}
              createIdeaStep={createIdeaStep}
              createIdeaInputChange={this.createIdeaInputChange}
              proposalType={proposalType}
              socialType={socialType}
              ocupation={ocupation}
              sectors={sectors}
              currentContest={currentContest}
              statesList={statesList}
              confirmCreateIdeaStep={this.confirmCreateIdeaStep}
              userSession={userSession}
              logoutService={this.logoutService}
              cancelIdeaCreation={this.cancelIdeaCreation}
            />

            {/* Ruta por defecto redirecciona si la ruta es / */}
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            {/* Default route if the route does not exist */}
            <Route
              render={(props) => {
                return (
                  <Route>
                    <Redirect props={props} to="/login" />
                  </Route>
                );
              }}
            />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}
export default App;
