import http from './httpServices';

export async function getCompanies() {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/empresas`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function getBusiness(params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/business?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createBusiness(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/business`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteCompany(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/business/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.delete(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getCompanyById(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/business/` + id;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function editCompany(data, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/business/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
