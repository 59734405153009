import React from "react";
import check from "../../../images/green_check.svg";
import badRed from "../../../images/red_no.svg";
// import badGrey from "../../../images/grey_no.svg";

export const ConfirmedElement = (props) => {
  const { checked } = props;
  if (checked === true) {
    return (
      <span className="vertical-timeline-element-icon bounce-in">
        <img src={check} alt="" />{" "}
      </span>
    );
  } else {
    return (
      <span className="vertical-timeline-element-icon bounce-in">
        <img src={badRed} alt="" />{" "}
      </span>
    );
  }
};
