import React, { Component } from "react";
import "./ErrorAlert.scss";

class ErrorAlert extends Component {
  error() {
    const { error } = this.props;
    if (!error) {
      return <div className="form-error-ok"></div>;
    } else {
      return <div className="form-error-alert">{error}</div>;
    }
  }
  render() {
    return <React.Fragment>{this.error()}</React.Fragment>;
  }
}

export default ErrorAlert;
