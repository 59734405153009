/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Button as ActionButton } from '../../../common/Buttons/Buttons';
import {
  ContestSelectInput,
  ContestInput,
} from '../../../common/ContestForms/ContestForms';
import { translateIdeaType } from '../../../../helpers/translate';
import './AssignJudgeModal.scss';

export const JudgeComponent = (props) => {
  const { judge, selectJudge } = props;
  return (
    <h6 onClick={() => selectJudge(judge)}>
      {judge[12]} {judge[11]}
    </h6>
  );
};

export const AssignJudgeModal = (props) => {
  const {
    show,
    selectedEval,
    judgesForIdea,
    selectJudge,
    selectedJudge,
    closeModal,
    assignJudge,
    handleUserSearchChange,
    searchJudge,
    searchJudgesFunction,
    asignedEvals,
    typesCanEval,
  } = props;

  const returnIdeaTypes = (typesCanEval) => {
    if (typesCanEval) {
      return typesCanEval?.types?.map((t) => translateIdeaType(t) + ',  ');
    } else return null;
  };

  return (
    <Modal size="xl" centered show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>Asignar Jurado Evaluador</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '300px' }}>
        <h5>{selectedEval?.judgeIdea?.idea?.title}</h5>

        <div
          style={{ padding: '0px', marginTop: '4px' }}
          className="contest-input-flex search-judge-input"
        >
          <div id="judge-name-id" className="evaluation-filter-container">
            <ContestInput
              handleChange={handleUserSearchChange}
              name="search"
              label="Nombre del Jurado o Identificación"
              value={searchJudge}
              errors={''}
              type={'text'}
              placeholder={''}
            />

            <ActionButton
              text={'Buscar'}
              action={() => searchJudgesFunction()}
            />
          </div>
        </div>

        {judgesForIdea.length > 0 ? (
          selectedJudge ? null : (
            <div className="judges-list-container">
              {judgesForIdea?.map((j) => (
                <JudgeComponent judge={j} selectJudge={selectJudge} />
              ))}
            </div>
          )
        ) : (
          <h4>No hay jurados disponibles</h4>
        )}
        {selectedJudge ? (
          <div className="row justify-content-md-center row-confirm judge-idea-info-margin-container">
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Nombre y Apellido</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>
                {selectedJudge[12]} {selectedJudge[11]}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Fase que Evalua</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{selectedEval?.judgeIdea?.idea?.stage?.stage}</p>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Email</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{selectedJudge[6]}</p>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Tipo de Idea</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{returnIdeaTypes(typesCanEval)}</p>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Cédula de Identidad</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{selectedJudge[10]}</p>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6># Ideas Asignadas</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{asignedEvals.length > 0 ? asignedEvals[0][5] : null}</p>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Teléfono</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{selectedJudge[14]}</p>
            </div>{' '}
            <div className="col-12 col-sm-12 col-lg-3 gray-data-label judge-idea-info-margin">
              <h6>Estatus</h6>
            </div>
            <div className="col-12 col-sm-12 col-lg-3 judge-idea-info-margin">
              <p>{selectedJudge[26] ? 'Activo' : 'Inactivo'}</p>
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="acknowledge-accept-container"></div>
        <br />
        <div className="judge-modal-buttons-container">
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button
            disabled={!selectedJudge}
            variant="primary"
            onClick={() => assignJudge()}
          >
            Asignar Jurado
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
