import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../../services/userListService.js';
import { getCompanies } from '../../../services/companyService';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  ContestSelectInput,
  ContestInput,
} from '../../common/ContestForms/ContestForms';
import { UsersListTable } from '../../common/Tables/Tables';
import { CreateUserModal } from './CreateUserModal/CreateUserModal';
import { regularExpression } from '../../../helpers/regex.js';
import { Switch, Route, useHistory } from 'react-router-dom';
import {
  createNewUser,
  editUser,
  deleteUser,
} from '../../../services/userListService.js';
import { DeleteUserModal } from './DeleteUserModal/DeleteUserModal';
import { DeactivateUserModal } from './DeactivateUserModal/DeactivateUserModal';
import { UserDetail } from './UserDetail/UserDetail';
import { toast } from 'react-toastify';
import './UserManagement.scss';

export const UserManagement = () => {
  const [userList, setUserList] = useState({ number: 0, content: [] });
  const [isLoading, setIsloading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [user, setUser] = useState('');
  const [userToDelete, setUserToDelete] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [canPost, setCanPost] = useState(true);
  const [filter, setFilter] = useState({ rol: '', estatus: '', empresa: '' });
  const [companyList, setCompanyList] = useState([]);
  const [canDelete, setCanDelete] = useState(true);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const history = useHistory();

  const rolesOptions = [
    { id: '0', name: 'Administrador' },
    { id: '1', name: 'Participante' },
    { id: '2', name: 'Jurado' },
    { id: '3', name: 'Jurado Líder' },
    { id: '4', name: 'Jurado Especial' },
  ];

  const statusOption = [
    { id: true, name: 'Activo' },
    { id: false, name: 'Inactivo' },
  ];

  const handleOpenCreateCriteriaModal = () => {
    setOpenCreateUserModal(true);
    setUser('');
    clearValidationErrors();
    setModalType('create');
  };

  const handleCloseCreateUserModal = () => {
    setOpenCreateUserModal(false);
    clearValidationErrors();
    setUser('');
    setModalType('');
  };

  const clearValidationErrors = () => {
    setUser({
      ...user,
      errors: {
        name: '',
        lastName: '',
        email: '',
      },
    });
  };

  const openEditModal = (selectedUser) => {
    setUser({
      id: selectedUser?.id,
      name: selectedUser?.name,
      lastName: selectedUser?.lastName,
      email: selectedUser?.email,
      userType: selectedUser?.userType,
      errors: {
        name: '',
        lastName: '',
        email: '',
      },
    });

    setOpenCreateUserModal(true);
    setModalType('edit');
  };

  const validateUser = () => {
    let nameError = '';
    let lastNameError = '';
    let emailError = '';
    let canPost = true;

    if (regularExpression.noSpecialCharacters.test(String(user.name))) {
      nameError = 'El nombre no puede contener caracteres especiales';
      canPost = false;
    }
    if (regularExpression.noSpecialCharacters.test(String(user.lastName))) {
      lastNameError = 'El apellido no puede contener caracteres especiales';
      canPost = false;
    }

    if (!regularExpression.emailFormat.test(String(user.email))) {
      emailError = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    setUser({
      ...user,
      errors: {
        name: nameError,
        lastName: lastNameError,
        email: emailError,
      },
    });

    return canPost;
  };

  const createEditUser = () => {
    // Validation
    let canPost = validateUser();
    if (canPost) {
      let data = {
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        userType: user.userType,
      };

      if (modalType === 'create') {
        createUserFunction(data);
      }
      if (modalType === 'edit') {
        editUserFunction(data);
      }
    }
  };

  const createUserFunction = (data) => {
    if (canPost) {
      setCanPost(false);
      createNewUser(data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Usuario Creado');
            getAllUsersFunction();
            handleCloseCreateUserModal();
            setCanPost(true);
            history.push(
              `/home/admin/user-management/${response.data.id}/general-info`
            );
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          setCanPost(true);
          console.log('error:', error);
          toast.error('Error creando usuario');
        });
    }
  };

  const editUserFunction = (data) => {
    if (canPost) {
      setCanPost(false);
      editUser(data, data.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Usuario Editado');
            getAllUsersFunction();
            handleCloseCreateUserModal();
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error creando usuario');
          setCanPost(true);
        });
    }
  };

  const handleUserChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUser({ ...user, [name]: value });
  };

  const options = [
    { id: 1, name: 'Grupo 1' },
    { id: 2, name: 'Grupo 2' },
    { id: 3, name: 'Grupo 3 ' },
  ];

  const deleteUserFunction = () => {
    if (canDelete) {
      setCanDelete(false);
      deleteUser(userToDelete?.id)
        .then((response) => {
          if (response?.status === 200) {
            toast.success('Usuario eliminado');
            getAllUsersFunction();
            handleCloseDeleteModal();
            setCanDelete(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error al eliminar usuario');
          setCanDelete(true);
        });
    }
  };

  const getAllUsersFunction = () => {
    let params = {
      page: userList.number,
      size: 10,
      rol: filter.rol,
      filtro: filter.filtro,
      estatus: filter.estatus,
      group: filter.group,
      empresa: filter.empresa ? filter.empresa.toString() : filter.empresa,
    };

    setIsloading(true);
    getAllUsers(params)
      .then((response) => {
        if (response.status === 200) {
          setUserList(response.data);
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete('');
    setIsDeleteModalOpen(false);
  };

  const handlePageChange = (event, value) => {
    setUserList({ ...userList, number: value - 1 }); // -1 To remove page counter offset
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;
    setFilter({ ...filter, [name]: value });
    setUserList({ ...userList, number: 0 });
  };

  const getCompanyList = () => {
    getCompanies()
      .then((response) => {
        if (response.status === 200) {
          let companies = [];
          for (let i = 0; i < response.data.length; i++) {
            companies.push({
              id: response.data[i]?.id,
              name: response.data[i]?.businessName,
            });
          }
          setCompanyList(companies);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const disbableUser = (user) => {
    setIsDeactivateModalOpen(true);
    setUser(user);
  };

  const disableUser = () => {
    let usuarioEdit = user;
    usuarioEdit.active = !usuarioEdit.active;
    editUserFunction(usuarioEdit);
    handleCloseActivateModal();
  };

  const handleCloseActivateModal = () => {
    setUser('');
    setIsDeactivateModalOpen(false);
  };

  useEffect(() => {
    getAllUsersFunction();
    getCompanyList();
  }, [
    userList.number,
    filter.rol,
    filter.estatus,
    filter.empresa,
    filter.group,
  ]);

  return (
    <>
      <Switch>
        <Route
          path="/home/admin/user-management/:id"
          render={(props) => {
            return <UserDetail {...props} />;
          }}
        />
        <Route
          path="/home/admin/user-management"
          render={(props) => {
            return (
              <div {...props}>
                <DeactivateUserModal
                  isOpen={isDeactivateModalOpen}
                  data={user}
                  closeDeleteModal={handleCloseActivateModal}
                  action={disableUser}
                />
                <DeleteUserModal
                  isOpen={isDeleteModalOpen}
                  data={userToDelete}
                  closeDeleteModal={handleCloseDeleteModal}
                  action={deleteUserFunction}
                />
                <CreateUserModal
                  isOpen={openCreateUserModal}
                  // contestList={contestList}
                  closeDeleteModal={handleCloseCreateUserModal}
                  // stagesOptions={stagesOptions}
                  action={createEditUser}
                  user={user}
                  handleChange={handleUserChange}
                  modalType={modalType}
                />

                <div className="dashboard-title-container">
                  <h1>GESTIÓN DE USUARIOS</h1>
                  <div style={{ width: '300px' }}>
                    <ActionButton
                      action={() => handleOpenCreateCriteriaModal()}
                      text="Agregar Usuario"
                    />
                  </div>
                </div>
                <div className="contest-input-flex col-12 col-md-6">
                  <div className="user-filter-container">
                    <ContestInput
                      handleChange={onChangeFilter}
                      name="filtro"
                      label="Nombre o Email"
                      value={filter?.filtro}
                      errors={''}
                      type={'text'}
                      placeholder={''}
                    />

                    <ActionButton
                      text={'Buscar'}
                      action={() => getAllUsersFunction()}
                    />
                  </div>
                </div>

                <div className="users-list-row">
                  <div className="users-dropdown-container">
                    <ContestSelectInput
                      options={rolesOptions}
                      handleChange={onChangeFilter}
                      name="rol"
                      label="Rol"
                      value={filter.rol}
                      errors={''}
                      type={'text'}
                      placeholder={''}
                    />
                  </div>
                  <div className="users-dropdown-container">
                    <ContestSelectInput
                      options={statusOption}
                      handleChange={onChangeFilter}
                      name="estatus"
                      label="Estatus"
                      value={filter.estatus}
                      errors={''}
                      type={'text'}
                      placeholder={''}
                    />
                  </div>
                  <div className="users-dropdown-container">
                    <ContestSelectInput
                      options={companyList}
                      handleChange={onChangeFilter}
                      name="empresa"
                      label="Empresa"
                      value={filter.empresa}
                      errors={''}
                      type={'text'}
                      placeholder={''}
                    />
                  </div>
                  <div className="users-dropdown-container">
                    <ContestSelectInput
                      options={options}
                      handleChange={onChangeFilter}
                      name="group"
                      label="Grupo"
                      value={filter.group}
                      errors={''}
                      type={'text'}
                      placeholder={''}
                    />
                  </div>
                </div>
                <UsersListTable
                  data={userList}
                  isLoading={isLoading}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                  disbableUser={disbableUser}
                  openEditModal={openEditModal}
                  handlePageChange={handlePageChange}
                />
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
};
