import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import dropFile from '../../../images/dropFile.svg';
import './DropZone.scss';

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const StyledDropzone = (props) => {
  const {
    acceptedFiles,
    handleDragAndDropFiles,
    maxFilesPermited,
    memberId,
    maxWeight,
  } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedFiles ? acceptedFiles : '.pdf',
    onChange: (acceptedFiles, fileRejections) =>
      handleDragAndDropFiles(
        acceptedFiles,
        fileRejections,
        maxFilesPermited,
        memberId
      ),
    onDrop: (acceptedFiles, fileRejections) =>
      handleDragAndDropFiles(
        acceptedFiles,
        fileRejections,
        maxFilesPermited,
        memberId
      ),
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : { borderColor: '#0098da' }),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="dropzone-container container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div>
          {/* <img src={folder} alt="" /> */}
          <img src={dropFile} alt="" />
          <h6>
            Arrastra tus documentos aquí para agregar o sustituir el archivo
            <br />
            <b>(peso máximo {maxWeight ? maxWeight : '25'}MB)</b>
          </h6>
        </div>
      </div>
    </div>
  );
};

export const ImageDropzone = (props) => {
  const {
    acceptedFiles,
    handleDragAndDropFiles,
    maxFilesPermited,
    memberId,
    maxWeight,
    label,
  } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedFiles ? acceptedFiles : '.pdf',
    onChange: (acceptedFiles, fileRejections) =>
      handleDragAndDropFiles(
        acceptedFiles,
        fileRejections,
        maxFilesPermited,
        memberId
      ),
    onDrop: (acceptedFiles, fileRejections) =>
      handleDragAndDropFiles(
        acceptedFiles,
        fileRejections,
        maxFilesPermited,
        memberId
      ),
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : { borderColor: '#0098da' }),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="dropzone-container container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div>
          {/* <img src={folder} alt="" /> */}
          <img src={dropFile} alt="" />
          <h6>
            {label}
            <br />
            <b>(peso máximo {maxWeight ? maxWeight : '25'}MB)</b>
          </h6>
        </div>
      </div>
    </div>
  );
};
