import React, { useState, useEffect } from 'react';
import Header from '../../common/Header/Header';
import { CreateAccountForm } from '../../common/Forms/Forms';
import { CreateAccountButton } from '../../common/Buttons/Buttons';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import Recaptcha from 'react-recaptcha';
import { toast } from 'react-toastify';
import './CreateAccount.scss';
import { getContestLogo } from '../../../services/contestService.js';
import ErrorAlert from '../../common/ErrorAlert/ErrorAlert';

export const CreateAccount = (props) => {
  const {
    confirmTemrsAndCondition,
    verifyUser,
    recaptchaLoaded,
    recaptchaExpired,
    createAccountFunction,
    createAccountinputChange,
    createAccountForm,
    blockedLogin,
    setPasswordShownValue,
    passwordShown,
    history,
  } = props;

  const [contestBackground, setContestBackground] = useState('');

  const getContestBackgroundFunction = () => {
    getContestLogo('BackgroundCreateAccount')
      .then((response) => {
        if (response.status === 200) {
          setContestBackground(response.data.fileUrl);
        }
      })
      .catch(() => {
        toast.error('Error al obtener fondo del concurso');
      });
  };

  useEffect(() => {
    getContestBackgroundFunction();
  }, []);

  return (
    <React.Fragment>
      <LoadSpinner
        blocked={blockedLogin}
        text="Espere mientras procesamos su solicitud"
        color="#000000"
      />

      <Header />
      <div className="create-account-container row">
        <div className="col-12 col-md-6">
          <h1 className="title">Crea una Cuenta</h1>
          <h6 id="create-account-message">
            Sólo un miembro del equipo necesita crear una cuenta para poder
            aplicar al concurso. <br /> Por favor completa cada campo del
            formulario para crear tu cuenta.
          </h6>
          <CreateAccountForm
            createAccountinputChange={createAccountinputChange}
            passwordShown={passwordShown}
            setPasswordShownValue={setPasswordShownValue}
            createAccountForm={createAccountForm}
          />

          <div className="terms-and-conditions-button-container">
            <input
              type="radio"
              name="termsAndConditions"
              value={createAccountForm.data.termsAndConditions}
              checked={createAccountForm.data.termsAndConditions}
              onChange={confirmTemrsAndCondition}
            ></input>

            <h6>
              He leído y acepto los{' '}
              <a
                target="_blank"
                href="https://ideas-s3-public.s3.amazonaws.com/files/Te%CC%81rminos+y+Condiciones+2021.pdf"
              >
                términos y condiciones
              </a>
            </h6>
          </div>

          {createAccountForm.data.termsAndConditions === true ? (
            <Recaptcha
              sitekey={process.env.REACT_APP_API_RE_CAPTCHA_SITE_KEY}
              // render="explicit"
              onloadCallback={recaptchaLoaded}
              verifyCallback={verifyUser}
              expiredCallback={recaptchaExpired}
            />
          ) : null}
          <ErrorAlert error={createAccountForm.errors.captcha} />
          <CreateAccountButton
            createAccountFunction={createAccountFunction}
            createAccountForm={createAccountForm}
            history={history}
          />
        </div>

        <div id="account-sidebar" className="side-img-disappear col-12 col-md-6 no-padding">
        {contestBackground && (
          <img
            className="login-dashboard-background"
            src={contestBackground}
            alt=""
          />
        )}{' '}

        </div>
      </div>
    </React.Fragment>
  );
};
