import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import './Distribution.scss';

const UserDetailsTab = (props) => {
  const { tab } = props;
  let pathArray = window.location.pathname.split('/');

  let selected = 'gray';
  let barColor = '#f2f2f2';
  if (pathArray[4] === tab.url) {
    selected = '#30377b';
    barColor = '#30377b';
  }

  return (
    <div className="users-detail-tab-component">
      <NavLink to={`/home/admin/idea-distribution/${tab.url}`}>
        <h6 style={{ color: selected }}>{tab.label}</h6>
      </NavLink>
      <div style={{ backgroundColor: barColor }}></div>
    </div>
  );
};

export const Distribution = () => {
  const tabs = [
    { label: 'Información General', url: 'general-info' },
    // { label: 'Histórico de Procesos', url: 'process-history' },
  ];

  return (
    <>
      <div className="dashboard-title-container">
        <h1>PROCESO DE DISTRIBUCIÓN DE IDEAS</h1>
      </div>
      <div className="user-details-tabs-container">
        {tabs.map((t) => (
          <UserDetailsTab key={t.label} tab={t} />
        ))}
      </div>
      <br />
      <Switch>
        <Route
          path="/home/admin/idea-distribution/general-info"
          render={(props) => {
            return <GeneralInfo {...props} />;
          }}
        />
      </Switch>
    </>
  );
};
