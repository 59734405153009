/* eslint-disable */
import React, { useState, useEffect } from 'react';

import './Evaluations.scss';

export const Evaluations = () => {
  return (
    <div>
      <h1>Evaluations</h1>
    </div>
  );
};
