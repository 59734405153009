import React, { useEffect, useState } from "react";
import { getContests } from "../../../../services/contestService";
import { ContestSelectInput } from "../../../common/ContestForms/ContestForms";
import { toast } from "react-toastify";
import { ActionButton } from "../../../common/Buttons/Buttons";
import { CriteriaTable } from "../../../common/Tables/Tables";
import { DeleteCriteriaModal } from "./DeleteCriteriaModal/DeleteCriteriaModal";
import { CreateCriteriaModal } from "./CreateCriteriaModal/CreateCriteriaModal";
import {
  getEvaluationCriteria,
  deleteCriteria,
  createCriteria,
  editCriteria,
  createCriteriaList,
  getAllEvaluationCriteriaFiltered
} from "../../../../services/evaluationCriteriaService.js";
import { getStageByContest } from "../../../../services/contestService";
import { regularExpression } from "../../../../helpers/regex.js";
import { MarketingDropdown } from "../Marketing/Marketing.jsx";
import "./EvaluationCriteria.scss";
import { DownloadedRemovableFilesList } from "../../../common/DocElements/DocElements";
import { StyledDropzone } from "../../../common/DropZone/DropZone.jsx";
import Loader from "react-loader-spinner";
import CopyCriteriaModal from "../EvaluationCriteria/CopyCriteriaModel/CopyCriteriaModal.jsx"
import { Modal, Button } from 'react-bootstrap';

export const EvaluationCriteria = (prop) => {
  const {
    evaluationcriteria,
    handleDragAndDropFilesEvaluationCriterias,
    removeDocumentsfromList,
    openBase64File,
    selectCriteriaDocument
  } = prop;

  const [contestList, setContestList] = useState([]);
  const [criteriaList, setCriteriaList] = useState({ number: 0 });
  const [criteriaListCopy,setCriteriaListCopy]= useState([]);
  const [stagesOptions, setStagesOptions] = useState([
    { id: "-1", name: "Fase 1" },
    { id: "-2", name: "Fase 2" },
    { id: "-3", name: "Fase 3" },
  ]);
  const [stagesCopyOptions,setStageCopyOptions] = useState([
    { id: "-1", name: "Fase 1" },
    { id: "-2", name: "Fase 2" },
    { id: "-3", name: "Fase 3" },
  ]);
  const [selectedContest, setSelectedContest] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedIdeaType, setSelectedIdeaType] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [totalPercentage, setTotalPercentage] = useState("");
  const [openCreateCriteriaModal, setOpenCreateCriteriaModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [criteria, setCriteria] = useState();
  const [criteriaToCopy,setCriteriaToCopy] = useState({
    contest: '',
    stage: '',
    types: '',
  });
  const [backgroundOpen, setBackgroundOpen] = useState(false);
  //copy modeal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [criteriaToSave, setCriteriaToSave] = useState([]);
  const [modalMessage, setModalMessage] = useState("");


  const showCriteriaSelected = (contestid,faseid,typeIdea) => {
    setBackgroundOpen(prevState => !prevState)
    selectCriteriaDocument(contestid,faseid,typeIdea)
  };
  // criteria modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);

  initcopyvars();
  setCriteriaListCopy([]); 
  setModalMessage(""); 
  };

  const openConfirm = () => {

     const selectedContestName = contestList.find(contest => contest.id === Number(criteriaToCopy?.contest))?.name || "concurso no seleccionado";
     const selectedStageName = stagesCopyOptions.find(stage => stage.id === Number(criteriaToCopy?.stage))?.name || "fase no seleccionada";
     const selectedIdeaTypeName = ideaTypes.find(type => type.id === criteriaToCopy?.types)?.name || "tipo de idea no seleccionado";

     const message = `¿Estás seguro de que deseas copiar los criterios del concurso "${selectedContestName}", fase "${selectedStageName}" y tipo de idea "${selectedIdeaTypeName}" 
     al concurso actual?`;

    setModalMessage(message);
    setConfirmIsOpen(true);
  };

  const closeConfirm = () => {
    setConfirmIsOpen(false);
    initcopyvars(); // Resetea los valores de criteriaToCopy
    setCriteriaListCopy([]); // Limpia la lista de criterios copiados
    setModalMessage(""); // Resetea el mensaje de la modal de confirmación
  };

  function cleanEvaluationCriteriaList(criteriaList) {
    return criteriaList.map(criteria => {
        // Create a shallow copy to avoid mutating the original object
        let cleanedCriteria = { ...criteria };

        // If contest is present, remove the stages property
        if (cleanedCriteria.contest) {
            cleanedCriteria.contest = { ...cleanedCriteria.contest };
            delete cleanedCriteria.contest.stages;
        }

        return cleanedCriteria;
    });
}

  const handleSave = (criteria) => {
    const cleanedList = cleanEvaluationCriteriaList(criteria);
    setCriteriaToSave(cleanedList);
    closeModal();
    openConfirm();
  };

  useEffect(() => {
    // Este código se ejecutará después de que `myState` haya sido actualizado
    console.log('State updated:', criteriaToSave);
    // Aquí puedes usar `myState` de manera segura
  }, [criteriaToSave]); // El efecto se ejecutará cada vez que `myState` cambie

  const confirmSave = () => {
    closeConfirm();
    createcriteriasListFunction(criteriaToSave);
  };

  //criteria modal
  // Create Criteria Modal
  const handleOpenCreateCriteriaModal = () => {
    setOpenCreateCriteriaModal(true);
    setCriteria({ contest: selectedContest });
    clearValidationErrors();
    setModalType("create");
  };

  const clearValidationErrors = () => {
    setCriteria({
      contest: selectedContest,
      errors: {
        description: "",
        percentage: "",
        criteriaName: "",
      },
    });
  };

  const handleCloseCreateCriteriaModal = () => {
    setOpenCreateCriteriaModal(false);
    clearValidationErrors();
    setCriteria("");
    setModalType("");
  };

  const validateCriteria = () => {
    let descriptionError = "";
    let percentageError = "";
    let criteriaNameError = "";
    let canPost = true;

    if (criteria.description.length > 300) {
      descriptionError = "La descripción no puede superar los 300 caracteres";
      canPost = false;
    }

    if (criteria.criteriaName.length > 70) {
      criteriaNameError =
        "El nombre del criterio no puede superar los 70 caracteres";
      canPost = false;
    }

    if (
      regularExpression.noSpecialCharacters.test(String(criteria.criteriaName))
    ) {
      criteriaNameError = "El criterio no puede contener caracteres especiales";
      canPost = false;
    }

    if (
      parseFloat(criteria.percentage) > 100 ||
      parseFloat(criteria.percentage) < 1
    ) {
      percentageError = "El peso del criterio debe estar entre 1 y 100 %";
      canPost = false;
    }

    setCriteria({
      ...criteria,
      errors: {
        description: descriptionError,
        percentage: percentageError,
        criteriaName: criteriaNameError,
      },
    });
    return canPost;
  };

  const openEditModal = (selectedCriteria) => {
    const tipos = [
      { value: 0, name: "BUSINESS" },
      { value: 1, name: "SOCIAL" },
      { value: 2, name: "DIGITAL" },
      { value: 3, name: "ONG" },
    ];

    let [seletectedType] = tipos.filter(
      (t) => t.name === selectedCriteria?.types
    );

    getStages(selectedCriteria?.contest?.id);

    setCriteria({
      id: selectedCriteria?.id,
      contest: selectedCriteria?.contest?.id,
      criteriaName: selectedCriteria?.criteriaName,
      description: selectedCriteria?.description,
      percentage: selectedCriteria?.percentage,
      stage: selectedCriteria?.stage?.id,
      types: seletectedType?.value.toString(),
      errors: {
        description: "",
        percentage: "",
        criteriaName: "",
      },
    });

    setOpenCreateCriteriaModal(true);
    setModalType("edit");
  };

  const createEditCriteria = () => {
    // Validation
    let canPost = validateCriteria();

    if (canPost) {
      let data = {
        contest: { id: parseInt(criteria?.contest) },
        stage: { id: parseInt(criteria?.stage) },
        types: parseInt(criteria.types),
        criteriaName: criteria.criteriaName,
        percentage: parseFloat(criteria.percentage),
        description: criteria.description,
      };

      if (modalType === "create") {
        createCriteriaFunction(data);
      }
      if (modalType === "edit") {
        editAwardFunction(data);
      }
    }
  };
  
  const createcriteriasListFunction = (data) =>{
    createCriteriaList(data)
    .then((response) => {
      if (response.status === 201) {
        toast.success("Criterio Creado");
        getEvaluationCriteriasFunction();

        closeModal();
      }
    })
    .catch((error) => {
      console.log("error:", error);
      toast.error("Error creando criterio");
    });


  }

  const createCriteriaFunction = (data) => {
    createCriteria(data)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Criterio Creado");
          getEvaluationCriteriasFunction();
          handleCloseCreateCriteriaModal();
        }
      })
      .catch((error) => {
        console.log("error:", error);
        toast.error("Error creando criterio");
      });
  };

  const editAwardFunction = (data) => {
    data.id = criteria.id;
    editCriteria(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Criterio Editado");
          getEvaluationCriteriasFunction();
          handleCloseCreateCriteriaModal();
        }
      })
      .catch((error) => {
        console.log("error:", error);
        toast.error("Error creando criterio");
      });
  };

  useEffect(() => {
    if (criteriaToCopy.contest && criteriaToCopy.stage) {
      getAllEvaluationCriteriasToBecopied();
    }
  }, [criteriaToCopy]);
  
  const handleCriteriaCopyChange = (e) => {
    const { value, name } = e.target;
  
    if (criteriaToCopy) {
      const newCriteriaToCopy = { ...criteriaToCopy, [name]: value };
  
      if (name === "contest") {
        getStagestobeCopied(value);
        newCriteriaToCopy.stage = ''; // Limpia el stage cuando cambie el concurso
      }
  
      setCriteriaToCopy(newCriteriaToCopy);
    } else {
      console.error("criteriaToCopy is undefined");
    }
  };

  const handleCriteriaChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "contest") {
      getStages(value);
      setCriteria({ ...criteria, ["stage"]: "" });
    }
    setCriteria({ ...criteria, [name]: value });
  };

  const deleteCriteriaFunction = () => {
      deleteCriteria(selectedCriteria?.id)
        .then((response) => {
          if (response?.status === 200) {
            toast.success("Criterio eliminado");
            getEvaluationCriteriasFunction();
            handleCloseDeleteModal();
           
          }
        })
        .catch((error) => {
          console.log("error:", error);
          toast.error("Error al eliminar criterio");
          
        });
  };

  const ideaTypes = [
    { id: "BUSINESS", name: "Negocio" },
    { id: "SOCIAL", name: "Social" },
    { id: "DIGITAL", name: "Digital" },
    { id: "ONG", name: "ONG" },
  ];

  const handleCloseDeleteModal = () => {
    setSelectedCriteria("");
    setIsDeleteModalOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCriteriaList({ ...criteriaList, number: value - 1 }); // -1 To remove page counter offset
    getEvaluationCriteria();
  };


  const getStages = (contestId) => {
    if (contestId) {
      getStageByContest(contestId, "1")
        .then((response) => {
          if (response?.status == 200) {
            let options = [];

            for (let i = 0; i < response.data.length; i++) {
              options.push({
                name: response.data[i].stage,
                id: response.data[i].id,
              });
            }
            response.data.forEach((element) => {
              element.name = response.data.stage;
            });

            setStagesOptions(options);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          toast.error("Error al obtener etapas");
        });
    }
  };

  const getStagestobeCopied = (contestIdCopy) => {
    if (contestIdCopy) {
      getStageByContest(contestIdCopy, "1")
        .then((response) => {
          if (response?.status == 200) {
            let options = [];

            for (let i = 0; i < response.data.length; i++) {
              options.push({
                name: response.data[i].stage,
                id: response.data[i].id,
              });
            }
            response.data.forEach((element) => {
              element.name = response.data.stage;
            });

            setStageCopyOptions(options);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          toast.error("Error al obtener etapas");
        });
    }
  };

  const handleOpenDeleteModal = (criteria) => {
    setSelectedCriteria(criteria);
    setIsDeleteModalOpen(true);
  };

  // Handle changes for filter search
  const onChangeSelectedContest = (event) => {
    const { value } = event.target;
    setCriteriaList({ ...criteriaList, number: 0 }); // -1 To remove page counter offset
    setSelectedContest(value);
    setSelectedStage("");
    getStages(value);
    // getEvaluationCriteriasFunction();
  };

  // Handle changes for filter search
  const onChangeSelectedStage = (event) => {
    const { value } = event.target;
    setSelectedStage(value);
    setCriteriaList({ ...criteriaList, number: 0 }); // -1 To remove page counter offset
  };

  // Handle changes for filter search
  const onChangeSelectedIdeaType = (event) => {
    const { value } = event.target;
    setCriteriaList({ ...criteriaList, number: 0 }); // -1 To remove page counter offset
    setSelectedIdeaType(value);
  };

  const getAllEvaluationCriteriasToBecopied = () => {

    let paramscopy = {
      contestId: criteriaToCopy.contest,
      stageId: criteriaToCopy.stage,
      evaluationType: criteriaToCopy.types,
    };

    setIsloading(true);
    getAllEvaluationCriteriaFiltered(paramscopy)
    .then((response) => {
      if (response?.status === 200) {
        setCriteriaListCopy(response.data);
        setIsloading(false);
      }
    })
    .catch((error) => {
      console.log("error:", error);
      setIsloading(false);
    });

  }

  const getEvaluationCriteriasFunction = () => {
    let params = {
      page: criteriaList.number,
      size: 10,
      contestId: selectedContest,
      stageId: selectedStage,
      evaluationType: selectedIdeaType,
    };


    setIsloading(true);
    getEvaluationCriteria(params)
      .then((response) => {
        if (response?.status === 200) {
          setCriteriaList(response.data);
          const total = response.data.content.reduce((accumulator, object) => {
            return accumulator + object.percentage;
          }, 0);

          if (selectedStage && selectedContest) {
            setTotalPercentage(total);
          }
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setIsloading(false);
      });
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response?.status == 200) {
        setContestList(response.data);
        // let [selectedContest] = response.data.filter((c) => c.isOpen);
        // if (selectedContest) {
        //   setSelectedContest(selectedContest?.id);
        //   getStages(selectedContest?.id);
        // } else {
        //   setSelectedContest(-1);
        // }
      } else {
        toast.error(`${response.message}` || "Error al obtener concursos");
      }
    });
  };

  const initcopyvars =()=>{
    setCriteriaToCopy({
      contest: '',
      stage: '',
      types: '',
    });
  }

  useEffect(() => {
    getContestFunction();
    initcopyvars();
    getEvaluationCriteriasFunction();
    setBackgroundOpen(false);
  }, [criteriaList.number, selectedContest, selectedStage, selectedIdeaType]);

  return (
    <div>
      <CreateCriteriaModal
        isOpen={openCreateCriteriaModal}
        contestList={contestList}
        closeDeleteModal={handleCloseCreateCriteriaModal}
        stagesOptions={stagesOptions}
        action={createEditCriteria}
        criteria={criteria}
        handleChange={handleCriteriaChange}
        modalType={modalType}
      />
    <div>
      <CopyCriteriaModal
        show={modalIsOpen}
        handleClose={closeModal}
        onSave={handleSave}
        contestList={contestList}
        handleChange={handleCriteriaCopyChange} //ccrear otro metodo 
        stagesOptions={stagesCopyOptions}
        criteria={criteriaToCopy}
        isLoading={isLoading}
        criteriaList={criteriaListCopy}
      />
      <Modal show={confirmIsOpen} onHide={closeConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirm}>
            No
          </Button>
          <Button variant="primary" onClick={confirmSave}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

      <div className="contest-list-container">
        <div className="contest-list-row">
          <div className="criteria-dropdown-container">
            <ContestSelectInput
              options={contestList}
              handleChange={onChangeSelectedContest}
              name="contestName"
              label="Concurso"
              value={selectedContest}
              errors={""}
              type={"text"}
              placeholder={""}
            />
          </div>
          <div className="criteria-dropdown-container">
            <ContestSelectInput
              options={stagesOptions}
              handleChange={onChangeSelectedStage}
              name="contestName"
              label="Fase"
              value={selectedStage}
              errors={""}
              type={"text"}
              placeholder={""}
            />
          </div>
          <div className="criteria-dropdown-container">
            <ContestSelectInput
              options={ideaTypes}
              handleChange={onChangeSelectedIdeaType}
              name="contestName"
              label="Tipo de Idea"
              value={selectedIdeaType}
              errors={""}
              type={"text"}
              placeholder={""}
            />
          </div>
          <div className="criteria-button-container">
            <ActionButton
              text="Crear Criterio"
              action={() => handleOpenCreateCriteriaModal()}
            />

          </div>
          {selectedContest?(
                      <div className="criteria-button-container">
                      <ActionButton
                        text="Copiar Criterios"
                        action={openModal}
                      />
          
                    </div>

          ):(null)}


        </div>
        <DeleteCriteriaModal
          isOpen={isDeleteModalOpen}
          data={selectedCriteria}
          closeDeleteModal={handleCloseDeleteModal}
          action={deleteCriteriaFunction}
        />
        {selectedContest && selectedStage && selectedIdeaType && (criteriaList?.numberOfElements > 0 )? (
          <>
            <MarketingDropdown
              label={"Cargar Documento criterios de evaluación"}
              isOpen={backgroundOpen}
              handleOpen={() => showCriteriaSelected(selectedContest,selectedStage,selectedIdeaType)}
            />
            {backgroundOpen && (
              <EvaluationDocumentDropzone
                evaluationcriteria={evaluationcriteria}
                handleDragAndDropFiles={
                  handleDragAndDropFilesEvaluationCriterias
                }
                removeDocumentsfromList={removeDocumentsfromList}
                openBase64File={openBase64File}
                evaluationFiles={evaluationcriteria.evaluationFiles}
                currentState={0}
                context={selectedContest}
                fase={selectedStage}
                type={selectedIdeaType}
              />
            )}
          </>
        ) : null}

        <CriteriaTable
          isLoading={isLoading}
          handleOpenDeleteModal={handleOpenDeleteModal}
          data={criteriaList}
          totalPercentage={totalPercentage}
          openEditModal={openEditModal}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export const EvaluationDocumentDropzone = (props) => {
  const {
    evaluationcriteria,
    handleDragAndDropFiles,
    evaluationFiles,
    removeDocumentsfromList,
    openBase64File,
    context,
    fase,
    type,
  } = props;

  let documentType = `${context}_${fase}_${type}`;
  evaluationcriteria.contestId = context;
  evaluationcriteria.faseId = fase;
  evaluationcriteria.typeIdea = type;

  let leaderFiles = evaluationcriteria.files.filter(
    (i) => i.type === documentType
  );
  let sentence = ` Aquí podrá subir el documento con los criterios de evaluación que será
                   usado por los jurados en las fases de evaluación y están clasificados para
                   la contienda actual y el tipo de ideas ${type} `;

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">


        <React.Fragment>
          <p>{sentence}</p>
        </React.Fragment>
      </div>
      <div className="vertical-timeline-element-content bounce-in">
        <DownloadedRemovableFilesList
          documents={leaderFiles}
          openBase64File={openBase64File}
        />
        {evaluationcriteria.evaluationFilesloading === true ? (
          <React.Fragment>
            <div className="loading-files-spinner-container">
              <div className="loading-files-spinner">
              <Loader
                type="Oval"
                color={"gray"}
                height={80}
                width={80}
                timeout={0} //3 secs
              />
              <h6>Subiendo archivo</h6>
             </div>
           </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <StyledDropzone
              handleDragAndDropFiles={handleDragAndDropFiles}
              files={evaluationFiles}
              maxFilesPermited={1}
            />

            <div className="accepted-files-conainer">
              {evaluationFiles.map((f, i) => (
                <AcceptedFiles
                  key={i}
                  index={i}
                  files={f}
                  removeDocumentsfromList={removeDocumentsfromList}
                />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
  // }
};
