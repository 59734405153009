export const documentType = {
  confidenciality: 'CONFIDENTIALITY',
  termsAndConditions: 'TERMS_AND_CONDITIONS',
};

export const rolesOptions = [
  // { id: 'participant', name: 'Participante' },
  { id: 'admin', name: 'Administrador' },
  { id: 'judge', name: 'Jurado' },
  // { id: 'judge_leader', name: 'Jurado Líder' },
  { id: 'judge_special', name: 'Jurado Especial' },
];
