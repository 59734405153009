import React from 'react';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import 'react-datepicker/dist/react-datepicker.css';
import './ContestForms.scss';
import 'date-fns';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import ReactTooltip from 'react-tooltip';

export const ConfirmInput = (props) => {
  const { label, value, desctiption } = props;

  let textarea = '';

  textarea = !desctiption ? '' : 'desctiption-confirm-data';

  return (
    <div className="confirm-input-container contest-input-main-container">
      <div className="contest-input-container">
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <div className={`${textarea} confirm-data`}>
          <h6>{value}</h6>
        </div>
      </div>
    </div>
  );
};

export const ContestInput = (props) => {
  const { label, errors, value, name, type, placeholder, handleChange } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <input
          onChange={handleChange}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
        />
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const RadioInput = (props) => {
  const { label, errors, handleChange, options, name, value } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container `}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <div id="radio-selected-container" className="contest-checks-container">
          {options.map((o) => (
            <div key={o.label}>
              <input
                type="radio"
                onChange={handleChange}
                checked={value == o.selected}
                value={o.selected}
                name={name}
              />
              <h6>{o.label}</h6>
            </div>
          ))}
        </div>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const CheckMarkInput = (props) => {
  const { label, errors, handleChange, options, value } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container `}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <div className="contest-checks-container">
          {options.map((o) => {
            return (
              <div key={o.label}>
                <input
                  onChange={handleChange}
                  type="checkbox"
                  value={o.selected}
                  name={o.label.toLowerCase()}
                  checked={value[o.label.toLowerCase()]}
                />
                <h6>{o.label}</h6>
              </div>
            );
          })}
        </div>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const ContestStartupInput = (props) => {
  const { label, errors, value, name, type, placeholder, handleChange } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div
        id="startup-input"
        className={`${errorStyle} + contest-input-container`}
      >
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <textarea
          onChange={handleChange}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
        />
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const ContestEvalSelectInput = (props) => {
  const {
    label,
    errors,
    value,
    name,
    placeholder,
    handleChange,
    options,
    criteria,
  } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <ReactTooltip
          id={`registerTip-${label}`}
          className="registerTip"
          place="top"
          effect="solid"
        >
          {criteria?.criteria?.description}
        </ReactTooltip>
        <div
          data-tip
          data-for={`registerTip-${label}`}
          className="gray-data-label"
        >
          <h6>{label}</h6>
        </div>
        <select onChange={handleChange} name={name} value={value}>
          <option className="option-place-holder" value="">
            {placeholder}
          </option>
          {options?.map((o, i) => (
            <option key={i} label={o.name} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const ContestSelectInput = (props) => {
  const { label, errors, value, name, placeholder, handleChange, options } =
    props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <select onChange={handleChange} name={name} value={value}>
          <option className="option-place-holder" value="">
            {placeholder}
          </option>
          {options?.map((o, i) => (
            <option key={i} label={o.name} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const ContestGenderInput = (props) => {
  const { label, errors, value, handleChange } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <div id="gender-select-container" className="gender-select-container">
          <input
            className="radio-input"
            onChange={handleChange}
            checked={value === '2'}
            type="radio"
            value="2"
            name="gender"
          />
          <h6>Masculino</h6>
          <input
            className="radio-input"
            onChange={handleChange}
            checked={value === '1'}
            type="radio"
            value="1"
            name="gender"
          />
          <h6>Femenino</h6>
        </div>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const EmploymentSituation = (props) => {
  const { handleChange, name, value } = props;

  return (
    <div className="contest-input-main-container">
      <div className="contest-input-container">
        <div className="employment-select-container">
          <div>
            <input
              className="radio-input"
              onChange={handleChange}
              checked={value === ''}
              type="radio"
              value=""
              name={name}
            />
            <h6>Sin empleo</h6>
          </div>
          <div>
            <input
              className="radio-input"
              onChange={handleChange}
              checked={value === '1'}
              type="radio"
              value="1"
              name={name}
            />
            <h6>Empleado</h6>
          </div>
          <div>
            <input
              className="radio-input"
              onChange={handleChange}
              checked={value === '2'}
              type="radio"
              value="2"
              name={name}
            />
            <h6>Independiente</h6>
          </div>
          <div>
            <input
              className="radio-input"
              onChange={handleChange}
              checked={value === '3'}
              type="radio"
              value="3"
              name={name}
            />
            <h6>Empleado con Emprendimiento</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ContestCedulaInput = (props) => {
  const {
    label,
    errors,
    nameIdType,
    handleChange,
    name_id,
    idType,
    placeholder,
    id,
  } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <div id="cedula-select-container" className="gender-select-container">
          <div>
            <input
              className="radio-input"
              onChange={handleChange}
              checked={idType === '1'}
              type="radio"
              value="1"
              name={nameIdType}
            />
            <h6>V</h6>
            <input
              className="radio-input"
              onChange={handleChange}
              checked={idType === '2'}
              type="radio"
              value="2"
              name={nameIdType}
            />
            <h6>E</h6>
          </div>
          <input
            onChange={handleChange}
            type={'number'}
            value={id}
            name={name_id}
            placeholder={placeholder}
          />
        </div>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

export const ContestAgeInput = (props) => {
  const { label, errors, handleChange, name, selected } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <div className="gray-data-label">
          <h6>{label}</h6>
        </div>
        <div className="birth-date-picker-container">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TextField
              name={name}
              id="date-picker-dialog"
              // label="Date picker dialog"
              type="date"
              placeholder="dd/mm/aaaa"
              format="dd/MM/yyyy"
              value={!selected ? new Date() : selected}
              maxDate={new Date()}
              onChange={handleChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};

// Advance filtet date input
export const AdvanceFilterDateInput = (props) => {
  const { errors, value, handleChange, name } = props;

  let errorStyle = '';

  errorStyle = errors !== '' ? 'error-contest-input' : '';

  return (
    <div id="advance-date-picker" className="contest-input-main-container">
      <div className={`${errorStyle} + contest-input-container`}>
        <div className="birth-date-picker-container">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TextField
              className="advance-text-field"
              id="date-picker-dialog"
              // label="Date picker dialog"
              type="date"
              placeholder="dd/mm/aaaa"
              format="dd/MM/yyyy"
              value={!value ? new Date() : value}
              name={name}
              maxDate={new Date()}
              onChange={handleChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <ErrorAlert error={errors} />
    </div>
  );
};
