import React from 'react';
import PropTypes from 'prop-types';
import './PdfViewer.scss';

const PdfViewer = (props) => {

  const {pdfUrl,pageTitle}=props
  return (
    <div className="pdf-viewer-container">
      <h1 className="title">{pageTitle}</h1>
      <iframe className="pdf-viewer" src={pdfUrl} title="PDF Viewer" />
    </div>
  );
};

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
};

export default  PdfViewer;