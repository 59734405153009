/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { EditUserFromAdminForm } from '../../../../common/Forms/Forms';
import {
  CancelActionButton,
  ActionButton,
} from '../../../../common/Buttons/Buttons';

import './GeneralInfo.scss';

export const GeneralInfo = (props) => {
  const { user, onChange, cancelEdition, save, stateList, companyList } = props;

  return (
    <div>
      <EditUserFromAdminForm
        data={user}
        onChange={onChange}
        stateList={stateList}
        companyList={companyList}
      />
      <div className="general-info-actions-button-container">
        <CancelActionButton text={'Cancelar'} action={cancelEdition} />
        <span style={{ marginLeft: '12px' }}>
          <ActionButton text={'Guardar Cambios'} action={save} />
        </span>
      </div>
    </div>
  );
};
