import deleteFile from "../../../images/delete.svg";
import moment from "moment";

import "./MemberCard.scss";

export const MemberCard = (props) => {
  const {
    name,
    lastName,
    email,
    phone,
    idNumber,
    idType,
    birthDate,
    idIdea,
    idMember,
    sessionToken,
    deleteMember,
    numberArray,
    currentState,
    isParticipant,
  } = props;

  const birthdateHelper = moment(birthDate).format("DD-MM-YYYY");

  return (
    <div className="col-12 col-md-12 col-lg-12 member-card-border">
      <div className="member-card">
        <div className="member-card-header">
          <h5>Integrante Adicional {numberArray + 1}</h5>
          {currentState && isParticipant ? (
            <img
              src={deleteFile}
              alt=""
              onClick={() => deleteMember(idIdea, idMember, sessionToken)}
            />
          ) : null}
        </div>

        <div className="row justify-content-md-center row-confirm">
          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
            <h6>Nombre</h6>
          </div>
          <div className="col-12 col-sm-12 col-lg-3">
            <p>{name}</p>
          </div>

          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
            <h6>Cédula de Identidad</h6>
          </div>
          <div className="col-12 col-sm-12 col-lg-3">
            <p>
              {idType.id === 1 ? "V" : "E"} - {idNumber}
            </p>
          </div>
        </div>

        <div className="row justify-content-md-center row-confirm">
          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
            <h6>Apellido</h6>
          </div>
          <div className="col-12 col-sm-12 col-lg-3">
            <p>{lastName}</p>
          </div>

          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
            <h6>Teléfono</h6>
          </div>
          <div className="col-12 col-sm-12 col-lg-3">
            <p>{phone}</p>
          </div>
        </div>

        <div className="row justify-content-md-center row-confirm">
          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
            <h6>Correo Electrónico</h6>
          </div>
          <div className="col-12 col-sm-12 col-lg-3">
            <p>{email}</p>
          </div>

          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
            <h6>Fecha de Nacimiento </h6>
          </div>
          <div className="col-12 col-sm-12 col-lg-3">
            <p>{birthdateHelper}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
