/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  CheckMarkInput,
  ContestSelectInput,
} from '../../../../common/ContestForms/ContestForms';
import {
  getJudgePreferences,
  createJudgePreference,
  editJudgePreference,
  updatedUserLeader,
} from '../../../../../services/judgeService.js';
import {
  getContests,
  getStageByContest,
} from '../../../../../services/contestService.js';
import { useParams } from 'react-router-dom';
import { ActionButton } from '../../../../common/Buttons/Buttons';
import { toast } from 'react-toastify';

import './Preferences.scss';

export const Preferences = () => {
  const params = useParams();
  const [selectedContest, setSelectedContest] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [stages, setStages] = useState([]);
  const [preferencesStages, setPreferencesStages] = useState([]);
  const [preferences, setPreferences] = useState({
    types: '',
    stage: '',
    negocio: false,
    social: false,
    digital: false,
    lider: false,
    'fase 1': false,
    'fase 2': false,
    'fase 3': false,
  });
  const options = [
    { label: 'Negocio', selected: false },
    { label: 'Social', selected: false },
    { label: 'Digital', selected: false },
  ];

  const stagesOptions = [
    { label: 'Fase 1', selected: false },
    { label: 'Fase 2', selected: false },
    { label: 'Fase 3', selected: false },
  ];
  const leadership = [{ label: 'Lider', selected: false }];

  const groupOptions = [
    { id: 1, name: 'Grupo 1' },
    { id: 2, name: 'Grupo 2' },
    { id: 3, name: 'Grupo 3' },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const options = ['negocio', 'social', 'digital'];
    const stages = ['Fase 1', 'Fase 2', 'Fase 3'];
    const leader = ['Lider'];

    if (
      options.some((o) => o.toLocaleLowerCase() === name) ||
      stages.some((o) => o.toLocaleLowerCase() === name) ||
      leader.some((o) => o.toLocaleLowerCase() === name)
    ) {
      setPreferences({ ...preferences, [name]: !preferences[name] });
    } else {
      setPreferences({ ...preferences, [name]: value });
    }
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response.status == 200) {
        //   setContestList(response.data);
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        if (selectedContest) {
          setSelectedContest(selectedContest?.id);
        } else {
          setSelectedContest(-1);
        }
        getContestsStages(selectedContest?.id, '1');
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  const getContestsStages = async (contest, modality) => {
    setIsLoading(true);
    setStages([]);

    if (contest && modality) {
      await getStageByContest(contest, modality)
        .then((response) => {
          if (response.status == 200) {
            let stages = response.data;

            // Set error messages and changed state in blank
            setStages(stages);

            setIsLoading(false);
          } else {
            toast.error(`Error al obtener concursos`);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const checkIfSelected = (data, stage) => {
    return data?.find(function (e) {
      return e.stage.stage === stage;
    });
  };

  const getJudgePreferencesFunction = () => {
    getJudgePreferences(params.id)
      .then((response) => {
        if (response?.status === 200) {
          setPreferencesStages(response.data.stages);
          setPreferences({
            ...preferences,
            id: response?.data?.id,
            social: response.data.types?.includes('SOCIAL'),
            digital: response.data.types?.includes('DIGITAL'),
            negocio: response.data.types?.includes('BUSINESS'),
            'fase 1': checkIfSelected(response.data.stages, 'Fase 1'),
            'fase 2': checkIfSelected(response.data.stages, 'Fase 2'),
            'fase 3': checkIfSelected(response.data.stages, 'Fase 3'),
            group: response.data.groupNumber,
            lider:
              response.data.user.userType === 'judge_leader' ? true : false,
          });
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const sendPreferences = () => {
    // Handle Idea types
    let types = [];
    types = [
      preferences['social'] ? 'SOCIAL' : '',
      preferences['negocio'] ? 'BUSINESS' : '',
      preferences['digital'] ? 'DIGITAL' : '',
    ];
    types = types.filter((t) => t !== '');

    let selectedStages = [];

    selectedStages = [
      preferences['fase 1']
        ? { stage: { id: parseInt(stages[0]?.id), stageName: 'fase1' } }
        : '',
      preferences['fase 2']
        ? { stage: { id: parseInt(stages[1]?.id), stageName: 'fase2' } }
        : '',
      preferences['fase 3']
        ? { stage: { id: parseInt(stages[2]?.id), stageName: 'fase3' } }
        : '',
    ];
    selectedStages = selectedStages.filter((s) => s !== '');

    let data = {
      user: { id: parseInt(params.id) },
      groupNumber: preferences.group
        ? parseInt(preferences.group)
        : preferences.group,
      types: types,
      stages: selectedStages,
    };

    if (!preferences.id) {
      createJudgePreference(data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Preferencias Actualizadas');
            getJudgePreferencesFunction();
            changeJudgeType(preferences.lider);
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    } else {
      updateJudgeFunction(data);
    }
  };

  const changeJudgeType = (judgeType) => {
    updatedUserLeader(params.id, judgeType ? 'judge_leader' : 'judge')
      .then((response) => {
        if (response.status === 200) {
          toast.success('Jurado actualizado');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error(error.message);
      });
  };

  const updateJudgeFunction = (data) => {
    let [stage1Id] = preferencesStages.filter(
      (p) => p.stage.stage === 'Fase 1'
    );
    let [stage2Id] = preferencesStages.filter(
      (p) => p.stage.stage === 'Fase 2'
    );
    let [stage3Id] = preferencesStages.filter(
      (p) => p.stage.stage === 'Fase 3'
    );

    let selectedStages = [
      preferences['fase 1']
        ? {
            id: stage1Id?.id ? stage1Id?.id : 0,
            stage: { id: parseInt(stages[0]?.id), stageName: 'fase1' },
          }
        : '',
      preferences['fase 2']
        ? {
            id: stage2Id?.id ? stage2Id?.id : 0,
            stage: { id: parseInt(stages[1]?.id), stageName: 'fase2' },
          }
        : '',
      preferences['fase 3']
        ? {
            id: stage3Id?.id ? stage3Id?.id : 0,
            stage: { id: parseInt(stages[2]?.id), stageName: 'fase3' },
          }
        : '',
    ];

    selectedStages = selectedStages.filter((s) => s !== '');

    data.stages = selectedStages;

    editJudgePreference(data, preferences.id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success('Preferencias Actualizadas');
          changeJudgeType(preferences.lider);
          // getJudgePreferencesFunction();
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const validateInputs = () => {
    if (
      preferences['social'] ||
      preferences['negocio'] ||
      preferences['digital']
    ) {
      return false;
    } else return true;
  };

  useEffect(() => {
    getContestFunction();
    getJudgePreferencesFunction();
  }, []);

  return (
    <div>
      <br />
      <div className="contest-list-container">
        <h5 className="preferences-subtitle">Preferencias de Evaluación</h5>
      </div>{' '}
      <div className="preferences-inputs-container">
        <div className="contest-input-flex col-12 col-md-12">
          <CheckMarkInput
            options={options}
            handleChange={handleChange}
            name="types"
            label="Tipo de propuesta permitidas para evaluar"
            value={preferences}
            errors={''}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-12">
          <CheckMarkInput
            options={stagesOptions}
            handleChange={handleChange}
            name="stage"
            label="Fases del proyecto permitidas para evaluar"
            value={preferences}
            errors={''}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>{' '}
        <div className="contest-input-flex col-12 col-md-12">
          <CheckMarkInput
            options={leadership}
            handleChange={handleChange}
            name="Lider"
            label="Rol"
            value={preferences}
            errors={''}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={groupOptions}
            handleChange={handleChange}
            name="group"
            label="Grupo"
            value={preferences?.group}
            errors={''}
            type={'text'}
            placeholder={''}
          />
        </div>
      </div>
      <br />
      <br />
      <div className="buttons-container">
        <span></span>
        <ActionButton
          text="Guardar"
          action={() => sendPreferences()}
          disabled={false}
        />
      </div>
    </div>
  );
};
