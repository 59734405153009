import React from 'react';
import addMember from '../../../images/add_member.png';
import { Button, Modal } from 'react-bootstrap';
import { MemberCard } from '../../common/MemberCard/MemberCard.jsx';
import {
  LeaderDropzone,
  IdeaSummaryDropzone,
  ParticipantDropzone,
  ONGDropzone,
} from '../IdeaDetail/MembersDropzone';
import { ConfirmedElement } from '../../common/ConfirmedElement/ConfirmedElement';
import {
  ContestInput,
  ContestAgeInput,
  ContestCedulaInput,
} from '../../common/ContestForms/ContestForms';
import moment from 'moment';
import './Stage1.scss';

export const IdeaDetailComponent = (props) => {
  const { ideaForEdition, userSession, filterStage } = props;

  return (
    <div className="container idea-detail">
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-8">
          <h1>{ideaForEdition?.idea?.title}</h1>
        </div>
        <div className="col-12 col-sm-12 col-lg-4">
          <h4 className="dead-line-date">
            ID {ideaForEdition?.idea?.codeDana}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-4">
          <h3>SECTOR</h3>
          <h4>{ideaForEdition?.idea?.sector?.economicSector}</h4>
        </div>

        <div className="col-12 col-sm-12 col-lg-4">
          <h3>CATEGORÍA</h3>
          <h4>{ideaForEdition?.idea?.proposalType?.proposalType}</h4>
        </div>

        <div className="col-12 col-sm-12 col-lg-4">
          <h3>ESTATUS</h3>
          <h4 className="dead-line-date">
            {ideaForEdition?.idea?.stage?.status}
          </h4>
        </div>
      </div>
      {userSession?.userType === 'participant' ? (
        <React.Fragment>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-4">
              <h3>Etapa</h3>
              <h4>{filterStage ? filterStage?.stage : 'Etapa inactiva'}</h4>
            </div>

            <div className="col-12 col-sm-12 col-lg-4">
              <h3>FECHA LÍMITE</h3>
              <h4 className="dead-line-date">
                {filterStage
                  ? moment(filterStage?.endDate)
                      .locale('es')
                      .format('DD/MM/YYYY')
                  : 'N/A'}
              </h4>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export const Stage1 = (props) => {
  const {
    show,
    ideaForEdition,
    isSocial,
    files,
    handleDragAndDropFiles,
    handleONGDragAndDropFiles,
    handleDragAndDropSummaryFiles,
    handleDragAndDropMembersFiles,
    removeDocumentsfromList,
    openBase64File,
    deleteMemberService,
    handleClose,
    handleShow,
    handleInputChange,
    validateIfMembersHaveFiles,
    handleDateChange,
    saveMember,
    userSession,
    member,
    showStage,
    currentState,
  } = props;
  if (showStage) {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      {' '}
                      <ConfirmedElement
                        checked={
                          ideaForEdition.idea.user.idNumber ? true : false
                        }
                      />
                      <div className="vertical-timeline-element-content bounce-in">
                        <h4 className="timeline-title">
                          INFORMACIÓN PERSONAL (LÍDER)
                        </h4>
                        <div className="row justify-content-md-center row-confirm">
                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Nombre</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition.idea.user.name}</p>
                          </div>

                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Teléfono de Habitación</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition.idea.user.phoneHome}</p>
                          </div>
                        </div>

                        <div className="row justify-content-md-center row-confirm">
                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Apellido</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition.idea.user.lastName}</p>
                          </div>

                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Teléfono Móvil</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition.idea.user.phoneMobile}</p>
                          </div>
                        </div>

                        <div className="row justify-content-md-center row-confirm">
                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Cédula de Identidad</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>
                              {
                                ideaForEdition?.idea?.user?.nationality
                                  ?.nationality
                              }{' '}
                              - {ideaForEdition?.idea?.user?.idNumber}
                            </p>
                          </div>

                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Teléfono Oficina / Otro</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition?.idea?.user?.phoneOther}</p>
                          </div>
                        </div>

                        <div className="row justify-content-md-center row-confirm">
                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Fecha de Nacimiento</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>
                              {moment(
                                ideaForEdition?.idea?.user?.birthDate
                              ).format('DD-MM-YYYY')}
                            </p>
                          </div>

                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Estado</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition?.idea?.user?.state?.state}</p>
                          </div>
                        </div>

                        <div className="row justify-content-md-center row-confirm">
                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Sexo</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>
                              {ideaForEdition?.idea?.user?.gender?.gender ===
                              'F'
                                ? 'Femenino'
                                : 'Masculino'}
                            </p>
                          </div>

                          <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                            <h6>Dirección</h6>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-3">
                            <p>{ideaForEdition?.idea?.user?.address}</p>
                          </div>
                        </div>
                        {userSession.userType === 'participant' ? null : (
                          <React.Fragment>
                            <div className="row justify-content-md-center row-confirm">
                              <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                                <h6>Correo Electrónico</h6>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-3">
                                <p>{ideaForEdition?.idea?.user?.email}</p>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-3 "></div>
                              <div className="col-12 col-sm-12 col-lg-3"></div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>

                  {isSocial ? (
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <div>
                        {' '}
                        <ConfirmedElement checked={isSocial ? true : false} />
                        <div className="vertical-timeline-element-content bounce-in">
                          <h4 className="timeline-title">
                            INFORMACIÓN DE LA ONG
                          </h4>
                          <div className="row justify-content-md-center row-confirm">
                            <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                              <h6>Nombre</h6>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                              <p>
                                {ideaForEdition.idea.ong
                                  ? ideaForEdition.idea.ong.name
                                  : ''}
                              </p>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                              <h6>Dirección</h6>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                              <p>
                                {ideaForEdition.idea.ong
                                  ? ideaForEdition.idea.ong.address
                                  : ''}
                              </p>
                            </div>
                          </div>

                          <div className="row justify-content-md-center row-confirm">
                            <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                              <h6>Correo electrónico</h6>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                              <p>
                                {ideaForEdition.idea.ong
                                  ? ideaForEdition.idea.ong.email
                                  : ''}
                              </p>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                              <h6>Teléfono</h6>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                              <p>
                                {ideaForEdition.idea.ong
                                  ? ideaForEdition.idea.ong.phone
                                  : ''}
                              </p>
                            </div>
                          </div>

                          <div className="row justify-content-md-left row-confirm">
                            <div className="col-12 col-sm-12 col-lg-3 gray-data-label">
                              <h6>Estado</h6>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                              <p>
                                {ideaForEdition.idea.ong
                                  ? ideaForEdition.idea.ong.state.state
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      <ConfirmedElement
                        checked={
                          ideaForEdition.files.filter(
                            (i) => i.type === 'Resumen'
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                      <IdeaSummaryDropzone
                        ideaSummaryFiles={ideaForEdition.ideaSummaryFiles}
                        ideaForEdition={ideaForEdition}
                        handleDragAndDropFiles={handleDragAndDropSummaryFiles}
                        removeDocumentsfromList={removeDocumentsfromList}
                        openBase64File={openBase64File}
                        currentState={currentState}
                        isParticipant={
                          userSession.userType === 'participant' ? true : null
                        }
                      />
                    </div>
                  </div>

                  {ideaForEdition.idea.ong ? (
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <ConfirmedElement
                        checked={
                          ideaForEdition.files.filter(
                            (i) => i.type === 'Resumen_ong'
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                      <div>
                        <ONGDropzone
                          files={files}
                          ideaForEdition={ideaForEdition}
                          handleDragAndDropFiles={handleONGDragAndDropFiles}
                          removeDocumentsfromList={removeDocumentsfromList}
                          openBase64File={openBase64File}
                          currentState={currentState}
                          isParticipant={
                            userSession.userType === 'participant' ? true : null
                          }
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      {' '}
                      <ConfirmedElement
                        checked={
                          ideaForEdition.files.filter(
                            (i) => i.type === 'CV_lider'
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                      <LeaderDropzone
                        files={files}
                        ideaForEdition={ideaForEdition}
                        handleDragAndDropFiles={handleDragAndDropFiles}
                        removeDocumentsfromList={removeDocumentsfromList}
                        openBase64File={openBase64File}
                        currentState={currentState}
                        isParticipant={
                          userSession.userType === 'participant' ? true : null
                        }
                      />
                    </div>
                  </div>

                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      {' '}
                      <ConfirmedElement
                        checked={ideaForEdition.members.length > 0}
                      />
                      <div className="vertical-timeline-element-content bounce-in">
                        <h4 className="timeline-title">
                          INTEGRANTES ADICIONALES (OPCIONAL)
                        </h4>
                        {userSession.userType === 'participant' ? (
                          <React.Fragment>
                            <p>
                              Sólo se puede ingresar un máximo de 5 integrantes.
                            </p>

                            {currentState ? (
                              ideaForEdition.members.length < 5 ? (
                                <Button
                                  variant="primary"
                                  className="btn-member"
                                  onClick={handleShow}
                                >
                                  <img src={addMember} alt="" />
                                  Agregar un integrante
                                </Button>
                              ) : null
                            ) : null}
                          </React.Fragment>
                        ) : null}
                        <div className="row idea-cards-container">
                          {ideaForEdition.members.length > 0
                            ? ideaForEdition.members.map((c, i) => (
                                <MemberCard
                                  key={i}
                                  numberArray={i}
                                  name={c.name}
                                  lastName={c.lastName}
                                  phone={c.phone}
                                  email={c.email}
                                  idNumber={c.idNumber}
                                  idType={c.nationality}
                                  birthDate={c.birthDate}
                                  idMember={c.id}
                                  idIdea={ideaForEdition.idea.id}
                                  sessionToken={userSession.sessionToken}
                                  deleteMember={deleteMemberService}
                                  currentState={currentState}
                                  isParticipant={
                                    userSession.userType === 'participant'
                                      ? true
                                      : null
                                  }
                                />
                              ))
                            : null}
                        </div>
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Agregar integrante</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="contest-input-flex col-12 col-md-12">
                              <ContestInput
                                handleChange={handleInputChange}
                                name="name"
                                label="Nombre"
                                type={'text'}
                                placeholder={'Nombre del participante'}
                                errors={member.errorName}
                                value={member.name}
                              />
                            </div>

                            <div className="contest-input-flex col-12 col-md-12">
                              <ContestInput
                                handleChange={handleInputChange}
                                name="lastName"
                                label="Apellido"
                                type={'text'}
                                placeholder={'Apellido del participante'}
                                errors={member.errorLastName}
                                value={member.lastName}
                              />
                            </div>

                            <div className="contest-input-flex col-12 col-md-12">
                              <ContestInput
                                handleChange={handleInputChange}
                                name="email"
                                label="Correo electrónico"
                                type={'text'}
                                placeholder={
                                  'Correo electrónico del participante'
                                }
                                errors={member.errorEmail}
                                value={member.email}
                              />
                            </div>

                            <div className="contest-input-flex col-12 col-md-12">
                              <ContestCedulaInput
                                handleChange={handleInputChange}
                                label="Cédula de Identidad"
                                idType={member.nationality}
                                id={member.idNumber}
                                nameIdType="nationality"
                                name_id="idNumber"
                                errors={
                                  member.erroridNumber
                                    ? member.erroridNumber
                                    : member.errornationality
                                }
                              />
                            </div>

                            <div className="contest-input-flex col-12 col-md-12">
                              <ContestInput
                                handleChange={handleInputChange}
                                name="phone"
                                label="Teléfono"
                                type={'number'}
                                placeholder={'0212 555555'}
                                errors={member.errorPhone}
                                value={member.phone}
                              />
                            </div>
                            <div className="contest-input-flex col-12 col-md-12">
                              <ContestAgeInput
                                handleChange={handleDateChange}
                                label="Fecha de Nacimiento"
                                selected={member.birthDate}
                                errors={member.errorBirthDate}
                                name="birthDate"
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Cancelar
                            </Button>
                            <Button variant="primary" onClick={saveMember}>
                              Guardar
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>

                  {ideaForEdition.members.length > 0 ? (
                    <div className="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <ConfirmedElement
                          checked={validateIfMembersHaveFiles(ideaForEdition)}
                        />
                        <ParticipantDropzone
                          files={files}
                          ideaForEdition={ideaForEdition}
                          handleDragAndDropFiles={handleDragAndDropMembersFiles}
                          removeDocumentsfromList={removeDocumentsfromList}
                          openBase64File={openBase64File}
                          currentState={currentState}
                          isParticipant={
                            userSession.userType === 'participant' ? true : null
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};
