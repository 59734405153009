import http from './httpServices';

//Function that gets the comments from an Audit
export async function getCurrentContest(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/contest`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

//Function that gets the comments from an Audit
export async function isContestOpen() {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/contest`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

//Function that gets the comments from an Audit
export async function getContestStagesBeforelogin() {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/stagesByCurrentContest`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

//Function that gets the comments from an Audit
export async function getStagesByCurrentContest(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/stagesByCurrentContest`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Function that POST data form for creating users
export async function updateStage(datos, idStage, contestId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/stage/${idStage}/${contestId}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

//Function that gets the comments from an Audit
export async function closeContest(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/closeContest`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createContest(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/contest`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, { name: data }, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getContests() {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/contests`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getContestsDocumentsStats(contestId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/statisticsByDocuments/${contestId}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getStageByContest(contestId, selectedModality) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/stagesByContest/${contestId}?type=${selectedModality}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function statisticsByPropalType(contestId) {
  let contest = '';

  if (contestId) {
    contest = '/' + contestId;
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/statisticsByPropalType${contest}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function ideasCountByStage(contestId) {
  let contest = '';

  if (contestId) {
    contest = contestId;
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/statisticsByStages/${contest}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getAdminDashboard() {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/adminDashboard`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getContestLogo(imageType) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/preferencesMarketing/${imageType}`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
