import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DeleteAwardModal.scss';

export const DeleteAwardModal = (props) => {
  const { isOpen, closeDeleteModal, selectedAward, action } = props;
  return (
    <Modal size="md" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Premio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Desea eliminar el siguiente premio especial:
        <br />
        <br />
        <h4>
          <b>{selectedAward?.title}</b> <br />
        </h4>
        <p>{selectedAward?.description}</p>
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={() => action(selectedAward)}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
