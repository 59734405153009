/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  getContests,
  getStageByContest,
} from '../../../services/contestService.js';
import {
  getIdeasByJudge,
  getJudgeAvailableByIdea,
  assignJudgeToIdea,
} from '../../../services/judgeService.js';
import { AssignJudgeModal } from '../../common/Modals/AssignJudgeModal/AssignJudgeModal';
import { EvaluationsListTable } from '../../common/Tables/Tables';
import {
  ContestSelectInput,
  ContestInput,
} from '../../common/ContestForms/ContestForms';
import { showDate } from '../../../helpers/dateFormat.js';
import {
  resetEvaluation,
  userEvaluationInformation,
} from '../../../services/judgeService.js';
import { useHistory } from 'react-router';
import './Evaluations.scss';

export const EvaluationStageElement = (props) => {
  const { stage } = props;

  let isOpen = '';

  if (stage.isOpen) {
    isOpen = 'evaluation-stage-is-open';
  }

  return (
    <div className={`evaluation-stage-element ${isOpen} `}>
      <h6>{stage.stage === 'Fase 3' ? 'Final' : stage.stage}</h6>
    </div>
  );
};

export const Evaluations = (props) => {
  const { selectIdea } = props;
  const [selectedContest, setSelectedContest] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    ideaType: '',
    filter: '',
    evalStatus: '',
  });
  const [stages, setStages] = useState([]);
  const [activeStage, setActiveStage] = useState('');
  const [userSession, setUserSession] = useState('');

  const [ideaList, setIdeaList] = useState({ number: 0, content: [] });

  const [showAsignModal, setShowAsignModal] = useState(false);
  const [selectedEval, setSelectedEval] = useState('');
  const [currentStage, setCurrentStage] = useState('');
  const [judgesForIdea, setJudgesForIdea] = useState('');
  const [selectedJudge, setSelectedJudge] = useState('');
  const [asignedEvals, setAsignedEvals] = useState('');

  const history = useHistory();

  const assignJudge = () => {
    assignJudgeToIdea(selectedJudge?.id, selectedEval?.judgeIdea?.idea?.id)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Jurado Asignado');
          closeAssginModal();
          getContestFunction();
          getUserData();
        } else {
          toast.error('Error al asignar jurado');
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const selectJudge = (judge) => {
    setSelectedJudge(judge);
    getUserEvaluationInformation(judge);
  };

  const closeAssginModal = () => {
    setSelectedEval('');
    setSelectedJudge('');
    setShowAsignModal(false);
  };

  const getUserEvaluationInformation = (selectedJudge) => {
    if (selectedJudge && selectedEval) {
      userEvaluationInformation(
        selectedJudge?.id,
        selectedEval?.judgeIdea?.stage?.stage
      )
        .then((response) => {
          if (response.status === 200) {
            setAsignedEvals(response.data);
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    }
  };

  const openAssginModal = (evaluation) => {
    setSelectedEval(evaluation);
    setShowAsignModal(true);
    getJudgeAvailableForIdea(evaluation?.judgeIdea?.idea?.id);
  };

  const options = [
    { id: '', name: 'Todos' },
    { id: 'NOT_EVALUATED', name: 'Sin Evaluar' },
    { id: 'IN_PROGRESS', name: 'En progreso' },
    { id: 'EVALUATED', name: 'Evaluadas' },
    { id: '4', name: 'Digital' },
    { id: '3', name: 'Social' },
    { id: '2', name: 'Negocio' },
  ];

  const getJudgeAvailableForIdea = (ideaId) => {
    getJudgeAvailableByIdea(currentStage?.id, ideaId)
      .then((response) => {
        if (response.status === 200) {
          setJudgesForIdea(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response.status == 200) {
        //   setContestList(response.data);
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        if (selectedContest) {
          setSelectedContest(selectedContest);
        } else {
          setSelectedContest(-1);
        }
        getContestsStages(selectedContest, '1');
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  const getContestsStages = async (contest, modality) => {
    if (contest && modality) {
      await getStageByContest(contest?.id, modality)
        .then((response) => {
          if (response.status == 200) {
            let stages = response.data;

            if (modality == '0') {
              // Remove Registrado and Ganador stages if modality == Postulacion
              stages = stages.filter(
                (s) => s.status !== 'Registrado' && s.status !== 'Ganador'
              );
            }
            // Set error messages and changed state in blank
            stages.forEach((item) => {
              item.hasChanged = false;
              item.startDateError = '';
              item.endDateError = '';
              item.isOpenError = '';
            });

            setStages(stages);

            let [isOpen] = stages.filter((s) => s.isOpen === true);

            if (isOpen) {
              setActiveStage(isOpen);
            }

            setIsLoading(false);
            getjudgeIdeas(contest, isOpen);
          } else {
            toast.error(`Error al obtener concursos`);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const getjudgeIdeas = (contest, stage) => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    let [seachStage] = contest.stages.filter(
      (s) => s.stage === stage?.stage && s.type === 'JURADO'
    );
    setCurrentStage(seachStage);
    let params = {
      page: ideaList.number,
      size: 10,
      idStage: seachStage?.id,
      idContest: contest?.id,
      idUser: userSession.userId,
      evalStatus: ['2', '3', '4'].includes(filter.evalStatus)
        ? ''
        : filter.evalStatus,
      ideaType: filter.ideaType,
      search: filter.search,
    };

    setIsLoading(true);
    getIdeasByJudge(params)
      .then((response) => {
        if (response.status == 200) {
          setIdeaList(response?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;

    if (['2', '3', '4'].includes(value)) {
      setFilter({ ...filter, ideaType: value, evalStatus: value });
    } else {
      setFilter({ ...filter, [name]: value, ideaType: '' });
    }

    if (!value) {
      setFilter({ ...filter, ideaType: '', evalStatus: '', [name]: value });
    }

    setIdeaList({ ...ideaList, number: 0 });
  };

  const handlePageChange = (event, value) => {
    setIdeaList({ ...ideaList, number: value - 1 }); // -1 To remove page counter offset
  };

  const getUserData = () => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    setUserSession(userSession);
  };

  const reEvaluate = (selectedEval) => {
    const { idea, user } = selectedEval.judgeIdea;
    resetEvaluation(idea?.id, user?.id).then((response) => {
      if (response?.status == 200) {
        //   setContestList(response.data);
        toast.success(`Evaluación Reseteada`);
        getjudgeIdeas(selectedContest, activeStage);
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  useEffect(() => {
    getContestFunction();
    getUserData();
  }, [ideaList.number, filter.ideaType, filter.evalStatus]);

  return (
    <>
      <AssignJudgeModal
        selectedEval={selectedEval}
        show={showAsignModal}
        judgesForIdea={judgesForIdea}
        selectJudge={selectJudge}
        selectedJudge={selectedJudge}
        closeModal={closeAssginModal}
        assignJudge={assignJudge}
        asignedEvals={asignedEvals}
      />

      <div className="dashboard-title-container">
        <h1>EVALUACIONES</h1>
      </div>

      {stages.length > 0 && (
        <div className="evaliation-state-container">
          {stages.map((s) => (
            <EvaluationStageElement key={s.id} stage={s} />
          ))}
        </div>
      )}
      <br />

      {activeStage && (
        <p className="judge-evaluation-end-date-message">
          Fecha límite para evaluar la fase actual termina{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            el {showDate(activeStage?.endDate)}
          </span>
          <br />
          {/* Le quedan cinco (5) días para completar las evaluaciones que le falten */}
        </p>
      )}

      <div className="judge-evaluation-filters-container">
        <div
          style={{ padding: '0px', marginTop: '4px' }}
          className="contest-input-flex evaluations-search-pt1-element"
        >
          <div className="evaluation-filter-container">
            <ContestInput
              handleChange={onChangeFilter}
              name="search"
              label="Buscar por palabra clave"
              value={filter?.search}
              errors={''}
              type={'text'}
              placeholder={''}
            />

            <ActionButton text={'Buscar'} action={() => getContestFunction()} />
          </div>
        </div>
        <div className="contest-input-flex evaluations-search-pt2-element ">
          <div className="users-dropdown-container">
            <ContestSelectInput
              options={options}
              handleChange={onChangeFilter}
              name="evalStatus"
              label="Filtrar por:"
              value={filter.evalStatus}
              errors={''}
              type={'text'}
              placeholder={''}
            />
          </div>
        </div>
      </div>

      <div className="contest-list-container">
        <h5 className="preferences-subtitle">
          Lista de proyectos para evaluar:
        </h5>
      </div>
      {!isLoading && ideaList.content.length === 0 ? (
        <h4 style={{ marginTop: '200px' }}>
          {' '}
          En este momento no tiene ideas <br /> asignadas para evaluar
        </h4>
      ) : null}
      <EvaluationsListTable
        data={ideaList}
        activeStage={activeStage}
        isLoading={isLoading}
        selectIdea={selectIdea}
        userSession={userSession}
        openAssginModal={openAssginModal}
        reEvaluate={reEvaluate}
        userSession={userSession}
        // handleOpenDeleteModal={handleOpenDeleteModal}
        // openEditModal={openEditModal}
        handlePageChange={handlePageChange}
      />
    </>
  );
};
