import React, { useEffect, useState } from 'react';
import  PdfViewer  from '../../../../common/PdfViewer/PdfViewer';
import { useParams } from 'react-router-dom';
import styles from  './DocumentCriterias.scss'

const DocumentCriteria = (props) => {
  const [pdfUrl, setPdfUrl] = useState([]);
  const {getDocumentsByCriteriaPrincipal,
        evaluationcriteria,

        }= props;

        const params = useParams();


        evaluationcriteria.contestId = params?.idcontest;
        evaluationcriteria.faseId  = params?.idfase;
        evaluationcriteria.typeIdea = params?.typeIdea;

        useEffect(() => {
          getDocumentsByCriteriaPrincipal(evaluationcriteria.contestId,
            evaluationcriteria.faseId,
            evaluationcriteria.typeIdea);

        }, [getDocumentsByCriteriaPrincipal,evaluationcriteria]);


        useEffect(() => {
          let documentType = `${evaluationcriteria.contestId}_${evaluationcriteria.faseId}_${evaluationcriteria.typeIdea}`;
          let leaderFiles = evaluationcriteria.files.filter(
            (i) => i.type === documentType
          );
      
          const hasFiles = leaderFiles && leaderFiles.length > 0;
          if (hasFiles) {
            setPdfUrl(leaderFiles[0].fileUrl);
          } else {
            setPdfUrl(null);
          }
        }, [evaluationcriteria.files, evaluationcriteria.contestId, evaluationcriteria.faseId, evaluationcriteria.typeIdea]);
    

  return (
   
    <div>
      {pdfUrl? (
      <PdfViewer 
      pdfUrl={pdfUrl} />
    ) : (
      <div className={styles['no-files-message']}>
      <h2>No hay archivos disponibles</h2>
    </div>
    )}
  </div>
  );
};

export default DocumentCriteria;