/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import './ReevalModal.scss';

export const ReevalModal = (props) => {
  const { isOpen, closeModal, reEvaluate } = props;

  return (
    <Modal size="md" centered show={isOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textTransform: 'capitalize' }}>
          Reevaluar Idea
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ marginTop: '24px' }}>
          ¿ Está seguro de que desea reevaluar la idea ?
        </h5>
        <br></br>
        <h5 style={{ marginBottom: '60px' }}>
          Recuerde que si hace alguna modificación no podra recuperar la
          evaluación anterior
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => reEvaluate()}>
          Reevaluar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
