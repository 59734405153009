/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../../services/userListService.js';
import { getCompanies } from '../../../services/companyService';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  ContestSelectInput,
  ContestInput,
} from '../../common/ContestForms/ContestForms';
import { SpecialMentionTable } from '../../common/Tables/Tables';
import { regularExpression } from '../../../helpers/regex.js';
import { Switch, Route, useHistory } from 'react-router-dom';
import {
  createNewUser,
  editUser,
  deleteUser,
} from '../../../services/userListService.js';
import {
  getSpecialAwardList,
  getSpecialAwards,
} from '../../../services/awardService.js';
import { toast } from 'react-toastify';
import { getContests } from '../../../services/contestService.js';
import './SpecialMention.scss';

export const SpecialMention = (props) => {
  const { selectIdea } = props;
  const [userList, setUserList] = useState({ number: 0, content: [] });
  const [loadCount, setLoadCount] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [user, setUser] = useState('');
  const [userToDelete, setUserToDelete] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [canPost, setCanPost] = useState(true);
  const [filter, setFilter] = useState({
    rol: '',
    estatus: '',
    empresa: '',
    winner: '',
  });
  const [companyList, setCompanyList] = useState([]);
  const [canDelete, setCanDelete] = useState(true);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [awardList, setAwardList] = useState([]);
  const history = useHistory();

  const rolesOptions = [
    { id: 'Participante', name: 'Participante' },
    { id: 'Semifinalista', name: 'Semifinalista' },
    { id: 'Finalista', name: 'Finalista' },
    { id: 'Ganador', name: 'Ganador' },
  ];

  const statusOption = [
    { id: true, name: 'Activo' },
    { id: false, name: 'Inactivo' },
  ];
  const statusSpecialMention = [
    { id: true, name: 'Ganadora' },
    { id: false, name: 'No Ganadora' },
  ];

  const handleOpenCreateCriteriaModal = () => {
    setOpenCreateUserModal(true);
    setUser('');
    clearValidationErrors();
    setModalType('create');
  };

  const handleCloseCreateUserModal = () => {
    setOpenCreateUserModal(false);
    clearValidationErrors();
    setUser('');
    setModalType('');
  };

  const clearValidationErrors = () => {
    setUser({
      ...user,
      errors: {
        name: '',
        lastName: '',
        email: '',
      },
    });
  };

  const openEditModal = (selectedUser) => {
    setUser({
      id: selectedUser?.id,
      name: selectedUser?.name,
      lastName: selectedUser?.lastName,
      email: selectedUser?.email,
      userType: selectedUser?.userType,
      errors: {
        name: '',
        lastName: '',
        email: '',
      },
    });

    setOpenCreateUserModal(true);
    setModalType('edit');
  };

  const validateUser = () => {
    let nameError = '';
    let lastNameError = '';
    let emailError = '';
    let canPost = true;

    if (regularExpression.noSpecialCharacters.test(String(user.name))) {
      nameError = 'El nombre no puede contener caracteres especiales';
      canPost = false;
    }
    if (regularExpression.noSpecialCharacters.test(String(user.lastName))) {
      lastNameError = 'El apellido no puede contener caracteres especiales';
      canPost = false;
    }

    if (!regularExpression.emailFormat.test(String(user.email))) {
      emailError = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    setUser({
      ...user,
      errors: {
        name: nameError,
        lastName: lastNameError,
        email: emailError,
      },
    });

    return canPost;
  };

  const createEditUser = () => {
    // Validation
    let canPost = validateUser();
    if (canPost) {
      let data = {
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        userType: user.userType,
      };

      if (modalType === 'create') {
        createUserFunction(data);
      }
      if (modalType === 'edit') {
        editUserFunction(data);
      }
    }
  };

  const createUserFunction = (data) => {
    if (canPost) {
      setCanPost(false);
      createNewUser(data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Usuario Creado');
            getAllUsersFunction();
            handleCloseCreateUserModal();
            setCanPost(true);
            history.push(
              `/home/admin/user-management/${response.data.id}/general-info`
            );
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          setCanPost(true);
          console.log('error:', error);
          toast.error('Error creando usuario');
        });
    }
  };

  const editUserFunction = (data) => {
    if (canPost) {
      setCanPost(false);
      editUser(data, data.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Usuario Editado');
            getAllUsersFunction();
            handleCloseCreateUserModal();
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error creando usuario');
          setCanPost(true);
        });
    }
  };

  const handleUserChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUser({ ...user, [name]: value });
  };

  const options = [
    { id: 1, name: 'Grupo 1' },
    { id: 2, name: 'Grupo 2' },
    { id: 3, name: 'Grupo 3 ' },
  ];

  const deleteUserFunction = () => {
    if (canDelete) {
      setCanDelete(false);
      deleteUser(userToDelete?.id)
        .then((response) => {
          if (response?.status === 200) {
            toast.success('Usuario eliminado');
            getAllUsersFunction();
            handleCloseDeleteModal();
            setCanDelete(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          toast.error('Error al eliminar usuario');
          setCanDelete(true);
        });
    }
  };

  const getAllUsersFunction = () => {
    getContests()
      .then((response) => {
        if (response.status === 200) {
          let [selectedContest] = response.data.filter(
            (d) => d.isOpen === true
          );

          let params = {
            page: userList.number,
            size: 10,
            status: filter.rol,
            search: filter.filtro,
            estatus: filter.estatus,
            group: filter.group,
            award: filter.award,
            winner: filter.winner,
            empresa: filter.empresa
              ? filter.empresa.toString()
              : filter.empresa,
          };

          setIsloading(true);

          getSpecialAwardsFunction(selectedContest?.id);

          getSpecialAwardList(params, selectedContest?.id)
            .then((response) => {
              if (response.status === 200) {
                setUserList(response.data);
                setLoadCount(loadCount + 1);
                setIsloading(false);
              }
            })
            .catch((error) => {
              console.log('error:', error);
            });
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete('');
    setIsDeleteModalOpen(false);
  };

  const handlePageChange = (event, value) => {
    setUserList({ ...userList, number: value - 1 }); // -1 To remove page counter offset
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;
    setFilter({ ...filter, [name]: value });
    setUserList({ ...userList, number: 0 });
  };

  const getCompanyList = () => {
    getCompanies()
      .then((response) => {
        if (response.status === 200) {
          let companies = [];
          for (let i = 0; i < response.data.length; i++) {
            companies.push({
              id: response.data[i]?.id,
              name: response.data[i]?.businessName,
            });
          }
          setCompanyList(companies);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const disbableUser = (user) => {
    setIsDeactivateModalOpen(true);
    setUser(user);
  };

  const disableUser = () => {
    let usuarioEdit = user;
    usuarioEdit.active = !usuarioEdit.active;
    editUserFunction(usuarioEdit);
    handleCloseActivateModal();
  };

  const handleCloseActivateModal = () => {
    setUser('');
    setIsDeactivateModalOpen(false);
  };

  const getSpecialAwardsFunction = (contestId) => {
    getSpecialAwards(contestId)
      .then((response) => {
        if (response.status === 200) {
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              id: response.data[i].title,
              name: response.data[i].title,
            });
          }
          setAwardList(list);
        }
      })
      .catch(() => {
        toast.error('Error al conseguir premios');
      });
  };

  useEffect(() => {
    getAllUsersFunction();
    getCompanyList();
  }, [
    userList.number,
    filter.rol,
    filter.estatus,
    filter.empresa,
    filter.group,
    filter.award,
    filter.winner,
  ]);

  return (
    <>
      <div>
        <div className="dashboard-title-container">
          <h1>POSTULADOS A MENCIÓN ESPECIAL</h1>
        </div>
        <div className="row">
          <div className="contest-input-flex col-12 col-md-5">
            <div
              id="special-mention-container"
              className="user-filter-container"
            >
              <ContestInput
                handleChange={onChangeFilter}
                name="filtro"
                label="Buscar por palabra clave"
                value={filter?.filtro}
                errors={''}
                type={'text'}
                placeholder={''}
              />

              <ActionButton
                text={'Buscar'}
                action={() => getAllUsersFunction()}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="users-dropdown-container">
              <ContestSelectInput
                options={rolesOptions}
                handleChange={onChangeFilter}
                name="rol"
                label="Estatus"
                value={filter.rol}
                errors={''}
                type={'text'}
                placeholder={''}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="users-dropdown-container">
              <ContestSelectInput
                options={awardList}
                handleChange={onChangeFilter}
                name="award"
                label="Mención"
                value={filter.award}
                errors={''}
                type={'text'}
                placeholder={''}
              />
            </div>
          </div>
          <div className="col-3">
            <div
              id="status-special-mention-input"
              className="users-dropdown-container"
            >
              <ContestSelectInput
                options={statusSpecialMention}
                handleChange={onChangeFilter}
                name="winner"
                label="Estatus Mención Especial"
                value={filter.winner}
                errors={''}
                type={'text'}
                placeholder={''}
              />
            </div>
          </div>
        </div>

        <SpecialMentionTable
          data={userList}
          loadCount={loadCount}
          isLoading={isLoading}
          handleOpenDeleteModal={handleOpenDeleteModal}
          disbableUser={disbableUser}
          openEditModal={openEditModal}
          handlePageChange={handlePageChange}
          selectIdea={selectIdea}
        />
      </div>
    </>
  );
};
