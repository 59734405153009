import React from 'react';
import { IdeaListTable, AdminIdeaListTable } from '../../common/Tables/Tables';
import { AdminFilter } from './Filters';

import './IdeaList.scss';

export const IdeaList = (props) => {
  const {
    ideaList,
    handleIdeaListPageChange,
    selectIdea,
    deleteIdea,
    blockedLogin,
    stagesDropdowns,
  } = props;

  return (
    <React.Fragment>
      <div className="dashboard-title-container">
        <h1>IDEAS</h1>
      </div>
      <IdeaListTable
        currentPage={ideaList.currentPage}
        ideas={ideaList.ideas}
        pages={ideaList.pages}
        handleIdeaListPageChange={handleIdeaListPageChange}
        selectIdea={selectIdea}
        deleteIdea={deleteIdea}
        blockedLogin={blockedLogin}
        stagesDropdowns={stagesDropdowns}
      />
    </React.Fragment>
  );
};

export const AdminIdeaList = (props) => {
  const {
    userSession,
    adminIdeaList,
    handleAdminIdeaListPageChange,
    selectIdea,
    handleAdminRowsPerPage,
    AdvaceFilterinputChange,
    getAdminIdeaListFunction,
    clearAdvanceFilter,
    handleAdvanceFilter,
    makeAdvanceSearch,
    changeIdeaStatus,
    advanceFilerInputChange,
    cancelAdvanceSearch,
  } = props;

  return (
    <React.Fragment>
      <div className="dashboard-title-container">
        <h1>IDEAS</h1>
      </div>
      <AdminFilter
        userSession={userSession}
        adminIdeaList={adminIdeaList}
        AdvaceFilterinputChange={AdvaceFilterinputChange}
        getAdminIdeaListFunction={getAdminIdeaListFunction}
        clearAdvanceFilter={clearAdvanceFilter}
        handleAdvanceFilter={handleAdvanceFilter}
        makeAdvanceSearch={makeAdvanceSearch}
        advanceFilerInputChange={advanceFilerInputChange}
        cancelAdvanceSearch={cancelAdvanceSearch}
      />

      <AdminIdeaListTable
        adminIdeaList={adminIdeaList}
        currentPage={adminIdeaList.currentPage}
        ideas={adminIdeaList.ideas}
        pages={adminIdeaList.pages}
        handleAdminIdeaListPageChange={handleAdminIdeaListPageChange}
        selectIdea={selectIdea}
        changeIdeaStatus={changeIdeaStatus}
        handleAdminRowsPerPage={handleAdminRowsPerPage}
      />
    </React.Fragment>
  );
};
