import React from 'react';
import { IdeaFinalPresentationDropzone } from '../IdeaDetail/MembersDropzone';
import { ConfirmedElement } from '../../common/ConfirmedElement/ConfirmedElement';
import { ContestInput } from '../../common/ContestForms/ContestForms';
import dropbox from '../../../images/dropbox.png';
import drive from '../../../images/drive.png';
import youtube from '../../../images/youtube.png';
import vimeo from '../../../images/vimeo.png';
import dropboxBlue from '../../../images/dropboxblue.png';
import driveBlue from '../../../images/driveblue.png';
import youtubeBlue from '../../../images/youtubeblue.png';
import vimeoBlue from '../../../images/vimeoblue.png';
import deleteLink from '../../../images/delete.png';
// import { InfoCenter } from '../../common/InfoCenter/InfoCenter';
import './Stage1.scss';

// const infoCenterURL = process.env.REACT_APP_API_STAGE_3_INFO_CENTER;

export const UrlInputComponent = (props) => {
  const {
    handleInputChangeUrl,
    url,
    saveUrl,
    currentState,
    ideaStatus,
    isParticipant,
  } = props;

  if (currentState && ideaStatus === 'Finalista' && isParticipant) {
    return (
      <React.Fragment>
        <div>
          <ContestInput
            handleChange={handleInputChangeUrl}
            name="url"
            label="Vínculo o URL"
            type={'text'}
            errors={url.errorUrl}
            value={url.url}
          />
          <div className="helper-text">Máximo 200 caracteres</div>
          <center>
            <button
              onClick={() => saveUrl()}
              className="button save-button login-button"
            >
              <h6>Guardar</h6>
            </button>
          </center>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export const Stage3 = (props) => {
  const {
    ideaForEdition,
    removeDocumentsfromList,
    openBase64File,
    showStage,
    handleDragAndDropFinalPresentationFiles,
    addFinalPresentationUrlService,
    userSession,
    files,
    currentState,
    currentStatus,
  } = props;

  const initialUrl = {
    url: ideaForEdition.idea.finalPresentationUrl,
    errorUrl: '',
    service: '',
    helper: true,
  };

  const [url, setUrl] = React.useState(initialUrl);

  const handleInputChangeUrl = (event) => {
    url.errorUrl = '';
    setUrl({ ...url, [event.target.name]: event.target.value });
  };

  const validateUrl = () => {
    var canpost = true;
    url.errorUrl = '';
    setUrl({ ...url });

    if (url.url === '') {
      canpost = false;
      url.errorUrl = 'La URL es requerida';
      setUrl({ ...url });
    }

    if (url.url === null) {
      canpost = false;
      url.errorUrl = 'La URL es requerida';
      setUrl({ ...url });
    } else if (url.url === '') {
      canpost = false;
      url.errorUrl = 'La URL es requerida';
      setUrl({ ...url });
    } else if (url.url.length > 200) {
      canpost = false;
      url.errorUrl = 'La URL debe tener menos de 200 caracteres';
      setUrl({ ...url });
    } else if (!validUrl()) {
      canpost = false;
      url.errorUrl = 'La URL es inválida';
      setUrl({ ...url });
    }

    return canpost;
  };

  const validUrl = () => {
    const re =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return re.test(url.url);
  };

  const saveUrl = async () => {
    let canpost = validateUrl();

    if (canpost) {
      let data = {
        finalPresentationUrl: url.url,
      };
      addFinalPresentationUrlService(data, userSession.sessionToken);
      url.service = '';
      url.helper = false;
    }
  };

  const deleteUrl = async () => {
    let data = {
      finalPresentationUrl: '',
    };
    url.url = '';
    url.service = '';
    url.helper = false;
    addFinalPresentationUrlService(data, userSession.sessionToken);
  };

  const selectService = (service) => {
    url.service = service;
    url.helper = false;
    setUrl({ ...url });
  };

  if (showStage && currentStatus) {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  {/* <InfoCenter infoCenterURL={infoCenterURL} /> */}
                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      <ConfirmedElement
                        checked={
                          ideaForEdition.files.filter(
                            (i) => i.type === 'Final_presentation'
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                      <IdeaFinalPresentationDropzone
                        files={files}
                        ideaForEdition={ideaForEdition}
                        handleDragAndDropFiles={
                          handleDragAndDropFinalPresentationFiles
                        }
                        removeDocumentsfromList={removeDocumentsfromList}
                        openBase64File={openBase64File}
                        currentState={currentState}
                        ideaStatus={ideaForEdition.idea.stage.status}
                        isParticipant={
                          userSession.userType === 'participant' ? true : null
                        }
                      />
                    </div>
                  </div>
                  <div className="vertical-timeline-item vertical-timeline-element">
                    <div>
                      {' '}
                      <ConfirmedElement
                        checked={
                          ideaForEdition.idea.finalPresentationUrl
                            ? true
                            : false
                        }
                      />
                      <div className="vertical-timeline-element-content bounce-in">
                        <h4 className="timeline-title">
                          VIDEO PARA LA PRESENTACIÓN FINAL
                        </h4>
                        <p>
                          Seleccione el servicio donde tiene alojado su video
                          para ver las indicaciones para compartir su archivo.
                          Puede eliminar y guardar el URL tantas veces como sea
                          necesario mientras la Fase 3 esté activa. Se tomará en
                          cuenta para la evaluación la última URL guardada.
                        </p>
                        <div className="services-div">
                          <div
                            onClick={() => selectService('drive')}
                            className="button-service"
                          >
                            {url.service === 'drive' ||
                            (url.url !== null &&
                              url.url.includes('docs.google.com') &&
                              url.helper) ? (
                              <img src={driveBlue} alt="" />
                            ) : (
                              <img src={drive} alt="" />
                            )}
                          </div>
                          <div
                            onClick={() => selectService('youtube')}
                            className="button-service"
                          >
                            {url.service === 'youtube' ||
                            (url.url !== null &&
                              url.url.includes('youtube.com') &&
                              url.helper) ? (
                              <img src={youtubeBlue} alt="" />
                            ) : (
                              <img src={youtube} alt="" />
                            )}
                          </div>
                          <div
                            onClick={() => selectService('dropbox')}
                            className="button-service"
                          >
                            {url.service === 'dropbox' ||
                            (url.url !== null &&
                              url.url.includes('dropbox.com') &&
                              url.helper) ? (
                              <img src={dropboxBlue} alt="" />
                            ) : (
                              <img src={dropbox} alt="" />
                            )}
                          </div>
                          <div
                            onClick={() => selectService('vimeo')}
                            className="button-service"
                          >
                            {url.service === 'vimeo' ||
                            (url.url !== null &&
                              url.url.includes('vimeo.com') &&
                              url.helper) ? (
                              <img src={vimeoBlue} alt="" />
                            ) : (
                              <img src={vimeo} alt="" />
                            )}
                          </div>
                        </div>
                        <div className="info-services">
                          {url.service === 'drive' ? (
                            <div>
                              <h4>
                                Cómo compartir archivos desde Google Drive.
                              </h4>
                              <p>
                                1. En una computadora, ingresa a{' '}
                                <a
                                  href="https://drive.google.com/"
                                  target="_blank"
                                >
                                  Google Drive
                                </a>{' '}
                                e inicia sesión con tu cuenta.
                                <br></br>
                                2. Haz clic con el botón derecho en el archivo
                                que deseas compartir.
                                <br></br>
                                3. Haz clic en la opción Obtener enlace.
                                <br></br>
                                4. Selecciona la opción de compartir con
                                "Cualquier persona con el enlace" en modo
                                "Lector".
                                <br></br>
                                5. Copia y pega el vínculo aquí. Después guarda
                                los cambios en Google Drive.
                              </p>
                            </div>
                          ) : null}

                          {url.service === 'youtube' ? (
                            <div>
                              <h4>Cómo compartir archivos desde Youtube.</h4>
                              <p>
                                1. En una computadora, ingresa a{' '}
                                <a
                                  href="https://www.youtube.com/"
                                  target="_blank"
                                >
                                  Youtube
                                </a>{' '}
                                e inicia sesión con tu cuenta.
                                <br></br>
                                2. Ve a la sección de Mis Videos, ubicada en el
                                menú.
                                <br></br>
                                2. Haz clic en el menú de Opciones del archivo
                                que deseas compartir.
                                <br></br>
                                3. Haz clic en la opción Obtener enlace para
                                compartir.
                                <br></br>
                                4. De manera automática, el enlace se copiará en
                                tu portapapeles.
                                <br></br>
                                5. Pega el vínculo aquí.
                              </p>
                            </div>
                          ) : null}

                          {url.service === 'dropbox' ? (
                            <div>
                              <h4>Cómo compartir archivos desde Dropbox.</h4>
                              <p>
                                1. En una computadora, ingresa a{' '}
                                <a
                                  href="https://www.dropbox.com/"
                                  target="_blank"
                                >
                                  Dropbox
                                </a>{' '}
                                e inicia sesión con tu cuenta.
                                <br></br>
                                2. Haz clic en el menú de Opciones del archivo
                                que deseas compartir.
                                <br></br>
                                3. Haz clic en la opción Copiar Vínculo.
                                <br></br>
                                4. Copia y pega el vínculo aquí.
                              </p>
                            </div>
                          ) : null}

                          {url.service === 'vimeo' ? (
                            <div>
                              <h4>Cómo compartir archivos desde Vimeo.</h4>
                              <p>
                                1. En una computadora, ingresa a{' '}
                                <a href="https://vimeo.com/es" target="_blank">
                                  Vimeo
                                </a>{' '}
                                e inicia sesión con tu cuenta.
                                <br></br>
                                2. Ve a la sección de Videos, ubicada en el
                                menú.
                                <br></br>
                                3. Haz clic en la opción Copiar Vínculo del
                                archivo que deseas compartir.
                                <br></br>
                                4. Selecciona la opción Copiar el Vínculo del
                                Video.
                                <br></br>
                                5. De manera automática, el enlace se copiará en
                                tu portapapeles.
                                <br></br>
                                6. Pega el vínculo aquí.
                              </p>
                            </div>
                          ) : null}
                        </div>

                        {ideaForEdition.idea.finalPresentationUrl === '' ||
                        ideaForEdition.idea.finalPresentationUrl === null ? (
                          <UrlInputComponent
                            currentState={currentState}
                            handleInputChangeUrl={handleInputChangeUrl}
                            url={url}
                            saveUrl={saveUrl}
                            ideaStatus={ideaForEdition.idea.stage.status}
                            isParticipant={
                              userSession.userType === 'participant'
                                ? true
                                : null
                            }
                          />
                        ) : (
                          <div className="left-align save-url">
                            <h4>
                              Vínculo o URL de mi video <br></br>
                              <br></br>{' '}
                              <a href={url.url} target="_blank">
                                {url.url}
                              </a>
                            </h4>
                            {currentState &&
                            ideaForEdition.idea.stage.status === 'Finalista' &&
                            userSession.userType === 'participant' ? (
                              <React.Fragment>
                                <img
                                  src={deleteLink}
                                  className="delete-img-link"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="Eliminar URL"
                                  alt="Eliminar URL"
                                  onClick={() => deleteUrl()}
                                />
                              </React.Fragment>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};
