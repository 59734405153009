import './AwardCard.scss';
import React from 'react';
import checkbox from '../../../images/checkbox.png';
import checkbox_unchecked from '../../../images/checkbox_unchecked.png';

export const AwardCard = (props) => {
  const {
    name,
    description,
    competing,
    index,
    currentState,
    ideaStatus,
    isParticipant,
    handleAwardOnChange,
  } = props;

  return (
    <div className="award-card">
      <div>
        {currentState && isParticipant && ideaStatus === 'Semifinalista' ? (
          <img
            className="checkbox-img"
            src={competing ? checkbox : checkbox_unchecked}
            alt=""
            onClick={() => handleAwardOnChange(index)}
          />
        ) : (
          <img
            className="checkbox-img"
            src={competing ? checkbox : checkbox_unchecked}
            alt=""
          />
        )}
        <h5>{name}</h5>
      </div>
      <p>{description}</p>
    </div>
  );
};
