/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { ActionButton } from '../../../common/Buttons/Buttons';
import { NavLink, useParams, Switch, Route } from 'react-router-dom';
import { JudgeGeneralInfo } from './JudgeGeneralInfo/JudgeGeneralInfo';
import { Preferences } from './Preferences/Preferences';
import {
  getUserById,
  editUserFromAdmin,
} from '../../../../services/userListService.js';
import { translateUserType } from '../../../../helpers/translate';

import { getCompanies } from '../../../../services/companyService';
import { regularExpression } from '../../../../helpers/regex.js';

import './JudgeDetail.scss';
import { toast } from 'react-toastify';

const JudgeDetailsTab = (props) => {
  const params = useParams();
  const { tab, user } = props;

  let pathArray = window.location.pathname.split('/');

  let selected = 'gray';
  let barColor = '#f2f2f2';
  if (pathArray[4] === tab.url) {
    selected = '#30377b';
    barColor = '#30377b';
  }

  if (['general-info', 'preferences'].includes(tab.url)) {
    return (
      <div className="users-detail-tab-component">
        <NavLink to={`/home/judge/settings/${tab.url}`}>
          <h6 style={{ color: selected }}>{tab.label}</h6>
        </NavLink>
        <div style={{ backgroundColor: barColor }}></div>
      </div>
    );
  } else {
    return null;
  }
};

export const JudgeDetail = () => {
  const [editInfo, setEditInfo] = useState({
    email: { edit: false, value: '', error: '' },
    phoneHome: { edit: false, value: '', error: '' },
    phoneMobile: { edit: false, value: '', error: '' },
    phoneOther: { edit: false, value: '', error: '' },
  });

  const enableEdit = (name) => {
    setEditInfo({
      ...editInfo,
      [name]: {
        edit: !editInfo[name].edit,
        value: user[name],
        error: editInfo[name].error,
      },
    });
  };

  const [user, setUser] = useState({
    errors: {
      name: '',
      lastName: '',
      email: '',
      birthDate: '',
      gender: '',
      phoneHome: '',
      phoneMobile: '',
      phoneOther: '',
      state: '',
      address: '',
      business: '',
      companyPosition: '',
    },
  });

  const [canPost, setCanPost] = useState(true);

  const tabs = [
    { label: 'Información General', url: 'general-info' },
    { label: 'Preferencias', url: 'preferences' },
  ];

  const getUserData = () => {
    let user = sessionStorage.getItem('userSession');
    user = JSON.parse(user);

    getUserById(user?.userId)
      .then((response) => {
        if (response.status === 200) {
          let userData = response.data;

          userData.gender = response.data?.gender?.id?.toString();
          userData.business = response.data?.business?.id;

          if (response.data?.birthDate) {
            let [newBirthdateFormat, rest] =
              response.data?.birthDate?.split('T');
            userData.birthDate = newBirthdateFormat;
          }

          setUser({ ...user, ...userData });
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const onChangeUser = (e) => {
    const { name, value } = e.target;

    setEditInfo({
      ...editInfo,
      [name]: {
        edit: editInfo[name].edit,
        value: value,
        error: editInfo[name].error,
      },
    });
  };

  const validateUser = (data) => {
    let canPost = true;
    //Delete error messages

    let errorString = {
      email: '',
      birthDate: '',
      phoneHome: '',
      phoneMobile: '',
      phoneOther: '',
    };

    // Evaluate required inputs
    if (data?.email == '') {
      errorString.email = 'email';
      canPost = false;
    }

    if (!regularExpression.emailFormat.test(String(data.email))) {
      errorString.email = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    if (
      data?.phoneHome &&
      (data?.phoneHome.length < 10 || data?.phoneHome.length > 12)
    ) {
      errorString.phoneHome = 'Ingrese un Teléfono valido';
      canPost = false;
    }

    if (
      data?.phoneMobile &&
      (data?.phoneMobile.length < 10 || data?.phoneMobile.length > 12)
    ) {
      errorString.phoneMobile = 'Ingrese un Teléfono valido';
      canPost = false;
    }

    if (
      data?.phoneOther &&
      (data?.phoneOther.length < 10 || data?.phoneOther.length > 12)
    ) {
      errorString.phoneOther = 'Ingrese un Teléfono valido';
      canPost = false;
    }

    if (
      data?.phoneOther &&
      data?.phoneMobile &&
      data?.phoneOther === data?.phoneMobile
    ) {
      errorString.phoneOther = 'Teléfono repetido';
      errorString.phoneMobile = 'Teléfono repetido';
      canPost = false;
    }

    if (
      data?.phoneHome &&
      data?.phoneMobile &&
      data?.phoneHome === data?.phoneMobile
    ) {
      errorString.phoneHome = 'Teléfono repetido';
      errorString.phoneMobile = 'Teléfono repetido';
      canPost = false;
    }

    if (
      data?.phoneHome &&
      data?.phoneOther &&
      data?.phoneHome === data?.phoneOther
    ) {
      errorString.phoneHome = 'Teléfono repetido';
      errorString.phoneOther = 'Teléfono repetido';
      canPost = false;
    }

    setEditInfo({
      ...editInfo,
      ['email']: {
        edit: editInfo['email'].edit,
        value: editInfo['email'].value,
        error: errorString.email,
      },
      ['phoneHome']: {
        edit: editInfo['phoneHome'].edit,
        value: editInfo['phoneHome'].value,
        error: errorString.phoneHome,
      },
      ['phoneMobile']: {
        edit: editInfo['phoneMobile'].edit,
        value: editInfo['phoneMobile'].value,
        error: errorString.phoneMobile,
      },
      ['phoneOther']: {
        edit: editInfo['phoneOther'].edit,
        value: editInfo['phoneOther'].value,
        error: errorString.phoneOther,
      },
    });

    return canPost;
  };

  const editUserFunction = () => {
    let data = {
      userType: user.userType,
      email: editInfo?.email?.value ? editInfo?.email?.value : user.email,
      name: user.name,
      lastName: user.lastName,
      birthDate: user.birthDate,
      gender: parseInt(user.gender),
      phoneHome: editInfo?.phoneHome?.value
        ? editInfo?.phoneHome?.value
        : user.phoneHome,
      phoneMobile: user.phoneMobile,
      phoneMobile: editInfo?.phoneMobile?.value
        ? editInfo?.phoneMobile?.value
        : user.phoneMobile,
      phoneOther: editInfo?.phoneOther?.value
        ? editInfo?.phoneOther?.value
        : user.phoneOther,
      state: parseInt(user.state),
      address: user.address,
      business: user.business ? { id: parseInt(user.business) } : user.business,
      companyPosition: user.companyPosition,
      active: user.active,
    };

    let isValid = validateUser(data);

    if (canPost && isValid) {
      setCanPost(false);
      editUserFromAdmin(data, user.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Usuario Editado');
            setTimeout(() => {
              setCanPost(true);
              window.location.reload();
            }, 1500);
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          setCanPost(true);
        });
    }
  };

  const cancelUserEdition = () => {
    window.location.reload();
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div>
      <div className="dashboard-title-container">
        <h1 style={{ textTransform: 'uppercase' }}>
          <NavLink to="/home/judge/settings/user-management">
            <span style={{ color: 'gray' }}>USUARIOS</span>
          </NavLink>
          {'   '}> DETALLES DEL USUARIO {user?.name} {user?.lastName}
        </h1>
      </div>
      <div className="user-detail-round-container-component">
        <div>
          <div className="round-name">
            <h1>
              {user?.name?.substring(0, 1)}
              {user?.lastName?.substring(0, 1)}
            </h1>
          </div>
          {user.active ? (
            <ion-icon
              style={{
                color: '#32CD32',
                transform: 'rotate(180deg)',
                fontSize: '50px',
                cursor: 'pointer',
              }}
              name="toggle"
            ></ion-icon>
          ) : (
            <ion-icon
              style={{
                color: 'gray',
                fontSize: '50px',
                cursor: 'pointer',
              }}
              name="toggle"
            ></ion-icon>
          )}
        </div>
        <div className="user-detail-info-container">
          <div className="user-detail-info">
            <h2>
              {user?.name} {user?.lastName}
            </h2>
            <h6>{translateUserType(user?.userType)}</h6>
            <h6>{user?.email}</h6>
          </div>
        </div>
      </div>
      <div className="user-details-tabs-container">
        {tabs.map((t) => (
          <JudgeDetailsTab tab={t} user={user} />
        ))}
      </div>
      <Switch>
        <Route
          path="/home/judge/settings/general-info"
          render={(props) => {
            return (
              <JudgeGeneralInfo
                {...props}
                user={user}
                editInfo={editInfo}
                enableEdit={enableEdit}
                onChange={onChangeUser}
                cancelEdition={cancelUserEdition}
                save={editUserFunction}
              />
            );
          }}
        />
        <Route
          path="/home/judge/settings/preferences"
          render={(props) => {
            return <Preferences {...props} />;
          }}
        />
      </Switch>
    </div>
  );
};
