import React, { useEffect } from 'react';
import CharacterCounter from 'react-character-counter';
import {
  SaveUserButton,
  EditUserButton,
  SavePasswordButton,
} from '../Buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ContestInput,
  ContestSelectInput,
  ContestGenderInput,
  ContestCedulaInput,
  ContestAgeInput,
  ConfirmInput,
  EmploymentSituation,
  ContestStartupInput,
} from '../../common/ContestForms/ContestForms';
import { getContests } from '../../../services/contestService';
import { toast } from 'react-toastify';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import 'react-datepicker/dist/react-datepicker.css';
import './Forms.scss';

export const ConfirmDataForm = (props) => {
  const {
    basicData,
    states,
    educationalInstitution,
    educationalLevels,
    economicSectors,
  } = props;
  return (
    <React.Fragment>
      <h6 className="data-type-label">Datos Básicos</h6>

      <div className="confirm-basic-data-display row">
        <div className="contest-input-flex col-12 col-md-6">
          <ConfirmInput label="Nombre" value={basicData.data.name} />
          <ConfirmInput label="Apellido" value={basicData.data.lastName} />
          <ConfirmInput label="Cédula de Identidad" value={basicData.data.id} />
          <ConfirmInput
            label="Fecha de Nacimiento"
            value={
              basicData.data.birthDate !== ''
                ? basicData.data.birthDate.toLocaleDateString('es-VE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                : ''
            }
          />
          <ConfirmInput
            label="Sexo"
            value={basicData.data.gender === '1' ? 'Femenino' : 'Masculino'}
          />
          <ConfirmInput
            label="Teléfono de Habitación"
            value={basicData.data.phoneNumber}
          />
          <ConfirmInput
            label="Teléfono Móvil"
            value={basicData.data.mobileNumber}
          />
          <ConfirmInput
            label="Teléfono Oficina / Otro"
            value={basicData.data.otherNumber}
          />
          <ConfirmInput label="Dirección" value={basicData.data.address} />
          <ConfirmInput
            label="Estado"
            value={states
              .filter((a) => a.id == basicData.data.state)
              .map((a) => a.name)}
          />
        </div>
      </div>
      {basicData.data.educationLevel || basicData.data.academicInstitution ? (
        <React.Fragment>
          <h6 className="data-type-label">Datos Acádemicos (Opcional)</h6>
          <div className="confirm-basic-data-display row">
            <div className="contest-input-flex col-12 col-md-6">
              <ConfirmInput
                label="Institución Educativa"
                value={educationalInstitution
                  .filter((a) => a.id == basicData.data.academicInstitution)
                  .map((a) => a.name)}
              />
              <ConfirmInput
                label="Nivel Educativo"
                value={educationalLevels
                  .filter((a) => a.id == basicData.data.educationLevel)
                  .map((a) => a.name)}
              />
            </div>
          </div>
        </React.Fragment>
      ) : null}
      {basicData.data.employmentState ? (
        <h6 className="data-type-label">Datos Laborales (Opcional)</h6>
      ) : null}
      <div className="row confirm-basic-data-display">
        <div className="contest-input-flex col-12 col-md-6">
          {basicData.data.economicSector ? (
            <ConfirmInput
              label="Sector Económico"
              value={economicSectors
                .filter((a) => a.id == basicData.data.economicSector)
                .map((a) => a.name)}
            />
          ) : null}
          {basicData.data.company ? (
            <ConfirmInput label="Empresa" value={basicData.data.company} />
          ) : null}
          {basicData.data.charge ? (
            <ConfirmInput
              label="Cargo en la Empresa"
              value={basicData.data.charge}
            />
          ) : null}

          {basicData.data.startUpDesctiption ? (
            <ConfirmInput
              label="Emprendimiento"
              value={basicData.data.startUpDesctiption}
              desctiption={true}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export const EditUserFromAdminForm = (props) => {
  const { data, onChange, stateList, companyList } = props;
  return (
    <React.Fragment>
      <h6 className="data-type-label">Información Personal</h6>

      <div className="row">
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="name"
            label="Nombre"
            value={data?.name}
            errors={data?.errors?.name}
            type={'text'}
            placeholder={''}
          />
          <div className="helper-text">Máximo 25 caracteres</div>
        </div>

        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="lastName"
            label="Apellido"
            value={data?.lastName}
            errors={data?.errors?.lastName}
            type={'text'}
            placeholder={''}
          />
          <div className="helper-text">Máximo 25 caracteres</div>
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="email"
            label="Correo"
            value={data?.email}
            errors={data?.errors?.email}
            type={'text'}
            placeholder={''}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="idNumber"
            label="Cédula"
            value={data?.idNumber}
            errors={data?.errors?.idNumber}
            type={'number'}
            placeholder={'14567928'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestAgeInput
            name="birthDate"
            label={'Fecha de Nacimiento'}
            handleChange={onChange}
            selected={data?.birthDate}
            errors={data?.errors?.birthDate}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestGenderInput
            handleChange={onChange}
            name="gender"
            label="Sexo"
            value={data?.gender}
            errors={data?.errors?.gender}
          />
        </div>

        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="phoneHome"
            label="Teléfono de Habitación"
            value={data?.phoneHome}
            errors={data?.errors?.phoneHome}
            type={'number'}
            placeholder={'0212 555555'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="phoneMobile"
            label="Teléfono Móvil"
            value={data?.phoneMobile}
            errors={data?.errors?.phoneMobile}
            type={'number'}
            placeholder={'0414 555555'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="phoneOther"
            label="Teléfono Oficina / Otro"
            value={data?.phoneOther}
            errors={data?.errors?.phoneOther}
            type={'number'}
            placeholder={'0212 555555'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={stateList}
            handleChange={onChange}
            name="state"
            label="Estado"
            value={data?.state?.id}
            errors={data?.errors?.state}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="address"
            label="Dirección"
            value={data?.address}
            errors={data?.errors?.address}
            type={'text'}
            placeholder={'Calle, Avenida, Municipio, Casa o Edif.'}
          />
          <div className="helper-text">Máximo 100 caracteres</div>
        </div>
      </div>

      <h6 className="data-type-label">Datos Laborales </h6>
      <div className="row">
        <React.Fragment>
          <div className="contest-input-flex col-12 col-md-6">
            <ContestSelectInput
              options={companyList}
              handleChange={onChange}
              name="business"
              label="Empresa"
              value={data?.business}
              errors={data?.errors?.business}
              type={'text'}
              placeholder={'Seleccione una opción...'}
            />
          </div>

          <div className="contest-input-flex col-12 col-md-6">
            <ContestInput
              handleChange={onChange}
              name="companyPosition"
              label="Cargo en la Empresa"
              value={data?.companyPosition}
              errors={data?.errors?.companyPosition}
              type={'text'}
              placeholder={'Escriba el cargo'}
            />
            <div className="helper-text">Máximo 100 caracteres</div>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export const EditCompanyForm = (props) => {
  const { data, onChange, sectorList, statesList } = props;
  return (
    <React.Fragment>
      <h6 className="data-type-label">Información Básica</h6>

      <div className="row">
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="businessName"
            label="Nombre *"
            value={data?.businessName}
            errors={data?.errors?.businessName}
            type={'text'}
            placeholder={''}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={statesList}
            handleChange={onChange}
            name="state"
            label="Estado"
            value={data?.state}
            errors={data?.errors?.state}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={sectorList}
            handleChange={onChange}
            name="economicSector"
            label="Sector *"
            value={data?.economicSector}
            errors={data?.errors?.economicSector}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="address"
            label="Dirección"
            value={data?.address}
            errors={data?.errors?.address}
            type={'text'}
            placeholder={''}
          />
        </div>{' '}
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="email"
            label="Email Principal"
            value={data?.email}
            errors={data?.errors?.email}
            type={'text'}
            placeholder={''}
          />
        </div>{' '}
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="phone"
            label="Teléfono"
            value={data?.phone}
            errors={data?.errors?.phone}
            type={'number'}
            placeholder={''}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={onChange}
            name="postalCode"
            label="Código Postal"
            value={data?.postalCode}
            errors={data?.errors?.postalCode}
            type={'number'}
            placeholder={''}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

// Form that handles the basic data info
export const BasicDataForm = (props) => {
  const {
    basicData,
    basicDataInputChange,
    handleActDateChange,
    states,
    educationalInstitution,
    educationalLevels,
    economicSectors,
  } = props;
  return (
    <React.Fragment>
      <h6 className="call-to-action">Introduzca sus datos personales</h6>
      <h6 className="data-type-label">Datos Básicos</h6>

      <div className="row">
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={basicDataInputChange}
            name="name"
            label="Nombre"
            value={basicData?.data?.name}
            errors={basicData?.errors?.name}
            type={'text'}
            placeholder={''}
          />
          <div className="helper-text">Máximo 25 caracteres</div>
        </div>

        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={basicDataInputChange}
            name="lastName"
            label="Apellido"
            value={basicData?.data?.lastName}
            errors={basicData?.errors?.lastName}
            type={'text'}
            placeholder={''}
          />
          <div className="helper-text">Máximo 25 caracteres</div>
        </div>

        <div className="contest-input-flex col-12 col-md-6">
          <ContestCedulaInput
            handleChange={basicDataInputChange}
            label="Cédula de Identidad"
            idType={basicData?.data?.idType}
            id={basicData?.data?.id}
            nameIdType="idType"
            name_id="id"
            errors={
              basicData?.errors?.id
                ? basicData?.errors?.id
                : basicData?.errors?.idType
            }
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestAgeInput
            label={'Fecha de Nacimiento'}
            handleChange={handleActDateChange}
            selected={basicData?.data?.birthDate}
            errors={basicData?.errors?.birthDate}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestGenderInput
            handleChange={basicDataInputChange}
            name="gender"
            label="Sexo"
            value={basicData?.data?.gender}
            errors={basicData?.errors?.gender}
          />
        </div>

        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={basicDataInputChange}
            name="phoneNumber"
            label="Teléfono de Habitación"
            value={basicData?.data?.phoneNumber}
            errors={basicData?.errors?.phoneNumber}
            type={'number'}
            placeholder={'0212 555555'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={basicDataInputChange}
            name="mobileNumber"
            label="Teléfono Móvil"
            value={basicData?.data?.mobileNumber}
            errors={basicData?.errors?.mobileNumber}
            type={'number'}
            placeholder={'0414 555555'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={basicDataInputChange}
            name="otherNumber"
            label="Teléfono Oficina / Otro"
            value={basicData?.data?.otherNumber}
            errors={basicData?.errors?.otherNumber}
            type={'number'}
            placeholder={'0212 555555'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={states}
            handleChange={basicDataInputChange}
            name="state"
            label="Estado"
            value={basicData?.data?.state}
            errors={basicData?.errors?.state}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={basicDataInputChange}
            name="address"
            label="Dirección"
            value={basicData?.data?.address}
            errors={basicData?.errors?.address}
            type={'text'}
            placeholder={'Calle, Avenida, Municipio, Casa o Edif.'}
          />
          <div className="helper-text">Máximo 100 caracteres</div>
        </div>
      </div>

      <h6 className="data-type-label">Datos Academicos (Opcional)</h6>
      <div className="row">
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={educationalLevels}
            handleChange={basicDataInputChange}
            name="educationLevel"
            label="Nivel Educativo"
            value={basicData?.data?.educationLevel}
            errors={basicData?.errors?.educationLevel}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={educationalInstitution}
            handleChange={basicDataInputChange}
            name="academicInstitution"
            label="Institución Educativa"
            value={basicData?.data?.academicInstitution}
            errors={basicData?.errors?.academicInstitution}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
      </div>
      <h6 className="data-type-label">Datos Laborales (Opcional)</h6>
      <h6>¿Situación actual de Empleo?</h6>
      <div className="row">
        <EmploymentSituation
          value={basicData?.data?.employmentState}
          handleChange={basicDataInputChange}
          name={'employmentState'}
        />

        {basicData?.data?.employmentState ? (
          <React.Fragment>
            <div className="contest-input-flex col-12 col-md-6">
              <ContestSelectInput
                options={economicSectors}
                handleChange={basicDataInputChange}
                name="economicSector"
                label="Sector Económico"
                value={basicData?.data?.economicSector}
                errors={basicData?.errors?.economicSector}
                type={'text'}
                placeholder={'Seleccione una opción...'}
              />
            </div>

            {basicData.data.employmentState === '1' ||
            basicData.data.employmentState === '3' ? (
              <div className="contest-input-flex col-12 col-md-6">
                <ContestInput
                  handleChange={basicDataInputChange}
                  name="company"
                  label="Empresa"
                  value={basicData?.data?.company}
                  errors={basicData?.errors?.company}
                  type={'text'}
                  placeholder={'Escriba el nombre'}
                />
                <div className="helper-text">Máximo 100 caracteres</div>
              </div>
            ) : null}

            {basicData?.data?.employmentState === '1' ||
            basicData?.data?.employmentState === '3' ? (
              <div className="contest-input-flex col-12 col-md-6">
                <ContestInput
                  handleChange={basicDataInputChange}
                  name="charge"
                  label="Cargo en la Empresa"
                  value={basicData?.data?.charge}
                  errors={basicData?.errors?.charge}
                  type={'text'}
                  placeholder={'Escriba el cargo'}
                />
                <div className="helper-text">Máximo 100 caracteres</div>
              </div>
            ) : null}

            {basicData?.data?.employmentState === '2' ||
            basicData?.data?.employmentState === '3' ? (
              <div className="contest-input-flex col-12 col-md-6">
                <ContestStartupInput
                  handleChange={basicDataInputChange}
                  name="startUpDesctiption"
                  label="Emprendimiento"
                  value={basicData?.data?.startUpDesctiption}
                  errors={basicData?.errors?.startUpDesctiption}
                  type={'text'}
                  placeholder={
                    'Escriba una descripción de su emprendimiento...'
                  }
                />
                <div className="helper-text">Máximo 100 caracteres</div>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export const CreateIdeaFormStep1 = (props) => {
  const {
    createIdea,
    createIdeaInputChange,
    proposalType,
    socialType,
    ocupation,
    statesList,
  } = props;

  /*
  /* Proposal 2-> Business / 3-> Social
  /* Social Type 2-> Individual / 3-> Organization
  */

  let auxiliarDisplay =
    createIdea.data.proposalType === '2' ||
    (createIdea.data.proposalType === '3' &&
      createIdea.data.socialType === '2');

  let auxiliarDisplaySocial =
    createIdea.data.proposalType === '3' && createIdea.data.socialType === '3';

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response.status == 200) {
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        let [currentStage] = selectedContest?.stages.filter(
          (s) =>
            s.stage === 'Fase 1' &&
            s.type === 'PARTICIPANTE' &&
            s.status === 'Registrado'
        );
        sessionStorage.setItem('contestId', selectedContest?.id);
        sessionStorage.setItem('stageId', currentStage?.id);
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  useEffect(() => {
    getContestFunction();
  }, []);
  return (
    <React.Fragment>
      <h6 className="call-to-action">Seleccione su tipo de propuesta</h6>
      <div className="contest-input-flex col-12 col-md-6">
        <ContestSelectInput
          options={proposalType}
          handleChange={createIdeaInputChange}
          name="proposalType"
          label="Tipo de propuesta"
          value={createIdea.data.proposalType}
          errors={createIdea.errors.proposalType}
          type={'text'}
          placeholder={'Seleccione una opción...'}
        />
      </div>
      {createIdea.data.proposalType === '3' ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={socialType}
            handleChange={createIdeaInputChange}
            name="socialType"
            label="Usted forma parte de"
            value={createIdea.data.socialType}
            errors={createIdea.errors.socialType}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
      ) : null}
      {auxiliarDisplay ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={ocupation}
            handleChange={createIdeaInputChange}
            name="ocupation"
            label="Ocupación actual"
            value={createIdea.data.ocupation}
            errors={createIdea.errors.ocupation}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={createIdeaInputChange}
            name="ongName"
            label="Nombre de la ONG"
            value={createIdea.data.ongName}
            errors={createIdea.errors.ongName}
            type={'text'}
            placeholder={'Ingrese el nombre de la organización'}
          />
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6 helper-text">
          Máximo 150 caracteres
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={createIdeaInputChange}
            name="ongAddress"
            label="Dirección de la ONG"
            value={createIdea.data.ongAddress}
            errors={createIdea.errors.ongAddress}
            type={'text'}
            placeholder={'Ingrese la dirección de la organización'}
          />
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6 helper-text">
          Máximo 250 caracteres
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestSelectInput
            options={statesList}
            handleChange={createIdeaInputChange}
            name="ongState"
            label="Estado de la ONG"
            value={createIdea.data.ongState}
            errors={createIdea.errors.ongState}
            type={'text'}
            placeholder={'Seleccione una opción...'}
          />
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={createIdeaInputChange}
            name="ongEmail"
            label="Correo electrónico de la ONG"
            value={createIdea.data.ongEmail}
            errors={createIdea.errors.ongEmail}
            type={'text'}
            placeholder={'Ingrese el correo electrónico de la organización'}
          />
        </div>
      ) : null}

      {auxiliarDisplaySocial ? (
        <div className="contest-input-flex col-12 col-md-6">
          <ContestInput
            handleChange={createIdeaInputChange}
            name="ongPhone"
            label="Teléfono de la ONG"
            value={createIdea.data.ongPhone}
            errors={createIdea.errors.ongPhone}
            type={'number'}
            placeholder={'0414 5555555'}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const CreateIdeaFormStep2 = (props) => {
  const { createIdea, createIdeaInputChange, statesList, sectors } = props;

  let auxiliarDisplay =
    createIdea.data.lastThreeYears === '1' && createIdea.data.firstTime === '0';

  return (
    <React.Fragment>
      <h6 className="call-to-action">Ingrese los datos de la Idea </h6>
      <div className="contest-input-flex col-12 col-md-6 helper-counter">
        <CharacterCounter value={createIdea.data.title} maxLength={100}>
          <ContestInput
            handleChange={createIdeaInputChange}
            name="title"
            label="Título de la idea"
            value={createIdea.data.title}
            errors={createIdea.errors.title}
            type={'text'}
            placeholder={'Escriba el título de su idea'}
            maxLength={100}
          />
        </CharacterCounter>
      </div>

      <div className="contest-input-flex col-12 col-md-6">
        <ContestSelectInput
          options={sectors}
          handleChange={createIdeaInputChange}
          name="sector"
          label="Sector"
          value={createIdea.data.sector}
          errors={createIdea.errors.sector}
          type={'text'}
          placeholder={'Seleccione una opción...'}
        />
      </div>

      <div className="contest-input-flex col-12 col-md-6">
        <ContestSelectInput
          options={statesList}
          handleChange={createIdeaInputChange}
          name="state"
          label="Estado"
          value={createIdea.data.state}
          errors={createIdea.errors.state}
          type={'text'}
          placeholder={'Seleccione una opción...'}
        />
      </div>

      <div className="questions-select-container col-12 col-md-8 row">
        <div className="col-12 col-md-6 col-lg-6">
          <h6> ¿Primera vez que participa? </h6>
        </div>

        <div className="col-12 col-md-6 col-lg-6 input-question-idea">
          <input
            className="radio-input"
            onChange={createIdeaInputChange}
            checked={createIdea.data.firstTime === '1'}
            type="radio"
            value="1"
            name="firstTime"
          />
          <h6>Si</h6>
          <input
            className="radio-input"
            onChange={createIdeaInputChange}
            checked={createIdea.data.firstTime === '0'}
            type="radio"
            value="0"
            name="firstTime"
          />
          <h6>No</h6>
        </div>
      </div>

      {createIdea.data.firstTime === '0' ? (
        <div className="questions-select-container col-12 col-md-8 row">
          <div className="col-12 col-md-6 col-lg-6">
            <h6> ¿Ha participado en los últimos 3 años? </h6>
          </div>

          <div className="col-12 col-md-6 col-lg-6 input-question-idea">
            <input
              className="radio-input"
              onChange={createIdeaInputChange}
              checked={createIdea.data.lastThreeYears === '1'}
              type="radio"
              value="1"
              name="lastThreeYears"
            />
            <h6>Si</h6>
            <input
              className="radio-input"
              onChange={createIdeaInputChange}
              checked={createIdea.data.lastThreeYears === '0'}
              type="radio"
              value="0"
              name="lastThreeYears"
            />
            <h6>No</h6>
          </div>
        </div>
      ) : null}

      {auxiliarDisplay ? (
        <div className="questions-select-container col-12 col-md-8 row">
          <div className="col-12 col-md-6 col-lg-6">
            <h6>
              {' '}
              ¿Su idea es nueva o es una idea mejorada de la participación
              anterior?{' '}
            </h6>
          </div>

          <div className="col-12 col-md-6 col-lg-6 input-question-idea">
            <input
              className="radio-input"
              onChange={createIdeaInputChange}
              checked={createIdea.data.newIdea === '1'}
              type="radio"
              value="1"
              name="newIdea"
            />
            <h6>Es nueva</h6>
            <input
              className="radio-input"
              onChange={createIdeaInputChange}
              checked={createIdea.data.newIdea === '0'}
              type="radio"
              value="0"
              name="newIdea"
            />
            <h6>Es mejorada</h6>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const ConfirmIdeaForm = (props) => {
  const {
    createIdea,
    proposalType,
    socialType,
    ocupation,
    statesList,
    sectors,
  } = props;

  /*
    /* Proposal 2-> Business / 3-> Social
    /* Social Type 2-> Individual / 3-> Organization
    */

  let auxiliarDisplayStep1 =
    createIdea.data.proposalType === '2' ||
    (createIdea.data.proposalType === '3' &&
      createIdea.data.socialType === '2');
  let auxiliarDisplayStep2 =
    createIdea.data.lastThreeYears === '1' && createIdea.data.firstTime === '0';
  let auxiliarDisplayOng =
    createIdea.data.proposalType === '3' && createIdea.data.socialType === '3';
  return (
    <React.Fragment>
      <h6 className="call-to-action">
        Confirme que los datos de su Idea estén correctos
      </h6>
      <div className="col-12 row justify-content-md-center row-confirm">
        <div className="col-lg-2 col-sm-6 gray-data-label">
          <h6>Tipo de Propuesta</h6>
        </div>
        <div className="col-12 col-md-4">
          <p>
            {proposalType
              .filter((a) => a.id == createIdea.data.proposalType)
              .map((a) => a.name)}
          </p>
        </div>
      </div>

      {createIdea.data.proposalType === '3' ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Usted forma parte de</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>
              {socialType
                .filter((a) => a.id == createIdea.data.socialType)
                .map((a) => a.name)}
            </p>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayStep1 ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Ocupación</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>
              {ocupation
                .filter((a) => a.id == createIdea.data.ocupation)
                .map((a) => a.name)}
            </p>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayOng ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Nombre de la ONG</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>{createIdea.data.ongName}</p>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayOng ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Dirección de la ONG</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>{createIdea.data.ongAddress}</p>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayOng ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Estado de la ONG</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>
              {statesList
                .filter((a) => a.id == createIdea.data.ongState)
                .map((a) => a.name)}
            </p>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayOng ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Correo electrónico de la ONG</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>{createIdea.data.ongEmail}</p>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayOng ? (
        <div className="col-12 row justify-content-md-center row-confirm">
          <div className="col-lg-2 col-sm-6 gray-data-label">
            <h6>Teléfono de la ONG</h6>
          </div>
          <div className="col-12 col-md-4">
            <p>{createIdea.data.ongPhone}</p>
          </div>
        </div>
      ) : null}

      <div className="col-12 row justify-content-md-center row-confirm">
        <div className="col-lg-2 col-sm-6 gray-data-label">
          <h6>Título de la Idea</h6>
        </div>
        <div className="col-12 col-md-4">
          <p>{createIdea.data.title}</p>
        </div>
      </div>

      <div className="col-12 row justify-content-md-center row-confirm">
        <div className="col-lg-2 col-sm-6 gray-data-label">
          <h6>Sector</h6>
        </div>
        <div className="col-12 col-md-4">
          <p>
            {sectors
              .filter((a) => a.id == createIdea.data.sector)
              .map((a) => a.name)}
          </p>
        </div>
      </div>

      <div className="col-12 row justify-content-md-center row-confirm">
        <div className="col-lg-2 col-sm-6 gray-data-label">
          <h6>Estado</h6>
        </div>
        <div className="col-12 col-md-4">
          <p>
            {statesList
              .filter((a) => a.id == createIdea.data.state)
              .map((a) => a.name)}
          </p>
        </div>
      </div>

      <div className="questions-select-container col-12 col-md-8 row">
        <div className="col-12 col-md-6 col-lg-6">
          <h6> ¿Primera vez que participa? </h6>
        </div>

        <div className="col-12 col-md-6 col-lg-6 input-question-idea">
          <input
            className="radio-input"
            checked={createIdea.data.firstTime === '1'}
            type="radio"
            value="1"
            name="firstTime"
          />
          <h6>Si</h6>
          <input
            className="radio-input"
            checked={createIdea.data.firstTime === '0'}
            type="radio"
            value="0"
            name="firstTime"
          />
          <h6>No</h6>
        </div>
      </div>

      {createIdea.data.firstTime === '0' ? (
        <div className="questions-select-container col-12 col-md-8 row">
          <div className="col-12 col-md-6 col-lg-6">
            <h6> ¿Ha participado en los últimos 3 años? </h6>
          </div>
          <div className="col-12 col-md-6 col-lg-6 input-question-idea">
            <input
              className="radio-input"
              checked={createIdea.data.lastThreeYears === '1'}
              type="radio"
              value="1"
              name="lastThreeYears"
            />
            <h6>Si</h6>
            <input
              className="radio-input"
              checked={createIdea.data.lastThreeYears === '0'}
              type="radio"
              value="0"
              name="lastThreeYears"
            />
            <h6>No</h6>
          </div>
        </div>
      ) : null}

      {auxiliarDisplayStep2 ? (
        <div className="questions-select-container col-12 col-md-8 row">
          <div className="col-12 col-md-6 col-lg-6">
            <h6>
              {' '}
              ¿Su idea es nueva o es una idea mejorada de la participación
              anterior?{' '}
            </h6>
          </div>
          <div className="col-12 col-md-6 col-lg-6 input-question-idea">
            <input
              className="radio-input"
              checked={createIdea.data.newIdea === '1'}
              type="radio"
              value="1"
              name="newIdea"
            />
            <h6>Es nueva</h6>
            <input
              className="radio-input"
              checked={createIdea.data.newIdea === '0'}
              type="radio"
              value="0"
              name="newIdea"
            />
            <h6>Es mejorada</h6>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const RecoverPasswordInput = (props) => {
  const { passwordForgotinputChange, forgotPwdForm } = props;

  return (
    <div className="recover-password-input-cotainer">
      <label id="recover-password-input-label" className="input-title">
        Indique el correo electrónico con el que está registrado
      </label>
      <input
        onChange={passwordForgotinputChange}
        className="input"
        type="text"
        name="email"
        placeholder="Correo electrónico"
        value={forgotPwdForm.data.email}
      />
      <ErrorAlert error={forgotPwdForm.errors.email} />
    </div>
  );
};

// Form that changes password
export const CreateAccountForm = (props) => {
  const {
    createAccountinputChange,
    passwordShown,
    setPasswordShownValue,
    createAccountForm,
  } = props;

  return (
    <div className="row">
      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Nombre *</label>
        <div className="input-eye-container">
          <input
            onChange={createAccountinputChange}
            className="input"
            type={'text'}
            name="name"
            placeholder="Nombre"
            value={createAccountForm.data.name}
          />
        </div>
        <ErrorAlert error={createAccountForm.errors.name} />
        <div className="helper-text">Máximo 80 caracteres</div>
      </div>

      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Apellido *</label>
        <div className="input-eye-container">
          <input
            onChange={createAccountinputChange}
            className="input"
            type={'text'}
            name="lastName"
            placeholder="Apellido"
            value={createAccountForm.data.lastName}
          />
        </div>
        <ErrorAlert error={createAccountForm.errors.lastName} />
        <div className="helper-text">Máximo 80 caracteres</div>
      </div>

      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Crear Contraseña *</label>
        <div className="input-eye-container">
          <input
            onChange={createAccountinputChange}
            className="input input-icon"
            type={passwordShown === true ? 'text' : 'password'}
            name="password"
            placeholder="Contraseña"
            value={createAccountForm.data.password}
          />
          <i
            id="eyePassword"
            className="font-eye"
            onClick={() => setPasswordShownValue()}
          >
            {passwordShown === false ? (
              <FontAwesomeIcon icon="eye" />
            ) : (
              <FontAwesomeIcon icon="eye-slash" />
            )}
          </i>
        </div>
        <ErrorAlert error={createAccountForm.errors.password} />
        <div className="helper-text">Mínimo 4 y máximo 80 caracteres</div>
      </div>

      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Confirmar Contraseña *</label>
        <div className="input-eye-container">
          <input
            onChange={createAccountinputChange}
            className="input input-icon"
            type={passwordShown === true ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Confirmar contraseña"
            value={createAccountForm.data.confirmPassword}
          />
          <i
            id="eyePassword"
            className="font-eye"
            onClick={() => setPasswordShownValue()}
          >
            {passwordShown === false ? (
              <FontAwesomeIcon icon="eye" />
            ) : (
              <FontAwesomeIcon icon="eye-slash" />
            )}
          </i>
        </div>
        <ErrorAlert error={createAccountForm.errors.confirmPassword} />
        <div className="helper-text">
          Debe coincidir con la contraseña ingresada
        </div>
      </div>

      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Correo electrónico *</label>
        <div className="input-eye-container">
          <input
            onChange={createAccountinputChange}
            className="input"
            type={'email'}
            name="email"
            placeholder="Correo electrónico"
            value={createAccountForm.data.email}
          />
        </div>
        <ErrorAlert error={createAccountForm.errors.email} />
      </div>
      <div id="register-email-message" className=" col-12 col-md-6">
        <h6>El correo electrónico será tu usuario en el sistema</h6>
      </div>
    </div>
  );
};

// Form that changes password
export const PasswordChangeForm = (props) => {
  const {
    passwordForminputChange,
    passwordShown,
    setPasswordShownValue,
    updatePasswordFunction,
    history,
    passwordUpdateForm,
  } = props;

  return (
    <div className="row">
      <div className="register-container col-12 col-sm-8 col-md-6 col-lg-4">
        <div>
          <div className="dashboard-title-container">
            <h1>CAMBIAR CONTRASEÑA</h1>
          </div>
          <div className="row">
            <div className="register-input-container col-12">
              <label className="input-title">Contraseña actual</label>
              <div className="input-eye-container">
                <input
                  onChange={passwordForminputChange}
                  autoComplete="off"
                  className="input"
                  type={passwordShown === true ? 'text' : 'password'}
                  name="oldPassword"
                  placeholder="Contraseña actual"
                  value={passwordUpdateForm.data.oldPassword}
                />
                <i
                  id="eyePassword"
                  className="font-eye"
                  onClick={() => setPasswordShownValue()}
                >
                  {passwordShown === false ? (
                    <FontAwesomeIcon icon="eye" />
                  ) : (
                    <FontAwesomeIcon icon="eye-slash" />
                  )}
                </i>
              </div>

              <ErrorAlert error={passwordUpdateForm.errors.oldPassword} />
            </div>

            <div className="register-input-container col-12">
              <label className="input-title">Contraseña nueva</label>
              <div className="input-eye-container">
                <input
                  onChange={passwordForminputChange}
                  autoComplete="off"
                  className="input"
                  type={passwordShown === true ? 'text' : 'password'}
                  name="newPassword"
                  placeholder="Contraseña nueva"
                  value={passwordUpdateForm.data.newPassword}
                />
                <i
                  id="eyePassword"
                  className="font-eye"
                  onClick={() => setPasswordShownValue()}
                >
                  {passwordShown === false ? (
                    <FontAwesomeIcon icon="eye" />
                  ) : (
                    <FontAwesomeIcon icon="eye-slash" />
                  )}
                </i>
              </div>
              <ErrorAlert error={passwordUpdateForm.errors.newPassword} />
            </div>

            <div className="register-input-container col-12">
              <label className="input-title">Confirmar contraseña nueva</label>
              <div className="input-eye-container">
                <input
                  onChange={passwordForminputChange}
                  autoComplete="off"
                  className="input"
                  type={passwordShown ? 'text' : 'password'}
                  name="confirmNewPassword"
                  placeholder="Confirmar contraseña nueva"
                  value={passwordUpdateForm.data.confirmNewPassword}
                />
                <i
                  id="eyePassword"
                  className="font-eye"
                  onClick={() => setPasswordShownValue()}
                >
                  {passwordShown === false ? (
                    <FontAwesomeIcon icon="eye" />
                  ) : (
                    <FontAwesomeIcon icon="eye-slash" />
                  )}
                </i>
              </div>

              <ErrorAlert
                error={passwordUpdateForm.errors.confirmNewPassword}
              />
            </div>

            <div className="col-12 save-button-contanier">
              <SavePasswordButton
                updatePasswordFunction={updatePasswordFunction}
                history={history}
                passwordUpdateForm={passwordUpdateForm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Edit user form

export const EditUserForm = (props) => {
  const { userForm, userForminputChange, editUserFunction, history } = props;

  if (!userForm.data.idUserType) {
    return null;
  }

  if (userForm.data.idUserType !== '') {
    return (
      <React.Fragment>
        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">Nombre</label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="name"
            placeholder="Nombre"
            value={userForm.data.name}
          />
          <ErrorAlert error={userForm.errors.name} />
        </div>

        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">Apellido</label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="lastName"
            placeholder="Apellido"
            value={userForm.data.lastName}
          />
          <ErrorAlert error={userForm.errors.lastName} />
        </div>

        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">
            Email (Usuario de la aplicación)
          </label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input blocked-input"
            type="text"
            name="email"
            placeholder="Email"
            value={userForm.data.email}
            disabled
          />
          <ErrorAlert error={userForm.errors.email} />
        </div>

        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">Teléfono</label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="phone"
            placeholder="Teléfono"
            value={userForm.data.phone}
          />
          <ErrorAlert error={userForm.errors.phone} />
        </div>

        <div className="col-12 save-button-contanier">
          <EditUserButton
            userForm={userForm}
            editUserFunction={editUserFunction}
            history={history}
          />
        </div>
      </React.Fragment>
    );
  }
};

// Form for creating users
export const CreateUserForm = (props) => {
  const { userForm, userForminputChange, createUserFunction, history } = props;

  if (userForm.data.idUserType === '' || userForm.data.idUserType === 0) {
    return null;
  }

  if (userForm.data.idUserType !== '') {
    return (
      <React.Fragment>
        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">Nombre</label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="name"
            placeholder="Nombre"
            value={userForm.data.name}
          />
          <ErrorAlert error={userForm.errors.name} />
        </div>

        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">Apellido</label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="lastName"
            placeholder="Apellido"
            value={userForm.data.lastName}
          />
          <ErrorAlert error={userForm.errors.lastName} />
        </div>

        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">
            Email (Usuario de la aplicación)
          </label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="email"
            placeholder="Email"
            value={userForm.data.email}
          />
          <ErrorAlert error={userForm.errors.email} />
        </div>

        <div className="register-input-container col-12 col-md-6">
          <label className="input-title">Teléfono</label>
          <input
            onChange={userForminputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="phone"
            placeholder="Teléfono"
            value={userForm.data.phone}
          />
          <ErrorAlert error={userForm.errors.phone} />
        </div>

        <div className="col-12 save-button-contanier">
          <SaveUserButton
            userForm={userForm}
            createUserFunction={createUserFunction}
            history={history}
          />
        </div>
      </React.Fragment>
    );
  }
};
