import http from './httpServices';

export async function createDocumentByMember(datos, memberId, userSession) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/${memberId}/documentByMember`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createDocumentByCriteria(datos,contestId,stageId,type,userSession){
  let url = process.env.REACT_APP_API_URL;
  url = url + `/documents/${contestId}/${stageId}/${type}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }

}

export async function getDocumentsByCriteria(userSession, contestId,stageId,type) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/documents/${contestId}/${stageId}/${type}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    console.log(error)
    return error.response;
  }
}

export async function createDocumentService(datos, ideaId, userSession) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/${ideaId}/document`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getDocumentsByIdeaId(sessionToken, ideaId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/${ideaId}/document`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteDocumentService(sessionToken, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/document/${id}`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.delete(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getContestDocument(contestId) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url = url + `/contestDocuments/${contestId}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function updateDocument(datos) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url = url + `/contestDocuments`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function updateMarketingImages(datos, documentType) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/preferencesMarketing/${documentType}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
