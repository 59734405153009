import http from './httpServices';

export async function getEvaluationCriteria(params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/evaluationcriteria?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getAllEvaluationCriteriaFiltered(params){
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/evaluationcriteria/filtered?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteCriteria(criteriaId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/evaluationcriteria/${criteriaId}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.delete(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createCriteria(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/evaluationcriteria`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createCriteriaList(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/evaluationcriteria/list`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}


export async function editCriteria(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/evaluationcriteria/${data.id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
