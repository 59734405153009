// Funcion que consigue el token del session storage
export function getToken() {
  try {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    if (userSession) {
      userSession = userSession.sessionToken;
    }
    return userSession;
  } catch (error) {
    return null;
  }
}

// Function that gets the user type from the sesion storage
function getUserType() {
  try {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    if (userSession) {
      userSession = userSession.userType;
    }
    return userSession;
  } catch (error) {
    return null;
  }
}

function getUserData() {
  try {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    return userSession;
  } catch (error) {
    return null;
  }
}

export default {
  getToken,
  getUserType,
  getUserData,
};
