/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Header from '../../common/Header/Header';
import { ForgotPasswordB } from '../../common/Buttons/Buttons';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import background from '../../../images/background2.jpg';
import { forgotPassword } from '../../../services/passwordService.js';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import './ForgotPassword.scss';
import { getContestLogo } from '../../../services/contestService.js';

const ForgottPassword = (props) => {
  const { blockedLogin } = props;

  const [email, setEmail] = useState('');
  const [contestBackground, setContestBackground] = useState('');
  const history = useHistory();

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const getContestBackgroundFunction = () => {
    getContestLogo('BackgroundPassword')
      .then((response) => {
        if (response.status === 200) {
          setContestBackground(response.data.fileUrl);
        }
      })
      .catch(() => {
        toast.error('Error al obtener fondo del concurso');
      });
  };

  const forgotPasswordAction = () => {
    forgotPassword({ email: email })
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            'Se ha enviado un correo para la recuperación de su contraseña. Sino lo recibe, verifique que está registrado con el correo electrónico ingresado.'
          );
          setEmail('');
          history.push('/login');
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Error al enviar recuperación de su contraseña');
      });
  };

  useEffect(() => {
    getContestBackgroundFunction();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="login-container row">
        <LoadSpinner
          blocked={blockedLogin}
          text="Enviando solicitud"
          color="grey"
        />

        <div className="login-dashboard login-container col-12 col-md-7 col-lg-6 ">
          <h1 className="title">Recuperar Contraseña</h1>
          <div className="finanzas-search-container">
            <div
              style={{ marginBottom: '12px' }}
              className="recover-password-input-cotainer"
            >
              <label id="recover-password-input-label" className="input-title">
                Indique el correo electrónico con el que está registrado
              </label>
              <input
                onChange={handleEmailChange}
                className="input"
                type="text"
                name="email"
                placeholder="Correo electrónico"
                value={email}
              />
            </div>
            <ForgotPasswordB
              text={'ENVIAR'}
              action={() => forgotPasswordAction()}
              disabled={!email}
            />
          </div>
        </div>
        <div className="login-background-container login-container  col-md-5 col-lg-6 ">
        {contestBackground && (
          <img
            className="login-dashboard-background"
            src={contestBackground}
            alt=""
          />
        )}{' '}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgottPassword;
