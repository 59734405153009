import http from "./httpServices";

export async function getEducationalInstitution(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/academic-institution`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getEducationalLevel(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/educational-level`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
