import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DeleteCriteriaModal.scss';

export const DeleteCriteriaModal = (props) => {
  const { isOpen, closeDeleteModal, data, action } = props;

  const typesComponent = (type) => {
    if (type === 'BUSINESS') {
      return 'Negocio';
    }
    if (type === 'SOCIAL') {
      return 'Social';
    }
    if (type === 'DIGITAL') {
      return 'Digital';
    }
    if (type === 'ONG') {
      return 'ONG';
    }
  };
  return (
    <Modal size="md" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Criterio de Evaluación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Desea eliminar el siguiente criterio de evaluación:
        <br />
        <br />
        <h5 className="delete-criteria-info-modal-data">
          <span>
            <b>{data?.stage?.stage} </b>
          </span>
          <span>
            <b>Tipo:</b> {typesComponent(data?.types)}
          </span>
          <span>
            <b>Peso :</b> {data?.percentage} %
          </span>
        </h5>
        <br />
        <p>
          {' '}
          <b>Criterio:</b> {data?.criteriaName}
          <br></br>
          {data?.description}
        </p>
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={() => action()}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
