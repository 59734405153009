import http from './httpServices';

export async function getJudgePreferences(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judge-preferences/` + id;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createJudgePreference(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judge-preferences`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function editJudgePreference(data, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judge-preferences/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getJudgeDistribution(contestId, stage) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/totalIdeasJudgesDistribute/${contestId}/${stage}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function distributeIdeas(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/distributeIdeas`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getJudgeDashboard(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judgeDashboard/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function getIdeasByJudge(params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideasByJudge?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getQualificationByJudge(idIdea, idStage) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url =
    url + `/qualificationByJudge/${userSession?.userId}/${idIdea}/${idStage}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function updateQualificationByJudge(data, idIdea) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/qualificationByJudge/${idIdea}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getjudgeIdeaEvaluation(idJudgeEvaluation) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judgeIdeaEvaluation/${idJudgeEvaluation}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getJudgeAvailableByIdea(stageId, ideaId, params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url =
    url +
    `/judgeAvailableByIdea/${stageId}/${ideaId}?` +
    new URLSearchParams(params);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getJudgeCanEvaluate(userId, ideaId, stageName, search) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url =
    url + `/juredCanEvaluateIdea/${userId}/${ideaId}/${stageName}/${search}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function assignJudgeToIdea(idUser, idIdea) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/assignJudgeToIdea/${idUser}/${idIdea}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, {}, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteJudgeIdea(userId, ideaId) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/deleteJudgeIdea/${userId}/${ideaId}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.delete(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function resetEvaluation(ideaId, userId) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/resetEvaluationIdea/${ideaId}/${userId}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, '', config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function updatedUserLeader(userId, roleUser) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/updatedUserLeader/${userId}/${roleUser}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, '', config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function userEvaluationInformation(userId, stageName) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/userEvaluationInformation/${userId}/${stageName}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function judgeIdeaStatus(userId, params) {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judgeIdeaStatus/${userId}?` + new URLSearchParams(params);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
