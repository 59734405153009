/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  ContestInput,
  ContestSelectInput,
} from '../../../common/ContestForms/ContestForms';
import { rolesOptions } from '../../../../helpers/types.js';

import './CreateCompanyModal.scss';

export const CreateCompanyModal = (props) => {
  const {
    isOpen,
    closeDeleteModal,
    action,
    user,
    handleChange,
    contestList,
    modalType,
    sectorList,
    statesList,
  } = props;

  const nameComponent = (type, rolesOptions) => {
    let [selectedType] = rolesOptions.filter((r) => r.id == type);
    return selectedType?.name || null;
  };

  let callToAction = '';
  if (modalType == 'create') {
    callToAction = 'Crear';
  } else {
    callToAction = 'Editar';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textTransform: 'capitalize' }}>
          {callToAction} Empresa {nameComponent(user?.userType, rolesOptions)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="businessName"
              label="Nombre *"
              value={user?.businessName}
              errors={user?.errors?.businessName}
              type={'text'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={sectorList}
              handleChange={handleChange}
              name="economicSector"
              label="Sector *"
              value={user?.economicSector}
              errors={user?.errors?.economicSector}
              type={'text'}
              placeholder={'Seleccione una opción...'}
            />
          </div>

          <br />
          <div className="col-12">
            <h4>Información Adicional (Opcional)</h4>
          </div>
          <br />
          <br />
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="email"
              label="Email Principal"
              value={user?.email}
              errors={user?.errors?.email}
              type={'text'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              options={rolesOptions}
              handleChange={handleChange}
              name="phone"
              label="Teléfono"
              type={'number'}
              placeholder={''}
              errors={user?.errors?.phone}
              value={user?.phone}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={statesList}
              handleChange={handleChange}
              name="state"
              label="Estado"
              value={user?.state}
              errors={user?.errors?.state}
              type={'text'}
              placeholder={'Seleccione una opción...'}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              options={rolesOptions}
              handleChange={handleChange}
              name="address"
              label="Dirección"
              type={'text'}
              placeholder={''}
              errors={user?.errors?.address}
              value={user?.address}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              options={rolesOptions}
              handleChange={handleChange}
              name="postalCode"
              label="Código Postal"
              type={'text'}
              placeholder={''}
              errors={user?.errors?.postalCode}
              value={user?.postalCode}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => action()}
          disabled={!user?.businessName || !user?.economicSector}
        >
          <span style={{ textTransform: 'capitalize' }}>
            {callToAction} Empresa {nameComponent(user?.userType, rolesOptions)}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
