/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { ActionButton } from '../../../common/Buttons/Buttons';
import {
  NavLink,
  useParams,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import { Preferences } from './Preferences/Preferences';
import {
  getUserById,
  editUserFromAdmin,
} from '../../../../services/userListService.js';
import { getStateListFunction } from '../../../../services/stateService.js';
import {
  getCompanyById,
  editCompany,
} from '../../../../services/companyService';
import { sendJudgeInvitation } from '../../../../services/userListService';
import { getEconomicSectorList } from '../../../../services/economicSectorService.js';
import { getIdeaStates } from '../../../../services/economicSectorService.js';
import { regularExpression } from '../../../../helpers/regex.js';
import { translateUserType } from '../../../../helpers/translate.js';
import {
  updatedUserLeader,
  getJudgePreferences,
} from '../../../../services/judgeService.js';

import './CompanyDetail.scss';
import { toast } from 'react-toastify';

const JudgeTypeComponent = (props) => {
  const { user, judgeLeadership, changeJudgeType, preferences } = props;

  if (['judge', 'judge_leader'].includes(user.userType) && preferences) {
    return (
      <div className="judge-change-tipe-container">
        <h6>{translateUserType(user.userType)}</h6>
      </div>
    );
  } else {
    return <h6>{translateUserType(user?.userType)}</h6>;
  }
};

const UserDetailsTab = (props) => {
  const params = useParams();
  const { tab, user } = props;

  let pathArray = window.location.pathname.split('/');

  let selected = 'gray';
  let barColor = '#f2f2f2';
  if (pathArray[6] === tab.url) {
    selected = '#30377b';
    barColor = '#30377b';
  }

  if (user.userType === 'admin') {
    if (['general-info'].includes(tab.url)) {
      return (
        <div className="users-detail-tab-component">
          <NavLink
            to={`/home/admin/company-management/edit/${params?.id}/${tab.url}`}
          >
            <h6 style={{ color: selected }}>{tab.label}</h6>
          </NavLink>
          <div style={{ backgroundColor: barColor }}></div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const CompanyDetail = () => {
  const [user, setUser] = useState({
    errors: {
      businessName: '',
      state: '',
      economicSector: '',
      address: '',
      email: '',
      phone: '',
      postalCode: '',
    },
  });

  const [preferences, setPreferences] = useState('');
  const [company, setCompany] = useState('');
  const [canPost, setCanPost] = useState(true);
  const [judgeLeadership, setJudgeLeadership] = useState();
  const [sectorList, setSectorList] = useState([]);
  const [statesList, setStatesList] = useState([]);

  const history = useHistory();
  const params = useParams();

  const tabs = [
    { label: 'Información General', url: 'general-info' },
    { label: 'Evaluaciones', url: 'evaluations' },
    { label: 'Preferencias', url: 'preferences' },
  ];

  const changeJudgeType = (judgeType) => {
    updatedUserLeader(user?.id, judgeType)
      .then((response) => {
        if (response.status === 200) {
          setJudgeLeadership(judgeType);
          toast.success('Jurado actualizado');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error(error.message);
      });
  };

  const getCompanyData = (userId) => {
    getCompanyById(userId)
      .then((response) => {
        if (response.status === 200) {
          setCompany(response.data);
          let data = response.data;
          data.economicSector = response.data?.economicSector?.id;
          data.state = response.data?.state?.id;
          setUser({ ...user, ...data });
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const onChangeUser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const getStates = () => {
    getStateListFunction()
      .then((response) => {
        if (response.status === 200) {
          setStateList(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getEconomicSectors = () => {
    getEconomicSectorList()
      .then((response) => {
        if (response.status === 200) {
          let data = [];

          for (let i = 0; i < response.data.length; i++) {
            data.push({
              id: response.data[i]?.id,
              name: response.data[i]?.name,
            });
          }

          setSectorList(data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getIdeaStatesFunction = () => {
    getIdeaStates()
      .then((response) => {
        if (response.status === 200) {
          let data = [];

          for (let i = 0; i < response.data.length; i++) {
            data.push({
              id: response.data[i]?.id,
              name: response.data[i]?.name,
            });
          }
          setStatesList(data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const validateCompany = () => {
    let canPost = true;
    //Delete error messages

    let errorString = {
      businessName: '',
      state: '',
      economicSector: '',
      address: '',
      email: '',
      phone: '',
      postalCode: '',
    };

    // Evaluate required inputs
    if (user?.businessName == '') {
      errorString.businessName = 'Requerido';
      canPost = false;
    }
    if (user?.businessName.length > 30) {
      errorString.businessName =
        'El nombre debe tener un máximo de 30 caracteres';
      canPost = false;
    }
    // Evaluate required inputs
    if (user?.economicSector == '') {
      errorString.economicSector = 'Requerido';
      canPost = false;
    }
    // if (user?.state == '') {
    //   errorString.state = 'Requerido';
    //   canPost = false;
    // }
    // Evaluate required inputs
    if (user?.email == '') {
      errorString.email = 'email';
      canPost = false;
    }

    // Evaluate required inputs
    if (user?.address && user?.address.length > 100) {
      errorString.address =
        'La dirección debe tener un máximo de 100 caracteres';
      canPost = false;
    }

    if (user.email && !regularExpression.emailFormat.test(String(user.email))) {
      errorString.email = 'Ingrese un correo electrónico válido';
      canPost = false;
    }

    if (user?.phone && user?.phone.length < 10) {
      errorString.phone = 'Ingrese un teléfono valido';
      canPost = false;
    }

    if (
      user?.postalCode &&
      (user?.postalCode.length > 6 || user?.postalCode.length < 4)
    ) {
      errorString.postalCode = 'Ingrese un código valido';
      canPost = false;
    }

    setUser({
      ...user,

      errors: {
        businessName: errorString.businessName,
        state: errorString.state,
        economicSector: errorString.economicSector,
        address: errorString.address,
        email: errorString.email,
        phone: errorString.phone,
        postalCode: errorString.postalCode,
      },
    });

    return canPost;
  };

  const clearErrors = () => {
    setUser({
      ...user,
      errors: {
        businessName: '',
        state: '',
        economicSector: '',
        address: '',
        email: '',
        phone: '',
        postalCode: '',
      },
    });
  };

  const editUserFunction = () => {
    let data = {
      businessName: user?.businessName,
      state: user?.state ? parseInt(user?.state) : null,
      economicSector: parseInt(user?.economicSector),
      address: user?.address,
      email: user?.email,
      phone: user?.phone,
      postalCode: user?.postalCode,
    };

    let isValid = validateCompany();

    if (canPost && isValid) {
      setCanPost(false);
      editCompany(data, user.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Empresa Editada');
            setCanPost(true);
            setTimeout(() => {
              setCanPost(true);
            }, 1500);
            // history.push('/home/admin/preferences');
            // history.push('/home/admin/company-management');
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          setCanPost(true);
        });
    }
  };

  const sendNotification = (id) => {
    if (canPost) {
      setCanPost(false);
      sendJudgeInvitation(id)
        .then((response) => {
          if (response.status === 200) {
            setCanPost(true);
            toast.success('Invitacion enviada correctamente');
          } else {
            setCanPost(true);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          setCanPost(true);
        });
    }
  };

  const cancelUserEdition = () => {
    clearErrors();
    history.push('/home/admin/company-management/');
  };

  const changeUserStatus = () => {
    let editUser = user;
    editUser.active = !user.active;

    setUser(editUser);
    editUserFunction();
  };

  const getJudgePreferencesFunction = () => {
    getJudgePreferences(params.id)
      .then((response) => {
        if (response?.status === 200) {
          setPreferences(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  useEffect(() => {
    getCompanyData(params.id);
    getEconomicSectors();
    getIdeaStatesFunction();
  }, []);

  return (
    <div>
      <div className="dashboard-title-container">
        <h1 style={{ textTransform: 'uppercase' }}>
          <NavLink to="/home/admin/company-management">
            <span style={{ color: 'gray' }}>EMPRESAS</span>
          </NavLink>
          {'   '}> DETALLES DE LA EMPRESA {company?.businessName}
        </h1>
      </div>
      <div className="user-detail-round-container-component">
        <div>
          <div className="round-name">
            <h1>{company?.businessName?.substring(0, 1)}</h1>
          </div>
        </div>
        <div className="user-detail-info-container">
          <div className="user-detail-info">
            <h2>{company?.businessName}</h2>
            <JudgeTypeComponent
              preferences={preferences}
              changeJudgeType={changeJudgeType}
              judgeLeadership={judgeLeadership}
              user={user}
            />{' '}
            <h6>
              <b>Email Principal:</b> {company?.email}
            </h6>
          </div>
        </div>
      </div>
      <div className="user-details-tabs-container">
        {tabs.map((t) => (
          <UserDetailsTab tab={t} user={user} />
        ))}
      </div>
      <Switch>
        <Route
          path="/home/admin/company-management/edit/:id/general-info"
          render={(props) => {
            return (
              <GeneralInfo
                {...props}
                user={user}
                onChange={onChangeUser}
                cancelEdition={cancelUserEdition}
                save={editUserFunction}
                statesList={statesList}
                sectorList={sectorList}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};
