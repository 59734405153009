import http from "./httpServices";

export async function signIn(datos) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/login`;


  try {
    const result = await http.post(url, datos);
    return result;
  } catch (error) {
    return error.response;
  }
}
