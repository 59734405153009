import http from './httpServices';

export async function getAwardsForIdea(ideaId, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/awardsByIdea/${ideaId}`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function saveAwardIdea(data, ideaId, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/awardsByIdea/${ideaId}`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getSpecialAwards(contestId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/awardsByContest/${contestId}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteAward(awardId) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/remove-award/${awardId}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.delete(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createAward(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/awards`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function editAward(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/awards/${data.id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function getSpecialAwardList(params, contestId) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url =
    url + `/ideasAwardPostulated/${contestId}?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
