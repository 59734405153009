import React, { useEffect, useState } from 'react';
import switchOpen from '../../../images/switchOpen.svg';
import switchClosed from '../../../images/switchClosed.svg';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModalComponent } from '../../common/Tables/Tables';
import TextField from '@material-ui/core/TextField';
import ErrorAlert from '../../common/ErrorAlert/ErrorAlert';
import { ContestInput } from '../../common/ContestForms/ContestForms';
import { NavLink, Switch, Route, useHistory } from 'react-router-dom';
import { createContest } from '../../../services/contestService.js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  getContests,
  getStageByContest,
  updateStage,
} from '../../../services/contestService';
import { ContestSelectInput } from '../../common/ContestForms/ContestForms';
import 'date-fns';
import Loader from 'react-loader-spinner';
import { Marketing } from './Marketing/Marketing';
import { EvaluationCriteria } from './EvaluationCriteria/EvaluationCriteria';
import { SpecialAwards } from './SpecialAwards/SpecialAwards';

import './Preferences.scss';

export const EndContest = (props) => {
  const { setShow, currentContestStage } = props;
  let contestOpen = false;

  if (currentContestStage.length > 0) {
    contestOpen = currentContestStage[4].isOpen;
  }

  if (!contestOpen) {
    return (
      <div onClick={setShow} className="modal-button modal-delete-button">
        <h6>Finalizar Concurso</h6>
      </div>
    );
  } else {
    return null;
  }
};

// Component that displays the stage status
export const RoundStatus = (props) => {
  const { status } = props;
  let statusText = 'Activa';
  let statusClass = 'opened';
  status === 1 ? (statusText = 'Activa') : (statusText = 'Cerrada');
  status === 1 ? (statusClass = 'opened') : (statusClass = 'closed');

  if (status === 3) {
    return null;
  } else {
    return (
      <div className={`round-status round-${statusClass}`}>
        <h6>Ronda {statusText}</h6>
      </div>
    );
  }
};

export const valitadeIfStageOpen = (stage, filteredStages) => {
  let index = filteredStages.findIndex((c) => c.id === stage.id);

  let today = new Date();
  // Change dates formants
  let newStartDate = new Date(moment(stage.startDate).format('llll'));
  let newEndDate = new Date(moment(stage.endDate).format('llll'));

  // Open
  if (stage.isOpen) {
    return 1;
  }
  // Open
  if (today >= newStartDate && today < newEndDate && stage.isOpen) {
    return 1;
  }
  if (today >= newStartDate && today < newEndDate && !stage.isOpen) {
    return 2;
  }
  // If previous stage is open current is not
  if (index > 0 && filteredStages[index - 1].isOpen) {
    return 2;
  }
  // Dont show anything
  if (today < newStartDate) {
    return 2;
  }
  // Closed
  if (!stage.isAutoEnd && !stage.isOpen) {
    return 2;
  }
};

export const FaseTitle = (props) => {
  const { number, stage, filteredStages } = props;
  let isOpen = valitadeIfStageOpen(stage, filteredStages);
  // Validate if stage is in current time period
  return (
    <div className="fase-title-container">
      <div className="fase-ball">
        <h6>{number}</h6>
      </div>
      <h4>Fase {number}</h4>
      {!stage.hasChanged ? <RoundStatus status={isOpen} /> : null}
    </div>
  );
};

// Component that opens or closes the selected stage
export const HandleStageClose = (props) => {
  const { isOpen, stage, handleStageChange, error } = props;
  let openClass = 'close';
  let openText = 'Cerrar';
  isOpen ? (openClass = 'close') : (openClass = 'open');
  isOpen ? (openText = 'Cerrar') : (openText = 'Abrir');
  return (
    <div className="row">
      <div className="col-6">
        <div className="preferences-component-footer">
          <div
            id="isOpen"
            onClick={(e) => handleStageChange(stage, e)}
            className={`stage-button modal-button ${openClass}-stage-button`}
          >
            <h6>{openText} Ronda</h6>
          </div>
          <ErrorAlert error={error} />
        </div>
      </div>
    </div>
  );
};

// Component that displays the stage start and limit date
export const StageDateLimitComponent = (props) => {
  const { text, date, stage, name, handleStageChange, error } = props;
  return (
    <div className="stage-date-limit-container">
      <div className="stage-date-limit-component">
        <div className="stage-date-limit-component-text">
          <h5>{text}</h5>
        </div>
        {/* <h6> {moment(date).locale("es").format("DD/MM/YYYY")}</h6> */}
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TextField
              className="stage-date-picker-container"
              id="date-picker-dialog"
              // label="Date picker dialog"
              type="date"
              placeholder="dd/mm/aaaa"
              format="dd/MM/yyyy"
              value={date}
              maxDate={new Date()}
              name={name}
              onChange={(e) => handleStageChange(stage, e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {error ? <ErrorAlert error={error} /> : null}
    </div>
  );
};

// Switch that displays and changes the auto start and end of the stage
export const StageHandlerComponent = (props) => {
  const { text, name, selected, stage, handleStageChange } = props;
  return (
    <div className="stage-handler-component">
      <h6>{text}</h6>
      <img
        onClick={(e) => handleStageChange(stage, e)}
        name={name}
        className="switch-img"
        src={selected ? switchOpen : switchClosed}
        alt="sw"
      />
    </div>
  );
};

export const SaveChangesButton = (props) => {
  const { accept, cancel, onAccept, onCancel, hasChanged } = props;
  if (hasChanged) {
    return (
      <div className="modal-component-footer">
        <div></div>
        <div className="modal-button-container">
          <div onClick={onCancel} className="modal-button modal-close-button">
            <h6>{cancel}</h6>
          </div>
          <div onClick={onAccept} className="modal-button modal-open-button">
            <h6>{accept}</h6>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const StageCard = (props) => {
  const { stage, updateStageFunction, handleStageChange, index, stages } =
    props;

  let disabledStage = '';

  if (
    index > 0 &&
    (!stages[index - 1]?.endDate || !stages[index - 1]?.startDate)
  ) {
    disabledStage = 'disabledStage';
  }

  if (disabledStage !== 'disabledStage') {
    return (
      <div className="stage-card-component">
        <div className={`card ${disabledStage}`}>
          <div className="stage-card-body">
            <div className="row">
              <div className="col-12 col-md-6">
                <StageDateLimitComponent
                  stage={stage}
                  name="startDate"
                  text={'Fecha de Lanzamiento'}
                  date={stage.startDate}
                  handleStageChange={handleStageChange}
                  error={stage.startDateError}
                />
                <StageDateLimitComponent
                  stage={stage}
                  name="endDate"
                  text={'Fecha Límite'}
                  date={stage.endDate}
                  handleStageChange={handleStageChange}
                  error={stage.endDateError}
                />
              </div>
              <div className="col-12 col-md-6">
                <StageHandlerComponent
                  stage={stage}
                  name={'isAutoStart'}
                  text={
                    'Abrir automáticamente la ronda en la fecha de lanzamiento'
                  }
                  selected={stage.isAutoStart}
                  handleStageChange={handleStageChange}
                />
                <StageHandlerComponent
                  stage={stage}
                  name={'isAutoEnd'}
                  text={'Cerrar automáticamente la ronda en la fecha límite '}
                  selected={stage.isAutoEnd}
                  handleStageChange={handleStageChange}
                />
              </div>
            </div>

            <HandleStageClose
              stage={stage}
              isOpen={stage.isOpen}
              error={stage.isOpenError}
              handleStageChange={handleStageChange}
            />
            <SaveChangesButton
              hasChanged={stage.hasChanged}
              accept={'Guardar Cambios'}
              cancel={'Cancelar'}
              onAccept={() => updateStageFunction(stage, true)} // Updates the cards in DB
              onCancel={() => updateStageFunction(stage, false)} //Restarts the cards
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`card ${disabledStage}`}>
        <div
          style={{ height: '200px', marginBottom: '40px' }}
          className="stage-card-body"
        >
          <div className="row">
            <div className="disabled-stage">
              <h5>
                Complete los pasos de la Fase {index} para continuar con la Fase{' '}
                {index + 1}
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const CreateContestComponent = () => {
  const [contestName, setContestName] = useState('');
  const [canCreateContest, setCanCreateContest] = useState(true);
  const history = useHistory();

  const onChangeContestName = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    setContestName(value);
  };

  const createContestFunction = async () => {
    // setIsSearching(true);
    if (canCreateContest) {
      setCanCreateContest(false);
      await createContest(contestName).then((response) => {
        if (response?.status === 200) {
          setCanCreateContest(true);
          setContestName('');
          toast.success('Concurso Creado');
          history.push('/home/admin/preferences/contests');
        }
        if (response?.status === 409) {
          setCanCreateContest(true);
          toast.error('Este nombre de Concurso ya ha sido registrado');
        } else {
          setCanCreateContest(true);
        }
      });
    }
  };

  return (
    <div className="contest-input-flex col-12">
      <div className="create-contest-button-container">
        <div className="contest-name-input-container">
          <ContestInput
            handleChange={onChangeContestName}
            name="ongName"
            label="Nombre del Concurso *"
            value={contestName}
            errors={''}
            type={'text'}
            placeholder={'Ingrese el nombre del Concurso'}
          />
        </div>
        <ActionButton
          action={() => createContestFunction()}
          text="Crear Concurso"
        />
      </div>
    </div>
  );
};

export const ContestManagement = (props) => {
  const { setShow, currentContestStage } = props;

  const [contestList, setContestList] = useState([]);
  const [selectedContest, setSelectedContest] = useState(19);
  const [openedContest, setOpenedContest] = useState('');
  const [selectedModality, setSelectedModality] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [isContestOpen, setIsContestOpen] = useState(true);
  const [stages, setStages] = useState([]);
  const modalities = [
    { name: 'Postulación', id: '0' },
    { name: 'Evaluación', id: '1' },
  ];

  const onChangeSelectedContest = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    setSelectedContest(value);
    getContestsStages(value, selectedModality);
  };

  const onChangeModality = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    setSelectedModality(value);
    getContestsStages(selectedContest, value);
  };

  const changeDateFormat = (date) => {
    let dateTimezone = moment(date).locale('es').format('llll');
    let newDateFormat = new Date(dateTimezone);
    return newDateFormat;
  };

  const validateIfCanOpen = (index) => {
    let currentContestStage = stages;
    // Counter of how many stages are open
    let isAnyOpen = 0;

    for (let i = 0; i < currentContestStage.length; i++) {
      if (currentContestStage[i].isOpen === true) {
        isAnyOpen++;
      }
    }

    if (isAnyOpen > 1) {
      currentContestStage[index].isOpenError =
        'No puede haber 2 rondas abiertas al mismo tiempo';
    }

    setStages([...currentContestStage]);
    return isAnyOpen < 2;
  };

  const validateEndDateWithNextStartDate = (index) => {
    let currentContestStage = stages;

    if (!currentContestStage[index]?.startDate) {
      currentContestStage[index].startDateError = 'Fecha de inicio requerida';
      setStages([...currentContestStage]);
      return false;
    }

    if (!currentContestStage[index]?.endDate) {
      currentContestStage[index].endDateError =
        'Fecha de finalización requerida';
      setStages([...currentContestStage]);
      return false;
    }

    let startDate = changeDateFormat(currentContestStage[index]?.startDate);
    let endDate = changeDateFormat(currentContestStage[index]?.endDate);

    // Compare with previous stages
    if (index > 0 && currentContestStage[index - 1]?.endDate) {
      let prevDate = changeDateFormat(currentContestStage[index - 1]?.endDate);
      if (startDate <= prevDate) {
        currentContestStage[index][
          'startDateError'
        ] = `La fecha de inicio debe ser mayor a la fecha finalización de la ${currentContestStage[
          index - 1
        ].stage.toLowerCase()} `;
        setStages([...currentContestStage]);
        return false;
      }
    }

    // Compare with previous stages
    if (index < stages.length && currentContestStage[index + 1]?.startDate) {
      let nextDate = changeDateFormat(
        currentContestStage[index + 1]?.startDate
      );
      if (nextDate <= endDate) {
        currentContestStage[index][
          'endDateError'
        ] = `La fecha límite debe ser menor a la fecha de lanzamiento de la ${currentContestStage[
          index + 1
        ].stage.toLowerCase()} `;
        setStages([...currentContestStage]);
        return false;
      }
    }

    return true;
  };

  // Validates if both dates are not bull
  const validateIfDatesAvaliable = (index) => {
    let currentContestStage = stages;

    if (!currentContestStage[index].startDate) {
      currentContestStage[index].startDateError = 'Fecha de inicio requerida';
      setStages([...currentContestStage]);
      return false;
    }
    if (!currentContestStage[index].endDate) {
      currentContestStage[index].endDateError =
        'Fecha de finalización requerida';
      setStages([...currentContestStage]);
      return false;
    } else {
      return true;
    }
  };

  // Function that validates if stage follows allowed conditions
  const validateStageDates = (data) => {
    let currentContestStage = stages;
    let index = currentContestStage.findIndex((c) => c.id === data.id);
    let canPost = true;
    // Validates if a stage is open
    !validateIfCanOpen(index) ? (canPost = false) : null;

    // Validates if stages have dates
    !validateIfDatesAvaliable(index) ? (canPost = false) : null;

    // Validate stages dates with their border stages
    !validateEndDateWithNextStartDate(index) ? (canPost = false) : null;

    //  Validate start and end date of the selected stage
    if (data.startDate >= data.endDate) {
      currentContestStage[index].startDateError =
        'La fecha de lanzamiento debe ser menor que la fecha límite';
      setStages([...currentContestStage]);
      canPost = false;
    }
    return canPost;
  };

  const updateContestStageFunction = async (stage, update) => {
    let currentContestStage = stages;
    // Remove errors messages from date pickers before validating
    let i = currentContestStage.findIndex((c) => c.id === stage.id);
    currentContestStage[i].startDateError = '';
    currentContestStage[i].endDateError = '';
    setStages([...currentContestStage]);

    if (!update) {
      // Refresh contest list
      getContestsStages(selectedContest, selectedModality);
    } else {
      let data = {
        endDate: changeDateFormat(stage.endDate),
        id: stage.id,
        isAutoEnd: stage.isAutoEnd,
        isAutoStart: stage.isAutoStart,
        isOpen: stage.isOpen,
        stage: stage.stage,
        type: stage.type,
        startDate: changeDateFormat(stage.startDate),
        status: stage.status,
      };

      let canPost = validateStageDates(data);

      if (canPost) {
        let response = await updateStage(data, stage.id, selectedContest);

        // No response from API
        if (!response) {
          // this.notify('Connection Error', 'error');
          toast.error(`Error actualizando la fase`);
          return null;
        }
        // Set states
        if (response.status == 200) {
          let currentContestStage = stages;
          // Find the changed stage and update it
          let index = currentContestStage.findIndex(
            (c) => c.id === response.data.id
          );
          response.data.hasChanged = false;
          currentContestStage[index] = response.data;
          setStages([...currentContestStage]);
          toast.success(`La fase ha sido actualizada`);
        }
      } else {
        return null;
      }
    }
  };

  const handleContestStageChange = (stage, { currentTarget: input }) => {
    let currentContestStage = stages;
    let index = currentContestStage.findIndex((c) => c.id === stage.id);

    if (input.name === 'startDate' || input.name === 'endDate') {
      currentContestStage[index][input.name] = input.value;
    } else if (input.id === 'isOpen') {
      currentContestStage[index][input.id] =
        !currentContestStage[index][input.id];
    } else {
      currentContestStage[index][input.name] =
        !currentContestStage[index][input.name];
    }
    currentContestStage[index].hasChanged = true;

    setStages([...currentContestStage]);
  };

  const getContestsStages = async (contest, modality) => {
    setIsLoading(true);
    setStages([]);

    if (contest && modality) {
      await getStageByContest(contest, modality)
        .then((response) => {
          if (response.status == 200) {
            let stages = response.data;

            if (modality == '0') {
              // Remove Registrado and Ganador stages if modality == Postulacion
              stages = stages.filter(
                (s) => s.status !== 'Registrado' && s.status !== 'Ganador'
              );
            }
            // Set error messages and changed state in blank
            stages.forEach((item) => {
              item.hasChanged = false;
              item.startDateError = '';
              item.endDateError = '';
              item.isOpenError = '';
            });

            setStages(stages);

            setIsLoading(false);
          } else {
            toast.error(`Error al obtener concursos`);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response.status == 200) {
        setContestList(response.data);
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        if (!selectedContest) {
          setIsContestOpen(false);
        } else {
          setIsContestOpen(true);
        }
        if (selectedContest) {
          setSelectedContest(selectedContest?.id);
          setOpenedContest(selectedContest?.id);
        } else {
          setSelectedContest(-1);
        }
        getContestsStages(selectedContest?.id, selectedModality);
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  useEffect(() => {
    getContestFunction();
  }, []);
  if (contestList.length > 0) {
    return (
      <>
        <div className="preferences-subtitle-container"></div>

        <div className="contest-list-container">
          <h5 className="preferences-subtitle">
            Configuración de las etapas del concurso
          </h5>
          <div className="contest-list-column">
            <ContestSelectInput
              options={contestList}
              handleChange={onChangeSelectedContest}
              name="contestName"
              label="Concurso"
              value={selectedContest}
              errors={''}
              type={'text'}
              placeholder={' Seleccione Concurso'}
            />
            <ContestSelectInput
              options={modalities}
              handleChange={onChangeModality}
              name="modality"
              label="Modalidad"
              value={selectedModality}
              errors={''}
              type={'text'}
              placeholder={''}
            />
          </div>
        </div>

        {isLoading && (
          <Loader
            type="Oval"
            color={'#000000'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
        )}

        {!isContestOpen ? (
          <div className="no-contest-message-open-container">
            <h1>No hay concursos abiertos</h1>
          </div>
        ) : null}

        {stages.length > 0 ? (
          <>
            {openedContest == selectedContest && (
              <div className="preferences-subtitle-container">
                <span></span>
                <EndContest
                  setShow={() => setShow(true)}
                  currentContestStage={currentContestStage}
                />
              </div>
            )}
            {selectedModality === '0' ? (
              <h3 className="modality-type-title">Postulación</h3>
            ) : (
              <h3 className="modality-type-title">Evaluación</h3>
            )}
            {stages.map((c, i) => (
              <React.Fragment key={c.id}>
                <FaseTitle number={i + 1} stage={c} filteredStages={stages} />
                <StageCard
                  index={i}
                  stage={c}
                  stages={stages}
                  updateStageFunction={updateContestStageFunction}
                  handleStageChange={handleContestStageChange}
                />
              </React.Fragment>
            ))}
          </>
        ) : null}
      </>
    );
  } else return null;
};

export const SelectedOption = (props) => {
  const { option } = props;

  let url = window.location.pathname;
  url = url.replace('/home/admin/preferences/', '');

  if (option.url == url) {
    return (
      <NavLink to={`/home/admin/preferences/${option.url}`}>
        <h6 style={{ color: '#006bb4' }}>{option.label}</h6>
      </NavLink>
    );
  } else {
    return (
      <NavLink to={`/home/admin/preferences/${option.url}`}>
        <h6 style={{ color: '#000000' }}>{option.label}</h6>
      </NavLink>
    );
  }
};

export const Preferences = (props) => {
  const {
    currentContestStage,
    updateStageFunction,
    handleStageChange,
    closeContestFunction,
    evaluationcriteria,
    handleDragAndDropFilesEvaluationCriterias,
    removeDocumentsfromList,
    openBase64File,
    selectCriteriaDocument
  } = props;

  const [show, setShow] = useState(false);
  const [createContestView, setCreateContestView] = useState(false);
  const [canCreateContest, setCanCreateContest] = useState(true);
  const [contestName, setContestName] = useState('');
  const [option, setOption] = useState('concurso');

  const history = useHistory();

  const selectOption = (option) => {
    setCreateContestView(false);
    setOption(option);
  };
  // Cambiar nombre del concurso
  const onChangeContestName = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    setContestName(value);
  };

  const createContestFunction = async () => {
    // setIsSearching(true);
    if (canCreateContest) {
      setCanCreateContest(false);
      await createContest(contestName).then((response) => {
        if (response?.status === 200) {
          setCanCreateContest(true);
          setContestName('');
          toast.success('Concurso Creado');
          setCreateContestView(false);
          window.location.reload();
        }
        if (response?.status === 409) {
          setCanCreateContest(true);
          toast.error('Este nombre de Concurso ya ha sido registrado');
        } else {
          setCanCreateContest(true);
          // setIsSearching(false);
          // notify(`${response.message}`, 'error');
        }
      });
    }
  };

  const endContest = () => {
    setShow(false);
    closeContestFunction();
  };

  const Button = (props) => {
    const { action, contestName } = props;
    if (!contestName) {
      return (
        <button
          id="login-button"
          className="button login-button blocked-button"
        >
          Crear Concurso
        </button>
      );
    } else {
      return (
        <button
          id="login-button"
          onClick={() => action()}
          className="button login-button"
        >
          Crear Concurso
        </button>
      );
    }
  };

  const options = [
    {
      label: 'concurso',
      url: 'contests',
    },
    {
      label: 'mercadeo',
      url: 'marketing',
    },
    {
      label: 'criterios de evaluación',
      url: 'evaluation-criteria',
    },
    {
      label: 'premios especiales',
      url: 'special-awards',
    },
  ];

  // Remove Registrado and Ganador from the array
  let filteredStages = currentContestStage.filter(
    (c) => c.id != 2 && c.id != 6
  );

  let url = window.location.pathname;
  url = url.replace('/home/admin/preferences/', '');

  const redirect = () => {
    history.push('/home/admin/preferences/contests/create-contest');
  };

  return (
    <React.Fragment>
      <ModalComponent
        title={'¿Está seguro de que desea finalizar el concurso? '}
        show={show}
        accept={'Si, Finalizar'}
        cancel={'Cancelar'}
        text={
          <p>
            Si selecciona finalizar, todas las fases abiertas serán cerradas
            automáticamente y el Concurso se dará por culminado.
          </p>
        }
        onCancel={() => setShow(false)}
        onAccept={() => endContest()}
      />
      <div className="dashboard-title-container">
        <h1>PREFERENCIAS</h1>
        {!createContestView && url === 'contests' ? (
          <div style={{ width: '300px' }}>
            <ActionButton action={() => redirect()} text="Crear Concurso" />
          </div>
        ) : null}
      </div>

      <div className="contest-options-container">
        {options.map((o) => (
          <SelectedOption
            key={o.label}
            option={o}
            selected={option}
            setOption={selectOption}
          />
        ))}
      </div>

      {createContestView ? (
        <div className="contest-input-flex col-12">
          <div style={{ display: 'flex' }}>
            <div className="contest-name-input-container">
              <ContestInput
                handleChange={onChangeContestName}
                name="ongName"
                label="Nombre del Concurso *"
                value={contestName}
                errors={''}
                type={'text'}
                placeholder={'Ingrese el nombre del Concurso'}
              />
            </div>
            <Button
              contestName={contestName}
              action={() => createContestFunction()}
            />
          </div>
        </div>
      ) : null}

      <Switch>
        <Route
          path="/home/admin/preferences/contests/create-contest"
          render={(props) => {
            return <CreateContestComponent {...props} />;
          }}
        />
        <Route
          path="/home/admin/preferences/contests"
          render={(props) => {
            return (
              <ContestManagement
                {...props}
                updateStageFunction={updateStageFunction}
                handleStageChange={handleStageChange}
                filteredStages={filteredStages}
                currentContestStage={currentContestStage}
                setShow={setShow}
              />
            );
          }}
        />
        <Route
          path="/home/admin/preferences/marketing"
          render={(props) => {
            return <Marketing {...props} />;
          }}
        />
        <Route
          path="/home/admin/preferences/evaluation-criteria"
          render={(props) => {
            return <EvaluationCriteria {...props} 
            evaluationcriteria={evaluationcriteria}
            handleDragAndDropFilesEvaluationCriterias={handleDragAndDropFilesEvaluationCriterias}
            removeDocumentsfromList={removeDocumentsfromList}
            openBase64File={openBase64File}  
            selectCriteriaDocument={selectCriteriaDocument}          
            />;
          }}
        />
        <Route
          path="/home/admin/preferences/special-awards"
          render={(props) => {
            return <SpecialAwards {...props} />;
          }}
        />
      </Switch>
    </React.Fragment>
  );
};
