import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  ContestInput,
  ContestStartupInput,
  ContestSelectInput,
} from '../../../../common/ContestForms/ContestForms';

import './CreateCriteriaModal.scss';

export const CreateCriteriaModal = (props) => {
  const {
    isOpen,
    closeDeleteModal,
    action,
    criteria,
    handleChange,
    contestList,
    modalType,
    stagesOptions,
  } = props;

  let filteredContest = contestList.filter((c) => c.isOpen === true);

  const ideaTypes = [
    { id: 0, name: 'Idea de Negocio' },
    { id: 1, name: 'Idea de Emprendimiento Social' },
    { id: 2, name: 'Idea de Emprendimiento Digital' },
    { id: 3, name: 'Idea Social ONG' },
  ];

  let callToAction = '';
  if (modalType == 'create') {
    callToAction = 'Crear';
  } else {
    callToAction = 'Editar';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{callToAction} Criterio de Evaluación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={filteredContest}
              handleChange={handleChange}
              name="contest"
              label="Concurso"
              type={'text'}
              placeholder={''}
              errors={''}
              value={criteria?.contest}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={ideaTypes}
              handleChange={handleChange}
              name="types"
              label="Tipo de Idea"
              type={'text'}
              placeholder={''}
              errors={''}
              value={criteria?.types}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={stagesOptions}
              handleChange={handleChange}
              name="stage"
              label="Fase"
              type={'text'}
              placeholder={''}
              errors={''}
              value={criteria?.stage}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="criteriaName"
              label="Criterio"
              value={criteria?.criteriaName}
              errors={criteria?.errors?.criteriaName}
              type={'text'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="percentage"
              label="Peso (%)"
              value={criteria?.percentage}
              errors={criteria?.errors?.percentage}
              type={'number'}
              placeholder={''}
            />{' '}
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestStartupInput
              handleChange={handleChange}
              name="description"
              label="Descripción"
              value={criteria?.description}
              errors={criteria?.errors?.description}
              type={'text'}
              placeholder={
                'Escriba una descripción del criterio de evaluación...'
              }
            />{' '}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => action()}
          disabled={
            !criteria?.contest ||
            !criteria?.types ||
            !criteria?.stage ||
            !criteria?.criteriaName ||
            !criteria?.percentage ||
            !criteria?.description
          }
        >
          {callToAction} Criterio
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
