/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button as ActionButton } from '../../common/Buttons/Buttons';
import {
  getContests,
  getStageByContest,
} from '../../../services/contestService.js';
import { evaluationAssignedByGroup } from '../../../services/ideaHelperService.js';
import {
  getJudgeAvailableByIdea,
  assignJudgeToIdea,
  getJudgePreferences,
  deleteJudgeIdea,
  userEvaluationInformation,
  getJudgeCanEvaluate,
} from '../../../services/judgeService.js';
import { AssignJudgeModal } from '../../common/Modals/AssignJudgeModal/AssignJudgeModal';
import { RemoveJudgeModal } from '../../common/Modals/RemoveJudgeModal/RemoveJudgeModal';
import { IdeaManagementTable } from '../../common/Tables/Tables';
import {
  ContestSelectInput,
  ContestInput,
} from '../../common/ContestForms/ContestForms';
import { showDate } from '../../../helpers/dateFormat.js';
import './IdeaManagement.scss';

export const EvaluationStageElement = (props) => {
  const { stage } = props;

  let isOpen = '';

  if (stage.isOpen) {
    isOpen = 'evaluation-stage-is-open';
  }

  return (
    <div className={`evaluation-stage-element ${isOpen} `}>
      <h6>{stage.stage === 'Fase 3' ? 'Final' : stage.stage}</h6>
    </div>
  );
};

export const IdeaManagement = (props) => {
  const { selectIdea } = props;
  const [selectedContest, setSelectedContest] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    ideaType: '',
    filter: '',
    evalStatus: '',
  });
  const [stages, setStages] = useState([]);
  const [activeStage, setActiveStage] = useState('');
  const [userSession, setUserSession] = useState('');

  const [ideaList, setIdeaList] = useState({ number: 0, content: [] });
  const [userPreferences, setUserPreferences] = useState('');

  const [showAsignModal, setShowAsignModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedEval, setSelectedEval] = useState('');
  const [currentStage, setCurrentStage] = useState('');
  const [judgesForIdea, setJudgesForIdea] = useState('');
  const [selectedJudge, setSelectedJudge] = useState('');
  const [searchJudge, setSearchJudge] = useState('');
  const [asignedEvals, setAsignedEvals] = useState('');
  const [typesCanEval, setTypesCanEval] = useState('');

  const handleUserSearchChange = (e) => {
    const { value } = e.target;
    setSearchJudge(value);
  };

  const assignJudge = () => {
    assignJudgeToIdea(selectedJudge[0], selectedEval?.judgeIdea?.idea?.id)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Jurado Asignado');
          closeAssginModal();
          // getContestFunction();
          getUserData();
          setIdeaList({ ...ideaList, number: 0 });
        } else {
          toast.error('Error al asignar jurado');
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const selectJudge = (judge) => {
    setSelectedJudge(judge);
    getUserEvaluationInformation(judge);
    getJudgePreferencesFunction(judge);
  };

  const getJudgePreferencesFunction = (judge) => {
    getJudgePreferences(judge[0]).then((response) => {
      if (response.status === 200) {
        setTypesCanEval(response.data);
      }
      console.log('response:', response);
    });
  };

  const closeAssginModal = () => {
    setSelectedEval('');
    setSelectedJudge('');
    setShowAsignModal(false);
  };

  const closeRemoveModal = () => {
    setSelectedEval('');
    setSelectedJudge('');
    setShowRemoveModal(false);
  };

  const openAssginModal = (evaluation) => {
    setSelectedEval(evaluation);
    setShowAsignModal(true);
    getJudgeEvaluatorAvailableForIdea(evaluation?.judgeIdea?.idea?.id);
  };

  const openRemoveModal = (evaluation) => {
    setSelectedEval(evaluation);
    setShowRemoveModal(true);
  };

  const getUserEvaluationInformation = (selectedJudge) => {
    if (selectedJudge && selectedEval) {
      userEvaluationInformation(
        selectedJudge[0],
        selectedEval?.judgeIdea?.stage?.stage
      )
        .then((response) => {
          if (response.status === 200) {
            setAsignedEvals(response.data);
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    }
  };

  const options = [
    { id: '', name: 'Todos' },
    { id: '0', name: 'Sin Evaluar' },
    { id: '1', name: 'En progreso' },
    { id: '2', name: 'Evaluadas' },
    { id: 'D', name: 'Digital' },
    { id: 'S', name: 'Social' },
    { id: 'N', name: 'Negocio' },
  ];

  const searchJudgesFunction = () => {
    getJudgeEvaluatorAvailableForIdea(selectedEval?.judgeIdea?.idea?.id);
  };

  const getJudgeAvailableForIdea = (ideaId) => {
    getJudgeAvailableByIdea(currentStage?.id, ideaId, { search: searchJudge })
      .then((response) => {
        if (response.status === 200) {
          setJudgesForIdea(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getJudgeEvaluatorAvailableForIdea = (ideaId) => {
    getJudgeCanEvaluate(
      userSession?.userId,
      ideaId,
      currentStage?.stage,
      searchJudge ? searchJudge : null
    )
      .then((response) => {
        if (response.status === 200) {
          setJudgesForIdea(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response?.status == 200) {
        //   setContestList(response.data);
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        if (selectedContest) {
          setSelectedContest(selectedContest);
        } else {
          setSelectedContest(-1);
        }
        getContestsStages(selectedContest, '1');
      } else {
        toast.error(`${response?.message}` || 'Error al obtener concursos');
      }
    });
  };

  const removeJudgeFromIdea = () => {
    const { idea, user } = selectedEval.judgeIdea;

    deleteJudgeIdea(user?.id, idea?.id).then((response) => {
      if (response?.status == 200) {
        toast.success(`Jurado Removido de la idea`);
        closeRemoveModal();
        getEvaluationAssignedByGroup(currentStage);
      } else {
        toast.error(`${response?.message}` || 'Error al obtener concursos');
      }
    });
  };

  const getContestsStages = async (contest, modality) => {
    if (contest && modality) {
      await getStageByContest(contest?.id, modality)
        .then((response) => {
          if (response.status == 200) {
            let stages = response.data;

            if (modality == '0') {
              // Remove Registrado and Ganador stages if modality == Postulacion
              stages = stages.filter(
                (s) => s.status !== 'Registrado' && s.status !== 'Ganador'
              );
            }
            // Set error messages and changed state in blank
            stages.forEach((item) => {
              item.hasChanged = false;
              item.startDateError = '';
              item.endDateError = '';
              item.isOpenError = '';
            });

            setStages(stages);

            let [isOpen] = stages.filter((s) => s.isOpen === true);

            if (isOpen) {
              setActiveStage(isOpen);
            }

            // getjudgeIdeas(contest, isOpen);
            let [seachStage] = contest.stages.filter(
              (s) => s.stage === isOpen?.stage && s.type === 'JURADO'
            );
            setCurrentStage(seachStage);

            getEvaluationAssignedByGroup(isOpen);
          } else {
            toast.error(`Error al obtener concursos`);
          }
        })
        .catch(() => {});
    } else {
    }
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;

    if (['D', 'S', 'N'].includes(value)) {
      setFilter({ ...filter, ideaType: value, evalStatus: value });
    } else {
      setFilter({ ...filter, [name]: value, ideaType: '' });
    }

    if (!value) {
      setFilter({ ...filter, ideaType: '', evalStatus: '', [name]: value });
    }

    setIdeaList({ ...ideaList, number: 0 });
  };

  const handlePageChange = (event, value) => {
    setTimeout(() => {
      setIdeaList({ ...ideaList, number: value - 1 }); // -1 To remove page counter offset
    }, 500);
  };

  const getUserData = () => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    setUserSession(userSession);
  };

  const getEvaluationFunction = (groupId, stageOpen) => {
    let params = {
      page: ideaList.number,
      size: 10,
      // idStage: seachStage?.id,
      // idContest: contest?.id,
      groupId: groupId ? groupId : '',
      idUser: userSession.userId,
      status: ['D', 'S', 'N'].includes(filter.evalStatus)
        ? ''
        : filter.evalStatus,
      ideaType: filter.ideaType,
      search: filter.search,
    };

    setUserPreferences(groupId);
    evaluationAssignedByGroup(stageOpen?.stage, params)
      .then((response) => {
        if (response.status === 200) {
          setIdeaList(response.data);
          setIsLoading(false);
        } else {
          toast.error('Error obtener evaluaciones');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        setIsLoading(false);
      });
  };

  const getEvaluationAssignedByGroup = (stageOpen) => {
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    if (userSession?.userType !== 'admin') {
      getJudgePreferences(userSession?.userId)
        .then((response) => {
          if (response.status === 200) {
            getEvaluationFunction(response?.data?.groupNumber, stageOpen);
          } else {
            toast.error('Error al obtener preferencias');
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    } else {
      getEvaluationFunction('', stageOpen);
    }
  };

  useEffect(() => {
    getUserData();
    getContestFunction();
    // getEvaluationAssignedByGroup();
  }, [ideaList.number, filter.ideaType, filter.evalStatus]);

  return (
    <>
      <AssignJudgeModal
        selectedEval={selectedEval}
        show={showAsignModal}
        judgesForIdea={judgesForIdea}
        selectJudge={selectJudge}
        selectedJudge={selectedJudge}
        closeModal={closeAssginModal}
        assignJudge={assignJudge}
        handleUserSearchChange={handleUserSearchChange}
        searchJudge={searchJudge}
        searchJudgesFunction={searchJudgesFunction}
        asignedEvals={asignedEvals}
        typesCanEval={typesCanEval}
      />
      <RemoveJudgeModal
        selectedEval={selectedEval}
        show={showRemoveModal}
        // judgesForIdea={judgesForIdea}
        // selectJudge={selectJudge}
        selectedJudge={selectedJudge}
        closeModal={closeRemoveModal}
        removeJudgeFromIdea={removeJudgeFromIdea}
        handleUserSearchChange={handleUserSearchChange}
        searchJudge={searchJudge}
        searchJudgesFunction={searchJudgesFunction}
      />

      <div className="dashboard-title-container">
        <h1>
          EVALUACIONES{' '}
          {userPreferences?.groupNumber
            ? `(Grupo ${userPreferences?.groupNumber})`
            : null}
        </h1>
      </div>

      {stages.length > 0 && (
        <div className="evaliation-state-container">
          {stages.map((s) => (
            <EvaluationStageElement key={s.id} stage={s} />
          ))}
        </div>
      )}
      <br />

      {activeStage && (
        <p className="judge-evaluation-end-date-message">
          Fecha límite para evaluar la fase actual termina{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            el {showDate(activeStage?.endDate)}
          </span>
          <br />
          {/* Le quedan cinco (5) días para completar las evaluaciones que le falten */}
        </p>
      )}

      <div className="judge-evaluation-filters-container">
        <div
          style={{ padding: '0px', marginTop: '4px' }}
          className="contest-input-flex evaluations-search-pt1-element"
        >
          <div className="evaluation-filter-container">
            <ContestInput
              handleChange={onChangeFilter}
              name="search"
              label="Buscar por palabra clave"
              value={filter?.search}
              errors={''}
              type={'text'}
              placeholder={''}
            />

            <ActionButton text={'Buscar'} action={() => getContestFunction()} />
          </div>
        </div>
        <div className="contest-input-flex evaluations-search-pt2-element ">
          <div className="users-dropdown-container">
            <ContestSelectInput
              options={options}
              handleChange={onChangeFilter}
              name="evalStatus"
              label="Filtrar por:"
              value={filter.evalStatus}
              errors={''}
              type={'text'}
              placeholder={''}
            />
          </div>
        </div>
      </div>

      <div className="contest-list-container">
        <h5 className="preferences-subtitle">
          Lista de proyectos para evaluar:
        </h5>
      </div>
      {!isLoading && ideaList.content.length === 0 ? (
        <h4 style={{ marginTop: '200px' }}>
          {' '}
          No existen ideas para Gestionar <br /> en el concurso y fase actual
        </h4>
      ) : null}
      <IdeaManagementTable
        data={ideaList}
        isLoading={isLoading}
        selectIdea={selectIdea}
        userSession={userSession}
        openAssginModal={openAssginModal}
        openRemoveModal={openRemoveModal}
        // handleOpenDeleteModal={handleOpenDeleteModal}
        // openEditModal={openEditModal}
        handlePageChange={handlePageChange}
      />
    </>
  );
};
