import React from "react";
import Header from "../../common/Header/Header";
import { BasicDataForm, ConfirmDataForm } from "../../common/Forms/Forms";
import {
  NextWizardButton,
  ReturnWizardButton,
  ConfirmWizardButton,
} from "../../common/Buttons/Buttons";
import { LoadSpinner } from "../../common/LoadSpinner/LoadSpinner";
import "./BasicData.scss";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

export const BasicData = (props) => {
  const {
    blockedLogin,
    basicData,
    basicDataStep,
    basicDataInputChange,
    handleActDateChange,
    increaseBasicDataStep,
    decreaseBasicDataStep,
    economicSectors,
    states,
    educationalInstitution,
    educationalLevels,
    updateBasicDataFunction,
    userSession,
    logoutService,
  } = props;

  return (
    <React.Fragment>
      <LoadSpinner
        blocked={blockedLogin}
        text="Espere mientras procesamos su solicitud"
        color="#000000"
      />
      <Header
        userSession={userSession}
        logoutService={logoutService}
        history={props}
      />

      <div className="login-dashboard login-container col-12">
        <h1 id="basic-data-title" className="title">
          Datos Básicos del Participante{" "}
        </h1>
        <Stepper activeStep={basicDataStep.counter} alternativeLabel>
          {basicDataStep.steps.map((s, i) => (
            <Step key={i}>
              <StepLabel>{s.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {basicDataStep.counter === 0 ? (
          <BasicDataForm
            basicData={basicData}
            basicDataInputChange={basicDataInputChange}
            handleActDateChange={handleActDateChange}
            states={states}
            economicSectors={economicSectors}
            educationalInstitution={educationalInstitution}
            educationalLevels={educationalLevels}
          />
        ) : null}
        {basicDataStep.counter === 1 ? (
          <ConfirmDataForm
            basicData={basicData}
            basicDataInputChange={basicDataInputChange}
            handleActDateChange={handleActDateChange}
            states={states}
            economicSectors={economicSectors}
            educationalInstitution={educationalInstitution}
            educationalLevels={educationalLevels}
          />
        ) : null}

        <div className="form-buttons-container col-12 col-md-6">
          {basicDataStep.counter !== 0 ? (
            <ReturnWizardButton
              text={"Regresar"}
              handlePrevStep={decreaseBasicDataStep}
            />
          ) : (
            <div></div>
          )}

          {basicDataStep.counter + 1 < basicDataStep.steps.length ? (
            <NextWizardButton
              text={"Siguiente"}
              handleNextStep={increaseBasicDataStep}
            />
          ) : null}

          {basicDataStep.counter + 1 === basicDataStep.steps.length ? (
            <ConfirmWizardButton
              text={"Confirmar"}
              handleConfirm={() => updateBasicDataFunction(props.history)}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
