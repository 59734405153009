/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  DashboardCard1Top,
  DashboardCard2Top,
  DashboardCard3Top,
  DashboardCard1Bottom,
  DashboardCard2Bottom,
  DashboardCard3Bottom,
  DataCard,
} from '../../../common/IdeaCard/IdeaCard';
import {
  getAdminDashboard,
  getCurrentContest,
} from '../../../../services/contestService.js';
import './NewDashboardAdmin.scss';

export const NewDashBoardAdmin = (props) => {
  const [dashboardData, setDashboardData] = useState([]);
  const [contestName, setContestName] = useState('');

  const getDashboardInfo = () => {
    getAdminDashboard().then((response) => {
      if (response.status === 200) {
        setDashboardData(response.data);
      }
    });
  };

  const getContest = () => {
    getCurrentContest().then((response) => {
      if (response.status === 200) {
        setContestName(response?.data?.name);
      }
    });
  };

  useEffect(() => {
    getDashboardInfo();
    getContest();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard-admin-title-container">
        <h1>DASHBOARD</h1>
        <div className="new-admin-dashboard-data-component-container">
          {dashboardData.length > 0 && (
            <DataCard title={'# Usuarios'} value={dashboardData[0][1]} />
          )}
          <h4>{contestName}</h4>
        </div>
      </div>
      {dashboardData.length > 0 && (
        <div className="row">
          <DashboardCard1Top data={dashboardData[0]} />
          <DashboardCard2Top data={dashboardData[1]} />
          <DashboardCard3Top data={dashboardData[2]} />
          <DashboardCard1Bottom data={dashboardData[0]} />
          <DashboardCard2Bottom data={dashboardData[1]} />
          <DashboardCard3Bottom data={dashboardData[2]} />
        </div>
      )}
    </React.Fragment>
  );
};
