import React from 'react';
import {
  IdeaCard,
  ContestStage,
  validateIfContestIsOpen,
} from '../../common/IdeaCard/IdeaCard.jsx';
import { NavLink } from 'react-router-dom';
import imageInfo from '../../../images/info.png';
import check from '../../../images/green_check.svg';
import badRed from '../../../images/red_no.svg';
import moment from 'moment';
import './DashBoard.scss';

export const Dashboard = (props) => {
  const {
    userSession,
    currentYearIdeas,
    selectIdea,
    currentContest,
    stagesDropdowns,
    currentYearDocuments,
    getCurrentContestStage,
  } = props;

  var stageAux;

  //Case 0 -> Fase 1 started
  if (stagesDropdowns.stage1.current) {
    stageAux = 0;
  }

  //Case 1 -> Fase 1 finished and Fase 2 has not started yet
  if (
    stagesDropdowns.stage1.stagedPassed &&
    !stagesDropdowns.stage2.current &&
    !stagesDropdowns.stage1.current
  ) {
    stageAux = 1;
  }

  //Case 2 -> Fase 2 started
  if (stagesDropdowns.stage2.current) {
    stageAux = 2;
  }

  //Case 3 -> Fase 2 finished and Fase 3 has not started yet
  if (
    stagesDropdowns.stage2.stagedPassed &&
    !stagesDropdowns.stage3.current &&
    !stagesDropdowns.stage2.current
  ) {
    stageAux = 3;
  }

  //Case 4 -> Fase 3 started
  if (stagesDropdowns.stage3.current) {
    stageAux = 4;
  }

  //Case 5 -> Fase 3 finished
  if (stagesDropdowns.stage3.stagedPassed && !stagesDropdowns.stage3.current) {
    stageAux = 5;
  }

  let contestIsOpen = true;
  if (currentContest.stages) {
    contestIsOpen = validateIfContestIsOpen(currentContest);
  }

  return (
    <React.Fragment>
      <div className="dashboard-title-container">
        <h1>DASHBOARD</h1>

        {currentYearIdeas.length < currentContest.maxIdeasAllowed &&
        stagesDropdowns.stage1.current ? (
          <NavLink to="/create-idea">
            <button className="create-idea-button">Crear Idea</button>
          </NavLink>
        ) : null}

        {currentYearIdeas.length >= currentContest.maxIdeasAllowed &&
        stagesDropdowns.stage1.current ? (
          <NavLink to="/create-idea">
            <button
              className="create-idea-button"
              title="Alcanzó el número máximo de ideas permitido"
              disabled
            >
              Crear Idea
            </button>
          </NavLink>
        ) : null}
      </div>

      <ContestStage
        currentContest={currentContest}
        contestIsOpen={contestIsOpen}
      />

      {!contestIsOpen ? (
        <React.Fragment>
          {/*-------------------START STAGE 0-----------------------*/}
          {stageAux === 0 ? (
            <div className="left-align-dashboard">
              <img
                src={imageInfo}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Recuerde que para este concurso solo puede crear máximo{' '}
                {currentContest.maxIdeasAllowed} Idea(s).
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 && stageAux === 0 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Faltan recaudos para cargar en alguna de sus Ideas. Solo las
                Ideas que tengan los recaudos mínimos completos se considerarán
                participando en el Concurso.
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Participante')
            .length > 0 &&
          currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length <= 0 &&
          stageAux === 0 ? (
            <div className="left-align-dashboard">
              <img
                src={check}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Su(s) Idea(s) tiene(n) los recaudos mínimos requeridos para
                considerarse participando en el Concurso.
              </p>
            </div>
          ) : null}
          {/*-------------------END STAGE 0-----------------------*/}

          {/*-------------------START STAGE 1-----------------------*/}
          {currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 && stageAux === 1 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para registrar los recaudos de su idea
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Participante')
            .length > 0 &&
          currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length <= 0 &&
          stageAux === 1 ? (
            <div className="left-align-dashboard">
              <img
                src={imageInfo}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para actualizar información. Se le informará
                proximamente si alguna de sus ideas es Semifinalista.
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
            .length > 0 && stageAux === 1 ? (
            <div className="left-align-dashboard">
              <img
                src={check}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Felicidades ha clasificado con alguna de sus ideas para la
                siguiente Fase. La siguiente Fase estará abierta el
                {moment(currentContest.stages[2].startDate)
                  .locale('es')
                  .format(' DD/MM/YYYY')}
              </p>
            </div>
          ) : null}

          {/*-------------------END STAGE 1-----------------------*/}

          {/*-------------------START STAGE 2-----------------------*/}
          {currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
            .length > 0 && stageAux === 2 ? (
            <div className="left-align-dashboard">
              <img
                src={check}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Felicidades ha clasificado para esta Fase con alguna de sus
                ideas
              </p>
            </div>
          ) : null}

          {currentYearDocuments.filter((i) => i.type === 'Plan_negocios')
            .length <= 0 &&
          currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
            .length > 0 &&
          stageAux === 2 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Falta completar recaudos requeridos para esta Fase en alguna de
                sus ideas
              </p>
            </div>
          ) : null}

          {(currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Participante')
              .length > 0) &&
          stageAux === 2 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Sus ideas no han clasificado para esta Fase del concurso
              </p>
            </div>
          ) : null}

          {/*-------------------END STAGE 2-----------------------*/}

          {/*-------------------START STAGE 3-----------------------*/}

          {(currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Participante')
              .length > 0) &&
          stageAux === 3 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Sus ideas no han clasificado para esta Fase del concurso
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
            .length > 0 &&
          currentYearDocuments.filter((i) => i.type === 'Plan_negocios')
            .length > 0 &&
          stageAux === 3 ? (
            <div className="left-align-dashboard">
              <img
                src={imageInfo}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para actualizar información. Se le informará
                proximamente si alguna de sus ideas es Finalista.
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
            .length > 0 &&
          currentYearDocuments.filter((i) => i.type === 'Plan_negocios')
            .length <= 0 &&
          stageAux === 3 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para registrar los recaudos de su idea
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Finalista')
            .length > 0 && stageAux === 3 ? (
            <div className="left-align-dashboard">
              <img
                src={check}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Felicidades ha clasificado con alguna de sus ideas para la
                siguiente Fase. La siguiente Fase estará abierta el
                {moment(currentContest.stages[3].startDate)
                  .locale('es')
                  .format(' DD/MM/YYYY')}
              </p>
            </div>
          ) : null}

          {/*-------------------END STAGE 3-----------------------*/}

          {/*-------------------START STAGE 4-----------------------*/}

          {currentYearIdeas.filter((i) => i.stage.status === 'Finalista')
            .length > 0 && stageAux === 4 ? (
            <div className="left-align-dashboard">
              <img
                src={check}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Felicidades ha clasificado para esta Fase con alguna de sus
                ideas
              </p>
            </div>
          ) : null}

          {currentYearDocuments.filter((i) => i.type === 'Final_presentation')
            .length <= 0 &&
          currentYearIdeas.filter((i) => i.stage.status === 'Finalista')
            .length > 0 &&
          stageAux === 4 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Falta completar recaudos requeridos para esta Fase en alguna de
                sus ideas
              </p>
            </div>
          ) : null}

          {(currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Participante')
              .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
              .length > 0) &&
          currentYearIdeas.filter((i) => i.stage.status === 'Finalista')
            .length > 0 &&
          stageAux === 4 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para actualizar información. Se le informará
                proximamente si alguna de sus ideas es Finalista
              </p>
            </div>
          ) : null}

          {(currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Participante')
              .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
              .length > 0) &&
          stageAux === 4 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Sus ideas no han clasificado para esta Fase del concurso
              </p>
            </div>
          ) : null}

          {/*-------------------END STAGE 4-----------------------*/}

          {/*-------------------START STAGE 5-----------------------*/}

          {(currentYearIdeas.filter((i) => i.stage.status === 'Registrado')
            .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Participante')
              .length > 0 ||
            currentYearIdeas.filter((i) => i.stage.status === 'Semifinalista')
              .length > 0) &&
          stageAux === 5 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Sus ideas no han clasificado para esta Fase del concurso
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Finalista')
            .length > 0 &&
          currentYearDocuments.filter((i) => i.type === 'Final_presentation')
            .length <= 0 &&
          stageAux === 5 ? (
            <div className="left-align-dashboard">
              <img
                src={badRed}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para registrar los recaudos de su idea
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Finalista')
            .length > 0 &&
          currentYearDocuments.filter((i) => i.type === 'Final_presentation')
            .length > 0 &&
          stageAux === 5 ? (
            <div className="left-align-dashboard">
              <img
                src={imageInfo}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Ya cerró la Fase para actualizar información. Se le informará
                proximamente si alguna de sus ideas es Ganadora
              </p>
            </div>
          ) : null}

          {currentYearIdeas.filter((i) => i.stage.status === 'Ganador').length >
            0 && stageAux === 5 ? (
            <div className="left-align-dashboard">
              <img
                src={check}
                alt=""
                className="display-inline icon-dashboard"
              />
              <p className="display-inline">
                Felicidades ha sido el Ganador del Concurso con alguna de sus
                ideas
              </p>
            </div>
          ) : null}

          {/*-------------------END STAGE 5-----------------------*/}
        </React.Fragment>
      ) : null}

      <div className="card">
        <h6 className="card-title">Ideas Participantes </h6>
        <div className="row idea-cards-container">
          {currentYearIdeas.length > 0
            ? currentYearIdeas.map((c, i) => (
                <IdeaCard
                  idea={c}
                  key={i}
                  id={c.id}
                  codeDana={c.codeDana}
                  title={c.title}
                  userSession={userSession}
                  proposalType={c.proposalType.proposalType}
                  status={c.stage.status}
                  endDate={c.stage.endDate}
                  selectIdea={selectIdea}
                  stage={stageAux}
                  currentYearDocuments={currentYearDocuments}
                  getCurrentContestStage={getCurrentContestStage}
                />
              ))
            : null}
        </div>
      </div>
    </React.Fragment>
  );
};
