/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { NavLink, Switch, Route, useParams } from 'react-router-dom';
import { ProcessHistory } from './ProcessHistory/ProcessHistory';
import {
  updateQualificationByJudge,
  getjudgeIdeaEvaluation,
} from '../../../../services/judgeService.js';
import { translateStatus } from '../../../../helpers/translate.js';
import { JudgeIdeaDetail } from '../../JudgeIdeaDetail/JudgeIdeaDetail';
import { getContests } from '../../../../services/contestService.js';
import { toast } from 'react-toastify';
import { showDate } from '../../../../helpers/dateFormat.js';
import { useHistory } from 'react-router-dom';
import  DocumentCriteria from '../EvaluationIdea/DocumentCriterias/DocumentCriteria.jsx'

import './EvaluationIdea.scss';

export const EvalStatusComponent = (props) => {
  const { status } = props;
  let color = '';
  if (status === 'NOT_EVALUATED') {
    color = '#ff0000';
  }
  if (status === 'IN_PROGRESS') {
    color = '#ff9900';
  }
  if (status === 'EVALUATED') {
    color = '#feb13e';
  }
  return (
    <h4 style={{ color: color }} className="evaluation-status-component-ideas">
      {translateStatus(status)}
    </h4>
  );
};

export const IdeaDetailComponent = (props) => {
  const { ideaForEdition, filterStage, criteriaList } = props;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  return (
    <div className="container idea-detail">
      <h1>{criteriaList?.judgeIdea?.idea?.title}</h1>
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-4">
          <h3>SECTOR</h3>
          <h4>{criteriaList?.judgeIdea?.idea?.sector?.economicSector}</h4>
        </div>
        <div className="col-12 col-sm-12 col-lg-4">
          <h3>Estatus de la Evaluación</h3>
          <EvalStatusComponent status={criteriaList?.evaluationStatus} />
        </div>
        <div className="col-12 col-sm-12 col-lg-4">
          <h3>ETAPA</h3>
          <h4 className="dead-line-date">
            {criteriaList?.judgeIdea?.idea?.stage.stage}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-4">
          <h3>CATEGORIA</h3>
          <h4>{criteriaList?.judgeIdea?.idea?.proposalType?.proposalType}</h4>
        </div>

        <div className="col-12 col-sm-12 col-lg-4">
          <h3>FECHA LÍMITE PARA EVALUAR</h3>
          <h4>{showDate(criteriaList?.judgeIdea?.stage?.endDate)}</h4>
        </div>

        <div className="col-12 col-sm-12 col-lg-4">
          <h3>LÍDER DE CATEGORÍA</h3>
          <h4 className="dead-line-date">
            {ideaForEdition?.idea?.stage?.status}
          </h4>
        </div>
      </div>
      {userSession?.userType === 'participant' ? (
        <React.Fragment>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-4">
              <h3>Etapa</h3>
              <h4>{filterStage ? filterStage?.stage : 'Etapa inactiva'}</h4>
            </div>

            <div className="col-12 col-sm-12 col-lg-4">
              <h3>FECHA LÍMITE</h3>
              <h4 className="dead-line-date">
                {filterStage
                  ? moment(filterStage?.endDate)
                      .locale('es')
                      .format('DD/MM/YYYY')
                  : 'N/A'}
              </h4>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

const UserDetailsTab = (props) => {
  const { tab, criteriaList, selectIdea, contest,stage,typeIdea } = props;
  const params = useParams();
  const history = useHistory();
  let pathArray = window.location.pathname.split('/');

  let selected = 'gray';
  let barColor = '#f2f2f2';
  if ((pathArray[5] === tab.url)||(pathArray[7] === tab.url)) {
    selected = '#30377b';
    barColor = '#30377b';
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const selectIdeaAndRedirect = (idea) => {
    selectIdea(idea);
    history.push(
      `/home/${
        userSession.userType === 'admin' ? 'admin' : 'judge'
      }/evaluations/${params.id}/${tab.url}`
    );
  };
  if (tab.url === 'project-info') {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => selectIdeaAndRedirect(criteriaList?.judgeIdea?.idea)}
        className="users-detail-tab-component"
      >
        <h6 style={{ color: selected }}>{tab.label}</h6>
        <div style={{ backgroundColor: barColor }}></div>
      </div>
    );
  } else if(tab.url==='evaluation-criteria-docs'){
    return (
      <div className="users-detail-tab-component">
      <NavLink
        to={`/home/${
          userSession.userType === 'admin' ? 'admin' : 'judge'
        }/evaluations/${contest}/${stage}/${typeIdea}/${tab.url}`}
      >
        <h6 style={{ color: selected }}>{tab.label}</h6>
      </NavLink>
      <div style={{ backgroundColor: barColor }}></div>
    </div>

    );

  }

  return (
    <div className="users-detail-tab-component">
      <NavLink
        to={`/home/${
          userSession.userType === 'admin' ? 'admin' : 'judge'
        }/evaluations/${params.id}/${tab.url}`}
      >
        <h6 style={{ color: selected }}>{tab.label}</h6>
      </NavLink>
      <div style={{ backgroundColor: barColor }}></div>
    </div>
  );
};

export const EvaluationIdea = (props) => {
  const {
    selectIdea,
    ideaList,
    ideaForEdition,
    addMemberService,
    files,
    handleDragAndDropFiles,
    handleBusinessDragAndDropFiles,
    handleONGDragAndDropFiles,
    handleDragAndDropSummaryFiles,
    handleDragAndDropMembersFiles,
    handleDragAndDropFinalPresentationFiles,
    removeDocumentsfromList,
    openBase64File,
    deleteMemberService,
    validateEmailFormat,
    validatePhoneNumberFormat,
    validateNumericFormat,
    validateIfLegal,
    blockedLogin,
    stagesDropdowns,
    handleStageDropDown,
    addFinalPresentationUrlService,
    selectAwardService,
    currentContestStage,
    handleAwardOnChange,
    getDocumentsByCriteriaPrincipal,
    evaluationcriteria,
  } = props;

  const params = useParams();
  const [criteriaList, setCriteriList] = useState([]);
  const [stage, setStage] = useState();
  const [stageId,setStageId]= useState();
  const [changed, setChanged] = useState(false);
  const [contest,setContest] = useState();
  const [typeIdea,setTypeIdea] = useState();

  const tabs = [
    { label: 'Información del Proyecto', url: 'project-info' },
    { label: 'Evaluación', url: 'evaluation' },
    { label: 'Criterios de Evaluación',url: 'evaluation-criteria-docs'}
  ];

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response.status == 200) {
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        setContest(selectedContest.id);
        let [judgeStages] = selectedContest.stages.filter(
          (s) => s.type === 'JURADO' && s.isOpen
        );
        console.log(judgeStages);
        if (judgeStages) {

          let [seachStage] = selectedContest.stages.filter(
            (s) => s.stage === judgeStages?.stage && s.type === 'PARTICIPANTE'
          );
          setStage(seachStage);
          setStageId(judgeStages.id);
        }
      } else {
        toast.error('Error al obtener criterios');
      }
    });
  };

  const handleCriteriaListChange = (e, i) => {
    const { value } = e.target;
    let auxList = criteriaList;
    auxList.singleQualifications[i].qualification = parseFloat(value);
    setCriteriList((prevState) => {
      return { ...prevState, auxList };
    });
    setChanged(true);
  };

  const updateQualification = () => {
    let auxArray = criteriaList?.singleQualifications;

    for (let i = 0; i < auxArray.length; i++) {
      delete auxArray[i].criteria.contest.stages;
    }

    updateQualificationByJudge(auxArray, params?.id)
      .then((response) => {
        if (response.status === 200) {
          setCriteriList(response.data);
          toast.success('Evaluación Guardada');
          getCriterias();
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getCriterias = () => {
    getjudgeIdeaEvaluation(params?.id)
      .then((response) => {
        if (response.status === 200) {
          setTypeIdea(response.data.singleQualifications[0].criteria.types)
          setCriteriList(response.data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };


  useEffect(() => {
    getContestFunction();
    getCriterias();
  }, []);

  return (
    <>
      <div className="dashboard-title-container">
        <h1 style={{ textTransform: 'uppercase' }}>
          <NavLink
            to={`/home/${
              userSession.userType === 'admin'
                ? 'admin/idea-management'
                : 'judge/evaluations'
            }`}
          >
            <span style={{ color: 'gray' }}>Evaluaciones</span>
          </NavLink>
          {'   '}> {criteriaList?.judgeIdea?.idea?.title}
        </h1>{' '}
      </div>
      <IdeaDetailComponent criteriaList={criteriaList} />
      <br />
      <div className="user-details-tabs-container">
        {tabs.map((t) => (
          <UserDetailsTab
            tab={t}
            criteriaList={criteriaList}
            selectIdea={selectIdea}
            contest={contest}
            stage={stageId}
            typeIdea={typeIdea}
          />
        ))}
      </div>
      <Switch>
        <Route
          path={`/home/${
            userSession.userType === 'admin' ? 'admin' : 'judge'
          }/evaluations/:id/evaluation`}
          render={(props) => {
            return (
              <ProcessHistory
                {...props}
                criteriaList={criteriaList}
                changed={changed}
                stage={stage}
                handleCriteriaListChange={handleCriteriaListChange}
                updateQualification={updateQualification}
              />
            );
          }}
        />
        <Route
          path={`/home/${
            userSession.userType === 'admin' ? 'admin' : 'judge'
          }/evaluations/:id/project-info`}
          render={(props) => {
            return (
              <JudgeIdeaDetail
                {...props}
                ideaList={ideaList}
                handleDragAndDropFiles={handleDragAndDropFiles}
                handleONGDragAndDropFiles={handleONGDragAndDropFiles}
                handleDragAndDropSummaryFiles={handleDragAndDropSummaryFiles}
                handleDragAndDropMembersFiles={handleDragAndDropMembersFiles}
                handleBusinessDragAndDropFiles={handleBusinessDragAndDropFiles}
                handleDragAndDropFinalPresentationFiles={
                  handleDragAndDropFinalPresentationFiles
                }
                files={files}
                ideaForEdition={ideaForEdition}
                removeDocumentsfromList={removeDocumentsfromList}
                openBase64File={openBase64File}
                userSession={userSession}
                addMemberService={addMemberService}
                addFinalPresentationUrlService={addFinalPresentationUrlService}
                deleteMemberService={deleteMemberService}
                validateEmailFormat={validateEmailFormat}
                validatePhoneNumberFormat={validatePhoneNumberFormat}
                validateNumericFormat={validateNumericFormat}
                validateIfLegal={validateIfLegal}
                currentContestStage={currentContestStage}
                blockedLogin={blockedLogin}
                stagesDropdowns={stagesDropdowns}
                handleStageDropDown={handleStageDropDown}
                selectAwardService={selectAwardService}
                handleAwardOnChange={handleAwardOnChange}
              />
            );
          }}
        />
        <Route
          path={`/home/${
            userSession.userType === 'admin' ? 'admin' : 'judge'
          }/evaluations/:idcontest/:idfase/:typeIdea/evaluation-criteria-docs`}
          render={(props) => {
            return (
              <DocumentCriteria
                {...props}
                getDocumentsByCriteriaPrincipal={getDocumentsByCriteriaPrincipal}
                evaluationcriteria={evaluationcriteria}
              />
            );
          }}
        />

      </Switch>
    </>
  );
};
