/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { getJudgePreferences } from '../../../../../services/judgeService.js';
import { toast } from 'react-toastify';
import { translateIdeaType } from '../../../../../helpers/translate.js';
import { translateUserType } from '../../../../../helpers/translate.js';
import './Preferences.scss';

export const InfoComponent = (props) => {
  const { label, value } = props;
  return (
    <>
      <div className="col-6 col-sm-6 col-lg-4 gray-data-label judge-idea-info-margin">
        <h6>{label}</h6>
      </div>
      <div className="col-6 col-sm-6 col-lg-8 judge-idea-info-margin">
        <p>{value}</p>
      </div>
    </>
  );
};

export const Preferences = () => {
  const [preferences, setPreferences] = useState('');

  const getJudgeData = () => {
    let user = sessionStorage.getItem('userSession');
    user = JSON.parse(user);

    getJudgePreferences(user?.userId)
      .then((response) => {
        if (response.status === 200) {
          setPreferences(response.data);
        } else {
          toast.error('Error al obtener preferencias').e;
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  useEffect(() => {
    getJudgeData();
  }, []);

  return (
    <div>
      <br />
      <h5 className="judge-general-info-section-title">
        PREFERENCIAS DE EVALUACIÓN
      </h5>
      <div className="row justify-content-md-center row-confirm judge-idea-info-margin-container">
        <InfoComponent
          label="Tipo de Jurado"
          value={translateUserType(preferences?.user?.userType)}
        />
        <InfoComponent
          label="Grupo"
          value={`Grupo ${preferences?.groupNumber}`}
        />
        <InfoComponent
          label="Tipo de Idea"
          value={preferences?.types?.map(
            (s) => `${translateIdeaType(s)},    ` + '   '
          )}
        />
        <InfoComponent
          label="Fase"
          value={preferences?.stages?.map(
            (s) => `${s?.stageName},    ` + '   '
          )}
        />
      </div>
      <br />
      <br />
    </div>
  );
};
