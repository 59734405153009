import http from './httpServices';

export async function getProposalTypes(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/proposal-types`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getSocialTypes(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/social-types`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getOcupations(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ocupations`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getEconomicSectors(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/economic-sectors`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeaStates(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/states`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function createOngFunction(data, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/create-ong`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeasParticipant(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideas-participant`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeasRegistered(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideas-registered`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeasSemiFinalist(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideas-semifinalist`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeasFinalist(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/ideas-finalist`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function addFinalPresentationUrl(data, ideaId, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/${ideaId}/add-final-presentation-url`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function evaluationWithoutQualification(
  contestId,
  stageName,
  params
) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url =
    url +
    `/evaluationIdeaWithoutQualificationReport/${contestId}/${stageName}?` +
    new URLSearchParams(params);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getRankingReport(contestId, stageName, params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url =
    url +
    `/finalQualificationReport/${contestId}/${stageName}?` +
    new URLSearchParams(params);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function evaluationAssignedByGroup(stageName, params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url =
    url +
    `/evaluationAssignedByGroup/${stageName}?` +
    new URLSearchParams(params);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getEvaluationScore(contestId, stageName, params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url =
    url +
    `/evaluationIdeaWithQualificationReport/${contestId}/${stageName}?` +
    new URLSearchParams(params);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function ideasReport(stageName, params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  // url = url + `/ideas-report/${stageName}?` + new URLSearchParams(params);
  url = url + `/ideas-report/${stageName}`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
