import React from 'react';
import { Modal, Button } from 'react-bootstrap';
// import { rolesOptions } from '../../../../helpers/types.js';
import './DeactivateCompanyModal.scss';

export const DeactivateUserModal = (props) => {
  const { isOpen, closeDeleteModal, data, action } = props;
  let callToAction = '';

  if (data.active) {
    callToAction = 'Desactivar';
  } else {
    callToAction = 'Activar';
  }

  // const nameComponent = (type, rolesOptions) => {
  //   let [selectedType] = rolesOptions.filter((r) => r.id == type);
  //   return selectedType?.name || null;
  // };

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{callToAction} Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Desea {callToAction.toLowerCase()} el siguiente usuario:
        <br />
        <br />
        <h5 className="delete-criteria-info-modal-data">
          <span>
            <b>Usuario:</b> {data?.name} {data?.lastName}
          </span>
          <span>
            <b>Correo:</b> {data?.email}
          </span>
          <span></span>
          {/* <span>
            <b>Rol:</b> {nameComponent(data?.userType, rolesOptions)}
          </span> */}
        </h5>
        <br />
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={() => action()}>
          {callToAction}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
