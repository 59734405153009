/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import './ReportModal.scss';

const options = [
  { label: 'Postular', selected: false },
  { label: 'Premiar', selected: false },
  { label: 'Eliminar', selected: false },
];

const optionProfile = [
  { label: 'Si', selected: 1 },
  { label: 'No', selected: 0 },
];

export const ReportModal = (props) => {
  const { isOpen, closeModal, csvData, modalType, selectedStage } = props;

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Descargar Reporte de Evaluaciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '12px' }}>
          {csvData.length > 0 ? (
            <h6>
              Desea descargar un reporte de las evaluaciones generadas en el
              proceso de distribución de{' '}
              <b>{csvData.length > 0 ? csvData[0]['Tipo de Idea'] : null}</b> de
              la <b>{selectedStage}</b>
            </h6>
          ) : (
            <h6>
              <b>Cargando Reporte ...</b>
            </h6>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>

        {csvData.length > 0 && (
          <CSVLink data={csvData} filename="reporte-de-evaluaciones.csv">
            <Button variant="primary" onClick={() => closeModal()}>
              Descargar
            </Button>
          </CSVLink>
        )}
      </Modal.Footer>
    </Modal>
  );
};
