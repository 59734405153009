/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { ContestSelectInput } from '../../common/ContestForms/ContestForms';
import { getContests } from '../../../services/contestService';
import { getBusiness } from '../../../services/companyService';
import {
  evaluationWithoutQualification,
  getRankingReport,
  ideasReport,
} from '../../../services/ideaHelperService.js';
import { getSpecialAwardList } from '../../../services/awardService';
import { getEvaluationScore } from '../../../services/ideaHelperService';

import { getUsersReport } from '../../../services/userListService.js';
import { CSVLink } from 'react-csv';
import exportImg from '../../../images/export.svg';
import {
  translateUserType,
  translateStatus,
} from '../../../helpers/translate.js';
import { getSpecialAwards } from '../../../services/awardService.js';
import moment from 'moment';
import { toast } from 'react-toastify';
import './Reports.scss';

const rolesOptions = [
  { id: '0', name: 'Administrador' },
  { id: '1', name: 'Participante' },
  { id: '2', name: 'Jurado' },
  { id: '3', name: 'Jurado Líder' },
  { id: '4', name: 'Jurado Especial' },
];
const stagesOption = [
  { id: 'Fase 1', name: 'Fase 1' },
  { id: 'Fase 2', name: 'Fase 2' },
  // { id: 'Fase 3', name: 'Fase 3' },
];

const ideaTypeOptions = [
  { id: 'D', name: 'Digital' },
  { id: 'S', name: 'Social' },
  { id: 'N', name: 'Negocio' },
];

const ideaStatusOptions = [
  // { id: '', name: 'Todos' },
  { id: '0', name: 'Sin Evaluar' },
  { id: '1', name: 'En progreso' },
  { id: '2', name: 'Evaluadas' },
];

const statusOption = [
  { id: true, name: 'Activo' },
  { id: false, name: 'Inactivo' },
];

const ideaOptions = [
  { id: 'Participante', name: 'Participante' },
  { id: 'Semifinalista', name: 'Semifinalista' },
  { id: 'Finalista', name: 'Finalista' },
  { id: 'Ganador', name: 'Ganador' },
];

export const Reports = (props) => {
  const [contestList, setContestList] = useState([]);
  const [isContestOpen, setIsContestOpen] = useState(true);
  const [selectedContest, setSelectedContest] = useState('');
  const [userReportFilter, setUserReportFilter] = useState({});
  const [evaluationsReportFilter, setEvaluationsReportFilter] = useState({});
  const [rankingReportFilter, setRankingReportFilter] = useState({});
  const [evaluationScoreReportFiter, setEvaluationScoreReportFiter] = useState(
    {}
  );
  const [specialMentionReportFilter, setSpecialMentionReportFilter] = useState(
    {}
  );
  const [ideaReportFilter, setIdeaReportFilter] = useState({
    stage: 'Fase 1',
  });
  const [companyList, setCompanyList] = useState('');
  const [userList, setUserList] = useState('');
  const [evaluationList, setEvaluationList] = useState('');
  const [rankingList, setRankingList] = useState('');
  const [specialAwardList, setSpecialAwardList] = useState('');
  const [evaluationScoreList, setEvaluationScoreList] = useState('');
  const [ideaList, setIdealist] = useState('');
  const [awardList, setAwardList] = useState('');

  const getSpecialAwardsFunction = (contestId) => {
    getSpecialAwards(contestId)
      .then((response) => {
        if (response.status === 200) {
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              id: response.data[i].title,
              name: response.data[i].title,
            });
          }
          setAwardList(list);
        }
      })
      .catch(() => {
        toast.error('Error al conseguir premios');
      });
  };

  const getAllCompanyFunction = () => {
    let params = {
      page: 0,
      size: 100000,
      // rol: filter.rol,
      // search: filter.filtro,
      // estatus: filter.estatus,
      // group: filter.group,
      // empresa: filter.empresa ? filter.empresa.toString() : filter.empresa,
    };

    // setIsloading(true);
    getBusiness(params)
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response.data.content.length; i++) {
            data.push({
              Nombre: response.data.content[i].businessName,
              Sector: response.data.content[i].economicSector?.economicSector,
              Email: response.data.content[i].email,
              // id: response.data.content[i].id,
              Teléfono: response.data.content[i].phone,
              Dirección: response.data.content[i].address,
              'Código Postal': response.data.content[i].postalCode,
              Estado: response.data.content[i].state?.state,
            });
          }
          setCompanyList(data);
          // setIsloading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getAllUsersFunction = () => {
    let params = {
      page: 0,
      size: 100000,
      rol: userReportFilter.rol,
      active: userReportFilter.active,
    };

    // setIsloading(true);
    getUsersReport(params)
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response.data.length; i++) {
            data.push({
              'Nombre y Apellido': response.data[i].name,
              Email: response.data[i].email,
              Rol: response.data[i].rol,
              Estatus: response.data[i].estatus,
              Sexo: response.data[i].gender,
              'Teléfono Hogar': response.data[i].phoneHome,
              'Teléfono Movil': response.data[i].phoneMobile,
              'Teléfono Adicional': response.data[i].phoneOther,
              Estado: response.data[i].state,
              Dirección: response.data[i].address,
              Empresa: response.data[i].companyName,
              Cargo: response.data[i].companyPosition,
              'Evalua Negocio (SI/NO)': response.data[i].evaluateBusiness,
              'Evalua Digital (SI/NO)': response.data[i].evaluateDigital,
              'Evalua Social (SI/NO)': response.data[i].evaluateSocial,
              'Evalua F1 (SI/NO)': response.data[i].evaluateStage1,
              'Evalua F2 (SI/NO)': response.data[i].evaluateStage2,
              'Evalua F3 (SI/NO)': response.data[i].evaluateStage3,
              'Es Lider (SI/NO)': response.data[i].isLeader,
              'Grupo al que pertenece': response.data[i].groupNumber,
            });
          }
          setUserList(data);
          // setIsloading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const getAllEvaluationsFunction = (contestId) => {
    if (contestId) {
      setEvaluationList('');
      let params = {
        page: 0,
        size: 100000,
        // groupId: groupId ? groupId : '',
        // idUser: userSession.userId,
        status: evaluationsReportFilter.status,
        ideaType: evaluationsReportFilter.ideaType,
      };

      evaluationWithoutQualification(
        contestId,
        evaluationsReportFilter?.stage,
        params
      )
        .then((response) => {
          if (response.status === 200) {
            let data = [];
            for (let i = 0; i < response.data.length; i++) {
              data.push({
                Fase: response.data[i]?.stageName,
                // 'ID de la Idea': response.data[i]?.judgeIdea?.idea?.id,
                'Nombre de la Idea': response.data[i]?.title,
                'Estatus de la idea': response.data[i]?.statusIdea,
                'ID DANA': response.data[i]?.danaCode,
                'Email Lider de Idea': response.data[i]?.emailLeader,
                'Tipo de Idea': response.data[i]?.ideaType,
                'Lider de Idea': response.data[i]?.nameLeader,
                'Email del jurado evaluador': response.data[i]?.emailJudge,
                'Estatus de Evaluación': response.data[i]?.statusEvaluation,
                'Jurado Evaluador': response.data[i]?.nameJudge,
                'Telefono Celular': response.data[i]?.phoneMobileJudge,
                'Telefono Jurado': response.data[i]?.phoneHomeJudge,
                'Nota total': response.data[i]?.qualification,
              });
            }
            setEvaluationList(data);
          } else {
            toast.error('Error obtener evaluaciones');
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          // setIsLoading(false);
        });
    }
  };

  const getAllRankingFunction = (contestId) => {
    if (contestId) {
      setRankingList('');
      let params = {
        page: 0,
        size: 100000,
        // groupId: groupId ? groupId : '',
        // idUser: userSession.userId,
        status: rankingReportFilter.status,
        ideaType: rankingReportFilter.ideaType,
      };

      getRankingReport(contestId, rankingReportFilter?.stage, params)
        .then((response) => {
          if (response.status === 200) {
            let data = [];
            for (let i = 0; i < response.data.length; i++) {
              data.push({
                Fase: response.data[i]?.stageName,
                // 'ID de la Idea': response.data[i]?.judgeIdea?.idea?.id,
                'ID DANA': response.data[i]?.danaCode,
                'Nombre de la Idea': response.data[i]?.title,
                'Tipo de Idea': response.data[i]?.ideaType,
                'Estado de la idea': response.data[i]?.stateIdea,
                'Lider de Idea': response.data[i]?.nameLeader,
                'Cédula del Lider de Idea':
                  response.data[i]?.identificationNumber,
                'Email Lider de Idea': response.data[i]?.emailLeader,
                // 'Estatus de Evaluación': response.data[i]?.statusEvaluation,
                // 'Jurado Evaluador': response.data[i]?.nameJudge,
                'Telefono Celular del Lider':
                  response.data[i]?.phoneMobileLeader,
                'Telefono del Lider': response.data[i]?.phoneHomeLeader,
                'Otro Telefono el Lider': response.data[i]?.phoneOtherLeader,
                'Nota Final': response.data[i]?.finalQualification,
              });
            }
            setRankingList(data);
          } else {
            toast.error('Error obtener evaluaciones');
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          // setIsLoading(false);
        });
    }
  };

  const getEvaluationScoreFunction = (contestId) => {
    if (contestId) {
      setEvaluationScoreList('');
      let params = {
        // groupId: groupId ? groupId : '',
        // idUser: userSession.userId,
        status: evaluationScoreReportFiter.status,
        ideaType: evaluationScoreReportFiter.ideaType,
      };

      getEvaluationScore(contestId, evaluationScoreReportFiter?.stage, params)
        .then((response) => {
          if (response.status === 200) {
            let data = [];
            for (let i = 0; i < response.data.length; i++) {
              data.push({
                Fase: response.data[i]?.stageName,
                'Título del proyecto': response.data[i]?.title,
                'ID de Dana': response.data[i]?.danaCode,
                'Tipo de Idea': response.data[i]?.ideaType,
                'Estatus de Idea': response.data[i]?.statusIdea,
                'Estatus de Evaluación': response.data[i]?.statusEvaluation,
                Jurado: response.data[i]?.nameJudge,
                Criterio: response.data[i]?.criteriaName,
                Calificación: response.data[i]?.qualification,
              });
            }
            setEvaluationScoreList(data);
          } else {
            toast.error('Error obtener evaluaciones');
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          // setIsLoading(false);
        });
    }
  };

  const getSpecialAwardFunction = (contest) => {
    if (contest) {
      setSpecialAwardList('');
      let params = {
        page: 0,
        size: 100000,
        // groupId: groupId ? groupId : '',
        // idUser: userSession.userId,
        status: specialMentionReportFilter.status,
        award: specialMentionReportFilter.award,
      };

      getSpecialAwardList(params, contest)
        .then((response) => {
          if (response.status === 200) {
            let data = [];
            for (let i = 0; i < response.data.content.length; i++) {
              data.push({
                'ID Dana': response.data?.content[i][1],
                'Nombre de la Idea': response?.data.content[i][0],
                'Tipo de Idea': response.data?.content[i][2],
                'Mención Especial	': response.data?.content[i][3],
                Estatus: response.data?.content[i][4],
                Líder: response.data?.content[i][9],
                'Correo del Líder': response.data?.content[i][10],
                'Telefono del Líder': response.data?.content[i][12],
                'Ganadora de la mención especial': response.data?.content[i][5]
                  ? 'Ganadora'
                  : '',
              });
            }
            setSpecialAwardList(data);
          } else {
            toast.error('Error obtener evaluaciones');
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log('error:', error);
          // setIsLoading(false);
        });
    }
  };

  const getAllIdeasFunction = (contestId) => {
    let params = {
      page: 0,
      size: 100000,
      // status: evaluationsReportFilter.status,
      // ideaType: evaluationsReportFilter.ideaType,
    };
    ideasReport(contestId, params)
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response.data.length; i++) {
            data.push({
              'Id de Idea': response.data[i]?.ideaId,
              'Id de Dana': response.data[i]?.danaCode,
              'Fecha de Registro': moment(response.data[i]?.creationDate)
                .locale('es')
                .format('DD/MM/YYYY'),
              'Titulo de la Idea': response.data[i]?.title,
              'Tipo de Idea': response.data[i]?.ideaType,
              'Sector Económico': response.data[i]?.economicSector,
              Estado: response.data[i]?.ideaState,
              Estatus: response.data[i]?.ideaStatus,
              'Nombre del Líder': response.data[i]?.leaderName,
              'Cédula del Líder': response.data[i]?.identificationNumber,
              'Fecha de Nacimiento del Líder': moment(
                response.data[i]?.birthdate
              )
                .locale('es')
                .format('DD/MM/YYYY'),
              'Sexo del Líder': response.data[i]?.gender,
              'Teléfono del Líder (HAB)': response.data[i]?.phoneHome,
              'Teléfono del Líder (MÓVIL)': response.data[i]?.phoneMobile,
              'Teléfono del Líder (OFIC/OTRO)': response.data[i]?.phoneOther,
              'Nivel educativo del Líder': response.data[i]?.educationalLevel,

              'Sector Económico del Líder':
                response.data[i]?.economicSectorLeader,
              'Empresa del Líder': response.data[i]?.companyName,
              'Cargo del Líder': response.data[i]?.companyPosition,
              'Emprendimiento del Líder':
                response.data[i]?.entrepeneurshipDescription,
              'Correo Electrónico del Líder': response.data[i]?.email,
              'Tipo de Idea Social': response.data[i]?.socialType,
              'Nombre de la ONG': response.data[i]?.nameOng,
              'Estado de la ONG': response.data[i]?.stateOng,
              'Correo Electrónico de la ONG': response.data[i]?.emailOng,
              'Estado de la ONG': response.data[i]?.stateOng,
              'Teléfono ONG': response.data[i]?.phoneOng,
              'Dirección de la ONG': response.data[i]?.addressOng,
              'Cargo Resumen (SI/NO)': response.data[i]?.withResume,
              'Cargo Caso de Negocio (SI/NO)':
                response.data[i]?.withBusinessPlan,
              'Cargo Presentación final (SI/NO)':
                response.data[i]?.withFinalPresentation,
              'Calificación final (Fase 1)':
                response.data[i]?.finalQualificationStage1,
              'Calificación final (Fase 2)':
                response.data[i]?.finalQualificationStage2,
            });
          }
          setIdealist(data);
        } else {
          toast.error('Error obtener evaluaciones');
          // setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('error:', error);
        // setIsLoading(false);
      });
  };

  const onChangeSelectedContest = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    if (value) {
      setSelectedContest(value);
      getSpecialAwardsFunction(value);
      // Search Reports by contest

      getAllIdeasFunction(value);
      getSpecialAwardFunction(value);
      getEvaluationScoreFunction(value);
      getAllEvaluationsFunction(value);
      getAllRankingFunction(value);
    } else {
      toast.info(`Debe seleccionar un concurso`);
    }
  };

  const getContestFunction = async (contest) => {
    if (!contest) {
      await getContests().then((response) => {
        if (response.status == 200) {
          setContestList(response.data);
          let [selectedContest] = response.data.filter((c) => c.isOpen);
          if (!selectedContest) {
            setIsContestOpen(false);
          } else {
            setIsContestOpen(true);
            getSpecialAwardsFunction(selectedContest.id);
          }
          if (selectedContest) {
            let [seletedStage] = selectedContest.stages.filter(
              (s) => s.isOpen == true
            );

            if (seletedStage) {
              setEvaluationsReportFilter({
                ...evaluationsReportFilter,
                stage: seletedStage?.stage,
              });
              setEvaluationScoreReportFiter({
                ...evaluationScoreReportFiter,
                stage: seletedStage?.stage,
              });
              setRankingReportFilter({
                ...rankingReportFilter,
                stage: seletedStage?.stage,
              });
            } else {
              setEvaluationsReportFilter({
                ...evaluationsReportFilter,
                stage: 'Fase 1',
              });
              setEvaluationScoreReportFiter({
                ...evaluationScoreReportFiter,
                stage: 'Fase 1',
              });
              setRankingReportFilter({
                ...rankingReportFilter,
                stage: 'Fase 1',
              });
            }

            setSelectedContest(selectedContest?.id);
            getAllIdeasFunction(selectedContest?.id);
            getEvaluationScoreFunction(selectedContest?.id);
            getAllEvaluationsFunction(selectedContest?.id);
            getAllRankingFunction(selectedContest?.id);
          } else {
            setSelectedContest(-1);
          }
        } else {
          toast.error(`${response.message}` || 'Error al obtener concursos');
        }
      });
    }
  };

  const handleUserReportFiltersChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUserList('');
    setUserReportFilter({ ...userReportFilter, [name]: value });
  };
  const handleEvaluationReportFiltersChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'stage' && !value) {
      return null;
    }
    setEvaluationsReportFilter({ ...evaluationsReportFilter, [name]: value });
  };

  const handleRankingReportFiltersChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'stage' && !value) {
      return null;
    }
    setRankingReportFilter({ ...rankingReportFilter, [name]: value });
  };

  const handleSpecialMentionReportFiltersChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'stage' && !value) {
      return null;
    }
    setSpecialMentionReportFilter({
      ...specialMentionReportFilter,
      [name]: value,
    });
  };

  const handleEvaluationScoreReportFiltersChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'stage' && !value) {
      return null;
    }
    setEvaluationScoreReportFiter({
      ...evaluationScoreReportFiter,
      [name]: value,
    });
  };

  const handleIdeaReportFiltersChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'stage' && !value) {
      return null;
    }
    setIdeaReportFilter({ ...ideaReportFilter, [name]: value });
  };

  useEffect(() => {
    getContestFunction(selectedContest);
    getAllCompanyFunction();
    getAllUsersFunction();
    getEvaluationScoreFunction(selectedContest);
    getSpecialAwardFunction(selectedContest);
    getAllEvaluationsFunction(selectedContest);
    getAllRankingFunction(selectedContest);
  }, [
    userReportFilter,
    evaluationsReportFilter,
    specialMentionReportFilter,
    evaluationScoreReportFiter,
    rankingReportFilter,
  ]);

  return (
    <React.Fragment>
      <div className="dashboard-admin-title-container">
        <h1>REPORTES</h1>
      </div>
      <div className="contest-list-column">
        <ContestSelectInput
          options={contestList}
          handleChange={onChangeSelectedContest}
          name="contestName"
          label="Concurso"
          value={selectedContest}
          errors={''}
          type={'text'}
          placeholder={' Seleccione Concurso'}
        />
      </div>
      <div className="reports-list-container">
        <UserReportComponent
          label={'Reporte de Usuarios'}
          csvData={userList}
          onChange={handleUserReportFiltersChange}
          data={userReportFilter}
        />
        <ReportComponent label={'Reporte de Empresas'} csvData={companyList} />
        <IdeasReportComponent
          label={'Reporte de Ideas'}
          csvData={ideaList}
          onChange={handleIdeaReportFiltersChange}
          data={ideaReportFilter}
        />
        <SpecialMentionReportComponent
          label={'Reporte Mención Especial'}
          csvData={specialAwardList}
          onChange={handleSpecialMentionReportFiltersChange}
          data={specialMentionReportFilter}
          awardList={awardList}
        />
        <EvaluationsReportComponent
          label={'Reporte de Evaluaciones'}
          csvData={evaluationList}
          onChange={handleEvaluationReportFiltersChange}
          data={evaluationsReportFilter}
        />
        <EvaluationScoreReportComponent
          csvData={evaluationScoreList}
          onChange={handleEvaluationScoreReportFiltersChange}
          data={evaluationScoreReportFiter}
        />
        <RankingReportComponent
          csvData={rankingList}
          onChange={handleRankingReportFiltersChange}
          data={rankingReportFilter}
        />
      </div>
    </React.Fragment>
  );
};

export const ReportComponent = (props) => {
  const { label, csvData } = props;
  return (
    <div className="report-element-component">
      <h4>{label}</h4>
      <div className="export-found-container">
        {!csvData && 'Cargando...'}

        {csvData && (
          <CSVLink data={csvData} filename="reporte-de-empresas.csv">
            <div className="export-action-component">
              <img src={exportImg} alt="" />
              <h6>Exportar</h6>
            </div>
          </CSVLink>
        )}
      </div>{' '}
    </div>
  );
};

export const UserReportComponent = (props) => {
  const { label, csvData, onChange, data } = props;
  return (
    <div className="report-element-component">
      <h4>{label}</h4>
      <div className="report-filter-container">
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={rolesOptions}
            handleChange={onChange}
            name="rol"
            label="Rol"
            value={data?.rol}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={statusOption}
            handleChange={onChange}
            name="active"
            label="Estatus"
            value={data?.active}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
      </div>
      {!csvData && 'Cargando...'}
      {csvData && (
        <CSVLink data={csvData} filename="reporte-de-usuarios.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      )}
    </div>
  );
};

export const EvaluationsReportComponent = (props) => {
  const { label, csvData, onChange, data } = props;
  return (
    <div className="report-element-component">
      <h4>{label}</h4>
      <div className="report-filter-container">
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={stagesOption}
            handleChange={onChange}
            name="stage"
            label="Fase *"
            value={data?.stage}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>

        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaStatusOptions}
            handleChange={onChange}
            name="status"
            label="Estatus"
            value={data?.status}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaTypeOptions}
            handleChange={onChange}
            name="ideaType"
            label="Tipo"
            value={data?.ideaType}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
      </div>
      {!csvData && 'Cargando...'}
      {csvData && (
        <CSVLink data={csvData} filename="reporte-de-evaluaciones.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      )}
    </div>
  );
};

export const RankingReportComponent = (props) => {
  const { csvData, onChange, data } = props;
  return (
    <div className="report-element-component">
      <h4 style={{ fontSize: '22px !important' }}>
        Reporte Ranking Evaluación <br /> Ideas{' '}
      </h4>
      <div className="report-filter-container">
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={stagesOption}
            handleChange={onChange}
            name="stage"
            label="Fase *"
            value={data?.stage}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>

        {/* <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaStatusOptions}
            handleChange={onChange}
            name="status"
            label="Estatus"
            value={data?.status}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div> */}
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaTypeOptions}
            handleChange={onChange}
            name="ideaType"
            label="Tipo"
            value={data?.ideaType}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
      </div>
      {!csvData && 'Cargando...'}
      {csvData && (
        <CSVLink data={csvData} filename="reporte-ranking-de-evaluaciones.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      )}
    </div>
  );
};

export const IdeasReportComponent = (props) => {
  const { label, csvData, onChange, data } = props;
  return (
    <div className="report-element-component">
      <h4>{label}</h4>
      {!csvData && 'Cargando...'}
      {csvData && (
        <CSVLink data={csvData} filename="reporte-de-ideas.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      )}
    </div>
  );
};

export const SpecialMentionReportComponent = (props) => {
  const { label, csvData, onChange, data, awardList } = props;
  return (
    <div className="report-element-component">
      <h4>{label}</h4>
      <div className="report-filter-container">
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaOptions}
            handleChange={onChange}
            name="status"
            label="Estatus"
            value={data?.status}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={awardList.length > 0 ? awardList : []}
            handleChange={onChange}
            name="award"
            label="Mención"
            value={data?.award}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
      </div>
      {!csvData && 'Cargando...'}

      {csvData && (
        <CSVLink data={csvData} filename="reporte-de-premios-especiales.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      )}
    </div>
  );
};

export const EvaluationScoreReportComponent = (props) => {
  const { csvData, onChange, data } = props;
  return (
    <div className="report-element-component">
      <h4>
        Reporte de Evaluaciones <br />
        <span style={{ textAlign: 'center' }}>(Con nota)</span>
      </h4>
      <div className="report-filter-container">
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={stagesOption}
            handleChange={onChange}
            name="stage"
            label="Fase *"
            value={data?.stage}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaStatusOptions}
            handleChange={onChange}
            name="status"
            label="Estatus"
            value={data?.status}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>

        <div className="report-filter-wrapper">
          <ContestSelectInput
            options={ideaTypeOptions}
            handleChange={onChange}
            name="ideaType"
            label="Tipo"
            value={data?.ideaType}
            errors={''}
            type={'text'}
            placeholder={'           '}
          />
        </div>
      </div>
      {!csvData && 'Cargando...'}

      {csvData && (
        <CSVLink data={csvData} filename="reporte-de-evaluaciones-con-nota.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      )}
    </div>
  );
};
