import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { CriteriaTableCopied } from '../../../../common/Tables/Tables';
import { ContestSelectInput } from '../../../../common/ContestForms/ContestForms';
import './CopyCriteriaModal.scss'; // Asegúrate de importar los estilos

const CopyCriteriaModal = (props) => {
  const {
    show,
    handleClose,
    onSave,
    contestList,
    handleChange,
    stagesOptions,
    criteria,
    isLoading,
    criteriaList,
  } = props;



  let filteredContest = contestList.filter((c) => c.isOpen === false);

  const ideaTypes = [
    { id: "BUSINESS", name: "Negocio" },
    { id: "SOCIAL", name: "Social" },
    { id: "DIGITAL", name: "Digital" },
    { id: "ONG", name: "ONG" },
  ];

  const stages = [ ...stagesOptions];

  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedContest, setSelectedContest] = useState('');
  const [selectedStage, setSelectedStage] = useState('');

  const resetModal = () => {
    setSelectedCriteria([]);
    setIsButtonEnabled(false);
    setSelectedContest('');
    setSelectedStage('');
  };

  const handleSelectAllAcrossPages = (selectAll) => {
    const allCriteria = criteriaList || [];
    const updatedCriteria = allCriteria.map(c => ({
      ...c,
      selected: selectAll
    }));
    setSelectedCriteria(updatedCriteria);
    checkButtonEnabled(selectAll);
  };

  useEffect(() => {
    if (criteriaList) {
      const initialSelected = criteriaList.map((c) => ({
        ...c,
        selected: false,
      }));
      setSelectedCriteria(initialSelected);
    }
  }, [criteriaList]);

  const checkButtonEnabled = () => {
    const anySelected = selectedCriteria.some((crit) => crit.selected);
    const isContestSelected = selectedContest !== '';
    const isStageSelected = selectedStage !== '';
    setIsButtonEnabled(anySelected && isContestSelected && isStageSelected);
  };

  useEffect(() => {
    checkButtonEnabled();
  }, [selectedCriteria, selectedContest, selectedStage]);

  const handleSave = () => {
    onSave(selectedCriteria.filter(c => c.selected));
    resetModal();
  };

  const handleContestChange = (name, value) => {
    handleChange(name, value);
    setSelectedContest(value); // Actualiza el estado del concurso seleccionado
    checkButtonEnabled(); // Revisa si el botón debe habilitarse
  };

  const handleStageChange = (name, value) => {
    handleChange(name, value);
    setSelectedStage(value); // Actualiza el estado de la fase seleccionada
    checkButtonEnabled(); // Revisa si el botón debe habilitarse
  };

  const handleTypesChange = (name, value) => {
    handleChange(name, value);
    // No afecta la habilitación del botón, pero se mantiene para filtrar o buscar
  };

  const handleCancel = () => {
    handleClose(); 
    resetModal(); // Reset modal when canceled
  };

  return (
    <Modal show={show} 
           onHide={handleClose}
           className="custom-modal" >
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Criterios de Evaluación a copiar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row">
      <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={filteredContest}
              handleChange={handleContestChange}  // Usa la función de manejo personalizada
              name="contest"
              label="Concurso *"
              type={'text'}
              placeholder={''}
              errors={''}
              value={criteria?.contest}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={stages}
              handleChange={handleStageChange}  // Usa la función de manejo personalizada
              name="stage"
              label="Fase *"
              type={'text'}
              placeholder={''}
              errors={''}
              value={criteria?.stage}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={ideaTypes}
              handleChange={handleTypesChange}  // Función de manejo personalizada para el tercer select
              name="types"
              label="Tipo de Idea"
              type={'text'}
              placeholder={''}
              errors={''}
              value={criteria?.types}
            />
          </div>
      </div>
      <div className="row justify-content-center">
      <div className="table-container">
     <CriteriaTableCopied
        data={criteriaList}
        isLoading={isLoading}
        selectedCriteria={selectedCriteria}
        setSelectedCriteria={setSelectedCriteria}
        handleSelectAllAcrossPages={handleSelectAllAcrossPages}
      />
      </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="primary" 
        disabled={!isButtonEnabled}
        onClick={handleSave}
        >
        Copiar criterios
      </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyCriteriaModal;
