/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  ContestInput,
  ContestStartupInput,
  CheckMarkInput,
  RadioInput,
  ContestSelectInput,
} from '../../../common/ContestForms/ContestForms';

import './SpecialMentionModal.scss';

const Info = (props) => {
  const { label, text } = props;
  return (
    <div className="mention-info-component">
      <h6>{label}</h6>
      <h5>
        <b>{text}</b>
      </h5>
    </div>
  );
};

const options = [
  { label: 'Postular', selected: false },
  { label: 'Premiar', selected: false },
  { label: 'Eliminar', selected: false },
];

const optionProfile = [
  { label: 'Si', selected: 1 },
  { label: 'No', selected: 0 },
];

export const SpecialMentionModal = (props) => {
  const {
    isOpen,
    closeModal,
    selectedIdea,
    action,
    handleChange,
    companiesList,
    modalType,
  } = props;

  const [award, setAward] = useState({
    negocio: false,
    social: false,
    digital: false,
  });

  let callToAction = '';
  if (modalType == 'create') {
    callToAction = 'Agregar';
  } else {
    callToAction = 'Editar';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Mención Especial - ID {selectedIdea[1]} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '12px' }}>
          <h3>{selectedIdea?.title}</h3>
          <div className="mention-info-component-container">
            <Info label={'TIPO'} text={selectedIdea[2]} />
            <Info label={'MENCIÓN ESPECIAL'} text={selectedIdea[3]} />
            <Info label={'IDEA'} text={selectedIdea[0]} />
          </div>

          <h6>
            Estas Seguro que deseas asignar la idea: <b>{selectedIdea[0]}</b>{' '}
            como ganadora de la mención especial: <b>{selectedIdea[3]}</b>
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => action(selectedIdea, true)}>
          Hacer Ganadora
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const DeleteSpecialMentionModal = (props) => {
  const {
    isOpen,
    closeModal,
    selectedIdea,
    action,
    handleChange,
    companiesList,
    modalType,
  } = props;

  const [award, setAward] = useState({
    negocio: false,
    social: false,
    digital: false,
  });

  let callToAction = '';
  if (modalType == 'create') {
    callToAction = 'Agregar';
  } else {
    callToAction = 'Editar';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>
          Remover Mención Especial - ID {selectedIdea[1]}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '12px' }}>
          <h3>{selectedIdea?.title}</h3>
          <div className="mention-info-component-container">
            <Info label={'TIPO'} text={selectedIdea[2]} />
            <Info label={'MENCIÓN ESPECIAL'} text={selectedIdea[3]} />
            <Info label={'IDEA'} text={selectedIdea[0]} />
          </div>

          <h6>
            Estas seguro que deseas remover la idea: <b>{selectedIdea[0]}</b> de
            la mención especial: <b>{selectedIdea[3]}</b>
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => action(selectedIdea, false)}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
