export const translateStatus = (status) => {
  if (status === 'NOT_EVALUATED') {
    return 'Sin Evaluar';
  }
  if (status === 'IN_PROGRESS') {
    return 'En Progreso';
  }

  if (status === 'EVALUATED') {
    return 'Evaluada';
  } else {
    return status;
  }
};

export const translateIdeaType = (ideaType) => {
  if (ideaType === 'SOCIAL') {
    return 'Social';
  }
  if (ideaType === 'BUSINESS') {
    return 'Negocio';
  }

  if (ideaType === 'DIGITAL') {
    return 'Digital';
  } else {
    return ideaType;
  }
};

export const translateUserType = (userType) => {
  if (userType === 'participant') {
    return 'Participante';
  }
  if (userType === 'admin') {
    return 'Administrador';
  }

  if (userType === 'judge') {
    return 'Jurado';
  }

  if (userType === 'judge_special') {
    return 'Jurado Especial';
  }
  if (userType === 'judge_leader') {
    return 'Líder de Grupo';
  } else {
    return userType;
  }
};

export const translateEvalType = (ideaType) => {
  ideaType = ideaType.replace('BUSINESS', 'NEGOCIO ');

  return ideaType;
};
