import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../../services/authService";

export const ProtectedRoute = ({level, path, component: Component, render, ...rest }) => {

  // Check if the access levels exist, if one of them matches the getUserType() show the componente else redirect to login 
  let hasPermition = false;
  if (level) {
    // Check the levels 
    for (let i = 0; i < level.length; i++) {
      if (level[i] === auth.getUserType()){
        hasPermition = true; 
      }
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getToken() || hasPermition === false) {
          return <Redirect to="/login" />;
        }
        return Component ? <Component path={path} {...props} {...rest} /> : render(props);
      }}
    />
  );
};
