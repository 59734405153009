/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Button as ActionButton } from '../../Buttons/Buttons';
import { ContestInput } from '../../ContestForms/ContestForms';
import './RemoveJudgeModal.scss';

export const JudgeComponent = (props) => {
  const { judge, selectJudge } = props;
  return (
    <h6 onClick={() => selectJudge(judge)}>
      {judge.name} {judge.lastName}
    </h6>
  );
};

export const RemoveJudgeModal = (props) => {
  const { show, selectedEval, closeModal, removeJudgeFromIdea } = props;
  return (
    <Modal size="xl" centered show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>
          Quitar Jurado Evaluador{' '}
          {'( ID ' + selectedEval?.judgeIdea?.idea?.codeDana + ')'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '300px' }}>
        <h5>{selectedEval?.judgeIdea?.idea?.title}</h5>

        <h4 style={{ marginTop: '48px', marginBottom: '80px' }}>
          ¿ Está seguro que desea quitar al jurado evaluador{' '}
          <b>
            {' '}
            {selectedEval?.judgeIdea?.user?.name}{' '}
            {selectedEval?.judgeIdea?.user?.lastName}{' '}
          </b>{' '}
          de la idea <br></br> <b>{selectedEval?.judgeIdea?.idea?.title}</b> ?
        </h4>

        <h6 style={{ color: 'red' }}>
          Esta operación no se puede deshacer y la idea quedara con un evaluador
          menos, hasta que asigne otro jurado evaluador.
        </h6>
        <h6 style={{ color: 'red' }}>
          No será posible hacer una nueva distribución de jurados para ésta
          idea.
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <div className="acknowledge-accept-container"></div>
        <br />
        <div className="judge-modal-buttons-container">
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => removeJudgeFromIdea()}>
            Si, quitar evaluador
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
