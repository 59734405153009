/* eslint-disable */
import React, { useState } from 'react';
import { ContestEvalSelectInput } from '../../../../common/ContestForms/ContestForms';
import { ActionButton } from '../../../../common/Buttons/Buttons';
import {
  fase1Points,
  fase2Points,
  socialPoint,
} from '../../../../../helpers/criteriaPoints.js';
import { resetEvaluation } from '../../../../../services/judgeService.js';
import { toast } from 'react-toastify';
import { ReevalModal } from './ReevalModal/ReevalModal';
import './ProcessHistory.scss';

export const ResutlBarComponent = (props) => {
  const { criteria } = props;
  let maxPoint = 5;

  if (criteria?.criteria?.stage?.stage == 'Fase 1') {
    maxPoint = 5;
  }
  if (criteria?.criteria?.stage?.stage == 'Fase 2') {
    if (
      criteria?.criteria?.types === 'BUSINESS' ||
      criteria?.criteria?.types === 'DIGITAL'
    ) {
      maxPoint = 10;
    } else {
      maxPoint = 5;
    }
  }

  return (
    <div className="result-bar-component">
      <h6>
        {criteria.criteria.criteriaName +
          '  (' +
          criteria.criteria.percentage +
          '%)'}
      </h6>
      <div className="result-container-color-bar">
        <div
          style={{ width: `${(100 * criteria?.qualification) / maxPoint}%` }}
          className="result-color-bar"
        ></div>
      </div>
      <h5 style={{ fontSize: '20px' }}>{criteria?.qualification}</h5>
    </div>
  );
};

export const ProcessHistory = (props) => {
  const {
    criteriaList,
    changed,
    stage,
    handleCriteriaListChange,
    updateQualification,
  } = props;

  const [reevalModal, setReevalModal] = useState(false);

  let totalNote = 0;
  for (let i = 0; i < criteriaList?.singleQualifications?.length; i++) {
    totalNote =
      totalNote +
      criteriaList?.singleQualifications[i].qualification *
        criteriaList?.singleQualifications[i]?.criteria?.percentage;
  }

  totalNote = parseFloat(totalNote / 100).toFixed(2);

  const reEvaluate = (criteriaList) => {
    const { idea, user } = criteriaList.judgeIdea;
    resetEvaluation(idea?.id, user?.id).then((response) => {
      if (response?.status == 200) {
        //   setContestList(response.data);
        toast.success(`Evaluación Reseteada`);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  const closeModal = () => {
    setReevalModal(false);
  };

  const evaluateCondition = (criteria) => {
    if (
      criteria.criteria.types === 'SOCIAL' ||
      criteria.criteria.types === 'ONG'
    ) {
      return socialPoint;
    }

    if (stage?.stage == 'Fase 1') {
      return fase1Points;
    }
    if (stage?.stage == 'Fase 2') {
      if (
        criteria.criteria.types === 'BUSINESS' ||
        criteria.criteria.types === 'DIGITAL'
      ) {
        return fase2Points;
      } else {
        return fase1Points;
      }
    }
  };

  return (
    <>
      <ReevalModal
        isOpen={reevalModal}
        closeModal={closeModal}
        reEvaluate={() => reEvaluate(criteriaList)}
      />
      <br />
      <div className="dashboard-title-container">
        <h1 style={{ textTransform: 'uppercase' }}>Tabla de Evaluación</h1>
      </div>
      {(criteriaList?.evaluationStatus === 'NOT_EVALUATED' ||
        criteriaList?.evaluationStatus === 'IN_PROGRESS') &&
        criteriaList?.singleQualifications?.map((c, i) => (
          <div className="judge-evaluation-select-input contest-input-flex col-12 col-md-8">
            <ContestEvalSelectInput
              options={evaluateCondition(c)}
              handleChange={(e) => handleCriteriaListChange(e, i)}
              name="proposalType"
              label={
                c.criteria.criteriaName + '  (' + c.criteria.percentage + '%)'
              }
              criteria={c}
              value={c?.qualification}
              errors={''}
              type={'text'}
              placeholder={'Seleccione Nota'}
            />
          </div>
        ))}

      <div className="evaluation-bar-results-container">
        {criteriaList?.evaluationStatus === 'EVALUATED' &&
          criteriaList?.singleQualifications?.map((c, i) => (
            <ResutlBarComponent key={c.id + i * 3} criteria={c} />
          ))}
      </div>
      <div className="contest-input-flex col-12 col-md-8">
        <h5 className="preferences-subtitle">Nota final: {totalNote}</h5>
      </div>

      {(criteriaList?.evaluationStatus === 'NOT_EVALUATED' ||
        criteriaList?.evaluationStatus === 'IN_PROGRESS') && (
        <div className="buttons-container">
          <span></span>
          <ActionButton
            text="Guardar Evaluación"
            action={() => updateQualification()}
            disabled={!changed}
          />
        </div>
      )}
      {criteriaList?.evaluationStatus === 'EVALUATED' && (
        <div className="buttons-container">
          <span></span>
          <ActionButton text="Reevaluar" action={() => setReevalModal(true)} />
        </div>
      )}
    </>
  );
};
