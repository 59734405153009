import React from "react";
import Header from "../../common/Header/Header";
import {
  CreateIdeaFormStep1,
  CreateIdeaFormStep2,
  ConfirmIdeaForm,
} from "../../common/Forms/Forms";
import {
  CancelWizardButton,
  NextWizardButton,
  ReturnWizardButton,
  ConfirmWizardButton,
} from "../../common/Buttons/Buttons";
import { LoadSpinner } from "../../common/LoadSpinner/LoadSpinner";
import "./CreateIdea.scss";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import imageInfo from "../../../images/info.png";

export const CreateIdea = (props) => {
  const {
    blockedLogin,
    createIdea,
    createIdeaStep,
    createIdeaInputChange,
    increaseCreateIdeaStep,
    decreaseCreateIdeaStep,
    proposalType,
    ocupation,
    socialType,
    states,
    sectors,
    statesList,
    confirmCreateIdeaStep,
    userSession,
    logoutService,
    cancelIdeaCreation,
    currentContest,
  } = props;

  return (
    <React.Fragment>
      <LoadSpinner
        blocked={blockedLogin}
        text="Espere mientras procesamos su solicitud"
        color="#000000"
      />
      <Header
        userSession={userSession}
        logoutService={logoutService}
        history={props}
        logout={false}
        cancelIdeaCreation={cancelIdeaCreation}
      />

      <div className="login-dashboard login-container col-12">
        <h1 id="basic-data-title" className="title">
          Datos Básicos de la Idea{" "}
        </h1>

        <div>
          <img src={imageInfo} alt="" className="display-inline icon-dashboard" />
          <p className="display-inline">
            Para este concurso solo es permitido crear{" "}
            {currentContest.maxIdeasAllowed} Idea(s). Sino está seguro de la
            información de la Idea en este momento, puede crearla después.
          </p>
        </div>

        <Stepper activeStep={createIdeaStep.counter} alternativeLabel>
          {createIdeaStep.steps.map((s, i) => (
            <Step key={i}>
              <StepLabel>{s.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {createIdeaStep.counter === 0 ? (
          <CreateIdeaFormStep1
            createIdea={createIdea}
            createIdeaInputChange={createIdeaInputChange}
            proposalType={proposalType}
            socialType={socialType}
            ocupation={ocupation}
            statesList={statesList}
          />
        ) : null}
        {createIdeaStep.counter === 1 ? (
          <CreateIdeaFormStep2
            createIdea={createIdea}
            createIdeaInputChange={createIdeaInputChange}
            states={states}
            statesList={statesList}
            sectors={sectors}
          />
        ) : null}
        {createIdeaStep.counter === 2 ? (
          <ConfirmIdeaForm
            createIdea={createIdea}
            createIdeaInputChange={createIdeaInputChange}
            proposalType={proposalType}
            socialType={socialType}
            ocupation={ocupation}
            statesList={statesList}
            sectors={sectors}
          />
        ) : null}

        <div className="form-buttons-container col-12 col-md-6">
          <CancelWizardButton
            text={"Cancelar"}
            cancelIdeaCreation={cancelIdeaCreation}
            history={props.history}
            userSession={userSession}
          />
          {createIdeaStep.counter !== 0 ? (
            <ReturnWizardButton
              text={"Regresar"}
              handlePrevStep={decreaseCreateIdeaStep}
            />
          ) : (
            <div></div>
          )}

          {createIdeaStep.counter + 1 < createIdeaStep.steps.length ? (
            <NextWizardButton
              text={"Siguiente"}
              handleNextStep={increaseCreateIdeaStep}
            />
          ) : null}

          {createIdeaStep.counter + 1 === createIdeaStep.steps.length ? (
            <ConfirmWizardButton
              text={"Confirmar"}
              handleConfirm={() => confirmCreateIdeaStep(props.history)}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
