import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './AcknowledgeModal.scss';
import { documentType } from '../../../../helpers/types';

export const AcknowledgeModal = (props) => {
  const { accept, document, setRead, agreementOpen } = props;

  let acknowledgeType = '';

  if (document?.documentType === documentType.confidenciality) {
    acknowledgeType = 'Acuerdo de Confidencialidad';
  }

  if (document?.documentType === documentType.termsAndConditions) {
    acknowledgeType = 'Términos y Condiciones';
  }

  return (
    <Modal size="lg" centered show={agreementOpen.open}>
      <Modal.Header>
        <Modal.Title>{acknowledgeType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          style={{ width: '100%' }}
          name="text"
          id=""
          cols="30"
          rows="10"
          value={document?.description}
          disabled
        ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <div className="acknowledge-accept-container">
          {agreementOpen.accepted ? (
            <ion-icon
              style={{ color: 'green', fontSize: '24px', cursor: 'pointer' }}
              name={'checkbox'}
              onClick={() => setRead()}
            ></ion-icon>
          ) : (
            <ion-icon
              style={{ color: 'blue', fontSize: '24px', cursor: 'pointer' }}
              name={'square-outline'}
              onClick={() => setRead()}
            ></ion-icon>
          )}

          <h6
            style={{
              marginBottom: '0px',
              marginLeft: '12px',
            }}
          >
            He leído y acepto: {acknowledgeType}
          </h6>
        </div>
        <br />
        <div className="acknowledge-buttons-container">
          <Button
            disabled={!agreementOpen.accepted}
            variant="primary"
            onClick={() => accept()}
          >
            ACEPTAR Y CONTINUAR
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
