/* eslint-disable */
import React from 'react';
import pencil from '../../../../../images/edit.png';
import ErrorAlert from '../../../../common/ErrorAlert/ErrorAlert';

import {
  CancelActionButton,
  ActionButton,
} from '../../../../common/Buttons/Buttons';
import './JudgeGeneralInfo.scss';

export const InfoComponent = (props) => {
  const { label, value } = props;
  return (
    <>
      <div className="col-6 col-sm-6 col-lg-3 gray-data-label judge-idea-info-margin">
        <h6>{label}</h6>
      </div>
      <div className="col-6 col-sm-6 col-lg-3 judge-idea-info-margin">
        <p>{value}</p>
      </div>
    </>
  );
};

export const InfoEditComponent = (props) => {
  const { label, value, editInfo, enableEdit, name, onChange, type } = props;

  let errorStyle = '';

  errorStyle = editInfo?.error !== '' ? 'error-contest-input' : '';

  if (!editInfo?.edit) {
    return (
      <>
        <div className="col-6 col-sm-6 col-lg-3 gray-data-label judge-idea-info-margin">
          <h6>{label}</h6>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 judge-idea-info-margin">
          <p>{value}</p>
          {!editInfo?.edit && (
            <img
              onClick={() => enableEdit(name)}
              style={{ height: '24px' }}
              src={pencil}
              alt="pencil"
            />
          )}
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{ padding: '0px' }}
        className="col-12 col-sm-12 col-lg-6 judge-idea-info-margin"
      >
        <div style={{ width: '100%' }} className="contest-input-main-container">
          <div className={`${errorStyle} + contest-input-container`}>
            <div className="gray-data-label">
              <h6>{label}</h6>
            </div>
            <input
              onChange={onChange}
              type={type}
              value={editInfo?.value}
              name={name}
              placeholder={''}
            />
          </div>
          <ErrorAlert error={editInfo?.error} />
        </div>
      </div>
    );
  }
};

export const JudgeGeneralInfo = (props) => {
  const { user, onChange, cancelEdition, save, editInfo, enableEdit } = props;

  const genderFunction = (gender) => {
    if (gender == 1) {
      return 'Mujer';
    }
    if (gender == 2) {
      return 'Hombre';
    } else return null;
  };

  return (
    <div>
      <h5 className="judge-general-info-section-title">Informacion Personal</h5>
      <div className="row justify-content-md-center row-confirm judge-idea-info-margin-container">
        <InfoComponent label="Nombre" value={user?.name} />
        <InfoEditComponent
          label="Teléfono de Habitación"
          value={user?.phoneHome}
          editInfo={editInfo.phoneHome}
          enableEdit={enableEdit}
          name="phoneHome"
          onChange={onChange}
          type={'number'}
        />
        <InfoComponent label="Apellido" value={user?.lastName} />
        <InfoEditComponent
          label="Teléfono de Móvil"
          value={user?.phoneMobile}
          editInfo={editInfo.phoneMobile}
          enableEdit={enableEdit}
          name="phoneMobile"
          onChange={onChange}
          type={'number'}
        />
        <InfoEditComponent
          label="Email Principal"
          value={user?.email}
          editInfo={editInfo.email}
          enableEdit={enableEdit}
          name="email"
          onChange={onChange}
          type={'email'}
        />
        <InfoEditComponent
          label="Teléfono Oficina / Otro"
          value={user?.phoneOther}
          editInfo={editInfo.phoneOther}
          enableEdit={enableEdit}
          name="phoneOther"
          onChange={onChange}
          type={'number'}
        />
        <InfoComponent label="Cédula de Identidad" value={user?.idNumber} />
        <InfoComponent label="Estado" value={user?.state?.state} />
        <InfoComponent label="Fecha de Nacimiento" value={user?.birthDate} />
        <InfoComponent label="Dirección" value={user?.address} />
        <InfoComponent label="Sexo" value={genderFunction(user?.gender)} />
        <>
          <div className="col-6 col-sm-6 col-lg-3 judge-idea-info-margin"></div>
          <div className="col-6 col-sm-6 col-lg-3 judge-idea-info-margin"></div>
        </>
      </div>
      <h5 className="judge-general-info-section-title">
        Informacion Profesional
      </h5>
      <div className="row justify-content-md-center row-confirm judge-idea-info-margin-container">
        <InfoComponent label="Empresa" value={user?.companyName} />
        <InfoComponent label="Cargo" value={user?.companyPosition} />
      </div>

      <div className="general-info-actions-button-container">
        <CancelActionButton text={'Cancelar'} action={cancelEdition} />
        <span style={{ marginLeft: '12px' }}>
          <ActionButton text={'Guardar Cambios'} action={save} />
        </span>
      </div>
    </div>
  );
};
