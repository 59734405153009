import React, { useEffect, useState } from 'react';
import { getContests } from '../../../../services/contestService';
import {
  createAward,
  editAward,
  getSpecialAwards,
  deleteAward,
} from '../../../../services/awardService';
import { ContestSelectInput } from '../../../common/ContestForms/ContestForms';
import { toast } from 'react-toastify';
import { ActionButton } from '../../../common/Buttons/Buttons';
import { DeleteAwardModal } from './DeleteAwardModal/DeleteAwardModal';
import { CreateAwardModal } from './CreateAwardModal/CreateAwardModal';
import { getCompanies } from '../../../../services/companyService.js';
import Loader from 'react-loader-spinner';
import './SpecialAwards.scss';

const PostulableSwitch = (props) => {
  const { award, postulable, changePostulation } = props;
  if (postulable) {
    return (
      <ion-icon
        onClick={() => changePostulation(award)}
        style={{ color: '#32CD32', transform: 'rotate(180deg)' }}
        name="toggle"
      ></ion-icon>
    );
  } else {
    return (
      <ion-icon
        style={{ color: 'gray' }}
        onClick={() => changePostulation(award)}
        name="toggle"
      ></ion-icon>
    );
  }
};

const SpecialAwardElement = (props) => {
  const { award, openDeleteModal, openEditModal, changePostulation, isActive } =
    props;
  const { title, description } = award;

  return (
    <div className="special-award-element">
      <div className="special-award-text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div className="special-awards-actions-container">
        {isActive && (
          <>
            <PostulableSwitch
              award={award}
              postulable={award?.postulable}
              changePostulation={changePostulation}
            />
            <ion-icon
              onClick={() => openEditModal(award)}
              style={{ color: '#30377b' }}
              name="create-outline"
            ></ion-icon>
            <ion-icon
              onClick={() => openDeleteModal(award)}
              style={{ color: 'red' }}
              name="trash-outline"
            ></ion-icon>
          </>
        )}
      </div>
    </div>
  );
};

export const SpecialAwards = () => {
  const [contestList, setContestList] = useState([]);
  const [selectedContest, setSelectedContest] = useState(19);
  const [activeContest, setActiveContest] = useState(19);
  const [awardList, setAwardList] = useState('');
  const [isLoading, setIsloading] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [openDeleteArawdModal, setOpenDeleteArawdModal] = useState(false);
  const [openCreateArawdModal, setOpenCreateArawdModal] = useState(false);
  const [selectedAward, setSelectedAward] = useState('');
  const [companiesList, setCompaniesList] = useState();
  const [modalType, setModalType] = useState('');

  const [award, setAward] = useState({
    negocio: false,
    social: false,
    digital: false,
  });

  const handleAwardChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const options = ['negocio', 'social', 'digital'];

    if (options.some((o) => o === name)) {
      setAward({ ...award, [name]: !award[name] });
    } else {
      setAward({ ...award, [name]: value });
    }
  };

  // Create Award Modal
  const handleOpenCreateArawdModal = () => {
    setOpenCreateArawdModal(true);
    setModalType('create');
  };

  const handleCloseCreateArawdModal = () => {
    setOpenCreateArawdModal(false);
    setAward({ negocio: false, social: false, digital: false });
    setModalType('');
  };

  // Delete Award
  const closeDeleteModal = () => {
    setOpenDeleteArawdModal(false);
    setSelectedAward('');
  };

  const openDeleteModal = (award) => {
    setOpenDeleteArawdModal(true);
    setSelectedAward(award);
  };

  const deleteAwardFunction = (award) => {
    if (canDelete) {
      deleteAward(award.id)
        .then((response) => {
          if (response.status == 200) {
            toast.success(`Premio Eliminado`);
            closeDeleteModal();
            getContestFunction();
            setCanDelete(true);
          } else {
            setCanDelete(true);
          }
        })
        .catch((error) => {
          setCanDelete(true);
          console.log('error:', error);
          toast.error(`Error al obtener eliminar premio`);
        });
    }
  };

  const onChangeSelectedContest = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    if (value) {
      setSelectedContest(value);
      getSpecialAwardsFunction(value);
    }
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response?.status == 200) {
        setContestList(response.data);
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        setActiveContest(selectedContest?.id);
        if (selectedContest) {
          setSelectedContest(selectedContest?.id);
          getSpecialAwardsFunction(selectedContest?.id);
        } else {
          setSelectedContest();
        }
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  const getSpecialAwardsFunction = (contestId) => {
    setIsloading(true);
    getSpecialAwards(contestId)
      .then((response) => {
        if (response.status === 200) {
          setAwardList(response.data);
          setIsloading(false);
        } else {
          toast.error('Error al conseguir premios');
          setIsloading(false);
        }
      })
      .catch(() => {
        toast.error('Error al conseguir premios');
        setIsloading(false);
      });
  };

  const createEditAwardFunction = () => {
    let business = '';
    let social = '';
    let digital = '';

    if (award.negocio) {
      business = 'BUSINESS';
    }
    if (award.social) {
      social = 'SOCIAL';
    }
    if (award.digital) {
      digital = 'DIGITAL';
    }

    let types = [business, social, digital];

    types = types.filter((element) => {
      return element !== null && element !== undefined && element !== '';
    });

    let [selectedBusiness] = companiesList.filter(
      (a) => a.id == award.business
    );

    const data = {
      title: award.title,
      description: award.description,
      postulable: award.postulable === '1' ? true : false,
      types: types,
      business: {
        id: selectedBusiness.id,
        businessName: selectedBusiness.name,
      },
    };
    if (modalType === 'create') {
      createAwardFunction(data);
    }
    if (modalType === 'edit') {
      data.id = award.id;
      editAwardFunction(data);
    }
  };

  const createAwardFunction = (data) => {
    createAward(data)
      .then((response) => {
        if (response.status === 201) {
          toast.success('Premio Creado');
          getContestFunction();
          handleCloseCreateArawdModal();
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Error creando premio');
      });
  };

  const editAwardFunction = (data) => {
    editAward(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Premio Editado');
          getContestFunction();
          handleCloseCreateArawdModal();
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Error creando premio');
      });
  };

  const getCompaniesFunction = () => {
    getCompanies()
      .then((response) => {
        if (response.status === 200) {
          let data = [];

          for (let i = 0; i < response.data.length; i++) {
            data.push({
              name: response.data[i].businessName,
              id: response.data[i].id,
            });
          }
          setCompaniesList(data);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const changePostulation = (selectedAward) => {
    selectedAward.postulable = !selectedAward.postulable;
    editAwardFunction(selectedAward);
  };

  const openEditModal = (selectedAward) => {
    setAward({
      id: selectedAward.id,
      business: selectedAward.business.id,
      postulable: selectedAward.postulable ? '1' : '0',
      negocio: selectedAward.types.includes('BUSINESS') ? true : false,
      social: selectedAward.types.includes('SOCIAL') ? true : false,
      digital: selectedAward.types.includes('DIGITAL') ? true : false,
      title: selectedAward.title,
      description: selectedAward.description,
    });
    setOpenCreateArawdModal(true);
    setModalType('edit');
  };

  useEffect(() => {
    getContestFunction();
    getCompaniesFunction();
  }, []);

  return (
    <div>
      <CreateAwardModal
        isOpen={openCreateArawdModal}
        selectedAward={selectedAward}
        closeDeleteModal={handleCloseCreateArawdModal}
        action={createEditAwardFunction}
        award={award}
        setAward={setAward}
        handleChange={handleAwardChange}
        companiesList={companiesList}
        modalType={modalType}
      />

      <DeleteAwardModal
        isOpen={openDeleteArawdModal}
        selectedAward={selectedAward}
        closeDeleteModal={closeDeleteModal}
        action={deleteAwardFunction}
      />
      <div className="contest-list-container">
        <div className="contest-list-column">
          <ContestSelectInput
            options={contestList}
            handleChange={onChangeSelectedContest}
            name="contestName"
            label="Concurso"
            value={selectedContest}
            errors={''}
            type={'text'}
            placeholder={' Seleccione Concurso'}
          />
        </div>
        {activeContest == selectedContest && (
          <ActionButton
            text="Agregar Premio"
            action={() => handleOpenCreateArawdModal()}
          />
        )}
      </div>

      {selectedContest ? (
        isLoading ? (
          <div style={{ marginTop: '120px' }}>
            <Loader
              type="Oval"
              color={'gray'}
              height={120}
              width={120}
              timeout={0} //3 secs
            />
            <h4 style={{ color: 'gray' }}>Cargando Premios</h4>
          </div>
        ) : awardList.length > 0 ? (
          <div className="special-awards-container">
            {awardList.map((a, i) => (
              <SpecialAwardElement
                key={a.title}
                index={i}
                award={a}
                openDeleteModal={openDeleteModal}
                changePostulation={changePostulation}
                openEditModal={openEditModal}
                isActive={activeContest == selectedContest}
              />
            ))}
          </div>
        ) : (
          <h3 style={{ marginTop: '60px' }}>
            El concurso seleccionado no posee premios especiales
          </h3>
        )
      ) : null}
    </div>
  );
};
