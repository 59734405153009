/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import threeDots from '../../../images/threeDots.svg';
import moment from 'moment';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './IdeaCard.scss';

var today = new Date();

// Contest stage limit date component
export const ContestStageLimitDate = (props) => {
  const { startDate, endDate } = props;

  // Change dates formants
  // let today = new Date();
  let newStartDate = new Date(moment(startDate).format('llll'));
  let newEndDate = new Date(moment(endDate).format('llll'));

  // Calculate gap between dates
  // Days from today until end date
  const diffFromToday = Math.abs(today - newEndDate);
  const diffFromTodayDays = Math.ceil(diffFromToday / (1000 * 60 * 60 * 24));

  // Days between start until end date

  const diffTime = Math.abs(newStartDate - newEndDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Validate if stage is in current time period
  let completed = false;
  today < newEndDate && today > newStartDate
    ? (completed = false)
    : (completed = true);

  let percentage = (Math.abs(diffDays - diffFromTodayDays) / diffDays) * 100;

  if (completed) {
    return <div className="limit-date-component"></div>;
  } else {
    return (
      <div className="limit-date-component">
        <h6>
          Fecha lí­mite: {moment(endDate).locale('es').format('DD/MM/YYYY')}
        </h6>
        <h5>{percentage.toFixed(0)} %</h5>
      </div>
    );
  }
};

export const SingleProgessBar = (props) => {
  const { startDate, endDate } = props;

  // Change dates formants
  // let today = new Date();
  let newStartDate = new Date(moment(startDate).format('llll'));
  let newEndDate = new Date(moment(endDate).format('llll'));
  let percentage = 0;

  if (today >= newStartDate) {
    // Calculate gap between dates
    // Days from today until end date
    const diffFromToday = Math.abs(today - newEndDate);
    const diffFromTodayDays = Math.ceil(diffFromToday / (1000 * 60 * 60 * 24));

    // Days between start until end date

    const diffTime = Math.abs(newStartDate - newEndDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    percentage = ((diffDays - diffFromTodayDays) / diffDays) * 100;
    today > newEndDate ? (percentage = 100) : '';
  }

  return (
    <div className="filled-stage-container">
      <div
        style={{ width: `${percentage}%` }}
        className="filled-stage-progfress-bar"
      ></div>
    </div>
  );
};

// Contest stage ball component
export const ContestStageProgressBar = (props) => {
  const { stages } = props;
  return (
    <div className={`contest-stage-progress-bar`}>
      {/* First Stage */}
      <SingleProgessBar
        startDate={stages[1].startDate}
        endDate={stages[1].endDate}
      />
      {/* Second Stage */}
      <SingleProgessBar
        startDate={stages[2].startDate}
        endDate={stages[2].endDate}
      />

      {/* Third Stage */}
      <SingleProgessBar
        startDate={stages[3].startDate}
        endDate={stages[3].endDate}
      />
    </div>
  );
};

// Contest stage ball component
export const ContestStageBall = (props) => {
  const { name, startDate } = props;

  // Change dates formants
  // let today = new Date();
  let newStartDate = new Date(moment(startDate).format('llll'));

  let completed = false;
  today >= newStartDate ? (completed = true) : (completed = false);

  let completedStage = `completed-stage-` + `${completed}`;
  return (
    <div className={`contest-stage-ball  ${completedStage}`}>
      <h6>{name}</h6>
    </div>
  );
};

export const validateIfContestIsOpen = (currentContest) => {
  let contestIsOpen = false;
  if (currentContest.stages.length > 0) {
    contestIsOpen = currentContest?.stages[4]?.isOpen;
  }
  return contestIsOpen;
};

// Contest stage component with progress bar
export const ContestStage = (props) => {
  const { currentContest, contestIsOpen } = props;
  const { stages } = currentContest;

  if (currentContest.stages) {
    if (contestIsOpen) {
      return (
        <div className="card">
          <h6 className="card-title">Concurso Finalizado </h6>
        </div>
      );
    } else {
      return (
        <div className="card">
          <h6 className="card-title">ETAPA DEL CONCURSO </h6>
          <div className="contest-stage-component">
            <div className="contest-stage-component-container">
              <ContestStageProgressBar stages={stages} />
              <ContestStageBall
                name={'Fase 1'}
                startDate={stages[1].startDate}
                endDate={stages[1].endDate}
              />
              <ContestStageLimitDate
                startDate={stages[1].startDate}
                endDate={stages[1].endDate}
              />
              <ContestStageBall
                name={'Fase 2'}
                startDate={stages[2].startDate}
                endDate={stages[2].endDate}
              />
              <ContestStageLimitDate
                startDate={stages[2].startDate}
                endDate={stages[2].endDate}
              />
              <ContestStageBall
                name={'Fase 3'}
                startDate={stages[3].startDate}
                endDate={stages[3].endDate}
              />
              <ContestStageLimitDate
                startDate={stages[3].startDate}
                endDate={stages[3].endDate}
              />
              <ContestStageBall
                name={'Final'}
                startDate={stages[4]?.startDate}
                endDate={stages[4]?.endDate}
              />
            </div>
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
};

export const IdeaCard = (props) => {
  const {
    proposalType,
    title,
    status,
    id,
    endDate,
    idea,
    selectIdea,
    codeDana,
    stage,
    currentYearDocuments,
    getCurrentContestStage,
    userSession,
  } = props;

  const showIdeaDetail = (idea) => {
    getCurrentContestStage(userSession);
    selectIdea(idea);
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 idea-card-border">
      <NavLink to={`/home/participant/ideas/${id}`}>
        <div onClick={() => showIdeaDetail(idea)} className="idea-card">
          <div className="idea-card-header">
            <h6>
              <b>Código </b>
              {codeDana}
            </h6>
            <img src={threeDots} alt="" />
          </div>
          <h6 className="idea-presentation-tag">
            <b> Tipo de propuesta: </b>
            {proposalType}
          </h6>
          <h6 className="idea-presentation-tag">
            <b> Estatus: </b>
            {status !== 'Registrado' ? (
              <span className="participant-color">{status}</span>
            ) : (
              <span className="registered-color">{status}</span>
            )}
          </h6>
          <div className="idea-title">
            <h6>{title}</h6>
          </div>
          <h6 className="card-limit-date-message">
            <b>Fecha Lí­mite para subir requisitos</b>
          </h6>
          <h6 className="card-limit-date">
            <b>{moment(endDate).locale('es').format('DD/MM/YYYY')}</b>
          </h6>

          {/*-------------------START STAGE 0-----------------------*/}

          {status === 'Participante' && stage === 0 ? (
            <h6 className="info-message-positive">
              Recaudos mínimos completos
            </h6>
          ) : null}

          {status === 'Registrado' && stage === 0 ? (
            <h6 className="info-message-negative">
              Requiere Resumen de la Idea
            </h6>
          ) : null}

          {/*-------------------END STAGE 0-----------------------*/}

          {/*-------------------START STAGE 1-----------------------*/}

          {(status === 'Participante' || status === 'Semifinalista') &&
          stage === 1 ? (
            <h6 className="info-message-positive">
              Recaudos mínimos completos
            </h6>
          ) : null}

          {status === 'Registrado' && stage === 1 ? (
            <h6 className="info-message-negative">
              No cargó Resumen de la Idea
            </h6>
          ) : null}

          {/*-------------------END STAGE 1-----------------------*/}

          {/*-------------------START STAGE 2-----------------------*/}

          {status === 'Semifinalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Plan_negocios' && i.ideaId === id
          ).length <= 0 &&
          stage === 2 ? (
            <h6 className="info-message-negative">Requiere Plan de Negocio</h6>
          ) : null}

          {status === 'Semifinalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Plan_negocios' && i.ideaId === id
          ).length > 0 &&
          stage === 2 ? (
            <h6 className="info-message-positive">
              Recaudos mínimos completos
            </h6>
          ) : null}

          {status === 'Registrado' && stage === 2 ? (
            <h6 className="info-message-negative">
              No cargó Resumen de la Idea
            </h6>
          ) : null}

          {status === 'Participante' && stage === 2 ? (
            <h6 className="info-message-negative">No clasificada</h6>
          ) : null}

          {/*-------------------END STAGE 2-----------------------*/}

          {/*-------------------START STAGE 3-----------------------*/}

          {status === 'Semifinalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Plan_negocios' && i.ideaId === id
          ).length <= 0 &&
          stage === 3 ? (
            <h6 className="info-message-negative">No cargó Plan de Negocio</h6>
          ) : null}

          {status === 'Semifinalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Plan_negocios' && i.ideaId === id
          ).length > 0 &&
          stage === 3 ? (
            <h6 className="info-message-positive">
              Recaudos mínimos completos
            </h6>
          ) : null}

          {status === 'Registrado' && stage === 3 ? (
            <h6 className="info-message-negative">
              No cargó Resumen de la Idea
            </h6>
          ) : null}

          {status === 'Participante' && stage === 3 ? (
            <h6 className="info-message-negative">No clasificada</h6>
          ) : null}

          {/*-------------------END STAGE 3-----------------------*/}

          {/*-------------------START STAGE 4-----------------------*/}

          {status === 'Finalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Final_presentation' && i.ideaId === id
          ).length <= 0 &&
          stage === 4 ? (
            <h6 className="info-message-negative">
              Requiere Presentación Final
            </h6>
          ) : null}

          {status === 'Finalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Final_presentation' && i.ideaId === id
          ).length > 0 &&
          stage === 4 ? (
            <h6 className="info-message-positive">
              Recaudos mínimos completos
            </h6>
          ) : null}

          {status === 'Registrado' && stage === 4 ? (
            <h6 className="info-message-negative">
              No cargó Resumen de la Idea
            </h6>
          ) : null}

          {(status === 'Participante' || status === 'Semifinalista') &&
          stage === 4 ? (
            <h6 className="info-message-negative">No clasificada</h6>
          ) : null}

          {/*-------------------END STAGE 4-----------------------*/}

          {/*-------------------START STAGE 5-----------------------*/}

          {status === 'Finalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Final_presentation' && i.ideaId === id
          ).length <= 0 &&
          stage === 5 ? (
            <h6 className="info-message-negative">
              No cargó Presentación Final
            </h6>
          ) : null}

          {status === 'Finalista' &&
          currentYearDocuments.filter(
            (i) => i.type === 'Final_presentation' && i.ideaId === id
          ).length > 0 &&
          stage === 5 ? (
            <h6 className="info-message-positive">
              Recaudos mínimos completos
            </h6>
          ) : null}

          {status === 'Registrado' && stage === 5 ? (
            <h6 className="info-message-negative">
              No cargó Resumen de la Idea
            </h6>
          ) : null}

          {(status === 'Participante' || status === 'Semifinalista') &&
          stage === 5 ? (
            <h6 className="info-message-negative">No clasificada</h6>
          ) : null}

          {status === 'Ganador' && stage === 5 ? (
            <h6 className="info-message-positive">Idea Ganadora</h6>
          ) : null}

          {/*-------------------END STAGE 5-----------------------*/}
        </div>
      </NavLink>
    </div>
  );
};

export const JudgDashboardCard = (props) => {
  const { ideaCount, color, name } = props;

  return (
    <div className="card judge-card-conatiner">
      <h2 style={{ color: color }}>{name}</h2>
      <h3 style={{ color: color }}>{ideaCount}</h3>
    </div>
  );
};

const IdeaStatusBall = (props) => {
  const { idea } = props;

  let color = '';

  if (idea.status === 'asignada') {
    color = '#66c780';
  }

  if (idea.status === 'evaluadas') {
    color = '#b9bcc8';
  }

  if (idea.status === 'por-evaluar') {
    color = '#f4441c';
  }

  return (
    <div className="idea-list-status-element">
      <div
        style={{ backgroundColor: color }}
        className={`idea-status-ball`}
      ></div>
      <h6>{idea.name}</h6>
    </div>
  );
};

export const IdeaListCard = () => {
  const ideas = [
    {
      name: 'Idea 1',
      status: 'asignada',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social 3',
      status: 'por-evaluar',
    },
    {
      name: 'Idea social 4',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social nueva',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea socialista',
      status: 'por-evaluar',
    },
    {
      name: 'Idea 1',
      status: 'asignada',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social 3',
      status: 'por-evaluar',
    },
    {
      name: 'Idea social 4',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social nueva',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea socialista',
      status: 'por-evaluar',
    },
    {
      name: 'Idea socialista',
      status: 'por-evaluar',
    },
    {
      name: 'Idea 1',
      status: 'asignada',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social 3',
      status: 'por-evaluar',
    },
    {
      name: 'Idea social 4',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social nueva',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea socialista',
      status: 'por-evaluar',
    },
    {
      name: 'Idea socialista',
      status: 'por-evaluar',
    },
    {
      name: 'Idea 1',
      status: 'asignada',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social 3',
      status: 'por-evaluar',
    },
    {
      name: 'Idea social 4',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea social nueva',
      status: 'por-evaluar',
    },
    {
      name: 'Idea Evaluada',
      status: 'evaluadas',
    },
    {
      name: 'Idea socialista',
      status: 'por-evaluar',
    },
  ];

  return (
    <div className="card idea-list-card-conatiner">
      <h6 className="card-title">IDEAS A EVALUAR</h6>
      <span>
        {ideas.map((i) => (
          <IdeaStatusBall key={i.name} idea={i} />
        ))}
      </span>
    </div>
  );
};

export const NotificationCard = () => {
  return (
    <div className="card idea-list-card-conatiner">
      <h6 className="card-title">ULTIMAS NOTIFICACIONES</h6>
    </div>
  );
};

export const LegendComponent = () => {
  return (
    <div className="new-dashboard-legend-container">
      <div className="new-dashboard-legend-content">
        <div style={{ backgroundColor: '#46c1e8' }}></div>
        <h6>Negocio</h6>
      </div>
      <div className="new-dashboard-legend-content">
        <div style={{ backgroundColor: '#e8ce46' }}></div>
        <h6>Social</h6>
      </div>
      <div className="new-dashboard-legend-content">
        <div style={{ backgroundColor: '#33a072' }}></div>
        <h6>Digital</h6>
      </div>
    </div>
  );
};

export const DataCard = (props) => {
  const { title, value } = props;
  return (
    <div className="data-container-component-single">
      <h3>{title}</h3>
      <h2 style={{ textAlign: 'center' }}>{value}</h2>
    </div>
  );
};

export const PercentageComponent = (props) => {
  const { value, percentage, title } = props;

  return (
    <div className="data-container-component data-container-percentage-component">
      <h3>{title}</h3>
      <h2>
        {percentage}% ({value})
      </h2>
    </div>
  );
};

export const SingleInfoComponent = (props) => {
  const { value, title } = props;

  return (
    <div className="data-single-container-component">
      <h3>{title}</h3>
      <h2>{value}</h2>
    </div>
  );
};

export const DataComponent = (props) => {
  const { business, social, digital, total, title, show } = props;

  const calculateWidth = (value) => {
    if (value === 0) {
      return '12%';
    } else {
      return `${(100 * value) / (business + social + digital)}%`;
    }
  };

  return (
    <div className="data-container-component">
      <h3>{title}</h3>
      <div className="please-alignthis">
        <h2>{total}</h2>
        <div className="dashboard-bar-container">
          <div
            style={{
              // width: `${(100 * business) / business + social + digital}%`,
              width: calculateWidth(business),
              minWidth: 'auto',
              backgroundColor: '#46c1e8',
            }}
            className="dashboard-bar-color dashboard-bar-color-first"
          >
            {business}
          </div>
          <div
            style={{
              width: calculateWidth(social),
              backgroundColor: '#e8ce46',
              minWidth: 'auto',
            }}
            className="dashboard-bar-color "
          >
            {social}
          </div>
          <div
            style={{
              width: calculateWidth(digital),
              backgroundColor: '#33a072',
              minWidth: 'auto',
            }}
            className="dashboard-bar-color dashboard-bar-color-last"
          >
            {digital}
          </div>
        </div>
      </div>

      {show && <LegendComponent />}
    </div>
  );
};

export const WinerIdeas = () => {
  return (
    <div className="winner-ideas-container">
      <h1>Ideas Ganadoras</h1>
      <div className="winner-ideas-list-container">
        <h2>idae uno</h2>
        <h2>idae un asd ao</h2>
        <h2>idae uno sd</h2>
      </div>
    </div>
  );
};

export const ProgressBarComponent = (props) => {
  const { value, title, legend, total } = props;

  const calculateValue = (value, total) => {
    if (value > 0) {
      return ((100 * value) / total).toFixed(2);
    } else {
      return 0;
    }
  };

  const calculatePercentage = (value, total, legend) => {
    if (value > 0) {
      return `${((100 * value) / total).toFixed(2)} % ${legend}`;
    } else {
      return `0 % ${legend}`;
    }
  };

  return (
    <div className="progress-component-container">
      <div className="progress-component-container-ammount-container">
        <h4>{title}</h4>
        <h2>
          {value}/{total}
        </h2>
      </div>
      <div className="circular-progress-bar-container">
        <div>
          <CircularProgressbar
            value={calculateValue(value, total)}
            // text={`${value} % Ideas Evaluadas`}
            styles={buildStyles({
              pathColor: '#00ba88',
              textSize: '10px',
            })}
          />
        </div>
        <h6>{calculatePercentage(value, total, legend)}</h6>
      </div>
    </div>
  );
};

export const DashboardCard1Top = (props) => {
  const { data } = props;
  return (
    <div className="card new-admin-dashboard-card-conatiner">
      <h2>{data[0]}</h2>
      <DataComponent
        title={'Ideas Registradas'}
        total={data[7]}
        business={data[8]}
        social={data[9]}
        digital={data[10]}
      />
      <DataComponent
        title={'Ideas Participando'}
        total={data[3]}
        business={data[4]}
        social={data[5]}
        digital={data[6]}
        show={true}
      />
    </div>
  );
};

export const DashboardCard1Bottom = (props) => {
  const { data } = props;
  return (
    <div className="card new-admin-dashboard-card-conatiner">
      <div className="new-admin-dashboard-data-component-container">
        <DataCard title={'Evaluadores'} value={data[2]} />
      </div>
      <ProgressBarComponent
        value={data[21]}
        total={data[22]}
        title={'Evaluaciones'}
        legend={'Evaluaciones Finalizadas'}
      />
    </div>
  );
};

export const DashboardCard2Top = (props) => {
  const { data } = props;
  return (
    <div className="card new-admin-dashboard-card-conatiner">
      <h2>{data[0]}</h2>
      <DataComponent
        title={'Semifinalistas'}
        business={data[12]}
        social={data[13]}
        digital={data[14]}
        total={data[11]}
      />
      <PercentageComponent
        title={'Ideas con Caso de Negocio'}
        value={data[15]}
        percentage={data[16]}
      />
      <SingleInfoComponent
        title={'Postuladas Mención Especial'}
        value={data[15]}
      />
    </div>
  );
};

export const DashboardCard2Bottom = (props) => {
  const { data } = props;
  return (
    <div className="card new-admin-dashboard-card-conatiner">
      <div className="new-admin-dashboard-data-component-container">
        <DataCard title={'Evaluadores'} value={data[2]} />
      </div>
      <ProgressBarComponent
        value={data[21]}
        total={data[22]}
        title={'Evaluaciones'}
        legend={'Evaluaciones Finalizadas'}
      />
    </div>
  );
};

export const DashboardCard3Top = (props) => {
  const { data } = props;
  return (
    <div className="card new-admin-dashboard-card-conatiner">
      <h2>{data[0]}</h2>
      <DataComponent
        title={'Finalistas'}
        business={data[18]}
        social={data[19]}
        digital={data[20]}
        total={data[17]}
      />
      <PercentageComponent
        title={'Cargar Video y Presentación'}
        value={data[24]}
        percentage={data[25]}
      />
    </div>
  );
};

export const DashboardCard3Bottom = (props) => {
  const { data } = props;
  return (
    <div className="card new-admin-dashboard-card-conatiner">
      {/* <DataComponent
        title={'Finalistas'}
        business={data[18]}
        social={data[19]}
        digital={data[20]}
        total={data[17]}
      />
      <PercentageComponent
        title={'Cargar Video y Presentación'}
        value={data[24]}
        percentage={data[25]}
      /> */}
    </div>
  );
};
