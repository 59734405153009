/* eslint-disable */
import React from 'react';
import { EditCompanyForm } from '../../../../common/Forms/Forms';
import {
  CancelActionButton,
  ActionButton,
} from '../../../../common/Buttons/Buttons';

import './GeneralInfo.scss';

export const GeneralInfo = (props) => {
  const {
    user,
    onChange,
    cancelEdition,
    save,
    statesList,
    companyList,
    sectorList,
  } = props;

  return (
    <div>
      <EditCompanyForm
        data={user}
        onChange={onChange}
        statesList={statesList}
        companyList={companyList}
        sectorList={sectorList}
      />
      <div className="general-info-actions-button-container">
        <CancelActionButton text={'Cancelar'} action={cancelEdition} />
        <span style={{ marginLeft: '12px' }}>
          <ActionButton text={'Guardar Cambios'} action={save} />
        </span>
      </div>
    </div>
  );
};
