/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import logo from '../../../images//logo-transparent.png';
import logoutImg from '../../../images/log-out.png';
import { getContestLogo } from '../../../services/contestService.js';
import { toast } from 'react-toastify';
import './Header.scss';

export const homeOption = (
  userSession,
  logoutService,
  history,
  logout,
  cancelIdeaCreation
) => {
  if (userSession && logout !== false) {
    return (
      <div onClick={() => logoutService(history)}>
        <img src={logoutImg} alt="" />
      </div>
    );
  }
  if (logout === false) {
    return (
      <div onClick={() => cancelIdeaCreation(history.history, userSession)}>
        <FontAwesomeIcon className="home-icon" icon="home" />
      </div>
    );
  } else {
    return (
      <NavLink to={`/home`}>
        <FontAwesomeIcon className="home-icon" icon="home" />
      </NavLink>
    );
  }
};

export const welcomeComponent = (userSession, logoutService, history) => {
  const historial = useHistory();

  return (
    <div id="header-welcome-component">
      <div>
        <h5>
          Bienvenido, {userSession.name} {userSession.lastName}
        </h5>
        <h6>{userSession.userType === 'participant' ? 'PARTICIPANTE' : ''}</h6>
        <h6>{userSession.userType === 'admin' ? 'ADMINISTRADOR' : ''}</h6>
        <h6>{userSession.userType === 'judge' ? 'JURADO' : ''}</h6>
        <h6>
          {userSession.userType === 'judge_special' ? 'JURADO ESPECIAL' : ''}
        </h6>
        <h6>
          {userSession.userType === 'judge_leader' ? 'LÍDER DE GRUPO' : ''}
        </h6>
      </div>
      <Dropdown>
        <Dropdown.Toggle id="header-dropdown"></Dropdown.Toggle>

        <Dropdown.Menu>
          {['judge_leader', 'judge_special', 'judge'].includes(
            userSession.userType
          ) && (
            <Dropdown.Item
              onClick={() =>
                historial.push('/home/judge/settings/general-info')
              }
            >
              Preferencias
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => logoutService(history)}>
            Salir
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const welcomeOption = (userSession) => {
  return (
    <div id="header-welcome-option">
      <h5>
        Bienvenido, {userSession.name} {userSession.lastName}
      </h5>
      <h6>{userSession.userType === 'participant' ? 'PARTICIPANTE' : ''}</h6>
    </div>
  );
};

const Header = (props) => {
  const {
    userSession,
    logoutService,
    history,
    logout,
    cancelIdeaCreation,
    handleSideBarShow,
  } = props;

  const [contestLogo, setContestLogo] = useState('');

  const getContestLogoFunction = () => {
    getContestLogo('Logo')
      .then((response) => {
        if (response.status === 200) {
          setContestLogo(response.data.fileUrl);
        }
      })
      .catch(() => {
        toast.error('Error al obtener logo del concurso');
      });
  };

  useEffect(() => {
    getContestLogoFunction();
  }, []);

  if (userSession) {
    return (
      <div className="header">
        <div id="hamburger" onClick={handleSideBarShow}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {contestLogo ? (
          <img className="header-logo" src={contestLogo} alt="logo" />
        ) : (
          <div className="image-logo-empty-container"></div>
        )}{' '}
        {welcomeComponent(userSession, logoutService, history)}
      </div>
    );
  } else {
    return (
      <div className="header">
        {contestLogo && (
          <img className="header-logo" src={contestLogo} alt="logo" />
        )}

        {homeOption(
          userSession,
          logoutService,
          history,
          logout,
          cancelIdeaCreation
        )}
      </div>
    );
  }
};

export default Header;
