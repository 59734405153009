import http from './httpServices';

export async function getEconomicSectorsList(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/economic-sector-list`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getEconomicSectorList() {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);
  let url = process.env.REACT_APP_API_URL;
  url = url + `/economic-sector-list`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getIdeaStates() {
  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  let url = process.env.REACT_APP_API_URL;
  url = url + `/states`;

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
