/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  getContests,
  getContestLogo,
} from '../../../../services/contestService';
import { updateMarketingImages } from '../../../../services/documentService.js';
import {
  getContestDocument,
  updateDocument,
} from '../../../../services/documentService';
import { TermsModal } from './TermsModal/TermsModal';
import { toast } from 'react-toastify';
import { documentType } from '../../../../helpers/types';
import Loader from 'react-loader-spinner';
import { ImageDropzone } from '../../../common/DropZone/DropZone';
import {
  AcceptedFiles,
  DownloadedRemovableFilesList,
} from '../../../common/DocElements/DocElements';
import open from '../../../../images/OldStageDownClosed.svg';
import './Marketing.scss';

export const Marketing = () => {
  const [confidenciality, setConfidenciality] = useState('');
  const [conditions, setConditions] = useState('');
  const [contest, setCurrentContest] = useState([]);
  const [isOpenModal, setIsopenModal] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [document, setDocument] = useState('');
  const [contestLogo, setContestLogo] = useState('');
  const [contestBackground, setContestBackground] = useState('');
  const [contestPasswordBackground, setContestPasswordBackground] = useState('');
  const [contestCreateUserBackground, setContestCreateUserBackground,] = useState('');
  const [contestWelcomeEmail,setContestWelcomeEmail] = useState('');

  const [logoOpen, setLogoOpen] = useState(false);
  const [backgroundOpen, setBackgroundOpen] = useState(false);
  const [backgroundPasswordOpen,setBackgroundPasswordOpen]=useState(false);
  const [backgroundCreateUserdOpen,setBackgroundCreateUserdOpen]=useState(false);
  const [backgroundWelcomeEmailOpen,setBackgroundWelcomeEmailOpen]=useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [agreementOpen, setAgreementOpen] = useState(false);

  const getContestLogoFunction = (imageType) => {
    getContestLogo(imageType)
      .then((response) => {
        if (response?.status === 200 && imageType == 'Logo') {
          setContestLogo(response.data.fileUrl);
        }
        if (response?.status === 200 && imageType == 'Background') {
          setContestBackground(response.data.fileUrl);
        }
        if (response?.status === 200 && imageType == 'BackgroundPassword') {
          setContestPasswordBackground(response.data.fileUrl);
        }
        if (response?.status === 200 && imageType == 'BackgroundCreateAccount') {
          setContestCreateUserBackground(response.data.fileUrl);
        }
        if (response?.status === 200 && imageType == 'EmailWelcome') {
          setContestWelcomeEmail(response.data.fileUrl);
        }
      })
      .catch(() => {
        toast.error('Error al obtener logo del concurso');
      });
  };

  const updateDocumentFunction = () => {
    updateDocument(document)
      .then((response) => {
        if (response?.status === 200) {
          toast.success('Documento Actualizado');
          closeModal();
          getTermsAndConditions();
        }
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Documento Actualizado');
      });
  };

  const getContestDocumentFunction = async (contestId) => {
    await getContestDocument(contestId)
      .then((response) => {
        if (response?.status === 200) {
          let [confidenciality] = response.data.filter(
            (c) => c.documentType === documentType.confidenciality
          );
          let [conditions] = response.data.filter(
            (c) => c.documentType === documentType.termsAndConditions
          );
          if (conditions) {
            setConditions(conditions);
          }
          if (confidenciality) {
            setConfidenciality(confidenciality);
          }
          setIsloading(false);
        } else {
          setIsloading(false);
        }
      })
      .catch(() => {
        setIsloading(false);
      });
  };

  const openModal = (document) => {
    setDocument(document);
    setIsopenModal(true);
  };

  const openModalForCreation = (documentType) => {
    setDocument({
      ...document,
      documentType: documentType,
      contest: { id: contest.id },
      description: '',
    });
    setIsopenModal(true);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setDocument({ ...document, description: value });
  };

  const clearDocument = () => {
    setDocument({ ...document, description: '' });
  };

  const closeModal = () => {
    setIsopenModal(false);
  };

  const getTermsAndConditions = async () => {
    await getContests().then((response) => {
      if (response?.status == 200) {
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        setCurrentContest(selectedContest);
        if (selectedContest) {
          getContestDocumentFunction(selectedContest?.id);
        } else {
          toast.error('No hay concurso activo');
        }
      } else {
        toast.error(`${response?.message}` || 'Error al obtener concursos');
      }
    });
  };

  useEffect(() => {
    getTermsAndConditions();
    getContestLogoFunction('Logo');
    getContestLogoFunction('Background');
    getContestLogoFunction('BackgroundPassword');
    getContestLogoFunction('BackgroundCreateAccount');
    getContestLogoFunction('EmailWelcome');

  }, []);

  return (
    <div>
      <MarketingDropdown
        label={'Logotipo del Concurso'}
        isOpen={logoOpen}
        handleOpen={setLogoOpen}
      />
      {logoOpen && (
        <ImageManagementComponent
          imageType="Logo"
          image={contestLogo}
          width={'260'}
          height={'100'}
          callToAction={
            'Aquí podras actualizar el logotipo del concurso. Los cambios seran actualizados en todas las secciones de la aplicación'
          }
        />
      )}

      <MarketingDropdown
        label={'Imagen de presentación del Concurso'}
        isOpen={backgroundOpen}
        handleOpen={setBackgroundOpen}
      />
      {backgroundOpen && (
        <ImageManagementComponent
          imageType="Background"
          image={contestBackground}
          width={'800'}
          height={'1000'}
          callToAction={
            'Aquí podras actualizar la imagen de presentación del concurso. Los cambios seran actualizados en todas las secciones de la aplicación'
          }
        />
      )}

      <MarketingDropdown
        label={'Imagen de la página de crear Usuario'}
        isOpen={backgroundCreateUserdOpen}
        handleOpen={setBackgroundCreateUserdOpen}
      />
      {backgroundCreateUserdOpen && (
        <ImageManagementComponent
          imageType="BackgroundCreateAccount"
          image={contestCreateUserBackground}
          width={'800'}
          height={'1000'}
          callToAction={
            'Aquí podras actualizar la imagen de presentación del concurso. Los cambios seran actualizados en todas las secciones de la aplicación'
          }
        />
      )}

      <MarketingDropdown
        label={'Imagen de la página de cambio de password'}
        isOpen={backgroundPasswordOpen}
        handleOpen={setBackgroundPasswordOpen}
      />
      {backgroundPasswordOpen && (
        <ImageManagementComponent
          imageType="BackgroundPassword"
          image={contestPasswordBackground}
          width={'800'}
          height={'1000'}
          callToAction={
            'Aquí podras actualizar la imagen de presentación del concurso. Los cambios seran actualizados en todas las secciones de la aplicación'
          }
        />
      )}


      <MarketingDropdown
        label={'Imagen del correo de comunicaciones'}
        isOpen={backgroundWelcomeEmailOpen}
        handleOpen={setBackgroundWelcomeEmailOpen}
      />
      {backgroundWelcomeEmailOpen && (
        <ImageManagementComponent
          imageType="EmailWelcome"
          image={contestWelcomeEmail}
          width={'2000'}
          height={'1400'}
          callToAction={
            'Aquí podras actualizar la imagen de presentación del concurso. Los cambios seran actualizados en todas las secciones de la aplicación'
          }
        />
      )}

    
      <MarketingDropdown
        label={'Términos y Condiciones'}
        isOpen={termsOpen}
        handleOpen={setTermsOpen}
      />

      <TermsModal
        onChange={onChange}
        isOpen={isOpenModal}
        document={document}
        closeModal={() => closeModal()}
        clear={clearDocument}
        save={updateDocumentFunction}
      />
      {isLoading ? (
        <div style={{ marginTop: '120px' }}>
          <Loader
            type="Oval"
            color={'gray'}
            height={120}
            width={120}
            timeout={0} //3 secs
          />
          <h4 style={{ color: 'gray' }}>Cargando Documentos</h4>
        </div>
      ) : (
        <>
          {termsOpen && (
            <div className="terms-and-conditions-container">
              {conditions ? (
                <h6 onClick={() => openModal(conditions)}>Ver y Editar</h6>
              ) : (
                <h6
                  onClick={() =>
                    openModalForCreation(documentType.termsAndConditions)
                  }
                >
                  Crear
                </h6>
              )}
            </div>
          )}

          <MarketingDropdown
            label={'Acuerdo de Confidencialidad'}
            isOpen={agreementOpen}
            handleOpen={setAgreementOpen}
          />

          {agreementOpen && (
            <div className="terms-and-conditions-container">
              {/* <h4>Acuerdo de Confidencialidad</h4> */}
              {confidenciality ? (
                <h6 onClick={() => openModal(confidenciality)}>Ver y Editar</h6>
              ) : (
                <h6
                  onClick={() =>
                    openModalForCreation(documentType.confidenciality)
                  }
                >
                  Crear
                </h6>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const ImageManagementComponent = (props) => {
  const { callToAction, height, width, image, imageType } = props;

  const [loading, setLoading] = useState(false);

  const uploadDocumentFunction = async (files, docType) => {
    setLoading(true);
    let datos = new FormData();
    for (let i = 0; i < files.length; i++) {
      datos.append('files', files[i], files[i].path);
    }
    datos.append('type', docType);
    let response = await updateMarketingImages(datos, docType);
    if (response?.status === 200) {
      toast.success(
        `Imagen actualizada`
      );
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else {
      setLoading(false);
    }
    // // No response from API
  };

  const validateIfExceedsWeight = (files, maxWeight) => {
    let totalWeight = 0;
    // Sum the weight of all the files
    for (let i = 0; i < files.length; i++) {
      totalWeight = totalWeight + files[i].size;
    }
    // Validate if the files weight more than 25Mb
    if (totalWeight > maxWeight * 1048576) {
      toast.error(
        `La carga de documentos no puede superar los ${maxWeight}MB`,
        { position: toast.POSITION.TOP_RIGHT, className: 'Ts-op' }
      );
      return false;
    } else {
      return true;
    }
  };

  const handleDragAndDropFiles = (accepted, rejected, maxFilesPermited) => {
    let files = '';
    let canPost = true;
    if (accepted.length <= maxFilesPermited) {
      files = accepted;
      // Error message in case PDF
      if (rejected.length > 0) {
        toast.error(`Solo se permiten archivos en formato .jpg, .png`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'Ts-op',
        });
        return null;
      }
    }

    canPost = validateIfExceedsWeight(files, 1);

    if (canPost === true) {
      uploadDocumentFunction(files, imageType);
    } else {
      files = [];
      return null;
    }
  };

  return (
    <div className="image-management-component">
      {/* <h4>{title}</h4> */}
      <h6>{callToAction}</h6>
      <div className="vertical-timeline-element-content bounce-in">
        {image || loading ? (
          <img
            className={`display-loaded-image-${imageType}`}
            src={image}
            alt="image"
          />
        ) : (
          <DownloadedRemovableFilesList />
        )}

        <React.Fragment>
          <ImageDropzone
            label={`Arrastra tus documentos aqui para agregar o sustituir el ${
              imageType == 'Background' ? 'fondo' : 'logo'
            } `}
            acceptedFiles={['.jpg', '.jpeg', '.jpg', '.png']}
            handleDragAndDropFiles={handleDragAndDropFiles}
            files={''}
            maxFilesPermited={1}
            maxWeight={'1'}
          />
          <div className="accepted-files-conainer"></div>
        </React.Fragment>
      </div>

      <h6 style={{ marginTop: '24px' }}>
        La imagen debe ser de <b>{width}px</b> X <b>{height}px</b> Tipos de
        archivos permitidos: JPG, PNG.
      </h6>
    </div>
  );
};

export const MarketingDropdown = (props) => {
  const { label, isOpen, handleOpen } = props;
  return (
    <div className={`stage-dropdown`}>
      <img
        onClick={() => handleOpen(!isOpen)}
        className={isOpen ? `stage-opened-dropdown` : `stage-closed-dropdown`}
        src={open}
        alt="arrow"
      />
      <h3>{label}</h3>
    </div>
  );
};
