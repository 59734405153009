/* eslint-disable */
import React from 'react';
import './JudgeIdeaDetail.scss';
import moment from 'moment';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { Redirect } from 'react-router-dom';
import { IdeaDetailComponent, Stage1 } from '../Stages/Stage1';
import { Stage2 } from '../Stages/Stage2';
import { Stage3 } from '../Stages/Stage3';
import { StageDropdown } from '../../common/StageDropdown/StageDropdown';

export const JudgeIdeaDetail = (props) => {
  const {
    ideaForEdition,
    userSession,
    addMemberService,
    files,
    handleDragAndDropFiles,
    handleBusinessDragAndDropFiles,
    handleONGDragAndDropFiles,
    handleDragAndDropSummaryFiles,
    handleDragAndDropMembersFiles,
    handleDragAndDropFinalPresentationFiles,
    removeDocumentsfromList,
    openBase64File,
    deleteMemberService,
    validateEmailFormat,
    validatePhoneNumberFormat,
    validateNumericFormat,
    validateIfLegal,
    blockedLogin,
    stagesDropdowns,
    handleStageDropDown,
    addFinalPresentationUrlService,
    selectAwardService,
    currentContestStage,
    handleAwardOnChange,
  } = props;

  let filterStageFunction = (currentContestStage) => {
    // Function that validates if in current state
    const validateIfCurrentStage = (
      startDate,
      endDate,
      currentContestStage
    ) => {
      let today = new Date();
      // Change dates formants
      let newStartDate = new Date(moment(startDate).format('llll'));
      let newEndDate = new Date(moment(endDate).format('llll'));

      // Validate if stage is in current time period and the stage is open manualy
      if (currentContestStage.isOpen) {
        return true;
      }
      return today < newEndDate && today >= newStartDate;
    };
    for (let i = 0; i < currentContestStage.length; i++) {
      let isCurrent = validateIfCurrentStage(
        currentContestStage[i].startDate,
        currentContestStage[i].endDate,
        currentContestStage[i]
      );
      if (isCurrent) {
        return currentContestStage[i];
      }
    }
  };

  let filterStage = filterStageFunction(currentContestStage);

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const initialMemberState = {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    nationality: '',
    idNumber: '',
    birthDate: new Date(),
    errorName: '',
    errorLastName: '',
    errorPhone: '',
    errorEmail: '',
    errornationality: '',
    erroridNumber: '',
    errorBirthDate: '',
  };

  const [member, setMember] = React.useState(initialMemberState);

  const handleInputChange = (event) => {
    if (event.target.name === 'idNumber') {
      if (parseInt(event.target.value) === 0) {
        event.target.value = '';
      }
      event.target.value = event.target.value.substring(0, 8);
    }
    if (event.target.name === 'phone') {
      if (parseInt(event.target.value) === 0) {
        event.target.value = '';
      }
      event.target.value = event.target.value.substring(0, 13);
    }
    setMember({ ...member, [event.target.name]: event.target.value });
  };

  // Function that validates if all members have uploaded their CVs
  const validateIfMembersHaveFiles = (ideaForEdition) => {
    if (ideaForEdition.members.length > 0) {
      // Validate if any members hasnt loaded a documment
      for (let i = 0; i < ideaForEdition.members.length; i++) {
        if (ideaForEdition.members[i].documents.length === 0) {
          return false;
        }
      }
      // If they all have return true
      return true;
    }
  };

  const validateFields = () => {
    var canpost = true;
    member.errorName = '';
    member.errorLastName = '';
    member.errorBirthDate = '';
    member.errorEmail = '';
    member.erroridNumber = '';
    member.errornationality = '';
    member.errorPhone = '';
    setMember({ ...member });

    if (!validateNumericFormat(member.idNumber)) {
      canpost = false;
      member.erroridNumber = 'Formato inválido';
      setMember({ ...member });
    }

    if (!validateEmailFormat(member.email)) {
      canpost = false;
      member.errorEmail = 'Formato inválido';
      setMember({ ...member });
    }

    if (!validatePhoneNumberFormat(member.phone)) {
      canpost = false;
      member.errorPhone = 'Formato inválido';
      setMember({ ...member });
    }

    if (!validateIfLegal(member.birthDate, 12)) {
      canpost = false;
      member.errorBirthDate = 'El participante debe ser mayor de 12 años';
      setMember({ ...member });
    }

    if (member.email === '') {
      canpost = false;
      member.errorEmail = 'Correo electrónico es requerido';
      setMember({ ...member });
    }

    if (member.name === '') {
      canpost = false;
      member.errorName = 'Nombre es requerido';
      setMember({ ...member });
    }

    if (member.lastName === '') {
      canpost = false;
      member.errorLastName = 'Apellido es requerido';
      setMember({ ...member });
    }

    if (member.phone === '') {
      canpost = false;
      member.errorPhone = 'Teléfono es requerido';
      setMember({ ...member });
    }

    if (member.idNumber === '') {
      canpost = false;
      member.erroridNumber = 'Cédula de identidad es requerida';
      setMember({ ...member });
    }

    if (member.idNumber.length < 6) {
      canpost = false;
      member.erroridNumber = 'Ingrese un número de cédula válido';
      setMember({ ...member });
    }

    if (member.nationality === '') {
      canpost = false;
      member.errornationality = 'Nacionalidad es requerida';
      setMember({ ...member });
    }

    if (member.birthDate === '') {
      canpost = false;
      member.errorBirthDate = 'Fecha de nacimiento es requerida';
      setMember({ ...member });
    }

    return canpost;
  };

  const handleDateChange = (event) => {
    let getTimezone = moment(event.target.value).format('llll');
    let changeFormat = new Date(getTimezone);
    member.birthDate = changeFormat;
    setMember({ ...member });
    return member;
  };

  const saveMember = async () => {
    let canpost = validateFields();

    if (canpost) {
      let data = {
        name: member.name,
        lastName: member.lastName,
        phone: member.phone,
        email: member.email,
        idNumber: parseInt(member.idNumber),
        nationality: parseInt(member.nationality),
        birthDate: member.birthDate,
        idMember: '',
        idMemberIdea: '',
      };
      let response = await addMemberService(data, userSession.sessionToken);
      if (response === true) {
        //Member added
        setMember(initialMemberState);
      } else {
        // Member not added
        setMember(initialMemberState);
      }
      setShow(false);
    }
  };

  if (!ideaForEdition.idea.id) {
    // Redirects to the home page if no idea selected
    let userSession = sessionStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    return <Redirect props={props} to={`/home/${userSession.userType}`} />;
  } else {
    let isSocial =
      ideaForEdition?.idea?.proposalType?.id === 3 &&
      ideaForEdition?.idea?.socialType?.id === 3;
    return (
      <React.Fragment>
        <LoadSpinner
          blocked={blockedLogin}
          text="Espere mientras procesamos su solicitud"
          color="#000000"
        />
        {/* <IdeaDetailComponent
          ideaForEdition={ideaForEdition}
          userSession={userSession}
          filterStage={filterStage}
        /> */}
        <StageDropdown
          showStage={stagesDropdowns.stage1.selected}
          label={'Lista de Requisitos (Fase 1)'}
          name={'stage1'}
          handleStageDropDown={handleStageDropDown}
          currentState={stagesDropdowns.stage1.current}
          stagedPassed={stagesDropdowns.stage1.stagedPassed}
          currentStatus={true}
        />
        <Stage1
          showStage={stagesDropdowns.stage1.selected}
          ideaForEdition={ideaForEdition}
          isSocial={isSocial}
          files={files}
          show={show}
          handleDragAndDropFiles={handleDragAndDropFiles}
          handleONGDragAndDropFiles={handleONGDragAndDropFiles}
          handleDragAndDropSummaryFiles={handleDragAndDropSummaryFiles}
          handleDragAndDropMembersFiles={handleDragAndDropMembersFiles}
          removeDocumentsfromList={removeDocumentsfromList}
          openBase64File={openBase64File}
          deleteMemberService={deleteMemberService}
          handleClose={handleClose}
          handleShow={handleShow}
          handleInputChange={handleInputChange}
          validateIfMembersHaveFiles={validateIfMembersHaveFiles}
          handleDateChange={handleDateChange}
          saveMember={saveMember}
          userSession={userSession}
          member={member}
          currentState={stagesDropdowns.stage1.current}
        />
        <StageDropdown
          showStage={stagesDropdowns.stage2.selected}
          label={'Lista de Requisitos (Fase 2)'}
          name={'stage2'}
          handleStageDropDown={handleStageDropDown}
          currentState={stagesDropdowns.stage2.current}
          stagedPassed={stagesDropdowns.stage2.stagedPassed}
          currentStatus={
            ideaForEdition.idea.stage.status === 'Semifinalista' ||
            ideaForEdition.idea.stage.status === 'Finalista' ||
            ideaForEdition.idea.stage.status === 'Ganador'
              ? true
              : false
          }
        />
        <Stage2
          showStage={stagesDropdowns.stage2.selected}
          ideaForEdition={ideaForEdition}
          handleBusinessDragAndDropFiles={handleBusinessDragAndDropFiles}
          removeDocumentsfromList={removeDocumentsfromList}
          openBase64File={openBase64File}
          currentState={stagesDropdowns.stage2.current}
          userSession={userSession}
          selectAwardService={selectAwardService}
          currentStatus={
            ideaForEdition.idea.stage.status === 'Semifinalista' ||
            ideaForEdition.idea.stage.status === 'Finalista' ||
            ideaForEdition.idea.stage.status === 'Ganador'
              ? true
              : false
          }
          handleAwardOnChange={handleAwardOnChange}
        />
        <StageDropdown
          showStage={stagesDropdowns.stage3.selected}
          label={'Lista de Requisitos (Fase 3)'}
          name={'stage3'}
          handleStageDropDown={handleStageDropDown}
          currentState={stagesDropdowns.stage3.current}
          stagedPassed={stagesDropdowns.stage3.stagedPassed}
          currentStatus={
            ideaForEdition.idea.stage.status === 'Finalista' ||
            ideaForEdition.idea.stage.status === 'Ganador'
              ? true
              : false
          }
        />
        <Stage3
          showStage={stagesDropdowns.stage3.selected}
          handleDragAndDropFinalPresentationFiles={
            handleDragAndDropFinalPresentationFiles
          }
          addFinalPresentationUrlService={addFinalPresentationUrlService}
          userSession={userSession}
          files={files}
          ideaForEdition={ideaForEdition}
          openBase64File={openBase64File}
          currentState={stagesDropdowns.stage3.current}
          userSession={userSession}
          currentStatus={
            ideaForEdition.idea.stage.status === 'Finalista' ||
            ideaForEdition.idea.stage.status === 'Ganador'
              ? true
              : false
          }
        />
      </React.Fragment>
    );
  }
};
