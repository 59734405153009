import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  ContestInput,
  ContestStartupInput,
  CheckMarkInput,
  RadioInput,
  ContestSelectInput,
} from '../../../../common/ContestForms/ContestForms';

import './CreateAwardModal.scss';

const options = [
  { label: 'Negocio', selected: false },
  { label: 'Social', selected: false },
  { label: 'Digital', selected: false },
];

const optionProfile = [
  { label: 'Si', selected: 1 },
  { label: 'No', selected: 0 },
];

export const CreateAwardModal = (props) => {
  const {
    isOpen,
    closeDeleteModal,
    action,
    award,
    handleChange,
    companiesList,
    modalType,
  } = props;

  let callToAction = '';
  if (modalType == 'create') {
    callToAction = 'Agregar';
  } else {
    callToAction = 'Editar';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeDeleteModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{callToAction} Premio Especial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="contest-input-flex col-12 col-md-12">
            <ContestSelectInput
              options={companiesList}
              handleChange={handleChange}
              name="business"
              label="Empresa *"
              type={'text'}
              placeholder={''}
              errors={''}
              value={award?.business}
            />
          </div>{' '}
          <div className="contest-input-flex col-12 col-md-12">
            <CheckMarkInput
              options={options}
              handleChange={handleChange}
              name="types"
              label="Tipo de Idea *"
              value={award}
              errors={''}
              type={'text'}
              placeholder={'Seleccione una opción...'}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <RadioInput
              options={optionProfile}
              handleChange={handleChange}
              name="postulable"
              label="Permitir Postularse *"
              value={award?.postulable}
              errors={''}
              type={'text'}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestInput
              handleChange={handleChange}
              name="title"
              label="Nombre del Premio *"
              type={'text'}
              placeholder={'Nombre del premio'}
              errors={''}
              value={award?.title}
            />
          </div>
          <div className="contest-input-flex col-12 col-md-12">
            <ContestStartupInput
              handleChange={handleChange}
              name="description"
              label="Descripción"
              value={award?.description}
              errors={''}
              type={'text'}
              placeholder={'Escriba una descripción de su emprendimiento...'}
            />{' '}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDeleteModal()}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => action()}
          disabled={
            !award?.business ||
            !award?.postulable ||
            !award?.title ||
            (!award?.digital && !award?.negocio && !award?.social)
          }
        >
          {callToAction} Premio
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
