import React from 'react';
import './MembersDropzone.scss';
import {
  AcceptedFiles,
  DownloadedRemovableFilesList,
} from '../../common/DocElements/DocElements';
import { StyledDropzone } from '../../common/DropZone/DropZone';
import Loader from 'react-loader-spinner';

export const BusinessPlanDropzone = (props) => {
  const {
    ideaForEdition,
    handleDragAndDropFiles,
    ideaSummaryFiles,
    removeDocumentsfromList,
    openBase64File,
    currentState,
    ideaStatus,
    isParticipant,
  } = props;

  let leaderFiles = ideaForEdition.files.filter(
    (i) => i.type === 'Plan_negocios'
  );

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">
        <h4 className="timeline-title">PLAN DE NEGOCIO</h4>
        {isParticipant ? (
          <React.Fragment>
            <p>
              Aquí podrá subir su plan de negocio. Puede subir su plan de
              negocio tantas veces como sea necesario mientras la Fase 2 esté
              activa. Se tomará en cuenta para la evaluación el último archivo
              cargado.
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="vertical-timeline-element-content bounce-in">
        <DownloadedRemovableFilesList
          documents={leaderFiles}
          openBase64File={openBase64File}
        />
        {currentState && ideaStatus === 'Semifinalista' ? (
          ideaForEdition.leaderDocloading === true ? (
            <React.Fragment>
              <div className="loading-files-spiner-container">
                <Loader
                  type="Oval"
                  color={'gray'}
                  height={80}
                  width={80}
                  timeout={0} //3 secs
                />
                <h6>Subiendo archivo</h6>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isParticipant ? (
                <StyledDropzone
                  handleDragAndDropFiles={handleDragAndDropFiles}
                  files={ideaSummaryFiles}
                  maxFilesPermited={1}
                />
              ) : null}
              <div className="accepted-files-conainer">
                {ideaSummaryFiles.map((f, i) => (
                  <AcceptedFiles
                    key={i}
                    index={i}
                    files={f}
                    removeDocumentsfromList={removeDocumentsfromList}
                  />
                ))}
              </div>
            </React.Fragment>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
  // }
};

export const IdeaSummaryDropzone = (props) => {
  const {
    ideaForEdition,
    handleDragAndDropFiles,
    ideaSummaryFiles,
    removeDocumentsfromList,
    openBase64File,
    currentState,
    isParticipant,
  } = props;

  let leaderFiles = ideaForEdition.files.filter((i) => i.type === 'Resumen');

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">
        <h4 className="timeline-title">RESUMEN DE LA IDEA</h4>
        {isParticipant ? (
          <React.Fragment>
            <p>
              Aquí podrá subir el resumen de su idea de negocio. Puede subir el
              resumen de la idea tantas veces como sea necesario mientras la
              Fase 1 esté activa. Se tomará en cuenta para la evaluación el
              último archivo cargado.
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="vertical-timeline-element-content bounce-in">
        <DownloadedRemovableFilesList
          documents={leaderFiles}
          openBase64File={openBase64File}
        />
        {currentState ? (
          ideaForEdition.ideaSummaryFilesloading === true ? (
            <React.Fragment>
              <div className="loading-files-spiner-container">
                <Loader
                  type="Oval"
                  color={'gray'}
                  height={80}
                  width={80}
                  timeout={0} //3 secs
                />
                <h6>Subiendo archivo</h6>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isParticipant ? (
                <StyledDropzone
                  handleDragAndDropFiles={handleDragAndDropFiles}
                  files={ideaSummaryFiles}
                  maxFilesPermited={1}
                />
              ) : null}
              <div className="accepted-files-conainer">
                {ideaSummaryFiles.map((f, i) => (
                  <AcceptedFiles
                    key={i}
                    index={i}
                    files={f}
                    removeDocumentsfromList={removeDocumentsfromList}
                  />
                ))}
              </div>
            </React.Fragment>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
  // }
};

export const ONGDropzone = (props) => {
  const {
    ideaForEdition,
    handleDragAndDropFiles,
    files,
    removeDocumentsfromList,
    openBase64File,
    currentState,
    isParticipant,
  } = props;

  let leaderFiles = ideaForEdition.files.filter(
    (i) => i.type === 'Resumen_ong'
  );

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">
        <h4 className="timeline-title">CURRICULUM VITAE DE LA ONG</h4>
        {isParticipant ? (
          <React.Fragment>
            <p>
              Aquí podrá subir el Currículum Vitae de la ONG. Puede actualizar
              su Currículum Vitae durante la Fase 1 y la Fase 2 del concurso.
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="vertical-timeline-element-content bounce-in">
        <DownloadedRemovableFilesList
          documents={leaderFiles}
          openBase64File={openBase64File}
        />
        {currentState ? (
          ideaForEdition.ongDocloading === true ? (
            <React.Fragment>
              <div className="loading-files-spiner-container">
                <Loader
                  type="Oval"
                  color={'gray'}
                  height={80}
                  width={80}
                  timeout={0} //3 secs
                />
                <h6>Subiendo archivo</h6>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isParticipant ? (
                <StyledDropzone
                  handleDragAndDropFiles={handleDragAndDropFiles}
                  files={files}
                  maxFilesPermited={1}
                />
              ) : null}
              <div className="accepted-files-conainer">
                {files.map((f, i) => (
                  <AcceptedFiles
                    key={i}
                    index={i}
                    files={f}
                    removeDocumentsfromList={removeDocumentsfromList}
                  />
                ))}
              </div>
            </React.Fragment>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
  // }
};

export const LeaderDropzone = (props) => {
  const {
    ideaForEdition,
    handleDragAndDropFiles,
    files,
    removeDocumentsfromList,
    openBase64File,
    currentState,
    isParticipant,
  } = props;

  let leaderFiles = ideaForEdition.files.filter((i) => i.type === 'CV_lider');

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">
        <h4 className="timeline-title">CURRICULUM VITAE DEL LÍDER</h4>
        {isParticipant ? (
          <React.Fragment>
            <p>
              Aquí podrá subir el Currículum Vitae del líder de la idea. Puede
              actualizar su Currículum Vitae durante la Fase 1 y la Fase 2 del
              concurso.
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="vertical-timeline-element-content bounce-in">
        <DownloadedRemovableFilesList
          documents={leaderFiles}
          openBase64File={openBase64File}
        />
        {currentState ? (
          ideaForEdition.leaderDocloading === true ? (
            <React.Fragment>
              <div className="loading-files-spiner-container">
                <Loader
                  type="Oval"
                  color={'gray'}
                  height={80}
                  width={80}
                  timeout={0} //3 secs
                />
                <h6>Subiendo archivo</h6>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isParticipant ? (
                <StyledDropzone
                  handleDragAndDropFiles={handleDragAndDropFiles}
                  files={files}
                  maxFilesPermited={1}
                />
              ) : null}

              <div className="accepted-files-conainer">
                {files.map((f, i) => (
                  <AcceptedFiles
                    key={i}
                    index={i}
                    files={f}
                    removeDocumentsfromList={removeDocumentsfromList}
                  />
                ))}
              </div>
            </React.Fragment>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
  // }
};

export const ParticipantCVDropzone = (props) => {
  const {
    member,
    index,
    openBase64File,
    handleDragAndDropFiles,
    counter,
    currentState,
    isParticipant,
  } = props;
  return (
    <div className="participant-cv-dropzone vertical-timeline-element-content bounce-in">
      <div className="preload-member-cv-name-container">
        <h6>
          <span>Currículum Vitae del Participante {index + 1}:</span> (
          {member.name + '  '}
          {member.lastName})
        </h6>
      </div>
      {member.documents.length > 0
        ? member.documents.map((d, i) => (
            <DownloadedRemovableFilesList
              key={i}
              documents={[{ fileName: d.fileName, fileUrl: d.fileUrl }]}
              openBase64File={openBase64File}
            />
          ))
        : null}
      {currentState ? (
        member.memberDocLoading === true ? (
          <React.Fragment>
            <div className="loading-files-spiner-container">
              <Loader
                type="Oval"
                color={'gray'}
                height={80}
                width={80}
                timeout={0} //3 secs
              />
              <h6>Subiendo archivo</h6>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {isParticipant ? (
              <StyledDropzone
                handleDragAndDropFiles={handleDragAndDropFiles}
                files={member.fileToUpload}
                maxFilesPermited={1}
                memberId={counter}
              />
            ) : null}

            {/* <div className="accepted-files-conainer">
            {member.fileToUpload.map((f, i) => (
              <AcceptedFiles key={i} index={i} files={f} />
            ))}
          </div> */}
          </React.Fragment>
        )
      ) : null}
    </div>
  );
};

export const ParticipantDropzone = (props) => {
  const {
    ideaForEdition,
    openBase64File,
    handleDragAndDropFiles,
    currentState,
    isParticipant,
  } = props;

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">
        <h4 className="timeline-title">CURRICULUM VITAE DE LOS INTEGRANTES</h4>
        {isParticipant ? (
          <React.Fragment>
            <p>
              Aquí podrá subir los Currículums Vitae de los integrantes. Puede
              actualizar los Currículums Vitae durante la Fase 1 y la Fase 2 del
              concurso, tantas veces como sea necesario.
            </p>
          </React.Fragment>
        ) : null}
      </div>

      {ideaForEdition.members.length > 0
        ? ideaForEdition.members.map((member, i) => (
            <ParticipantCVDropzone
              key={i}
              index={i}
              counter={i}
              member={member}
              openBase64File={openBase64File}
              handleDragAndDropFiles={handleDragAndDropFiles}
              currentState={currentState}
              isParticipant={isParticipant}
            />
          ))
        : null}
    </React.Fragment>
  );
  // }
};

export const IdeaFinalPresentationDropzone = (props) => {
  const {
    ideaForEdition,
    handleDragAndDropFiles,
    files,
    removeDocumentsfromList,
    openBase64File,
    currentState,
    ideaStatus,
    isParticipant,
  } = props;

  let presentationFiles = ideaForEdition.files.filter(
    (i) => i.type === 'Final_presentation'
  );

  return (
    <React.Fragment>
      <div className="vertical-timeline-element-content bounce-in">
        <h4 className="timeline-title">PRESENTACIÓN PARA EL EVENTO FINAL</h4>
        {isParticipant ? (
          <React.Fragment>
            <p>
              Aquí podrá subir su presentación para el evento final. Puede subir
              su presentación tantas veces como sea necesario mientras la Fase 3
              esté activa. Se tomará en cuenta para la evaluación el último
              archivo cargado.
            </p>
          </React.Fragment>
        ) : null}
      </div>
      <div className="vertical-timeline-element-content bounce-in">
        <DownloadedRemovableFilesList
          documents={presentationFiles}
          openBase64File={openBase64File}
        />
        {currentState && ideaStatus === 'Finalista' ? (
          ideaForEdition.finalPresentationloading === true ? (
            <React.Fragment>
              <div className="loading-files-spiner-container">
                <Loader
                  type="Oval"
                  color={'gray'}
                  height={80}
                  width={80}
                  timeout={0} //3 secs
                />
                <h6>Subiendo archivo</h6>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isParticipant ? (
                <StyledDropzone
                  handleDragAndDropFiles={handleDragAndDropFiles}
                  files={files}
                  maxFilesPermited={1}
                />
              ) : null}
              <div className="accepted-files-conainer">
                {files.map((f, i) => (
                  <AcceptedFiles
                    key={i}
                    index={i}
                    files={f}
                    removeDocumentsfromList={removeDocumentsfromList}
                  />
                ))}
              </div>
            </React.Fragment>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
  // }
};
