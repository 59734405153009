import React, { useEffect, useState } from 'react';
import advanceFilter from '../../../images/advanceFilter.svg';
import magnifier from '../../../images/magnifier.svg';
import { AdvanceFilterDateInput } from '../../common/ContestForms/ContestForms';
import exportImg from '../../../images/export.svg';
import { getContests } from '../../../services/contestService';
import { CSVLink } from 'react-csv';
import { ContestSelectInput } from '../../common/ContestForms/ContestForms';
import { toast } from 'react-toastify';
import moment from 'moment';
import './Filters.scss';

// Advance filter checkbox
export const AdvanceField = (props) => {
  const { name, value, label, advanceFilerInputChange } = props;
  return (
    <div className="advance-filter-field-container">
      <input
        type="checkbox"
        name={name}
        value={value}
        defaultChecked={value}
        onChange={advanceFilerInputChange}
      />
      <h6>{label}</h6>
    </div>
  );
};

export const AdvanceFilterOptions = (props) => {
  const {
    show,
    makeAdvanceSearch,
    advanceFilerInputChange,
    adminIdeaList,
    cancelAdvanceSearch,
  } = props;
  if (show) {
    return (
      <div className="advance-filter-options">
        <div className="advance-filter-section">
          <h6 className="advance-filter-lable">Estatus</h6>
          {adminIdeaList.advanceStatusSearch.map((a, i) => (
            <AdvanceField
              key={i}
              label={a.search}
              name={a.name}
              value={a.selected}
              advanceFilerInputChange={advanceFilerInputChange}
            />
          ))}
        </div>
        <div className="second-advance-filter-section advance-filter-section ">
          <h6 className="advance-filter-lable">Documentos Requeridos</h6>
          <div className="advance-field-container">
            {adminIdeaList.advanceDocumentSearch.map((a, i) => (
              <AdvanceField
                key={i}
                label={a.label}
                name={a.name}
                value={a.selected}
                advanceFilerInputChange={advanceFilerInputChange}
              />
            ))}
          </div>
        </div>
        <div className="second-advance-filter-section advance-filter-section ">
          <h6 className="advance-filter-lable">Tipo</h6>
          {adminIdeaList.advanceTypeSearch.map((a, i) => (
            <AdvanceField
              key={i}
              label={a.search}
              name={a.name}
              value={a.selected}
              advanceFilerInputChange={advanceFilerInputChange}
            />
          ))}
        </div>
        <div className="advance-date-container second-advance-filter-section advance-filter-section">
          <h6 id="third-advance-filter-lable" className="advance-filter-lable">
            Fecha de Creación
          </h6>
          <div className="advance-date-picker-container">
            <AdvanceFilterDateInput
              handleChange={advanceFilerInputChange}
              name="startDate"
              label=""
              value={adminIdeaList.startDate.data}
              errors={adminIdeaList.startDate.errors}
            />
            <div className="advance-separator-line"></div>
            <AdvanceFilterDateInput
              handleChange={advanceFilerInputChange}
              name="endDate"
              label=""
              value={adminIdeaList.endDate.data}
              errors={adminIdeaList.endDate.errors}
            />
          </div>
        </div>
        <div className="third-advance-filter-section">
          <div className="handle-filter-modal-button-container">
            <h6
              className="cancel-filter-message"
              onClick={() => cancelAdvanceSearch()}
            >
              Cerrar
            </h6>
            <h6
              className="clear-filter-message"
              onClick={() => makeAdvanceSearch()}
            >
              Aplicar
            </h6>
          </div>
        </div>
      </div>
    );
  } else {
    return true;
  }
};

export const AdvanceFilter = (props) => {
  const {
    userSession,
    adminIdeaList,
    AdvaceFilterinputChange,
    getAdminIdeaListFunction,
    handleAdvanceFilter,
    clearAdvanceFilter,
    makeAdvanceSearch,
    advanceFilerInputChange,
    cancelAdvanceSearch,
  } = props;
  return (
    <div className="advance-filter-container">
      <div>
        <input
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              getAdminIdeaListFunction(
                userSession,
                adminIdeaList.search,
                adminIdeaList.currentPage,
                adminIdeaList.ideasPerPage
              );
            }
          }}
          autoComplete="off"
          className="advance-filter advance-filter-container-input"
          onChange={AdvaceFilterinputChange}
          type={'text'}
          value={adminIdeaList.search}
          name={'search'}
          placeholder={'Buscar por palabra clave...'}
        />
        <div
          className="maginifier-container-img"
          onClick={() =>
            getAdminIdeaListFunction(
              userSession,
              adminIdeaList.search,
              adminIdeaList.currentPage,
              adminIdeaList.ideasPerPage
            )
          }
        >
          <img src={magnifier} alt="" />
        </div>
        <div
          onClick={() => handleAdvanceFilter()}
          className="advance-search-button"
        >
          <h6>Filtro Avanzado </h6>
          <img src={advanceFilter} alt="" />
        </div>
      </div>

      <AdvanceFilterOptions
        handleAdvanceFilter={handleAdvanceFilter}
        show={adminIdeaList.showAdvanceFilter}
        clearAdvanceFilter={clearAdvanceFilter}
        makeAdvanceSearch={makeAdvanceSearch}
        advanceFilerInputChange={advanceFilerInputChange}
        adminIdeaList={adminIdeaList}
        cancelAdvanceSearch={cancelAdvanceSearch}
      />
    </div>
  );
};

export const FoundElements = (props) => {
  const { found } = props;
  return (
    <h6 className="found-elements-component">{found} elementos encontrados</h6>
  );
};

// Admin filter and options
export const AdminFilter = (props) => {
  const {
    userSession,
    adminIdeaList,
    AdvaceFilterinputChange,
    getAdminIdeaListFunction,
    clearAdvanceFilter,
    handleAdvanceFilter,
    makeAdvanceSearch,
    advanceFilerInputChange,
    cancelAdvanceSearch,
  } = props;

  const [contestList, setContestList] = useState([]);
  const [selectedContest, setSelectedContest] = useState('');

  //Titles for CSV Columns
  const csvData = [
    [
      'ID',
      'ID DANNA',
      'FECHA DE REGISTRO IDEA',
      'TÍTULO IDEA',
      'TIPO DE IDEA',
      'SECTOR ECONÓMICO DE LA IDEA',
      'ESTADO DE LA IDEA',
      'PRIMERA VEZ QUE PARTICIPA',
      'HA PARTICIPADO EN LOS ÚLTIMOS 3 AÑOS',
      'IDEA NUEVA O MEJORADA',
      'ESTATUS',
      'NOMBRE DEL LÍDER',
      'CÉDULA DEL LÍDER',
      'FECHA DE NACIMIENTO DEL LÍDER',
      'SEXO DEL LÍDER',
      'TELÉFONO DEL LÍDER (HAB)',
      'TELÉFONO DEL LÍDER (MÓVIL)',
      'TELÉFONO DEL LÍDER (OFIC/OTRO)',
      'NIVEL EDUCATIVO DEL LÍDER',
      'SITUACIÓN DE EMPLEO DEL LÍDER',
      'SECTOR ECONÓMICO DEL LÍDER',
      'EMPRESA DEL LÍDER',
      'CARGO DEL LÍDER',
      'EMPRENDIMIENTO DEL LÍDER',
      'CORREO ELECTRÓNICO DEL LÍDER',
      'OCUPACIÓN DEL LÍDER',
      'TIPO DE IDEA SOCIAL',
      'NOMBRE DE LA ONG',
      'ESTADO DE LA ONG',
      'CORREO ELECTRÓNICO DE LA ONG',
      'TELÉFONO DE LA ONG',
      'DIRECCIÓN DE LA ONG',
    ],
  ];

  var helper = [];

  const addToCsv = (element) => {
    helper.push(element.id);
    helper.push(element.codeDana);
    helper.push(moment(element.creationDate).format('l'));
    helper.push(element.title);
    helper.push(element.proposalType.proposalType);
    helper.push(element.sector.economicSector);
    helper.push(element.state.state);

    if (element.firstTime) {
      helper.push('Si');
    } else {
      helper.push('No');
    }

    if (element.lastThreeYears) {
      helper.push('Si');
    } else {
      helper.push('No');
    }

    if (element.newIdea) {
      helper.push('Si');
    } else {
      helper.push('No');
    }

    helper.push(element.stage?.status);
    helper.push(element.user.name + ' ' + element.user.lastName);
    if(element.user.nationality!=null && element.user.idNumber!=null){
      helper.push(
        element.user.nationality.nationality + ' - ' + element.user.idNumber
      );
    }else{
      helper.push("N/A");
    }
    helper.push(moment(element.user.birthDate).format('l'));

    if (element.user.gender?.gender === 'F') {
      helper.push('Femenino');
    } else {
      helper.push('Masculino');
    }

    helper.push(String(element.user.phoneHome));
    helper.push(String(element.user.phoneMobile));
    helper.push(String(element.user.phoneOther));

    if (element.user.educationalLevel != null) {
      helper.push(element.user.educationalLevel.educationalLevel);
    } else {
      helper.push('N/A');
    }

    if (element.user.economicSector != null) {
      // Person has a job
      if (element.user.enterpreneurship) {
        if (
          element.user.companyName != '' ||
          element.user.companyName != null
        ) {
          // Does not have company
          helper.push('Independiente');
          helper.push(element.user.economicSector.economicSector);
          helper.push('N/A');
          helper.push('N/A');
          helper.push(element.user.entrepreneurshipDsc);
        } else {
          // Belongs to a company
          helper.push('Empleado con Emprendimiento');
          helper.push(element.user.economicSector.economicSector);
          helper.push(element.user.companyName);
          helper.push(element.user.companyPosition);
          helper.push(element.user.entrepreneurshipDsc);
        }
      } else {
        helper.push('Empleado');
        helper.push(element.user.economicSector.economicSector);
        helper.push(element.user.companyName);
        helper.push(element.user.companyPosition);
        helper.push('N/A');
      }
    } else {
      // Does not have job
      helper.push('Sin Empleo');
      helper.push('N/A');
      helper.push('N/A');
      helper.push('N/A');
      helper.push('N/A');
    }

    helper.push(element.user.email);

    if (element.ocupation != null) {
      helper.push(element.ocupation.ocupation);
    } else {
      helper.push('N/A');
    }

    if (element.socialType != null) {
      helper.push(element.socialType.socialType);
    } else {
      helper.push('N/A');
    }

    if (element.ong != null) {
      helper.push(element.ong.name);
      helper.push(element.ong.state.state);
      helper.push(element.ong.email);
      helper.push(String(element.ong.phone));
      helper.push(element.ong.address);
    } else {
      helper.push('N/A');
      helper.push('N/A');
      helper.push('N/A');
      helper.push('N/A');
      helper.push('N/A');
    }

    csvData.push(helper);

    helper = [];
  };

  adminIdeaList.ideasToPrint.forEach((element) => addToCsv(element));

  const onChangeSelectedContest = (event) => {
    const { value } = event.target; // <-- moved outside asynchronous context
    setSelectedContest(value);
    sessionStorage.setItem('contestId', value);

    getAdminIdeaListFunction(
      userSession,
      adminIdeaList.search,
      adminIdeaList.currentPage,
      adminIdeaList.ideasPerPage
    );
  };

  const getContestFunction = async () => {
    await getContests().then((response) => {
      if (response.status == 200) {
        setContestList(response.data);
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        setSelectedContest(selectedContest?.id ||'');
        sessionStorage.setItem('contestId', selectedContest?.id);
       
      } else {
        toast.error(`${response.message}` || 'Error al obtener concursos');
      }
    });
  };

  useEffect(() => {
    getContestFunction();
    clearAdvanceFilter(true)
  }, []);

  return (
    <React.Fragment>
      <div className="advance-filter-section-container">
        <AdvanceFilter
          userSession={userSession}
          adminIdeaList={adminIdeaList}
          AdvaceFilterinputChange={AdvaceFilterinputChange}
          getAdminIdeaListFunction={getAdminIdeaListFunction}
          handleAdvanceFilter={handleAdvanceFilter}
          clearAdvanceFilter={clearAdvanceFilter}
          makeAdvanceSearch={makeAdvanceSearch}
          advanceFilerInputChange={advanceFilerInputChange}
          cancelAdvanceSearch={cancelAdvanceSearch}
          selectedContest={selectedContest}
        />

        <div style={{ marginTop: '20px' }} className="contest-list-container">
          <div className="contest-list-column">
            <ContestSelectInput
              options={contestList}
              handleChange={onChangeSelectedContest}
              name="contestName"
              label="Concurso"
              value={selectedContest}
              errors={''}
              type={'text'}
              placeholder={''}
            />
          </div>
        </div>
        <h6
          className="clear-filter-message"
          onClick={() =>{
            clearAdvanceFilter(true)
            sessionStorage.removeItem('contestId');
            setSelectedContest('');
            sessionStorage.setItem('contestId', '');
          } }
        >
          Limpiar filtros
        </h6>
      </div>
      <div className="export-found-container">
        <FoundElements found={adminIdeaList.totalElements} />
        <CSVLink data={csvData} filename="ConcursoIdeasListadoGeneral.csv">
          <div className="export-action-component">
            <img src={exportImg} alt="" />
            <h6>Exportar</h6>
          </div>
        </CSVLink>
      </div>
    </React.Fragment>
  );
};
