export let meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const showDate = (date) => {
  if (date) {
    let [year, month, day] = date.split('-');
    let stringDate = `${parseInt(day)} de ${
      meses[parseInt(month) - 1]
    } del ${year}`;
    return stringDate;
  } else return null;
};
