/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { UsersTable } from '../../common/Tables/Tables';
import { Sidebar, ResponsiveSidebar } from '../../common/Sidebar/Sidebar';
import { Dashboard } from '../DashBoard/DashBoard';
import { JudgeDashboard } from '../JudgeDashboard/JudgeDashboard';
// import { DashBoardAdmin } from '../DashBoard/DashBoardAdmin';
import { NewDashBoardAdmin } from '../../views/DashBoard/NewDashboardAdmin/NewDashboardAdmin';

import { IdeaList, AdminIdeaList } from '../DashBoard/IdeaList';
import { PasswordChangeForm } from '../../common/Forms/Forms';
import Header from '../../common/Header/Header';
// import { RegisterUser, EditUser } from "../../common/Menu/Usuarios/Usuarios";
import auth from '../../../services/authService';
import ReactPaginate from 'react-paginate';
import arrow from '../../../images/arrow.png';
import mas from '../../../images/mas.png';
import './Home.scss';
import empty from 'is-empty';
import { IdeaDetail } from '../IdeaDetail/IdeaDetail';
import { JudgeIdeaDetail } from '../JudgeIdeaDetail/JudgeIdeaDetail';
import { Preferences } from '../Preferences/Preferences';
import { UserManagement } from '../UserManagement/UserManagement';
import { CompanyManagement } from '../CompanyManagement/CompanyManagement';
import { SpecialMention } from '../SpecialMention/SpecialMention';
import { Evaluations } from '../../views/Evaluations/Evaluations';
import { IdeaManagement } from '../../views/IdeaManagement/IdeaManagement';
import { EvaluatorsManagement } from '../../views/EvaluatorsManagement/EvaluatorsManagement';
import { EvaluationIdea } from '../../views/Evaluations/EvaluationIdea/EvaluationIdea';
import { Reports } from '../../views/Reports/Reports';
import { AcknowledgeModal } from '../JudgeDashboard/AcknowledgeModal/AcknowledgeModal';
import { getContests } from '../../../services/contestService.js';
import {
  getUserById,
  editUserFromAdmin,
} from '../../../services/userListService';
import { getContestDocument } from '../../../services/documentService';
import { Distribution } from '../../views/Distribution/Distribution';
import authService from '../../../services/authService';
import { documentType } from '../../../helpers/types';
import { JudgeDetail } from '../../views/JudgeDashboard/JudgePreferences/JudgeDetail';
import { toast } from 'react-toastify';

// Button that creates new users
export const CreateNewUserButton = () => {
  return (
    <div className="button login-button">
      <h6 id="create-new-user-button">Crear nueva</h6>{' '}
      <img src={mas} alt={mas} />
    </div>
  );
};

// User list component
export const UserList = (
  userList,
  handleUserPageClick,
  query,
  searchUserBox,
  loadUserDataForEdition,
  deleteIdea
) => {
  /*************************************************SEARCHBOX************************************************/
  const handleInputChange = (event) => {
    if (!empty(event)) {
      query = event.target.value;
      searchUserBox(query);
    }
  };
  /*************************************************SEARCHBOX************************************************/

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="userlist-search-container">
            <input
              autoComplete="off"
              className="input"
              type="text"
              name="userFilter"
              placeholder="Buscar"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="call-to-action-back col-12 col-lg-4">
          <NavLink to="/gestion/registro">
            <div className="call-to-action-link">
              <h6 id="call-to-action-user-list" className="title">
                Volver
              </h6>
              <img src={arrow} alt="" />
            </div>
          </NavLink>
        </div>
      </div>

      {!empty(userList) ? (
        <div className="align-table-left">
          <UsersTable
            users={userList.data}
            loadUserDataForEdition={loadUserDataForEdition}
            deleteIdea={deleteIdea}
          />

          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Siguiente'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={userList.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handleUserPageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
      ) : (
        <div className="call-to-action-link">
          <div className="empty-lists">No hay usuarios registrados</div>
        </div>
      )}
    </div>
  );
};

// Button that saves the register
export const savePasswordButton = (
  updatePasswordFunction,
  history,
  passwordUpdateForm
) => {
  let { oldPassword, newPassword, confirmNewPassword } =
    passwordUpdateForm.data;

  if (empty(oldPassword) || empty(newPassword) || empty(confirmNewPassword)) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => updatePasswordFunction(history)}
        className="button login-button"
      >
        <h6>Guardar</h6>
      </div>
    );
  }
};

// Main Home component
export const Home = (props) => {
  const {
    sidebarShow,
    handleSideBarShow,
    userSession,
    logoutService,
    restoreAllForms,
    handleIdeaListPageChange,
    ideaList,
    currentYearIdeas,
    currentYearDocuments,
    handleDragAndDropFiles,
    handleBusinessDragAndDropFiles,
    handleONGDragAndDropFiles,
    files,
    ideaForEdition,
    selectIdea,
    removeDocumentsfromList,
    openBase64File,
    addMemberService,
    handleDragAndDropSummaryFiles,
    handleDragAndDropMembersFiles,
    handleDragAndDropFinalPresentationFiles,
    deleteMemberService,
    validateEmailFormat,
    validatePhoneNumberFormat,
    validateNumericFormat,
    validateIfLegal,
    currentContest,
    blockedLogin,
    stagesDropdowns,
    handleStageDropDown,
    addFinalPresentationUrlService,
    selectAwardService,
    getCurrentContestStage,
    // Change password methods
    passwordForminputChange,
    setPasswordShownValue,
    updatePasswordFunction,
    passwordUpdateForm,
    passwordShown,
    // Awards
    handleAwardOnChange,
    // Admin Methods
    adminIdeaList,
    handleAdminIdeaListPageChange,
    handleAdminRowsPerPage,
    deleteIdea,
    changeIdeaStatus,
    AdvaceFilterinputChange,
    getAdminIdeaListFunction,
    clearAdvanceFilter,
    handleAdvanceFilter,
    ideasParticipant,
    ideasRegistered,
    ideasFinalist,
    ideasSemifinalist,
    documentActives,
    documentActivesPresentation,
    ideasWinner,
    usersParticipant,
    makeAdvanceSearch,
    advanceFilerInputChange,
    cancelAdvanceSearch,
    currentContestStage,
    updateStageFunction,
    handleStageChange,
    closeContestFunction,
    updateAdminDashboard,
    //evaluationcriteria
    handleDragAndDropFilesEvaluationCriterias,
    evaluationcriteria,
    getDocumentsByCriteriaPrincipal,
    selectCriteriaDocument

  } = props;

  const [agreementOpen, setAgreementOpen] = useState({
    open: false,
    accepted: false,
  });
  // const [conditionsOpen, setConditionsOpen] = useState({
  //   open: false,
  //   accepted: false,
  // });
  const [confidenciality, setConfidenciality] = useState('');
  // const [conditions, setConditions] = useState('');

  const acceptTermsOfAgreement = () => {
    let user = authService.getUserData();
    editUserFromAdmin(
      {
        selectedJudgeAC: true,
      },
      user.userId
    )
      .then(() => {
        setAgreementOpen({
          ...agreementOpen,
          open: false,
          accepted: true,
        });
        location.reload();
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  // const acceptConditions = () => {
  //   let user = authService.getUserData();
  //   editUserFromAdmin(
  //     {
  //       selectedJudgeTC: true,
  //     },
  //     user.userId
  //   )
  //     .then(() => {
  //       setConditionsOpen({
  //         ...agreementOpen,
  //         open: false,
  //         accepted: true,
  //       });
  //       location.reload();
  //     })
  //     .catch((error) => {
  //       console.log('error:', error);
  //     });
  // };

  const getTermsAndConditions = async () => {
    await getContests().then((response) => {
      if (response?.status == 200) {
        let [selectedContest] = response.data.filter((c) => c.isOpen);
        // setCurrentContest(selectedContest);
        if (selectedContest) {
          getContestDocumentFunction(selectedContest?.id);
        } else {
          toast.error('No hay concurso activo');
        }
      } else {
        toast.error(`${response?.message}` || 'Error al obtener concursos');
      }
    });
  };

  const getContestDocumentFunction = async (contestId) => {
    await getContestDocument(contestId).then((response) => {
      if (response.status === 200) {
        let [confidenciality] = response.data.filter(
          (c) => c.documentType === documentType.confidenciality
        );
        // let [conditions] = response.data.filter(
        //   (c) => c.documentType === documentType.termsAndConditions
        // );
        // if (conditions) {
        //   setConditions(conditions);
        // }
        if (confidenciality) {
          setConfidenciality(confidenciality);
        }
      }
    });
  };

  const setReadAgreement = () => {
    let accepted = !agreementOpen.accepted;

    setAgreementOpen({
      ...agreementOpen,
      open: true,
      accepted: accepted,
    });
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  // const setReadConditions = () => {
  //   let accepted = !agreementOpen.accepted;

  //   setConditionsOpen({
  //     ...agreementOpen,
  //     open: true,
  //     accepted: accepted,
  //   });
  // };

  const getUserData = () => {
    let user = authService.getUserData();

    getUserById(user.userId)
      .then((response) => {
        if (response.status === 200) {
          // if (!response.data.selectedJudgeTC) {
          //   setConditionsOpen({ open: true, accepted: false });
          // }
          if (!response.data.selectedJudgeAC) {
            setAgreementOpen({ open: true, accepted: false });
          }
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  useEffect(() => {
    getTermsAndConditions();
    getUserData();
  }, []);

  return (
    <React.Fragment>
      <Header
        handleSideBarShow={handleSideBarShow}
        userSession={userSession}
        logoutService={logoutService}
        history={props}
      />
      <div className="menu-container">
        <div className="menu-sidebar">
          <Sidebar
            userSession={userSession}
            restoreAllForms={restoreAllForms}
            updateAdminDashboard={updateAdminDashboard}
          />
        </div>
        <ResponsiveSidebar
          userSession={userSession}
          sidebarShow={sidebarShow}
          handleSideBarShow={handleSideBarShow}
          restoreAllForms={restoreAllForms}
          updateAdminDashboard={updateAdminDashboard}
        />
        {['judge', 'judge_leader', 'judge_special'].some(
          (l) => l === userSession?.userType
        ) && (
          <>
            <AcknowledgeModal
              accept={acceptTermsOfAgreement}
              agreementOpen={agreementOpen}
              document={confidenciality}
              setRead={setReadAgreement}
            />
            {/* <AcknowledgeModal
              accept={acceptConditions}
              agreementOpen={conditionsOpen}
              document={conditions}
              setRead={setReadConditions}
            /> */}
          </>
        )}

        <Switch>
          <Route
            path="/home/judge/"
            render={() => {
              if (
                ['judge', 'judge_leader', 'judge_special'].some(
                  (l) => l === auth.getUserType()
                )
              ) {
                return (
                  <React.Fragment>
                    <div className="menu-dashboard container">
                      <Switch>
                        <Route
                          path="/home/judge/ideas/:id"
                          render={(props) => {
                            return (
                              // <JudgeIdeaDetail
                              <IdeaDetail
                                {...props}
                                ideaList={ideaList}
                                handleDragAndDropFiles={handleDragAndDropFiles}
                                handleONGDragAndDropFiles={
                                  handleONGDragAndDropFiles
                                }
                                handleDragAndDropSummaryFiles={
                                  handleDragAndDropSummaryFiles
                                }
                                handleDragAndDropMembersFiles={
                                  handleDragAndDropMembersFiles
                                }
                                handleBusinessDragAndDropFiles={
                                  handleBusinessDragAndDropFiles
                                }
                                handleDragAndDropFinalPresentationFiles={
                                  handleDragAndDropFinalPresentationFiles
                                }
                                files={files}
                                ideaForEdition={ideaForEdition}
                                removeDocumentsfromList={
                                  removeDocumentsfromList
                                }
                                openBase64File={openBase64File}
                                userSession={userSession}
                                addMemberService={addMemberService}
                                addFinalPresentationUrlService={
                                  addFinalPresentationUrlService
                                }
                                deleteMemberService={deleteMemberService}
                                validateEmailFormat={validateEmailFormat}
                                validatePhoneNumberFormat={
                                  validatePhoneNumberFormat
                                }
                                validateNumericFormat={validateNumericFormat}
                                validateIfLegal={validateIfLegal}
                                currentContestStage={currentContestStage}
                                blockedLogin={blockedLogin}
                                stagesDropdowns={stagesDropdowns}
                                handleStageDropDown={handleStageDropDown}
                                selectAwardService={selectAwardService}
                                handleAwardOnChange={handleAwardOnChange}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/judge/evaluations/:id"
                          render={(props) => {
                            return (
                              <EvaluationIdea
                                {...props}
                                selectIdea={selectIdea}
                                ideaList={ideaList}
                                handleDragAndDropFiles={handleDragAndDropFiles}
                                handleONGDragAndDropFiles={
                                  handleONGDragAndDropFiles
                                }
                                handleDragAndDropSummaryFiles={
                                  handleDragAndDropSummaryFiles
                                }
                                handleDragAndDropMembersFiles={
                                  handleDragAndDropMembersFiles
                                }
                                handleBusinessDragAndDropFiles={
                                  handleBusinessDragAndDropFiles
                                }
                                handleDragAndDropFinalPresentationFiles={
                                  handleDragAndDropFinalPresentationFiles
                                }
                                files={files}
                                ideaForEdition={ideaForEdition}
                                removeDocumentsfromList={
                                  removeDocumentsfromList
                                }
                                openBase64File={openBase64File}
                                userSession={userSession}
                                addMemberService={addMemberService}
                                addFinalPresentationUrlService={
                                  addFinalPresentationUrlService
                                }
                                deleteMemberService={deleteMemberService}
                                validateEmailFormat={validateEmailFormat}
                                validatePhoneNumberFormat={
                                  validatePhoneNumberFormat
                                }
                                validateNumericFormat={validateNumericFormat}
                                validateIfLegal={validateIfLegal}
                                currentContestStage={currentContestStage}
                                blockedLogin={blockedLogin}
                                stagesDropdowns={stagesDropdowns}
                                handleStageDropDown={handleStageDropDown}
                                selectAwardService={selectAwardService}
                                handleAwardOnChange={handleAwardOnChange}
                                getDocumentsByCriteriaPrincipal={getDocumentsByCriteriaPrincipal}
                                evaluationcriteria={evaluationcriteria}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/judge/settings"
                          render={(props) => {
                            return <JudgeDetail {...props} />;
                          }}
                        />
                        <Route
                          path="/home/judge/evaluations"
                          render={(props) => {
                            return (
                              <Evaluations {...props} selectIdea={selectIdea} />
                            );
                          }}
                        />
                        <Route
                          path="/home/judge/idea-management"
                          render={(props) => {
                            return (
                              <IdeaManagement
                                {...props}
                                selectIdea={selectIdea}
                              />
                            );
                          }}
                        />{' '}
                        <Route
                          path="/home/judge/evaluators-management"
                          render={(props) => {
                            return (
                              <EvaluatorsManagement
                                {...props}
                                selectIdea={selectIdea}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/judge/change-password"
                          render={(props) => {
                            return (
                              <PasswordChangeForm
                                {...props}
                                passwordForminputChange={
                                  passwordForminputChange
                                }
                                passwordShown={passwordShown}
                                setPasswordShownValue={setPasswordShownValue}
                                updatePasswordFunction={updatePasswordFunction}
                                history={props.history}
                                passwordUpdateForm={passwordUpdateForm}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/judge/special-mention"
                          render={(props) => {
                            return (
                              <SpecialMention
                                {...props}
                                selectIdea={selectIdea}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/judge"
                          render={(props) => {
                            return (
                              <JudgeDashboard
                                {...props}
                                userSession={userSession}
                                currentYearIdeas={currentYearIdeas}
                                selectIdea={selectIdea}
                                currentContest={currentContest}
                                stagesDropdowns={stagesDropdowns}
                                currentYearDocuments={currentYearDocuments}
                                getCurrentContestStage={getCurrentContestStage}
                              />
                            );
                          }}
                        />
                      </Switch>
                    </div>
                  </React.Fragment>
                );
              } else {
                return <Redirect to="/login" />;
              }
            }}
          />
          <Route
            path="/home/participant/"
            render={() => {
              if (['participant'].some((l) => l === auth.getUserType())) {
                return (
                  <React.Fragment>
                    <div className="menu-dashboard container">
                      <Switch>
                        <Route
                          path="/home/participant/ideas/:id"
                          render={(props) => {
                            return (
                              <IdeaDetail
                                {...props}
                                ideaList={ideaList}
                                handleDragAndDropFiles={handleDragAndDropFiles}
                                handleONGDragAndDropFiles={
                                  handleONGDragAndDropFiles
                                }
                                handleDragAndDropSummaryFiles={
                                  handleDragAndDropSummaryFiles
                                }
                                handleDragAndDropMembersFiles={
                                  handleDragAndDropMembersFiles
                                }
                                handleBusinessDragAndDropFiles={
                                  handleBusinessDragAndDropFiles
                                }
                                handleDragAndDropFinalPresentationFiles={
                                  handleDragAndDropFinalPresentationFiles
                                }
                                files={files}
                                ideaForEdition={ideaForEdition}
                                removeDocumentsfromList={
                                  removeDocumentsfromList
                                }
                                openBase64File={openBase64File}
                                userSession={userSession}
                                addMemberService={addMemberService}
                                addFinalPresentationUrlService={
                                  addFinalPresentationUrlService
                                }
                                deleteMemberService={deleteMemberService}
                                validateEmailFormat={validateEmailFormat}
                                validatePhoneNumberFormat={
                                  validatePhoneNumberFormat
                                }
                                validateNumericFormat={validateNumericFormat}
                                validateIfLegal={validateIfLegal}
                                currentContestStage={currentContestStage}
                                blockedLogin={blockedLogin}
                                stagesDropdowns={stagesDropdowns}
                                handleStageDropDown={handleStageDropDown}
                                selectAwardService={selectAwardService}
                                handleAwardOnChange={handleAwardOnChange}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/participant/ideas"
                          render={(props) => {
                            return (
                              <IdeaList
                                {...props}
                                ideaList={ideaList}
                                handleIdeaListPageChange={
                                  handleIdeaListPageChange
                                }
                                selectIdea={selectIdea}
                                deleteIdea={deleteIdea}
                                blockedLogin={blockedLogin}
                                stagesDropdowns={stagesDropdowns}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/participant/change-password"
                          render={(props) => {
                            return (
                              <PasswordChangeForm
                                {...props}
                                passwordForminputChange={
                                  passwordForminputChange
                                }
                                passwordShown={passwordShown}
                                setPasswordShownValue={setPasswordShownValue}
                                updatePasswordFunction={updatePasswordFunction}
                                history={props.history}
                                passwordUpdateForm={passwordUpdateForm}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/participant"
                          render={(props) => {
                            return (
                              <Dashboard
                                {...props}
                                userSession={userSession}
                                currentYearIdeas={currentYearIdeas}
                                selectIdea={selectIdea}
                                currentContest={currentContest}
                                stagesDropdowns={stagesDropdowns}
                                currentYearDocuments={currentYearDocuments}
                                getCurrentContestStage={getCurrentContestStage}
                              />
                            );
                          }}
                        />
                      </Switch>
                    </div>
                  </React.Fragment>
                );
              } else {
                return <Redirect to="/login" />;
              }
            }}
          />
          <Route
            path="/home/admin"
            render={() => {
              if (['admin'].some((l) => l === auth.getUserType())) {
                return (
                  <React.Fragment>
                    <div className="menu-dashboard container">
                      <Switch>
                        <Route
                          path="/home/admin/ideas/:id"
                          render={(props) => {
                            return (
                              <IdeaDetail
                                {...props}
                                ideaList={ideaList}
                                handleDragAndDropFiles={handleDragAndDropFiles}
                                handleONGDragAndDropFiles={
                                  handleONGDragAndDropFiles
                                }
                                handleDragAndDropSummaryFiles={
                                  handleDragAndDropSummaryFiles
                                }
                                handleDragAndDropMembersFiles={
                                  handleDragAndDropMembersFiles
                                }
                                handleBusinessDragAndDropFiles={
                                  handleBusinessDragAndDropFiles
                                }
                                handleDragAndDropFinalPresentationFiles={
                                  handleDragAndDropFinalPresentationFiles
                                }
                                files={files}
                                ideaForEdition={ideaForEdition}
                                removeDocumentsfromList={
                                  removeDocumentsfromList
                                }
                                openBase64File={openBase64File}
                                userSession={userSession}
                                addMemberService={addMemberService}
                                addFinalPresentationUrlService={
                                  addFinalPresentationUrlService
                                }
                                deleteMemberService={deleteMemberService}
                                validateEmailFormat={validateEmailFormat}
                                validatePhoneNumberFormat={
                                  validatePhoneNumberFormat
                                }
                                validateNumericFormat={validateNumericFormat}
                                validateIfLegal={validateIfLegal}
                                currentContestStage={currentContestStage}
                                blockedLogin={blockedLogin}
                                stagesDropdowns={stagesDropdowns}
                                handleStageDropDown={handleStageDropDown}
                                handleAwardOnChange={handleAwardOnChange}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/ideas"
                          render={(props) => {
                            return (
                              <AdminIdeaList
                                {...props}
                                userSession={userSession}
                                adminIdeaList={adminIdeaList}
                                handleAdminIdeaListPageChange={
                                  handleAdminIdeaListPageChange
                                }
                                selectIdea={selectIdea}
                                changeIdeaStatus={changeIdeaStatus}
                                handleAdminRowsPerPage={handleAdminRowsPerPage}
                                AdvaceFilterinputChange={
                                  AdvaceFilterinputChange
                                }
                                getAdminIdeaListFunction={
                                  getAdminIdeaListFunction
                                }
                                clearAdvanceFilter={clearAdvanceFilter}
                                handleAdvanceFilter={handleAdvanceFilter}
                                makeAdvanceSearch={makeAdvanceSearch}
                                advanceFilerInputChange={
                                  advanceFilerInputChange
                                }
                                cancelAdvanceSearch={cancelAdvanceSearch}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/evaluations/:id"
                          render={(props) => {
                            return (
                              <EvaluationIdea
                                {...props}
                                selectIdea={selectIdea}
                                ideaList={ideaList}
                                handleDragAndDropFiles={handleDragAndDropFiles}
                                handleONGDragAndDropFiles={
                                  handleONGDragAndDropFiles
                                }
                                handleDragAndDropSummaryFiles={
                                  handleDragAndDropSummaryFiles
                                }
                                handleDragAndDropMembersFiles={
                                  handleDragAndDropMembersFiles
                                }
                                handleBusinessDragAndDropFiles={
                                  handleBusinessDragAndDropFiles
                                }
                                handleDragAndDropFinalPresentationFiles={
                                  handleDragAndDropFinalPresentationFiles
                                }
                                files={files}
                                ideaForEdition={ideaForEdition}
                                removeDocumentsfromList={
                                  removeDocumentsfromList
                                }
                                openBase64File={openBase64File}
                                userSession={userSession}
                                addMemberService={addMemberService}
                                addFinalPresentationUrlService={
                                  addFinalPresentationUrlService
                                }
                                deleteMemberService={deleteMemberService}
                                validateEmailFormat={validateEmailFormat}
                                validatePhoneNumberFormat={
                                  validatePhoneNumberFormat
                                }
                                validateNumericFormat={validateNumericFormat}
                                validateIfLegal={validateIfLegal}
                                currentContestStage={currentContestStage}
                                blockedLogin={blockedLogin}
                                stagesDropdowns={stagesDropdowns}
                                handleStageDropDown={handleStageDropDown}
                                selectAwardService={selectAwardService}
                                handleAwardOnChange={handleAwardOnChange}
                                getDocumentsByCriteriaPrincipal={getDocumentsByCriteriaPrincipal}
                                evaluationcriteria={evaluationcriteria}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/idea-management"
                          render={(props) => {
                            return (
                              <IdeaManagement
                                {...props}
                                selectIdea={selectIdea}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/evaluators-management"
                          render={(props) => {
                            return (
                              <EvaluatorsManagement
                                {...props}
                                selectIdea={selectIdea}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/idea-distribution"
                          render={(props) => {
                            return <Distribution {...props} />;
                          }}
                        />
                        <Route
                          path="/home/admin/company-management"
                          render={(props) => {
                            return (
                              <CompanyManagement
                                {...props}
                                currentContestStage={currentContestStage}
                                updateStageFunction={updateStageFunction}
                                handleStageChange={handleStageChange}
                                closeContestFunction={closeContestFunction}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/user-management"
                          render={(props) => {
                            return (
                              <UserManagement
                                {...props}
                                currentContestStage={currentContestStage}
                                updateStageFunction={updateStageFunction}
                                handleStageChange={handleStageChange}
                                closeContestFunction={closeContestFunction}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/preferences"
                          render={(props) => {
                            return (
                              <Preferences
                                {...props}
                                currentContestStage={currentContestStage}
                                updateStageFunction={updateStageFunction}
                                handleStageChange={handleStageChange}
                                closeContestFunction={closeContestFunction}
                                evaluationcriteria={evaluationcriteria}
                                handleDragAndDropFilesEvaluationCriterias={handleDragAndDropFilesEvaluationCriterias}
                                removeDocumentsfromList={removeDocumentsfromList}
                                openBase64File={openBase64File}
                                selectCriteriaDocument={selectCriteriaDocument}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/change-password"
                          render={(props) => {
                            return (
                              <PasswordChangeForm
                                {...props}
                                passwordForminputChange={
                                  passwordForminputChange
                                }
                                passwordShown={passwordShown}
                                setPasswordShownValue={setPasswordShownValue}
                                updatePasswordFunction={updatePasswordFunction}
                                history={props.history}
                                passwordUpdateForm={passwordUpdateForm}
                              />
                            );
                          }}
                        />
                        <Route
                          path="/home/admin/special-mention"
                          render={(props) => {
                            return (
                              <SpecialMention
                                {...props}
                                selectIdea={selectIdea}
                              />
                            );
                          }}
                        />{' '}
                        <Route
                          path="/home/admin/reports"
                          render={(props) => {
                            return <Reports {...props} />;
                          }}
                        />
                        <Route
                          path="/home/admin"
                          render={(props) => {
                            return (
                              <NewDashBoardAdmin
                                {...props}
                                ideasParticipant={ideasParticipant}
                                ideasRegistered={ideasRegistered}
                                usersParticipant={usersParticipant}
                                stagesDropdowns={stagesDropdowns}
                                ideasFinalist={ideasFinalist}
                                documentActives={documentActives}
                                documentActivesPresentation={
                                  documentActivesPresentation
                                }
                                ideasWinner={ideasWinner}
                                ideasSemifinalist={ideasSemifinalist}
                              />
                            );
                          }}
                        />
                      </Switch>
                    </div>
                  </React.Fragment>
                );
              } else {
                return <Redirect to="/login" />;
              }
            }}
          />
          <Route>
            <Redirect props={props} to="/consulta" />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
};
