import React from "react";
import Loader from "react-loader-spinner";
import "./LoadSpinner.scss";

// Load spinner that blocks the page while submitting
export const LoadSpinner = (props) => {
  const { blocked, text, color } = props;

  if (blocked === true) {
    return (
      <div className="load-spinner-container">
        <Loader
          type="Oval"
          color={color}
          height={120}
          width={120}
          timeout={0} //3 secs
        />
        <h4 style={{ color: color }}>{text}</h4>
      </div>
    );
  } else {
    return null;
  }
};
