import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { documentType } from '../../../../../helpers/types';
import './TermsModal.scss';

export const TermsModal = (props) => {
  const { isOpen, closeModal, onChange, document, clear, save } = props;

  let title = '';

  if (document.documentType === documentType.confidenciality) {
    title = 'Acuerdo de Confidencialidad';
  }

  if (document.documentType === documentType.termsAndConditions) {
    title = 'Términos y Condiciones';
  }

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="terms-and-condintions-text-area"
          onChange={onChange}
          name="description"
          value={document.description}
        ></textarea>
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={() => clear()}>
          Limpiar
        </Button>
        <Button variant="primary" onClick={() => save()}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
