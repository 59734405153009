import http from './httpServices';

export async function getUserData(sessionToken, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/` + id;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of users
export async function getUserType(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/usertype`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function getGovType(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/govtype`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Function that POST data form for creating users
export async function createUser(data) {
  let url = process.env.REACT_APP_API_URL;

  url = url + `/user/createUser`;

  try {
    const result = await http.post(url, data);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Function that POST data form for creating users
export async function updateUser(datos, sessionToken, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/${id}`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, datos, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function getRegisteredUser(sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/users-participant`;

  const config = {
    headers: {
      Authorization: `${sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function getAllUsers(params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/users?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getUsersReport(params) {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }

  let url = process.env.REACT_APP_API_URL;
  url = url + `/userReport?` + new URLSearchParams(params);

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of government account
export async function createNewUser(data) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/createUser`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function editUser(data, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function deleteUser(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/${id}`;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.delete(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getUserById(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/` + id;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.get(url, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function editUserFromAdmin(data, id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/` + id;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.put(url, data, config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function sendJudgeInvitation(id) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/judge-invitation/` + id;

  let userSession = sessionStorage.getItem('userSession');
  userSession = JSON.parse(userSession);

  const config = {
    headers: {
      Authorization: `${userSession.sessionToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await http.post(url, {}, config);
    return result;
  } catch (error) {
    return error.response;
  }
}
